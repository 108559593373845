import {
    date,
    findColumn,
    findColumns,
    FormattedStreamData,
    FormattedStreamValue,
    FoundColumn,
    number,
    percent,
    preferred,
    required,
    state,
    StreamDataColumn,
    string,
    VisualisationHint
} from '@squaredup/data-streams';
import { Result } from '@squaredup/utilities';
import { visualisationOptionsRepo } from 'dashboard-engine/repositories/visualisationsRepo';
import { DataStreamLineGraphConfig } from 'dashboard-engine/visualisations/DataStreamLineGraph/Config';

export type VisualisationOption =
    | {
          name: VisualisationHint;
          isValid: false;
          failure: string | false;
      }
    | {
          name: VisualisationHint;
          isValid: true;
      };

export const getLinegraphColumns = (
    columns: StreamDataColumn[],
    rows: FormattedStreamValue[][],
    config: DataStreamLineGraphConfig = {}
): Result<{
    timestampColumn: FoundColumn;
    valueColumns: FoundColumn[];
    labelColumnsResult: Result<FoundColumn[]>;
    unitLabelColumnResult: Result<FoundColumn>;
}> => {
    if (rows.length === 0) {
        return Result.fail('No rows of data');
    }

    let timestampColumn = findColumn(columns, required('name', config?.xAxisColumn ?? ''));
    if (timestampColumn.failed) {
        timestampColumn = findColumn(columns, required('shapeName', date.name), preferred('role', 'timestamp'));
    }

    const valueColumns = findColumns(columns, required('valueShapeName', number.name), preferred('role', 'value'));
    const labelColumnsResult = findColumns(
        columns,
        preferred('role', 'label'),
        preferred('valueShapeName', string.name),
        preferred.not('role', 'value'),
        preferred.not('valueShapeName', number.name),
        preferred.not('shapeName', state.name),
        preferred.not('shapeName', date.name)
    );
    const unitLabelColumnResult = findColumn(
        columns,
        required('valueShapeName', string.name),
        required('role', 'unitLabel')
    );

    if (timestampColumn.failed) {
        return Result.fail(`Couldn't find a timestamp column: 
${timestampColumn.reason}`);
    }

    if (valueColumns.failed) {
        return Result.fail(`Couldn't find any value columns: 
${valueColumns.reason}`);
    }

    return Result.success({
        timestampColumn: timestampColumn.value,
        valueColumns: valueColumns.value,
        labelColumnsResult: labelColumnsResult.map((cols) => {
            if (cols.some((c) => c.column.role === 'label')) {
                return cols.filter((c) => c.column.role === 'label');
            }

            return [cols[0]];
        }),
        unitLabelColumnResult
    });
};

export const getScalarValueColumn = (columns: StreamDataColumn[]): Result<FoundColumn> =>
    findColumn(columns, preferred('valueShapeName', number.name), preferred('role', 'value'));

export const getStateColumn = (columns: StreamDataColumn[]): Result<FoundColumn> =>
    findColumn(columns, required('shapeName', state.name)); // 'shapeName' not 'valueShapeName' for a state column

export const getStateColumns = (
    columns: StreamDataColumn[]
): Result<{
    labelColumn: FoundColumn;
    stateColumn: FoundColumn;
}> => {
    const labelColumn = findColumn(
        columns,
        preferred('valueShapeName', string.name),
        preferred.not('shapeName', state.name),
        preferred.not('shapeName', date.name),
        preferred('role', 'label')
    );
    const stateColumn = findColumn(columns, required('shapeName', state.name));

    if (labelColumn.failed) {
        return Result.fail(`Couldn't find a label column: ${labelColumn.reason}`);
    }

    if (stateColumn.failed) {
        return Result.fail(`Couldn't find a state column: ${stateColumn.reason}`);
    }

    return Result.success({
        labelColumn: labelColumn.value,
        stateColumn: stateColumn.value
    });
};

export const defaultVisualisations: VisualisationHint[] = ['data-stream-table', 'data-stream-scalar'];

/**
 * Determines whether a given column has a unique value for every row
 */
const hasUniqueValues = (rows: FormattedStreamValue[][], columnIndex: number) => {
    const seenValues = new Set<string>();

    const isUnique = rows.every((r) => {
        if (seenValues.has(r[columnIndex].formatted)) {
            return false;
        }

        seenValues.add(r[columnIndex].formatted);

        return true;
    });

    return isUnique;
};

export const matchVisualisations = (
    data: FormattedStreamData,
    config?: Record<string, unknown>,
    embedVisualisationFlagEnabled?: boolean
): VisualisationHint[] => {
    const columns = data?.metadata?.columns ?? [];
    const rows = data?.rows ?? [];

    let visualisations = [
        'data-stream-scalar',
        'data-stream-blocks',
        'data-stream-table',
        'data-stream-iframe',
        'data-stream-donut-chart',
        'data-stream-bar-chart',
        'data-stream-line-graph'
    ]
        .reverse()
        .filter((name) => (name === 'data-stream-iframe' ? embedVisualisationFlagEnabled : true))
        .filter((name) => visualisationOptionsRepo.get(name).matchesData(data, config).success);

    // Table is prioritized if there is a single row and >1 column
    if (rows.length === 1 && columns.length > 1) {
        visualisations = ['data-stream-table', ...visualisations.filter((vis) => vis !== 'data-stream-table')];
    }
    // Scalar is prioritized if there is just a single number (1 row, 1 cell)
    if (rows.length === 1 && columns.length === 1 && columns[0].shapeName === number.name) {
        visualisations = ['data-stream-scalar', ...visualisations.filter((vis) => vis !== 'data-stream-scalar')];
    }

    // Blocks is prioritized if there are valid state/label columns
    if (getStateColumns(columns).succeeded && visualisations.includes('data-stream-blocks')) {
        visualisations = ['data-stream-blocks', ...visualisations.filter((vis) => vis !== 'data-stream-blocks')];
    }

    // Gauge is prioritized if there is just a single percentage (1 row, 1 cell, shape percent)
    if (rows.length === 1 && columns.length === 1 && columns[0].shapeName === percent.name) {
        visualisations = ['data-stream-gauge', ...visualisations];
    } else {
        //Add gauge to the list but with low precedence.
        visualisations = [...visualisations, 'data-stream-gauge'];
    }

    // If there is a state column and a time column, we prioritize table, because we don't yet have
    // a good visualisation for showing state changes over time.
    if (getStateColumns(columns).succeeded && getLinegraphColumns(columns, rows).succeeded) {
        visualisations = ['data-stream-table', ...visualisations.filter((vis) => vis !== 'data-stream-table')];
    }

    return visualisations as VisualisationHint[];
};
