import { getWorkspaceIdFromConfigId } from '@squaredup/ids';
import type { HealthState as HealthStateRecord } from '@squaredup/monitoring';
import stringify from 'fast-json-stable-stringify';
import { groupBy, orderBy } from 'lodash';
import { useQuery } from 'react-query';
import { ListWorkspaceHealthByIds, TileState } from 'services/HealthService';

const WORKSPACE_HEALTH_DATA = 'WORKSPACE_HEALTH_DATA';

const stateValues = {
    unknown: 0,
    success: 1,
    warning: 2,
    error: 3
};

export type WorkspaceHealthData = Map<
    string,
    {
        state: HealthStateRecord;
        tileStates: TileState[];
    }
>;

const getWorkspaceHealthWithTileStatesLookup = async (workspacesToLookup: string[]): Promise<WorkspaceHealthData> => {
    if (!workspacesToLookup.length) {
        return new Map();
    }

    // Lookup workspace health states
    const includeTileStates = true,
        includeDashboardNames = true;
    const { workspaceStates, tileStates } = await ListWorkspaceHealthByIds(
        workspacesToLookup,
        includeTileStates,
        includeDashboardNames
    );

    const tilesGroupedByWorkspaceId = groupBy(
        tileStates?.filter(({ state }) => state !== 'unknown'),
        ({ configId }) => getWorkspaceIdFromConfigId(configId)
    );
    const workspaceTileStateLookup = new Map(Object.entries(tilesGroupedByWorkspaceId));

    return new Map(
        workspaceStates.map(({ workspaceId, state }) => {
            const workspaceTileStates = workspaceTileStateLookup.get(workspaceId);
            const workspaceTilesSortedByState = orderBy(
                workspaceTileStates,
                [({ state: tileState }) => stateValues[tileState as keyof typeof stateValues], 'name'],
                'desc'
            );

            return [
                workspaceId,
                {
                    state: state as HealthStateRecord,
                    tileStates: workspaceTilesSortedByState.map((tileState) => ({
                        ...tileState,
                        dashboardName: tileState.dashboardName
                    }))
                }
            ];
        })
    );
};

export const useWorkspaceHealthData = (data: string[]) =>
    useQuery([WORKSPACE_HEALTH_DATA, stringify(data)], () => getWorkspaceHealthWithTileStatesLookup(data), {
        keepPreviousData: true
    });
