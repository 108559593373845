import { Toggle, ToggleProps } from '@/components/forms/Toggle';
import { cn } from '@/lib/cn';
import { ToggleStyleWrapper } from 'components/ToggleStyleWrapper';

interface ToggleParams extends Omit<ToggleProps, 'onChange'> {
    name: string;
    label: string;
    wrapperClassName?: string;
    help?: string;
    onChange: (choice: boolean) => void;
};

export const OptionToggle = ({ 
    name, 
    label, 
    help,  
    wrapperClassName, 
    checked, 
    onChange, 
    ...props 
}: ToggleParams) => {

    return (
        <ToggleStyleWrapper 
            name={name}
            label={label}
            help={help}
            wrapperClassName={cn('bg-tagBackground', wrapperClassName)}
            disabled={props.disabled}
        >
            <Toggle 
                name={name}
                checked={checked} 
                onCheckedChange={(value) => onChange(value)} 
                {...props} 
            />
        </ToggleStyleWrapper>        
    );
};
