import { fiveMinutes } from 'queries/constants';
import { perspectiveQueryKeys } from 'queries/queryKeys/perspectiveKeys';
import { sortPerspectives } from 'queries/utils/sortPerspectives';
import { useQuery } from 'react-query';
import { GetPerspectivesForNode } from 'services/PerspectiveService';
import { useWorkspaces } from './useWorkspaces';

export const usePerspectives = (nodeId?: string) => {
    const { data: workspaces, isLoading: isLoadingWorkspaces } = useWorkspaces();

    const { data: perspectives, isLoading: isLoadingPerspectives } = useQuery(
        perspectiveQueryKeys.detail(nodeId),
        () => GetPerspectivesForNode(String(nodeId)),
        {
            staleTime: fiveMinutes,
            cacheTime: fiveMinutes,
            enabled: Boolean(nodeId) && nodeId?.startsWith('node-'),
            select: (perspectivesData) => perspectivesData.perspectives
        }
    );

    return {
        isLoading: isLoadingWorkspaces || isLoadingPerspectives,
        perspectives: workspaces && perspectives ? sortPerspectives(workspaces, perspectives) : []
    };
};
