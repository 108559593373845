import { Serialised, SqUpPluginDisplayName, SqUpPluginId } from '@squaredup/ids';
import Button from 'components/button/Button';
import type { ProjectedDataStreamDefinitionEntity } from 'dynamo-wrapper';
import _ from 'lodash';
import { useDatasourceConfigs } from 'queries/hooks/useDatasourceConfigs';
import { useState } from 'react';
import { useQuery } from 'react-query';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { CUSTOM_DATA_STREAM_DEFINITIONS, ListCustomDataStreams } from '../../../services/DataStreamDefinitionService';
import { SettingsTemplate } from '../SettingsTemplate';
import { DataStreamDefinitionAddEditModal } from './DataStreamDefinitionAddEditModal';
import { DataStreamDefinitionDeleteModal } from './DataStreamDefinitionDeleteModal';
import DataStreamDefinitionsTable from './DataStreamDefinitionsTable';
import { injectSqUpPluginSource } from 'utilities/injectSqUpDataSource';

export interface CustomDataStreamProjectedRow extends Serialised<ProjectedDataStreamDefinitionEntity> {
    pluginName: string;
}

const useDataStreamsWithEntryPoints = () => {
    const { data: customDataStreams, isLoading } = useQuery(
        [CUSTOM_DATA_STREAM_DEFINITIONS],
        ListCustomDataStreams,
        {
            cacheTime: 60_000
        }
    );
	const { data: sources, isLoading: isLoadingSources } = useDatasourceConfigs(
        {
            select: injectSqUpPluginSource,
            cacheTime: Number.POSITIVE_INFINITY,
            staleTime: Number.POSITIVE_INFINITY
        }
    );    
    const customDataStreamRows: CustomDataStreamProjectedRow[] | undefined = customDataStreams?.map(stream => {
        return {
            ...stream,
            pluginName: sources?.find(p => p.plugin?.pluginId === stream.pluginId)?.plugin?.displayName || 'Unknown'
        };
    });

    return {
        isLoading: isLoading || isLoadingSources,
        data: customDataStreamRows
    };
};

const useDataSourceKeyValueList = () => {
    const { data: configItems, isLoading } = useDatasourceConfigs({ cacheTime: 60_000 });
    const plugins = configItems
        ?.filter((p: object) => Object.prototype.hasOwnProperty.call(p, 'plugin'))
        .map((p: { plugin?: { displayName?: string; pluginId?: string; onPrem?:boolean } }) => ({
            label: p?.plugin?.displayName + (p?.plugin?.onPrem ? ' (On-Prem)' : '') ?? p?.plugin?.pluginId,
            value: p?.plugin?.pluginId
        }));
    plugins?.push({
        label: SqUpPluginDisplayName,
        value: SqUpPluginId.value
    });
    const pluginItems = _.uniqBy(_.orderBy(plugins, 'label'), 'value');
    return {
        dataSourcesList: pluginItems,
        isLoading: isLoading
    };
};

/**
 * Renders the data stream definitions page
 */
const DataStreamDefinitions = () => {
    const { data: customDataStreams, isLoading } = useDataStreamsWithEntryPoints();
    const { dataSourcesList, isLoading: isDataSourcesListLoading } = useDataSourceKeyValueList();

    // eslint-disable-next-line max-len
    const [dataStreamDefinitionBeingDeleted, setDataStreamDefinitionBeingDeleted] =
        useState<CustomDataStreamProjectedRow>();
    // eslint-disable-next-line max-len
    const [dataStreamDefinitionBeingEdited, setDataStreamDefinitionBeingEdited] =
        useState<CustomDataStreamProjectedRow>();
    const [dataStreamDefinitionEditorOpen, setDataStreamDefinitionEditorOpen] = useState(false);

    return (
        <SettingsTemplate
            title='Custom Data Streams'
            description='Custom data streams are used to customize existing data streams or write new ones.'
            learnMoreLink='https://docs.squaredup.com/advanced-features/custom-data-streams'
        >
            {(isLoading || isDataSourcesListLoading) && (
                <span className='flex justify-center'>
                    <LoadingSpinner />
                </span>
            )}
            {!isLoading && !isDataSourcesListLoading && (
                <div className='flex flex-col flex-1 min-h-0'>
                    <div>
                        <Button onClick={() => setDataStreamDefinitionEditorOpen(true)}>Add custom data stream</Button>
                    </div>

                    <div className='flex flex-col min-h-0 mt-4 mb-8'>
                        <DataStreamDefinitionsTable
                            dataStreamDefinitions={customDataStreams || []}
                            onEdit={(dataStreamDefinition) => {
                                setDataStreamDefinitionBeingEdited(dataStreamDefinition);
                                setDataStreamDefinitionEditorOpen(true);
                            }}
                            onDelete={(dataStreamDefinition) =>
                                setDataStreamDefinitionBeingDeleted(dataStreamDefinition)
                            }
                        />
                    </div>
                </div>
            )}

            {dataStreamDefinitionEditorOpen && (
                <DataStreamDefinitionAddEditModal
                    onSave={() => setDataStreamDefinitionBeingEdited(undefined)}
                    onClose={() => {
                        setDataStreamDefinitionBeingEdited(undefined);
                        setDataStreamDefinitionEditorOpen(false);
                    }}
                    stream={dataStreamDefinitionBeingEdited}
                    dataSources={dataSourcesList}
                    key='custom-correlations-add-edit'
                />
            )}

            {dataStreamDefinitionBeingDeleted && (
                <DataStreamDefinitionDeleteModal
                    stream={dataStreamDefinitionBeingDeleted}
                    onClose={() => setDataStreamDefinitionBeingDeleted(undefined)}
                />
            )}
        </SettingsTemplate>
    );
};

export default DataStreamDefinitions;
