import stringify from 'fast-json-stable-stringify';
import { WorkspaceWithDependencies } from 'pages/monitoring/common';
import { useDatasourceConfigsForAdministration } from 'queries/hooks/useDatasourceConfigsForAdministration';
import { useWorkspacesForAdministration } from 'queries/hooks/useWorkspaceForAdministration';
import { useQuery } from 'react-query';

const WORKSPACE_DATASOURCE_DETAILS = 'WORKSPACE_DATASOURCE_DETAILS';

export const useDatasourceDetails = (workspacesWithDependencies: WorkspaceWithDependencies[]) => {
    const { data: workspaces } = useWorkspacesForAdministration();
    const { data: datasourceConfigs } = useDatasourceConfigsForAdministration();

    return useQuery(
        [WORKSPACE_DATASOURCE_DETAILS, stringify(workspacesWithDependencies)],
        async () => {
            const workspacePlugins: Map<string, { id: string; name?: string; displayName?: string }[]> = new Map();
            workspacesWithDependencies.forEach((w) => {
                const fullWorkspace = workspaces?.find((fw: any) => fw.data.id === w.id);
                const linkedDatasources = datasourceConfigs
                    ?.filter((p) => (fullWorkspace?.data?.links?.plugins ?? []).includes(p.id))
                    .map((p) => ({
                        id: p.id,
                        displayName: p.displayName?.toString(),
                        name: p.plugin?.name
                    }));

                workspacePlugins.set(w.id, linkedDatasources ?? []);
            });

            return workspacePlugins;
        },
        {
            enabled: Boolean(workspacesWithDependencies) && Boolean(workspaces) && Boolean(datasourceConfigs),
            keepPreviousData: true
        }
    );
};
