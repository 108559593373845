import { sqlDataSource } from '@squaredup/data-streams';
import { useDashboardContext } from 'contexts/DashboardContext';
import { streamDataKeys } from 'queries/queryKeys/streamDataKeys';
import { useIsFetching } from 'react-query';
import { useTileEditorContext } from '../../contexts/TileEditorContext';

export const useIsFetchingSQLData = (sqlQuery: string) => {
    const { tileConfig } = useTileEditorContext();
    const { timeframe: dashboardTimeframe } = useDashboardContext();

    return (
        useIsFetching({
            queryKey: streamDataKeys.forRequest({
                dataStreamId: `datastream-${sqlDataSource.name}`,
                pluginConfigId: tileConfig.dataStream?.pluginConfigId,
                timeframe: tileConfig.timeframe || dashboardTimeframe,
                dataSourceConfig: {
                    ...tileConfig.dataStream?.dataSourceConfig,
                    sql: sqlQuery
                }
            })
        }) > 0
    );
};
