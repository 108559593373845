import { Button } from '@/components/Button';
import { Text } from '@/components/forms/Text';
import { zodResolver } from '@hookform/resolvers/zod';
import LoadingSpinner from 'components/LoadingSpinner';
import { ModalButtons } from 'components/Modal';
import Field from 'components/forms/field/Field';
import { useDashboardContext } from 'contexts/DashboardContext';
import { DashboardVariable } from 'dashboard-engine/types/Dashboard';
import { useHandleSave } from 'pages/dashboard/hooks/useHandleSave';
import { CreateEditScope } from 'pages/scope/CreateEditScope';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { perspectiveQueryKeys } from 'queries/queryKeys/perspectiveKeys';
import { scopeQueryKeys } from 'queries/queryKeys/scopeKeys';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { AddVariable, UpdateVariable } from 'services/VariableService';
import { useCurrentWorkspaceId } from 'services/WorkspaceUtil';
import { DashboardVariableDefaultSelection } from './DashboardVariableDefaultSelection';
import { DashboardVariableObjectVariableFields } from './DashboardVariableObjectVariableFields';
import { VariableFormData, variableFormSchema } from './utils/getVariableFromFormData';

interface DashboardVariableFormProps {
    existingVariable?: DashboardVariable;
    onClose: () => void;
}
export const DashboardVariableForm: React.FC<DashboardVariableFormProps> = ({ existingVariable, onClose }) => {
    const { dashboard, setVariables } = useDashboardContext();
    const queryClient = useQueryClient();

    const [isScopeModalOpen, setIsScopeModalOpen] = useState(false);

    const formProps = useForm<VariableFormData>({
        defaultValues: {
            name: existingVariable?.name || '',
            scope: existingVariable?.scopeId || undefined,
            type: 'object',
            default: existingVariable?.default || 'all',
            allowMultipleSelection: existingVariable?.allowMultipleSelection || false
        },
        mode: 'all',
        reValidateMode: 'onChange',
        resolver: zodResolver(variableFormSchema)
    });

    const {
        handleSubmit,
        register,
        setValue,
        watch,
        formState: { isValid, isSubmitting }
    } = formProps;

    const { mutate: updatedDashboard } = useHandleSave();

    const workspaceId = useCurrentWorkspaceId();

    const currentValues = watch();

    const onSubmit = async (data: VariableFormData) => {
        let id = existingVariable?.id || '';

        if (existingVariable) {
            await UpdateVariable(existingVariable.id, {
                ...data,
                dashboardId: dashboard.id
            });
        } else {
            const newVariable = await AddVariable(workspaceId, {
                ...data,
                dashboardId: dashboard.id
            });
            id = newVariable.id;
        }

        const variables = [
            {
                id,
                name: data.name,
                scopeId: data.scope,
                selectedAll: data.default === 'all',
                selectedObjects: [],
                default: data.default,
                allowMultipleSelection: data.default === 'all' ? false : data.allowMultipleSelection
            }
        ];

        queryClient.setQueryData(dashboardQueryKeys.variables(dashboard.id), variables);
        queryClient.invalidateQueries(perspectiveQueryKeys.all);

        setVariables?.(variables);

        updatedDashboard({
            ...dashboard,
            // Currently only one variable per dashboard
            variables: variables.map((v) => v.id)
        });

        onClose();
    };

    return (
        <>
            <FormProvider {...formProps}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex flex-col px-8 space-y-4 mt-7'>
                        <Field htmlFor='name' label='Name'>
                            <Text id='name' placeholder='e.g. Pipeline' {...register('name', { required: true })} />
                        </Field>

                        <DashboardVariableObjectVariableFields setIsScopeModalOpen={setIsScopeModalOpen} />
                        <DashboardVariableDefaultSelection />
                    </div>

                    <ModalButtons>
                        <Button variant='tertiary' type='button' disabled={isSubmitting} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button type='submit' disabled={isSubmitting || !isValid}>
                            {isSubmitting && <LoadingSpinner size={18} className='mr-3' />}
                            {existingVariable ? 'Update' : 'Add'}
                        </Button>
                    </ModalButtons>
                </form>
            </FormProvider>
            {isScopeModalOpen && (
                <CreateEditScope
                    defaultName={currentValues.name}
                    onClose={(modifiedScope) => {
                        if (modifiedScope) {
                            setValue('scope', modifiedScope.id, { shouldValidate: true });
                            queryClient.invalidateQueries(scopeQueryKeys.workspace(workspaceId));
                        }

                        setIsScopeModalOpen(false);
                    }}
                />
            )}
        </>
    );
};
