import { cn } from '@/lib/cn';
import { stateStrings } from '@squaredup/monitoring';
import { useNetworkBackground } from 'components/map/context/NetworkMapStoreContext';
import { PinnableNodeData } from 'components/map/data/types';
import { useStoreHealthStateNode } from 'components/map/hooks/useStoreHealthStateNode';
import { TruncatedText } from 'components/TruncatedText';
import { WorkspaceStateIndicator } from 'components/ui/state/WorkspaceStateIndicator';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { useWorkspaceAvatar } from 'queries/hooks/useWorkspaceAvatar';
import { useWorkspaceHealthStates } from 'queries/hooks/useWorkspaceHealthStates';
import { memo } from 'react';
import { Handle, Position } from 'reactflow';
import { useHover } from 'ui/hooks/useHover';
import { NodeToolbar } from '../../nodeToolbar/NodeToolbar';
import { WORKSPACE_NODE_SIZE } from '../WorkspaceNode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { workspacePredefinedIconKey } from 'lib/fontawesome/fontawesome';

const healthStateStyles = {
    [stateStrings.error]: 'stroke-statusErrorPrimary',
    [stateStrings.warning]: 'stroke-statusWarningPrimary',
    [stateStrings.success]: 'stroke-statusHealthyPrimary',
    [stateStrings.unknown]: 'stroke-statusUnknownPrimary'
};

interface WorkspaceNodeInternalProps extends PinnableNodeData {
    id: string;
}

export const WorkspaceNodeInternal: React.FC<WorkspaceNodeInternalProps> = memo(
    ({ id, label, pinned, expanded, workspaceId, hiddenConnections, fixedPosition }) => {
        const background = useNetworkBackground();
        const { isHovered, ...hoverProps } = useHover();

        const { data: workspaceHealthStates = [] } = useWorkspaceHealthStates();

        const healthState = workspaceHealthStates.find(
            ({ id: workspaceHealthStateId }) => workspaceId === workspaceHealthStateId
        )?.state;

        useStoreHealthStateNode(id, healthState);

        return (
            <div
                className={cn(
                    'inline-flex items-center justify-center relative rounded-full',
                    healthState && `bg-${background}`
                )}
                style={{ width: WORKSPACE_NODE_SIZE, height: WORKSPACE_NODE_SIZE }}
                {...hoverProps}
            >
                <NodeToolbar
                    id={id}
                    isVisible={isHovered}
                    isPinned={pinned}
                    isFixed={fixedPosition}
                    isExpanded={expanded}
                    label={label}
                    hiddenConnections={hiddenConnections}
                    url={`/workspace/${workspaceId}`}
                />

                <svg viewBox='0 0 100 100' className={cn(`absolute inset-0 w-full fill-${background}`)}>
                    <circle
                        cx='50%'
                        cy='50%'
                        r='40'
                        vectorEffect='non-scaling-stroke'
                        strokeWidth={5}
                        className={cn(
                            `fill-${background} stroke-filterActive`,
                            healthState && healthState !== stateStrings.unknown && healthStateStyles[healthState]
                        )}
                        {...(!pinned && { strokeDasharray: '2 2' })}
                    />
                </svg>

                <WorkspaceAvatar workspaceId={workspaceId} label={label} />

                <Handle
                    type='target'
                    position={Position.Top}
                    className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
                />
                <Handle
                    type='source'
                    position={Position.Bottom}
                    className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
                />

                {label && (
                    <>
                        <span className='sr-only'>{label}</span>
                        <div className='text-primary rounded-sm text-[8px] font-bold bg-backgroundSecondary px-1 py-0.5 leading-tight absolute top-[calc(100%+4px)] max-w-[160px] left-1/2 -translate-x-1/2 text-center'>
                            <TruncatedText title={label}>{label}</TruncatedText>
                        </div>
                    </>
                )}
            </div>
        );
    }
);

const WorkspaceAvatar = (props: Pick<WorkspaceNodeInternalProps, 'workspaceId' | 'label'>) => {
    const { data: workspace } = useWorkspace(props.workspaceId);
    const { data: avatar } = useWorkspaceAvatar({
        id: props.workspaceId ?? '',
        enabled: !workspace?.data?.properties?.avatarIconName
    });

    if (!avatar && !workspace?.data?.properties?.avatarIconName) {
        return <WorkspaceStateIndicator id={props.workspaceId ?? ''} size={46} className='relative z-10' />;
    }

    if (workspace?.data?.properties?.avatarIconName) {
        return (
            <FontAwesomeIcon
                className='text-textPrimary w-[30px] h-[30px] z-10'
                icon={workspace.data.properties.avatarIconName.replace(workspacePredefinedIconKey, '') as IconProp}
            />
        );
    }

    if (avatar && avatar?.startsWith(workspacePredefinedIconKey)) {
        return (
            <FontAwesomeIcon
                className='text-textPrimary w-[30px] h-[30px] z-10'
                icon={avatar.replace(workspacePredefinedIconKey, '') as IconProp}
            />
        );
    }

    return (
        <img
            src={avatar}
            alt={`${props.label} avatar`}
            className={
                'w-[46px] h-[46px] object-contain left-[12px] top-[12px] rounded-full overflow-hidden z-10 bg-white'
            }
        />
    );
};
