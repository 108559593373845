import type { DataStreamBaseTileConfig,DataStreamScope } from '@squaredup/data-streams';
import { buildQuery,type Node } from '@squaredup/graph';
import { ConfigId,SqUpPluginConfigId } from '@squaredup/ids';
import { baseDataStreamConfig } from 'dashboard-engine/constants';
import type { TileConfig } from 'dashboard-engine/types/Tile';
import { useEffect,useState,type Dispatch,type SetStateAction } from 'react';
import { useQuery } from 'react-query';
import { Query } from '../../../../../services/GraphService';

const isUnscopedGlobalDataStreamTile = (tileConfig: TileConfig) => {
    return !('scope' in tileConfig) || (tileConfig.scope == null && tileConfig.dataStream?.pluginConfigId != null);
};

const findDataSourceNodes = async (pluginConfigIds: `config-${string}`[]): Promise<Node[]> => {
    const dataSourceNodeQuery = buildQuery(
        { properties: { sourceType: ['squaredup/data-source'], __configId: pluginConfigIds } },
        '.valueMap(true)'
    );

    return (await Query(dataSourceNodeQuery)).gremlinQueryResults;
};

const getDataSourceNodeScope = (dataSourceNode: Node) => {
    return {
        query: "g.V().has('id', within(ids_defaultScopeIds))",
        bindings: {
            // eslint-disable-next-line camelcase
            ids_defaultScopeIds: [dataSourceNode.id]
        },
        queryDetail: {
            ids: [dataSourceNode.id],
            types: [{ value: 'data-source' }]
        }
    } as DataStreamScope;
};

const lookupNodeIdForConfig = async (id: ConfigId['value']): Promise<DataStreamScope> => {
    const [dataSourceNode] = await findDataSourceNodes([id]);

    if (dataSourceNode == null) {
        throw Error('Could not find data source node for global query');
    }

    const scope = await getDataSourceNodeScope(dataSourceNode);

    return scope;
};

const isMigrationRequired = (tileConfig: DataStreamBaseTileConfig) =>
    isUnscopedGlobalDataStreamTile(tileConfig) &&
    tileConfig?.dataStream?.pluginConfigId != null &&
    tileConfig?.dataStream?.pluginConfigId !== SqUpPluginConfigId.value;

type MigrationResult = {
    isMigrationRequired: boolean;
    isLoading: boolean;
    data?: DataStreamBaseTileConfig;
}

/**
 * Generates a default scope object for tiles using formerly global data streams that
 * now require a data source scope.
 */
export const useMigratedUnscopedGlobalDataStreamTile = (tileConfig: DataStreamBaseTileConfig): MigrationResult => {
    const tileConfigRequiresMigration = isMigrationRequired(tileConfig);
    const sqlDataStreamRequiresMigration = Boolean(
        tileConfig.dataStream?.id === 'datastream-sql' && tileConfig.dataStream?.dataSourceConfig?.tables?.some(t => isMigrationRequired(t?.config as DataStreamBaseTileConfig))
    );
    
    const migrationRequired = tileConfigRequiresMigration || sqlDataStreamRequiresMigration;

    // No query key as not caching
    const query = useQuery([], async() => {
        // Handle main data stream
        const id = tileConfig.dataStream?.pluginConfigId;
        if(id && tileConfigRequiresMigration) {
            const scope = await lookupNodeIdForConfig(id);
            return {
                ...tileConfig,
                scope
            };
        }

        // Handle SQL analytics tables
        if(sqlDataStreamRequiresMigration && tileConfig.dataStream?.dataSourceConfig?.tables) {
            const updated = await Promise.all(tileConfig.dataStream.dataSourceConfig.tables.map(async table => {
                const tablePluginConfigId = table?.config?.dataStream?.pluginConfigId;
                if(table && tablePluginConfigId && isMigrationRequired(table.config as DataStreamBaseTileConfig)) { // only migrated if required
                    const scope = await lookupNodeIdForConfig(tablePluginConfigId);
                    return {
                        ...table,
                        config: {
                            ...table.config,
                            scope
                        }
                    };
                }
                return table;
            }).filter(Boolean));
            
            return {
                ...tileConfig,
                dataStream: {
                    ...tileConfig.dataStream,
                    dataSourceConfig: {
                        ...tileConfig.dataStream?.dataSourceConfig,
                        tables: updated
                    }
                }
            } as DataStreamBaseTileConfig;
        }

        return tileConfig;

    }, { enabled: migrationRequired, cacheTime: 0 });

    return {
        isMigrationRequired: migrationRequired,
        data: query.data,
        isLoading: query.isLoading
    };
};

/**
 * A useState hook that remains undefined until the tile config is migrated.
 */
export const useMigratedTileConfigState = (
    savedTileConfig: DataStreamBaseTileConfig
):
    | {
          isMigrating: true;
          tileConfig: undefined;
          setTileConfig: Dispatch<SetStateAction<DataStreamBaseTileConfig>>;
      }
    | {
          isMigrating: false;
          tileConfig: DataStreamBaseTileConfig;
          setTileConfig: Dispatch<SetStateAction<DataStreamBaseTileConfig>>;
      } => {
    const [tileConfig, setTileConfig] = useState<DataStreamBaseTileConfig>(
        undefined as unknown as DataStreamBaseTileConfig
    );

    const {
        data: migratedTileConfig,
        isLoading: isMigrating,
        isMigrationRequired: migrationRequired
    } = useMigratedUnscopedGlobalDataStreamTile({
        ...savedTileConfig,
        ...baseDataStreamConfig
    });

    useEffect(() => {
        if (migrationRequired) {
            if (!isMigrating) {
                setTileConfig(migratedTileConfig!);
            }
        } else {
            setTileConfig({
                ...savedTileConfig,
                ...baseDataStreamConfig
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMigrating]);

    if (isMigrating || tileConfig == null) {
        return { isMigrating: true, tileConfig: undefined, setTileConfig };
    }

    return { isMigrating: false, tileConfig: tileConfig, setTileConfig };
};
