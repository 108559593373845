import { TimeframeEnumValue, defaultTimeframeEnum } from '@squaredup/timeframes';
import { DashboardType, DashboardVariable } from 'dashboard-engine/types/Dashboard';
import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Workspace } from 'services/WorkspaceService';

export interface DashboardContextValue {
    editing: boolean;
    dashboard: DashboardType;
    timeframe: TimeframeEnumValue;
    showDetailedErrors: boolean;
    currentWorkspace?: Workspace;
    variables?: DashboardVariable[];
    setVariables?: Dispatch<SetStateAction<DashboardVariable[]>>;
    updateTile?: (config: Record<string, any>, tileId: string) => void;
    setEditing?: (editing: boolean) => void;
    [key: string]: unknown;
}

const DashboardContext = createContext<DashboardContextValue>({
    editing: false,
    setEditing: (editing: boolean) => undefined,
    setVariables: () => undefined,
    timeframe: defaultTimeframeEnum,
    dashboard: {} as DashboardType,
    showDetailedErrors: false,
    updateTile: () => undefined
});

DashboardContext.displayName = 'DashboardContext';

export const useDashboardContext = () => useContext(DashboardContext);

export default DashboardContext;
