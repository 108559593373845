import Text from '@/components/Text';
import * as Popover from '@radix-ui/react-popover';
import { FeatureKey, getFeatureDisplay, isFeatureEnabled } from '@squaredup/tenants';
import { Pill } from 'components/pill/Pill';
import { useTenant } from 'queries/hooks/useTenant';
import { useTier } from 'queries/hooks/useTier';
import { IsTenantAdmin } from 'services/AccessControlService';
import { UpgradeMessageContainer } from './UpgradeMessageContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { NarrowFeatureUnavailableContent } from './common';
import { useState } from 'react';
import { cn } from '@/lib/cn';

export interface FeatureUnavailablePillProps {
    featureKey: FeatureKey;
    summary?: string;
    title?: string;
    className?: string;
    onUpgradeClicked?: () => void;
}

export const FeatureUnavailablePill: React.FC<FeatureUnavailablePillProps> = ({
    featureKey,
    className,
    summary,
    title,
    onUpgradeClicked
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const { data: tenant } = useTenant();
    const canUpgrade = IsTenantAdmin(tenant) ?? false;
    // useTier handles validating the tier, and coercing items like dates from strings
    const { data: tier } = useTier();

    const feature = getFeatureDisplay(featureKey);
    if (!tier || feature === undefined) {
        return <></>;
    }

    const isEnabled = isFeatureEnabled(tier, featureKey);
    if (isEnabled) {
        return <></>;
    }

    const clickHandler = () => {
        setIsOpen(false);
        if (onUpgradeClicked) {
            onUpgradeClicked();
        }
    };

    return (
        <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
            <Popover.Trigger asChild>
                <Pill 
                    role='button'
                    tabIndex={0}
                    className={cn('flex items-center bg-upgrade text-primaryButtonText pointer-events-auto', className)}
                    variant='roundedSmall'
                    data-testid={`upgrade-pill-${feature.key}`}
                >
                    <Text.H5 className='leading-4'>{feature.availableInTier}</Text.H5>
                </Pill>
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content align='start' alignOffset={-23} sideOffset={1} className='z-20 min-w-[350px]' onOpenAutoFocus={e => e.preventDefault()}>
                    <UpgradeMessageContainer 
                        data-testid='feature-unavailable-pill'
                        variant='normal'
                    >
                        <NarrowFeatureUnavailableContent
                            feature={feature}
                            title={title}
                            summary={summary}
                            canUpgrade={canUpgrade}
                            onUpgradeClicked={clickHandler}
                        >
                            <Popover.Close>
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    className='text-xl cursor-pointer text-tertiaryButton hover:text-tertiaryButtonHover'
                                    data-testid='feature-unavailable-pill-close'
                                />
                            </Popover.Close>
                        </NarrowFeatureUnavailableContent>
                    </UpgradeMessageContainer>
                    <Popover.Arrow 
                        className='-translate-y-[1px] stroke-1 fill-tagBackground stroke-secondaryButtonOutline'
                        style={{ strokeDasharray: '0 30 36.055' }}
                        height={14}
                        width={16} 
                    />
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};
