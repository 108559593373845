import { Button } from '@/components/Button';
import Text from '@/components/Text';
import Config from 'config';
import { useState } from 'react';
import Auth from 'services/Auth';
import { SetUpgradePending } from 'services/TenantService';
import { PlanUpgradeModal } from './PlanUpgradeModal';

type UserPlanProps = {
    tenantId?: string;
    tenantDisplayName?: string;
};

export const UpgradeButton = ({ tenantId, tenantDisplayName }: UserPlanProps) => {
    const [purchaseSucceeded, setPurchaseSucceeded] = useState(false);
    const [isUpgradeModalOpen, setUpgradeModalOpen] = useState(false);

    const submitUpgradeRequest = (planId: number, users: number) => {
        Paddle.Checkout.open({
            product: planId,
            email: Auth.user?.name || '',
            quantity: users,
            allowQuantity: false,
            disableLogout: true,
            successCallback: onSuccessfulPurchase,
            passthrough: `{"tenantId": "${tenantId}", "environment": "${Config.Environment}" }`
        });
    };

    const onSuccessfulPurchase = (_data: unknown) => {
        SetUpgradePending();
        setPurchaseSucceeded(true);
    };

    if (purchaseSucceeded) {
        return (
            <Text.Body className='mb-4 text-statusHealthyPrimary'>
                Thank you - your purchase was successful and your new plan will be active shortly.
            </Text.Body>
        );
    }

    return (
        <>
            {isUpgradeModalOpen && (
                <PlanUpgradeModal
                    onClose={() => setUpgradeModalOpen(false)}
                    onSubmit={submitUpgradeRequest}
                    organisation={tenantDisplayName}
                />
            )}
            <Button variant={'upgrade'} onClick={() => setUpgradeModalOpen(true)}>
                Upgrade
            </Button>
        </>
    );
};
