import { hasStringProperty, hasNonNullProperty } from '@squaredup/utilities';
import { GroupBase, OptionsOrGroups } from 'react-select';
import { match, __ } from 'ts-pattern';
import type { Primitive } from 'type-fest';

type GetByType<T, U extends string> = T extends { type: U } ? T : never;

export const isAutocompleteOption = (x: unknown): x is AutocompleteOption =>
    typeof x === 'object' &&
    x != null &&
    ((hasStringProperty(x, 'label') && hasStringProperty(x, 'value')) ||
        (hasNonNullProperty(x, 'label', 'object') && hasStringProperty(x, 'labelText')));

export const isAutocompleteOptionWithStringLabel = (x: unknown): x is AutocompleteOptionWithStringLabel =>
    typeof x === 'object' && x != null && hasStringProperty(x, 'label') && hasStringProperty(x, 'value');

export const getAutoCompleteLabelText = (option: AutocompleteOption): string =>
    isAutocompleteOptionWithStringLabel(option) ? option.label : option.labelText;

/**
 * Get the value of an autocomplete option, or return the given
 * value if it is not an autocomplete option.
 */
export const getAutocompleteValue = (option?: AutocompleteOption | Primitive): Primitive => {
    return isAutocompleteOption(option) ? option.value : option;
};

export interface AutocompleteOptionWithStringLabel {
    label: string;
    value: string;
    isDisabled?: boolean;
}

export interface AutocompleteOptionWithElementLabel {
    label: JSX.Element;
    labelText: string;
    value: string;
    isDisabled?: boolean;
}

export type AutocompleteOption = AutocompleteOptionWithElementLabel | AutocompleteOptionWithStringLabel;

export type AutocompleteOptionsOrGroups = OptionsOrGroups<AutocompleteOption, GroupBase<AutocompleteOption>>;

/**
 * Autocomplete options, and/or groups of options, that can be displayed and selected.
 */
export type FixedOptions = AutocompleteOptionsOrGroups;

/**
 * A function that asynchronously loads autocomplete options and/or groups of options.
 */
export type OptionsLoader = {
    loadOptions: (searchValue: string) => Promise<AutocompleteOptionsOrGroups>;
    /**
     * Find the loaded option which matches the given value.
     * If no value matches, or options have not been loaded,
     * the value is converted to an option object.
     */
    getOption: (value: unknown) => unknown;
    /**
     * Call a function when the options have been loaded.
     */
    onLoad: (handler: () => void) => void;
    /**
     * If false this loader cannot load options at this time,
     * e.g. because required dependent fields have not been populated.
     */
    canLoad: boolean;
};

export type AutocompleteOptions = FixedOptions | OptionsLoader;

export const isOptionsLoader = (x: AutocompleteOptions | undefined): x is OptionsLoader =>
    x != null && 'loadOptions' in x;

/**
 * Convert any supported value format to a list of options.
 * This helps us avoid handling these cases all over, and also creates option objects from
 * strings that the user manually entered.
 */
export const valuesToOptions = (
    values?: unknown | string | string[] | AutocompleteOption | AutocompleteOption[]
): AutocompleteOption[] =>
    match(values)
        .with({ value: __.string, label: __.string }, (option) => [ensureLabel(option)])
        .with([{ value: __.string, label: __.string }], (options) => options.map((o) => ensureLabel(o)))
        .with({ value: __.string, label: __, labelText: __.string }, (option) => [ensureLabel(option)])
        .with([{ value: __.string, label: __, labelText: __.string }], (options) => options.map((o) => ensureLabel(o)))
        .with(__.string, (s) => [ensureLabel({ value: s })])
        .with([__.string], (vs) => vs.map((v) => ensureLabel({ value: v })))
        .otherwise(() => []);

export const ensureLabel = (o: { value: string; label?: unknown; labelText?: string }): AutocompleteOption =>
    o.label
        ? typeof o.label === 'string' || o.labelText
            ? (o as AutocompleteOption)
            : { ...o, label: o.label as JSX.Element, labelText: o.value }
        : { ...o, label: o.value };

export const isAutocomplete = <T extends { type: string }>(f: T): f is GetByType<T, 'autocomplete'> =>
    f.type === 'autocomplete';

/**
 * Get the complete list of options in a single array by flattening out any groups.
 */
const flattenGroups = (optionsOrGroups: AutocompleteOptionsOrGroups): AutocompleteOption[] =>
    optionsOrGroups.flatMap((o) => ('value' in o ? o : o.options));

/**
 * Ensure that one or more unknown values are AutocompleteOption objects, picking
 * options from a known list if possible
 */
export const ensureOptions = (optionsOrGroups: AutocompleteOptionsOrGroups, selected: unknown | unknown[]) => {
    if (selected == null) {
        return selected; // Allow programmatic clearing of selection
    }

    const options = flattenGroups(optionsOrGroups);
    const asMatchedOptions = valuesToOptions(selected)
        .map(ensureLabel)
        .map((s) => {
            const optionMatch = options.find((o) => o.value === s.value);

            return optionMatch ?? s;
        });

    return Array.isArray(selected) ? asMatchedOptions : asMatchedOptions[0];
};
