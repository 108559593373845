import { InfoTip } from '@/components/InfoTip';
import Text from '@/components/Text';
import { Toggle } from '@/components/forms/Toggle';
import { faCircleQuestion, faWarning } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isUnderLimit, isUnlimited } from '@squaredup/tenants';
import { TimeframeEnumValue } from '@squaredup/timeframes';
import { LimitReachedBanner } from 'components/plans/LimitReachedBanner';
import Tooltip from 'components/tooltip/Tooltip';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useTileContext } from 'contexts/TileContext';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import { debounce } from 'lodash';
import { useMonitorsCount } from 'queries/hooks/useMonitorsCount';
import { useTier } from 'queries/hooks/useTier';
import { useMemo, useState } from 'react';
import { CodeEditor } from 'ui/editor/components/CodeEditor';
import { useZendeskLabels } from 'ui/zendesk/ZendeskContext';
import { useTileEditorContext } from '../../contexts/TileEditorContext';
import { useDataStreamSupportedTimeframes } from '../hooks/useDataStreamSupportedTimeframes';
import { getTileTimeframe } from '../hooks/useTileTimeframes';
import { DataStreamTileEditorMonitorPreview } from './DataStreamTileEditorMonitorPreview';
import { DataStreamTileEditorMonitoringExpandable } from './DataStreamTileEditorMonitoringExpandable';
import { DataStreamTileEditorMonitoringForm, FieldTitle } from './DataStreamTileEditorMonitoringForm';
import { NotificationRuleSummaryForMonitor } from './NotificationRuleSummaryForMonitor';
import { MonitorTypes } from './constants';

/**
 * Determines the best timeframe to set based on the various timeframe options available
 * @param supported Supported timeframes for the current datastream
 * @param dashboard Current dashboard timeframe
 * @param current Current tile timeframe, if available
 * @returns
 */
export const determineBestTimeframe = (
    supported: boolean | TimeframeEnumValue[],
    dashboard: TimeframeEnumValue,
    current?: TimeframeEnumValue
) => {
    // If we already have a timeframe, return it
    if (current) {
        return current;
    }

    // If the selected stream doesn't support/need timeframe, return undefined
    if (supported === false) {
        return undefined;
    }

    // Otherwise get the most sensible tile timeframe based on the supported & dashboard timeframes
    return getTileTimeframe(dashboard, supported);
};

export const DataStreamTileEditorMonitoring = () => {
    const {
        dashboard: { id: dashboardId },
        timeframe: dashboardTimeframe,
        variables = [],
        currentWorkspace
    } = useDashboardContext();

    const { tileId } = useTileContext();
    const { tileConfig, setTileConfig, savedTileConfig } = useTileEditorContext();

    const { isConfigured: isDatastreamConfigured } = useDataStreamConfig(tileConfig);

    const supportedTimeframes = useDataStreamSupportedTimeframes(tileConfig?.dataStream?.id);

    const isMonitorEnabledPreviously = savedTileConfig.monitor !== undefined;
    const { data: tier } = useTier();
    const isMonitoringUnlimited = tier && isUnlimited(tier, 'monitors');
    const { data: currentMonitorCount } = useMonitorsCount({ enabled: !isMonitoringUnlimited });

    useZendeskLabels('monitoring');
    const { _type } = tileConfig.monitor || {};

    const [monitorFormView, setMonitorFormView] = useState(_type !== MonitorTypes.custom);
    const [monitoringEnabled, setMonitoringEnabled] = useState(tileConfig.monitor !== undefined);
    const [originalConfig] = useState(tileConfig.monitor);

    const handleMonitoringToggle = () => {
        setTileConfig((currentConfig) => {
            const timeframe = determineBestTimeframe(supportedTimeframes, dashboardTimeframe, currentConfig.timeframe);

            return {
                ...currentConfig,
                monitor: !monitoringEnabled ? currentConfig.monitorOld || originalConfig : undefined,
                monitorOld: monitoringEnabled ? currentConfig.monitor : undefined,
                timeframe: !monitoringEnabled ? timeframe : currentConfig.timeframe
            };
        });
        setMonitoringEnabled(!monitoringEnabled);
    };

    const advancedMonitorOnChange = useMemo(
        () =>
            debounce(
                (newConfig) =>
                    setTileConfig((currentConfig) => ({
                        ...currentConfig,
                        monitor: { ...newConfig, _type: MonitorTypes.custom }
                    })),
                250
            ),
        [setTileConfig]
    );

    const tileVariables = variables.filter((v) => (tileConfig.variables || []).includes(v.id));
    const variablesPreventingMonitoring = tileVariables.length > 0 && tileVariables.every((v) => v.default !== 'all');

    const adjustedMonitorCount =
        !monitoringEnabled && isMonitorEnabledPreviously && currentMonitorCount !== undefined
            ? currentMonitorCount - 1
            : currentMonitorCount;

    const isMonitoringUnavailable =
        !monitoringEnabled &&
        !isMonitoringUnlimited &&
        (tier === undefined ||
            adjustedMonitorCount === undefined ||
            !isUnderLimit(tier, 'monitors', adjustedMonitorCount));

    return (
        <div className='flex flex-col flex-1 h-full min-h-0 px-4' data-panel='monitoring'>
            {!monitoringEnabled && (
                <LimitReachedBanner
                    currentUsage={adjustedMonitorCount}
                    featureKey='monitors'
                    content='subtle'
                    container='modal'
                    className='mt-5'
                />
            )}
            <div className='flex items-center justify-between w-full mt-5 shrink-0'>
                <FieldTitle className='w-auto'>
                    <Text.H3 className='flex-shrink-0'>Monitoring</Text.H3>
                </FieldTitle>

                <div className='pr-2'>
                    <div>
                        <Tooltip
                            title={!isDatastreamConfigured && 'This tile must be configured to enable monitoring.'}
                            disabled={
                                isDatastreamConfigured || isMonitoringUnavailable || variablesPreventingMonitoring
                            }
                        >
                            <Toggle
                                name='monitoringEnabled'
                                checked={monitoringEnabled && !variablesPreventingMonitoring}
                                onCheckedChange={handleMonitoringToggle}
                                disabled={
                                    !isDatastreamConfigured || isMonitoringUnavailable || variablesPreventingMonitoring
                                }
                                data-testid='monitoringToggle'
                            />
                        </Tooltip>
                    </div>
                </div>
            </div>

            {variablesPreventingMonitoring && (
                <InfoTip icon={faWarning} iconClassName='text-statusWarningPrimary'>
                    Monitoring is not available as this tile uses a variable without a default value.{' '}
                    <a
                        className='text-textLink'
                        href='https://squaredup.com/cloud/variables'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Learn more.
                    </a>
                </InfoTip>
            )}

            <div className='flex-1 pb-4 mt-3 tile-scroll-overflow'>
                {monitoringEnabled && !variablesPreventingMonitoring && (
                    <>
                        {!monitorFormView ? (
                            <div className='flex-1 w-full min-w-0 min-h-0 mt-5 border bg-componentBackgroundSecondary border-outlinePrimary'>
                                <CodeEditor
                                    onValidUpdatedContent={advancedMonitorOnChange}
                                    content={{
                                        ...tileConfig.monitor,
                                        _type: MonitorTypes.custom
                                    }}
                                />
                            </div>
                        ) : (
                            <div className='w-full'>
                                <DataStreamTileEditorMonitoringForm />
                            </div>
                        )}
                        <div className='border-b border-b-dividerPrimary'>
                            <DataStreamTileEditorMonitoringExpandable
                                summary='Preview'
                                initiallyOpen={true}
                                summaryStyles='mt-0.5 font-semibold text-sm'
                                scrollIntoViewOnExpand={true}
                                summaryDataTestId='monitorPreviewExpander'
                                helpIcon={
                                    <Tooltip title='A preview of the tile health with this monitoring configuration.'>
                                        <FontAwesomeIcon className='ml-3 text-textSecondary' icon={faCircleQuestion} />
                                    </Tooltip>
                                }
                            >
                                <div className='mb-5'>
                                    <DataStreamTileEditorMonitorPreview />
                                </div>
                            </DataStreamTileEditorMonitoringExpandable>
                        </div>

                        <DataStreamTileEditorMonitoringExpandable
                            summary='Notifications'
                            initiallyOpen={true}
                            summaryStyles='font-semibold text-sm'
                            childrenStyles=''
                            scrollIntoViewOnExpand={true}
                            summaryDataTestId='notificationsExpander'
                            helpIcon={
                                <Tooltip title='A summary of the notification rules enabled for this monitor.'>
                                    <FontAwesomeIcon className='ml-3 text-textSecondary' icon={faCircleQuestion} />
                                </Tooltip>
                            }
                        >
                            <NotificationRuleSummaryForMonitor
                                dashboardId={dashboardId}
                                tileId={tileId}
                                alertingRules={currentWorkspace?.data?.alertingRules}
                                workspaceId={currentWorkspace?.id}
                                tier={tier}
                            />
                        </DataStreamTileEditorMonitoringExpandable>
                    </>
                )}
            </div>
        </div>
    );
};
