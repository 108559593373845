import { HealthState } from '@squaredup/monitoring';
import pluralize from 'pluralize';
import { useWorkspacesWithHealthRollup } from 'queries/hooks/useWorkspacesWithHealthRollup';
import { useMemo } from 'react';
import { IterableElement } from 'type-fest';
import { StatusRequestType } from '../ui/StatusOverview';

type BlockReason = IterableElement<IterableElement<ReturnType<typeof useWorkspacesWithHealthRollup>['data']>['reasons']>;

function getDashboardMonitorId(reason: BlockReason, type: StatusRequestType) {
    if (type === 'dash') {
        return reason.dashId;
    }
    return `${reason.dashId}/${reason.tileId}`;
}

export const getCountText = (errorCount: number, warningCount: number) => {
    if (errorCount === 0) {
        return `${warningCount} ${pluralize('monitor', warningCount)} ${
            warningCount > 1 ? 'are' : 'is'
        } in a warning state.`;
    }
    if (warningCount === 0) {
        return `${errorCount} ${pluralize('monitor', errorCount)} ${errorCount > 1 ? 'are' : 'is'} in an error state.`;
    }
    return `${errorCount + warningCount} ${pluralize(
        'monitor',
        errorCount + warningCount
    )} are in an error or warning state.`;
};

export function useBlockReason(type: StatusRequestType, id: string, state: HealthState) {
    const { data: workspaces = [], isLoading } = useWorkspacesWithHealthRollup({
        enabled: ['error', 'warning'].includes(state)
    });

    const reason = useMemo(() => {
        if (['success', 'unknown'].includes(state) || isLoading) {
            return;
        }

        let reasons = [];

        // Get all the reasons for the object
        switch (type) {
            case 'space':
                reasons.push(...(workspaces.find((w) => w.id === id)?.reasons || []));

                break;
            case 'dash':
            case 'monitor':
                for (const w of workspaces) {
                    const tempReasons = w.reasons?.filter((d) => getDashboardMonitorId(d, type) === id);
                    if (tempReasons && tempReasons.length > 0) {
                        reasons.push(...tempReasons);
                        break; // We found the one and only reason
                    }
                }
                break;
            default:
                break;
        }

        const firstTextReason = reasons[0]?.text ? reasons[0] : undefined;

        // Only ever 1 monitor
        if (type === 'monitor' && !firstTextReason) {
            return 'This monitor is in a degraded state.';
        }

        if (!firstTextReason || firstTextReason.state !== state) {
            const errorCount = reasons.filter((r) => r.state === 'error').length;
            return getCountText(errorCount, reasons.length - errorCount);
        }

        return `${firstTextReason.text}${
            reasons.length > 1 ? ` (+${reasons.length - 1} ${pluralize('monitor', reasons.length - 1)})` : ''
        }`;
    }, [state, isLoading, type, workspaces, id]);

    return reason;
}
