import Text from '@/components/Text';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBooks, faBullhorn, faCircleQuestion, faComment, faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Content, Portal, Root, Trigger } from '@radix-ui/react-popover';
import { FC } from 'react';
import { NavTitle } from './ui/NavLinks';

type ExternalLinkProps = {
    title: string;
    icon: IconProp;
    subtitle: string;
    link: string;
};

const ExternalNavLink: FC<ExternalLinkProps> = ({ title, icon, subtitle, link }) => (
    <a
        className='flex flex-col gap-1 cursor-pointer text-textSecondary hover:text-textPrimary'
        href={link}
        target='_blank'
        rel='noreferrer'
    >
        <span className='flex'>
            <FontAwesomeIcon fixedWidth icon={icon} className='mr-2 shrink-0 text-[15px]' />
            <Text.H4 className='ml-px leading-[15px]'>{title}</Text.H4>
        </span>
        <Text.SmallBody>{subtitle}</Text.SmallBody>
    </a>
);

export const HelpMenu: FC = () => (
    <Root>
        <Trigger className='group'>
            <NavTitle
                title='Help'
                icon={faCircleQuestion}
                iconClassName='mr-3 w-8 ml-[2px]'
                className='mt-3.5 hover:text-textPrimary group-data-[state=open]:text-textPrimary'
                data-test='help-button'
            />
        </Trigger>
        <Portal>
            <Content
                align='start'
                alignOffset={13}
                className='z-50 w-[380px] border shadow-none rounded-b input bg-backgroundSecondary text-textPrimary border-modalOutline p-sm group'
                data-theme='dark'
            >
                <div className='flex flex-col gap-4'>
                    <ExternalNavLink
                        title='Documentation'
                        icon={faBooks}
                        link='https://docs.squaredup.com/'
                        subtitle='Learn about a feature or troubleshoot a problem.'
                    />
                    <ExternalNavLink
                        title='Ask The Community'
                        icon={faComment}
                        link='https://community.squaredup.com/c/cloud/8'
                        subtitle='Find expert answers to your questions.'
                    />
                    <ExternalNavLink
                        title='Contact Support'
                        icon={faEnvelope}
                        link='https://docs.squaredup.com/submit-ticket'
                        subtitle='Our friendly support team is just a click away.'
                    />
                    <hr className='border-outlinePrimary' />
                    <ExternalNavLink
                        title="What's New"
                        icon={faBullhorn}
                        link='https://feedback.squaredup.com/changelog'
                        subtitle="It's all go at SquaredUp, check out the latest features."
                    />
                </div>
            </Content>
        </Portal>
    </Root>
);
