const script = document.createElement('script');

let statusPageEmbedUrl = 'https://squaredup.statuspage.io/embed/script.js';
if (window.SQUPRELEASEVARS?.Environment === 'ProductionEU') {
    statusPageEmbedUrl = 'https://squaredup-eu.statuspage.io/embed/script.js';
} else if (window.SQUPRELEASEVARS?.Environment === 'ProductionUK') {
    statusPageEmbedUrl = 'https://squaredup-uk.statuspage.io/embed/script.js';
}

script.src = statusPageEmbedUrl;
script.async = true;
document.body.appendChild(script);
