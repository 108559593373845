import { AutocompleteOption } from 'components/forms/jsonForms/autocompleteOptions';
import { useDOMElement } from 'components/hooks/useDOMElement';
import { VisualisationOptionAction } from 'dashboard-engine/types/Visualisation';
import { useEffect, useState } from 'react';
import Select, { GroupBase, SingleValue } from 'react-select';

type DropdownParams = {
    name: string;
    options: GroupBase<AutocompleteOption>[];
    defaultValue: AutocompleteOption;
    isDisabled?: boolean;
    onChange: (action: VisualisationOptionAction) => void;
};

export const OptionDropdown = ({ name, options, defaultValue, isDisabled, onChange }: DropdownParams) => {
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const handleChange = (choice: SingleValue<AutocompleteOption>) => {
        setValue(choice ?? defaultValue);
        onChange({ action: `${name}-select`, data: choice?.value });
    };

    const portalTarget = useDOMElement('visualizationConfigPanel');

    return (
        <Select<AutocompleteOption>
            name={name}
            defaultValue={defaultValue}
            classNamePrefix='autocomplete'
            className='text-sm ring-1 ring-inset ring-outlinePrimary focus-within:ring-outlineSecondary rounded-input bg-componentBackgroundPrimary'
            blurInputOnSelect={true}
            options={options}
            value={value}
            onChange={handleChange}
            isDisabled={isDisabled ?? false}
            menuPortalTarget={portalTarget}
        />
    );
};
