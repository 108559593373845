import Text from '@/components/Text';
import Markdown from 'react-markdown';

type FieldMarkdownProps = {
    content: string;
}

export const FieldMarkdown: React.FC<FieldMarkdownProps> = ({ content }) => {
    if (!content) {
        return null;
    }
    return (
        <div className='relative mt-6 space-y-4 first:mt-0 text-textPrimary'>
            <Markdown components={{
                a: ({ href, children, ...rest }) => <a href={href as string} className='text-textLink' target='_blank' rel='noopener noreferrer' {...rest}>{children}</a>,
                h1: ({ children }) => <Text.H1>{children}</Text.H1>,
                h2: ({ children }) => <Text.H2>{children}</Text.H2>,
                h3: ({ children }) => <Text.H3>{children}</Text.H3>,
                h4: ({ children }) => <Text.H4>{children}</Text.H4>,
                h5: ({ children }) => <Text.H5>{children}</Text.H5>,
                p: ({ children }) => <Text.Body className=''>{children}</Text.Body>,
                pre: ({ children }) => <pre className='p-3 text-sm border rounded-md bg-modalOutline border-outlinePrimary'>{children}</pre>,
                ol: ({ children }) => <ol className='space-y-1 list-decimal list-inside marker:text-textSecondary marker:mr-2'>{children}</ol>,
                ul: ({ children }) => <ul className='space-y-1 list-disc list-inside '>{children}</ul>,
                hr: () => <hr className='border-dividerPrimary' />
            }}>
                {content}
            </Markdown>
        </div>
    );
};
