import { useAppContext } from 'contexts/AppContext';
import type { DashboardType } from 'dashboard-engine/types/Dashboard';
import type { UserTenantProperties } from 'dynamo-wrapper';
import { type DashboardFolder, isDashboard, isFolder } from 'queries/utils/dashboardSorted';
import { useCallback, useMemo } from 'react';
import { type UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import { GetTenantUserProperties, SetUserForTenantProperty } from 'services/UserService';

export const SAVE_FOLDER_OPEN = 'SAVE_FOLDER_OPEN';

export const useFoldersOpen = (
    options?: UseQueryOptions<
        UserTenantProperties['workspacesDashbaordFoldersOpenState'] | undefined,
        unknown,
        UserTenantProperties['workspacesDashbaordFoldersOpenState'] | undefined,
        string[]
    >
) => {
    const queryClient = useQueryClient();
    return useQuery(
        [SAVE_FOLDER_OPEN],
        ({ signal }) => {
            if (queryClient.isMutating({ mutationKey: [SAVE_FOLDER_OPEN] })) {
                return queryClient.getQueryData([
                    SAVE_FOLDER_OPEN
                ]) as UserTenantProperties['workspacesDashbaordFoldersOpenState'];
            }
            return GetTenantUserProperties(signal).then((data) => data?.workspacesDashbaordFoldersOpenState);
        },
        options
    );
};

export const useIsFolderOpen = (item: DashboardFolder | DashboardType) => {
    const { data } = useFoldersOpen();
    const { currentWorkspaceID } = useAppContext();

    return useMemo(() => {
        if (isDashboard(item)) {
            return undefined;
        }
        return data?.[currentWorkspaceID!]?.[item.id];
    }, [currentWorkspaceID, data, item]);
};

export const useSetFolderOpen = () => {
    const queryClient = useQueryClient();

    const { currentWorkspaceID } = useAppContext();

    const { data: currentState = {} } = useFoldersOpen();

    const update = useMutation(
        (states: { item: DashboardType | DashboardFolder; open: boolean }[]) =>
            SetUserForTenantProperty('workspacesDashbaordFoldersOpenState', {
                ...currentState,
                [currentWorkspaceID!]: {
                    ...currentState[currentWorkspaceID!],
                    ...states.reduce(
                        (prev, { item, open }) => {
                            prev[item.id] = open;
                            return prev;
                        },
                        {} as Record<string, boolean>
                    )
                }
            }),
        {
            mutationKey: [SAVE_FOLDER_OPEN],
            async onMutate(states) {
                await queryClient.cancelQueries([SAVE_FOLDER_OPEN]);

                const previousState = queryClient.getQueryData([
                    SAVE_FOLDER_OPEN
                ]) as UserTenantProperties['workspacesDashbaordFoldersOpenState'];

                queryClient.setQueryData([SAVE_FOLDER_OPEN], {
                    ...previousState,
                    [currentWorkspaceID!]: {
                        ...previousState?.[currentWorkspaceID!],
                        ...states.reduce(
                            (prev, { item, open }) => {
                                prev[item.id] = open;
                                return prev;
                            },
                            {} as Record<string, boolean>
                        )
                    }
                });

                return { previousState };
            },
            onError(_err, _config, context) {
                queryClient.setQueryData([SAVE_FOLDER_OPEN], context?.previousState);
            },
            onSettled() {
                queryClient.invalidateQueries([SAVE_FOLDER_OPEN]);
            }
        }
    );

    return useCallback(
        (states: { item: DashboardType | DashboardFolder; open: boolean }[]) => {
            const currState = queryClient.getQueryData([
                SAVE_FOLDER_OPEN
            ]) as UserTenantProperties['workspacesDashbaordFoldersOpenState'];

            const filtered = states.filter(({ item }) => isFolder(item));

            if (filtered.some(({ item, open }) => currState?.[currentWorkspaceID!]?.[item.id] !== open)) {
                update.mutate(filtered);
            }
        },
        [update, currentWorkspaceID, queryClient]
    );
};
