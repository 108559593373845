import { Serialised } from '@squaredup/ids';
import type { ProjectedPlugin } from 'dynamo-wrapper';
import { PluginCard } from './PluginCard';
import { gridResponsiveClassName } from './common';

const candidatePluginNames = ['Web API', 'Web API On-Premise', 'PowerShell', 'PowerShell On-Premise'];

interface SuggestedSearchResultProps {
    onSelect?: (plugin: Serialised<ProjectedPlugin>) => void;
    plugins: Serialised<ProjectedPlugin>[];
    excludedPlugins?: Serialised<ProjectedPlugin>[];
}

export function SuggestedSearchResult({ onSelect, plugins, excludedPlugins }: SuggestedSearchResultProps) {
    const suggestedPluginNames = candidatePluginNames.filter(
        (pluginName) => !excludedPlugins?.find((exclusion) => exclusion.displayName === pluginName)
    );

    const suggestedPlugins = plugins
        ?.filter((plugin) => suggestedPluginNames.includes(plugin.displayName || ''))
        .sort((a, b) => suggestedPluginNames.indexOf(a.displayName || '') - suggestedPluginNames.indexOf(b.displayName || ''));

    if (!suggestedPlugins?.length) {
        return null;
    }
    
    return (
        <div className='flex mb-6'>
            <ol className={gridResponsiveClassName}>
                {suggestedPlugins.map((plugin) => (
                    <li className='h-36' key={plugin.id}>
                        <PluginCard plugin={plugin} onClick={onSelect} />
                    </li>
                ))}
            </ol>
        </div>
    );
}
