import { isDefined } from '@squaredup/utilities';
import { DashboardType } from 'dashboard-engine/types/Dashboard';
import stringify from 'fast-json-stable-stringify';
import { omit, orderBy } from 'lodash';

/**
 * Determine if one dashboard has newer content than another
 * @param oldDashboard The dashboard suspected of being the older dashboard
 * @param newDashboard The dashboard suspected of being the newer dashboard
 * @returns true if newDashboard is newer than oldDashboard,
 * 'version-only' if newDashboard has the same content but a newer version than oldDashboard,
 * and false if oldDashboard is newer or the same as newDashboard
 */
export const isNewerDashboard = (
    oldDashboard: DashboardType | undefined,
    newDashboard: DashboardType
): true | false | 'version-only' => {
    if (oldDashboard == null) {
        return true;
    }

    const oldContent = getComparableDashboardString(oldDashboard);
    const newContent = getComparableDashboardString(newDashboard);

    const oldVersion = oldDashboard.content.version ?? 0;
    const newVersion = newDashboard.content.version ?? 0;

    if (oldContent === newContent) {
        return oldVersion < newVersion ? 'version-only' : false;
    }

    return oldVersion <= newVersion;
};

/**
 * Get the current version of a dashboard
 */
export const getDashboardVersion = (dashboard: DashboardType | undefined): number => {
    return dashboard?.content.version ?? 0;
};

/**
 * Set the version of a dashboard, returning a new dashboard instance
 * @param dashboard The dashboard with the version to set
 * @param newVersion The value to set as the version
 */
export const setDashboardVersion = (dashboard: DashboardType, newVersion: number) => {
    return {
        ...dashboard,
        content: { ...dashboard.content, version: newVersion }
    };
};

/**
 * Set the version of a dashboard by mutating the {@link dashboard} instance
 * @param dashboard The dashboard with the version to set
 * @param newVersion The value to set as the version
 */
export const mutateDashboardVersion = (dashboard: DashboardType, newVersion: number): void => {
    dashboard.content.version = newVersion;
};

/**
 * Find the newest dashboard, i.e. the one most likely to be up to date
 * @param dashboards The dashboards to pick from
 */
export const getNewestDashboard = (...dashboards: (DashboardType | undefined)[]) => {
    const newest = orderBy(dashboards.filter(isDefined), ['content.version', 'lastUpdated'], 'desc')[0];

    return newest;
};

/**
 * Returns a string representation of a dashboard which can be safely used
 * in the dependencies of useMemo, useEffect etc. without excessive re-renders
 * @param dashboard The dashboard to serialise
 */
export const getComparableDashboardString = (dashboard: DashboardType | undefined): string => {
    if (dashboard == null) {
        return '';
    }

    // Ignore properties which can change without needing a re-render,
    // we don't care if only these properties change
    return stringify(omit(dashboard, 'lastUpdated', 'content.version'));
};
