import { useAppContext } from 'contexts/AppContext';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import { TileConfig } from 'dashboard-engine/types/Tile';
import objectHash from 'object-hash';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { NO_ACTIVE_WORKSPACE } from 'services/WorkspaceUtil';

export const EXPLORE_QUERY_KEY = 'explore';

/** This hook returns a react router navigate function that will take the user to the explore page with
 * the current tile config. We use react query to store the tile's config in the cache with a hash of the config
 * The global prop is used to append the global URL param to the explore link. This ensures we can fetch data
 * on that page, regardless of whether the datasource is in the current workspace
 */
export const useNavigateToExplore = (config: TileConfig, isGlobal?: boolean) => {
    const navigate = useNavigate();

    const queryClient = useQueryClient();
    const { workspace } = useDataStreamWorkspaceContext();
    const { searchOpen, setSearchOpen } = useAppContext();

    return () => {
        const exploreHash = objectHash(config, { encoding: 'base64' });
        queryClient.setQueryData([EXPLORE_QUERY_KEY, exploreHash], config);

        navigate({
            pathname: '/explore/',
            search: createSearchParams({
                config: exploreHash,
                ...(workspace && workspace !== NO_ACTIVE_WORKSPACE && { workspace }),
                ...(isGlobal && { global: 'true' })
            }).toString()
        });

        // The navigation to explore may be used within the search dialog, we therefore need to close
        // the search dialog if it is open as it will no longer be relevant
        if (searchOpen) {
            setSearchOpen(false);
        }
    };
};
