import { Button } from '@/components/Button';
import { Switch } from '@/components/Switch';
import Template from '@/components/Template';
import { faChartNetwork, faGlobe, faGrid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BehindFlag } from 'components/BehindFlag';
import LoadingSpinner from 'components/LoadingSpinner';
import { useModal } from 'components/Modal';
import { Presence } from 'components/Presence';
import { useTenantSessionStorage } from 'components/hooks/useTenantSessionStorage';
import { CreateWorkspaceModal } from 'components/workspaceModals/CreateWorkspaceModal';
import { TenantNetwork } from 'dashboard-engine/visualisations/Network/TenantNetwork';
import type { BooleanViewOption, OrganisationHomeConfig, ViewOption } from 'dynamo-wrapper';
import { useOrgTitle } from 'queries/hooks/useOrgTitle';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import SortMenu from './ui/SortMenu';
import StateFilter from './ui/StateFilterMenu';
import StatusOverview from './ui/StatusOverview';
import TagMenu from './ui/TagMenu';
import TypeFilter from './ui/TypeMenu';
import ViewSettingsMenu from './ui/ViewSettingsMenu';
import WorkspaceTypeMenu from './ui/WorkspaceTypeMenu';
import { useSaveStatusConfig, useStatusConfig } from './utils/useStatusConfig';
import withoutWorkspace from './utils/withoutWorkspace';

const displayModeOptions = [
    {
        value: 'tile',
        label: (
            <span className='inline-flex items-center space-x-2 align-middle'>
                <FontAwesomeIcon size='lg' icon={faGrid} />
                <span>Tile</span>
            </span>
        )
    },
    {
        value: 'map',
        label: (
            <span className='inline-flex items-center space-x-2 align-middle'>
                <FontAwesomeIcon size='lg' icon={faChartNetwork} />
                <span>Map</span>
            </span>
        )
    }
];

const viewOptions: Record<string, Record<string, ViewOption[]>> = {
    tile: {
        space: [
            {
                name: 'type',
                displayName: 'Type',
                type: 'boolean',
                value: true
            },
            {
                name: 'tags',
                displayName: 'Tags',
                type: 'boolean',
                value: true
            },
            {
                name: 'dataSources',
                displayName: 'Data sources',
                type: 'boolean',
                value: true
            },
            {
                name: 'sublabel',
                displayName: 'State description',
                type: 'boolean',
                value: true
            },
            {
                name: 'avatar',
                displayName: 'Icon',
                type: 'boolean',
                value: true
            },
            { type: 'separator' },
            {
                name: 'columnCount',
                displayName: 'Columns',
                type: 'number',
                value: 3,
                minimum: 1,
                maximum: 12
            }
        ]
    },
    map: {
        space: [
            {
                name: 'showHealthyMonitors',
                displayName: 'Healthy monitors',
                type: 'boolean',
                value: false
            },
            {
                name: 'showHealthyDependencies',
                displayName: 'Healthy dependencies',
                type: 'boolean',
                value: true
            }
        ]
    }
};

const convertOldViewOptions = (oldViews: Record<string, Record<string, string[]>>) => {
    const array = oldViews.tile.space;

    const optionsCopy = [...viewOptions.tile.space];

    const typeOption: any = optionsCopy.find((o: any) => o.name === 'type');
    if (typeOption) {
        typeOption.value = array.includes('type');
    }

    const tagsOption: any = optionsCopy.find((o: any) => o.name === 'tags');
    if (tagsOption) {
        tagsOption.value = array.includes('tags');
    }

    const dataSourceOption: any = optionsCopy.find((o: any) => o.name === 'dataSources');
    if (dataSourceOption) {
        dataSourceOption.value = array.includes('data sources');
    }

    const sublabelOption: any = optionsCopy.find((o: any) => o.name === 'sublabel');
    if (sublabelOption) {
        sublabelOption.value = array.includes('state description');
    }

    const avatarOption: any = optionsCopy.find((o: any) => o.name === 'avatar');
    if (avatarOption) {
        avatarOption.value = array.includes('icon');
    }

    return {
        ...viewOptions,
        tile: {
            ...viewOptions.tile,
            space: optionsCopy
        }
    };
};

const VIEW_TYPE_KEY = '__TENANT_STATUS_VIEW__';

export const defaultGlobalViewConfig: OrganisationHomeConfig = {
    filter: [],
    sort: {
        value: 'state',
        direction: 'desc'
    },
    type: 'space',
    tags: [],
    workspaceTypes: [],
    views: viewOptions
};

export function StatusPage() {
    const { data: orgTitle, isLoading: isOrgTitleLoading } = useOrgTitle();

    const [statusView, setStatusView] = useTenantSessionStorage(VIEW_TYPE_KEY, 'tile');

    const createWorkspaceModal = useModal();

    const queryClient = useQueryClient();

    const { data: config = defaultGlobalViewConfig, isLoading: isConfigLoading } = useStatusConfig();

    if (typeof config.views?.tile?.space[0] === 'string') {
        config.views = convertOldViewOptions(config.views as any);
    }

    const { mutate: saveConfig } = useSaveStatusConfig();

    useEffect(() => {
        // Map view is only permitted for workspace config
        if (statusView === 'map' && config.type !== 'space') {
            setStatusView('tile');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config.type]);

    return (
        <Template
            icon={<FontAwesomeIcon className='p-1 h-[27px]' icon={faGlobe} />}
            iconAlignment='center'
            title={!isOrgTitleLoading && orgTitle ? orgTitle : ''}
            flex
            equalPadding
        >
            <div className='flex items-center space-x-4 h-11'>
                {!isConfigLoading && (
                    <>
                        <>
                            <TypeFilter
                                type={config.type}
                                setType={(type: any) =>
                                    saveConfig({
                                        ...config,
                                        type
                                    })
                                }
                            />
                            <span className='w-0.5 h-10 mx-1 shrink-0 rounded-full bg-dividerPrimary' />
                        </>
                        <StateFilter
                            states={config.filter || []}
                            setStates={(filter: any) =>
                                saveConfig({
                                    ...config,
                                    filter
                                })
                            }
                        />
                        <WorkspaceTypeMenu
                            types={config.workspaceTypes}
                            setTypes={(workspaceTypes) =>
                                saveConfig({
                                    ...config,
                                    workspaceTypes
                                })
                            }
                        />
                        <TagMenu
                            selectedTags={config.tags}
                            setSelectedTags={(tags) =>
                                saveConfig({
                                    ...config,
                                    tags
                                })
                            }
                        />
                        {(config.filter.length > 0 || config.workspaceTypes.length > 0 || config.tags.length > 0) && (
                            <Button
                                variant='tertiary'
                                onClick={() =>
                                    saveConfig({
                                        ...config,
                                        filter: [],
                                        workspaceTypes: [],
                                        tags: []
                                    })
                                }
                            >
                                Clear filters
                            </Button>
                        )}
                        <div className='flex justify-end flex-1 space-x-4'>
                            {statusView !== 'map' && (
                                <SortMenu
                                    sort={config.sort}
                                    setSort={(sort: any) =>
                                        saveConfig({
                                            ...config,
                                            sort
                                        })
                                    }
                                />
                            )}
                            <BehindFlag flagName='tenantMap'>
                                {config.type === 'space' && (
                                    <Switch
                                        value={statusView}
                                        options={displayModeOptions}
                                        onValueChange={(view) => {
                                            setStatusView(view || 'tile');
                                        }}
                                        disabled={config.type !== 'space'}
                                    />
                                )}
                            </BehindFlag>
                            {viewOptions[statusView] &&
                                viewOptions[statusView]?.[config.type] &&
                                viewOptions[statusView]?.[config.type]?.length > 0 && (
                                    <ViewSettingsMenu
                                        options={viewOptions[statusView][config.type]}
                                        selectedOptions={config.views?.[statusView]?.[config.type] ?? []}
                                        setViews={(views) =>
                                            saveConfig({
                                                ...config,
                                                views: {
                                                    ...config.views,
                                                    [statusView]: {
                                                        ...(config.views?.[statusView] ?? {}),
                                                        [config.type]: views
                                                    }
                                                }
                                            })
                                        }
                                    />
                                )}
                        </div>
                    </>
                )}
            </div>
            {isConfigLoading && <LoadingSpinner />}
            {!isConfigLoading && (
                <div className='relative flex mt-10 mb-4 overflow-auto grow scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                    {statusView === 'map' && (
                        <div className='w-full h-full'>
                            <TenantNetwork
                                sort={config.sort}
                                type={config.type}
                                tags={config.tags}
                                workspaceTypes={config.workspaceTypes}
                                stateFilters={config.filter || []}
                                config={{ layoutType: 'hierarchy' }}
                                filterCriteria={{
                                    showIssuesOnly: !(
                                        (
                                            config?.views?.map?.space?.find(
                                                (entry) => (entry as any).name === 'showHealthyDependencies'
                                            ) as BooleanViewOption
                                        )?.value ?? true
                                    ),
                                    showHealthyMonitors:
                                        (
                                            config?.views?.map?.space?.find(
                                                (entry) => (entry as any).name === 'showHealthyMonitors'
                                            ) as BooleanViewOption
                                        )?.value ?? false
                                }}
                            />
                        </div>
                    )}
                    {statusView === 'tile' && (
                        <StatusOverview
                            sort={config.sort}
                            type={config.type}
                            tags={config.tags}
                            maxColumns={
                                (config.views?.tile?.[config.type]?.find((o: any) => o.name === 'columnCount') as any)
                                    ?.value ?? 3
                            }
                            workspaceTypes={config.workspaceTypes}
                            stateFilters={config.filter || []}
                            viewOptions={config.views?.tile?.[config.type]}
                            fillBlocksGrid
                            openCreateWorkspace={createWorkspaceModal.open}
                        />
                    )}
                </div>
            )}

            <Presence isPresent={createWorkspaceModal.isOpen}>
                <CreateWorkspaceModal close={createWorkspaceModal.close} />
            </Presence>
        </Template>
    );
}

export default withoutWorkspace(StatusPage);
