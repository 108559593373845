import { cn } from '@/lib/cn';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import Tooltip from 'components/tooltip/Tooltip';
import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';

export const buttonVariants = cva(
    'inline-flex items-center select-none focus:ring-0 focus:outline-none disabled:pointer-events-none',
    {
        variants: {
            variant: {
                destructive:
                    'py-input px-md font-semibold rounded leading-input bg-destructiveButtonBackground text-primaryButtonText whitespace-nowrap hover:bg-destructiveButtonBackgroundHover focus:bg-destructiveButtonBackgroundHover disabled:bg-destructiveButtonBackgroundDisabled disabled:text-primaryButtonTextDisabled',
                primary:
                    'py-input px-md font-semibold rounded leading-input bg-primaryButtonBackground text-primaryButtonText whitespace-nowrap hover:bg-primaryButtonBackgroundHover focus:bg-primaryButtonBackgroundHover disabled:bg-primaryButtonBackgroundDisabled disabled:text-primaryButtonTextDisabled',
                secondary:
                    'py-input px-md font-semibold rounded leading-input bg-secondaryButtonBackground text-secondaryButtonText whitespace-nowrap ring-secondaryButtonOutline ring-inset ring-1 hover:bg-secondaryButtonBackgroundHover hover:ring-secondaryButtonOutlineHover focus:bg-secondaryButtonBackgroundHover focus:ring-secondaryButtonOutlineHover disabled:bg-secondaryButtonBackgroundDisabled disabled:text-secondaryButtonTextDisabled',
                tertiary:
                    'text-tertiaryButton tracking-body hover:text-tertiaryButtonHover focus:text-tertiaryButtonHover whitespace-nowrap disabled:text-tertiaryButtonDisabled',
                link: 'text-textLink tracking-body hover:underline focus:underline disabled:text-textLinkDisabled',
                choice:
                    'py-input px-md rounded leading-input bg-componentBackgroundPrimary text-textPrimary whitespace-nowrap ring-tileOutline ring-inset ring-1 hover:bg-tagBackground hover:ring-outlinePrimary focus-visible:bg-tagBackground focus-visible:ring-outlinePrimary disabled:bg-componentBackgroundPrimary disabled:text-textDisabled',
                upgrade:
                    'py-input px-md font-semibold rounded leading-input bg-upgradeButtonBackgroundPrimary text-upgradeButtonTextPrimary whitespace-nowrap hover:bg-upgradeButtonBackgroundHover focus:bg-upgradeButtonBackgroundHover disabled:bg-upgradeButtonBackgroundDisabled disabled:text-upgradeButtonTextDisabled'
            }
        },
        defaultVariants: {
            variant: 'primary'
        }
    }
);

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
    icon?: ReactNode;
    href?: string;
    asChild?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, icon, href, title, children, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : 'button';

        return (
            <Comp 
                className={cn(buttonVariants({ variant, className }), children && 'gap-x-3')} 
                ref={ref} 
                {...props}
            >
                {icon && <span>{icon}</span>}
                {children && (<span>{children}</span>)}
            </Comp>
        );
    }
);


export const TooltipButton = forwardRef<HTMLButtonElement, ButtonProps & { tooltipClassName?: string }>(
    ({ className, tooltipClassName, variant, icon, title, children, ...props }, ref) => {
        return (
            <Tooltip 
                title={title}
                className='inline-flex'
                tooltipClassName={tooltipClassName}
            >
                <button 
                    className={cn(buttonVariants({ variant, className }), children && 'gap-x-3')} 
                    ref={ref}
                    {...props}
                >
                    {icon && <span>{icon}</span>}
                    <span>{children}</span>
                </button>
            </Tooltip>
        );
    }
);
