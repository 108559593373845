import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedStreamValue } from '@squaredup/data-streams';
import { Table } from '@tanstack/react-table';
import clsx from 'clsx';
import Button from 'components/button/Button';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useVisualisationContext } from 'contexts/VisualisationContext';
import { DataStreamTableConfig } from 'dashboard-engine/visualisations/DataStreamTable/types';
import { Select } from 'ui/select/Select';
import { SelectContent } from 'ui/select/SelectContent';
import { SelectItem } from 'ui/select/SelectItem';
import { SelectTrigger } from 'ui/select/SelectTrigger';
import { SelectValue } from 'ui/select/SelectValue';

const pageSizeOptions = [10, 20, 30, 40, 50];

interface TablePaginationProps
    extends Pick<
        Table<FormattedStreamValue[]>,
        | 'getState'
        | 'getPrePaginationRowModel'
        | 'getCanPreviousPage'
        | 'getCanNextPage'
        | 'previousPage'
        | 'nextPage'
        | 'setPageSize'
    > {
    config: DataStreamTableConfig;
    className?: string;
}

export function TablePagination({
    config,
    className,
    getState,
    getPrePaginationRowModel,
    getCanPreviousPage,
    getCanNextPage,
    previousPage,
    nextPage,
    setPageSize
}: TablePaginationProps) {
    const { editing } = useDashboardContext();
    const { updateVisualisationConfig } = useVisualisationContext();

    const handlePageSizeChange = (pageSize: number) => {
        if (editing) {
            updateVisualisationConfig?.({
                ...config,
                pageSize
            });
        }

        setPageSize(pageSize);
    };

    return (
        <div
            className={clsx(
                'flex flex-shrink-0 items-center mt-4 pb-1.5 justify-between hide-for-image-export',
                className
            )}
            aria-label='pagination'
        >
            <div className='flex items-center mr-4 space-x-3'>
                <p className='text-sm font-medium whitespace-nowrap'>Per page</p>
                <Select
                    value={`${getState().pagination.pageSize}`}
                    onValueChange={(value: string) => handlePageSizeChange(Number(value))}
                >
                    <SelectTrigger className='w-[70px]'>
                        {pageSizeOptions.includes(getState().pagination.pageSize) ? (
                            <SelectValue placeholder={getState().pagination.pageSize} />
                        ) : (
                            <span className='italic pointer-events-none'>{getState().pagination.pageSize}</span>
                        )}
                    </SelectTrigger>
                    <SelectContent side='top' aria-label='pagination rows-per-page'>
                        {pageSizeOptions.map((pageSize) => (
                            <SelectItem key={pageSize} value={`${pageSize}`} aria-label={`${pageSize} rows-per-page`}>
                                {pageSize}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>

            <div className='flex items-center space-x-3'>
                <p className='flex items-center justify-center text-sm font-medium whitespace-nowrap'>
                    Showing {getState().pagination.pageIndex * getState().pagination.pageSize + 1} -{' '}
                    {getCanNextPage()
                        ? (getState().pagination.pageIndex + 1) * getState().pagination.pageSize
                        : getPrePaginationRowModel().rows.length}{' '}
                    of {getPrePaginationRowModel().rows.length}
                </p>

                <div className='flex space-x-2'>
                    <Button
                        variant='secondary'
                        className='px-input py-smallButton'
                        onClick={() => previousPage()}
                        disabled={!getCanPreviousPage()}
                        icon={<FontAwesomeIcon icon={faChevronLeft} />}
                    />
                    <Button
                        variant='secondary'
                        className='px-input py-smallButton'
                        onClick={() => nextPage()}
                        disabled={!getCanNextPage()}
                        icon={<FontAwesomeIcon icon={faChevronRight} />}
                    />
                </div>
            </div>
        </div>
    );
}
