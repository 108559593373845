import type { AccessControlType } from '@squaredup/constants';
import type { ResolveScopesParams } from 'dynamo-wrapper';
import { GraphQueryConfig } from '../dashboard-engine/datasources/graph/graphCustom/Config';
import API from './API';
import { getCurrentWorkspaceId } from './WorkspaceUtil';
import { handleError, handleResponse } from './util';

interface GraphResults {
    verticesResults?: object[];
    edgesResults?: object[];
    gremlinQueryResults?: unknown[];
}

/**
 * Perform a query against the graph database
 * @param data Query & bindings
 */
export const Query = (data: GraphQueryConfig, accessControlType?: AccessControlType) => {
    // The active workspace is used for access control in workspace contexts,
    // so include it if we have it.
    //
    // If the caller doesn't specify access control type, default to 'workspaceLinks',
    // as this is normally what we want for graph queries.
    //
    const workspaceId = data.workspaceId ?? data.workspaceId === null ? undefined : getCurrentWorkspaceId();
    const defaultAccessControlType = workspaceId ? 'workspaceLinks' : 'direct';

    const payload = {
        workspaceId,
        ...data,
        accessControlType: accessControlType ?? defaultAccessControlType
    };

    return API.post<{
        gremlinQueryResults: any[];
    }>('graph/query', payload)
        .then(handleResponse)
        .catch(handleError);
};

/**
 * Perform an incremental upsert against the graph database
 * @param data Query & bindings
 */
export type GraphUpdatePayload = {
    vertices?: object[];
    edges?: object[];
};
export const Update = (data: GraphUpdatePayload) =>
    API.post<GraphResults>('graph/update', data).then(handleResponse).catch(handleError);

/**
 * Perform a replace against the graph database
 * @param data Query & bindings
 */
export const Replace = (data: any) =>
    API.post<GraphResults>('graph/replace', data).then(handleResponse).catch(handleError);

/**
 * Perform a delete against the graph database
 * @param data Query & bindings
 */
export const Delete = (data: any) =>
    API.post<GraphResults>('graph/delete', data).then(handleResponse).catch(handleError);

/**
 * Determine what object(s) are in scope for a specific workspace / all workspaces,
 * a specific scope / all scopes. Can invoke for a list of nodes ids, a list of
 * sourceTypes, or a list of types, or any combination of these.
 * Returns a map key'd by workspaceId whose values are arrays of node objects
 * currently containing only the id field (a string).
 * @param data Query & bindings
 */
export const ResolveWorkspaceScopesToNodeIds = (data: ResolveScopesParams) =>
    API.post('graph/resolvescopes', data).then(handleResponse).catch(handleError);
