import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IsTenantAdmin } from 'services/AccessControlService';
import { TENANT_DATA, Tenant, TenantResponse, UpdateTenant } from 'services/TenantService';
import { SettingsTemplate } from '../SettingsTemplate';

interface OrganisationFormValues {
    displayName: string;
}

function OrganisationForm({ tenant }: { tenant: TenantResponse }) {
    const isAdmin = IsTenantAdmin(tenant);

    const formProps = useForm<OrganisationFormValues>({
        defaultValues: { displayName: tenant.displayName }
    });

    const queryClient = useQueryClient();

    const { mutateAsync, isLoading } = useMutation(UpdateTenant, {
        onSettled: (res) => {
            queryClient.invalidateQueries([TENANT_DATA]);
            formProps.reset({ displayName: res?.displayName });
        }
    });

    if (!isAdmin) {
        return (
            <dl>
                <dt className='text-sm font-medium leading-6'>Name</dt>
                <dd className='mt-1 text-lg leading-6 text-textSecondary'>{tenant.displayName}</dd>
            </dl>
        );
    }

    return (
        <FormProvider {...formProps}>
            <form className='max-w-4xl' onSubmit={formProps.handleSubmit((data) => mutateAsync(data))}>
                <Field.Input
                    disabled={isLoading}
                    name='displayName'
                    label='Name'
                    data-testid='displayName'
                    validation={{
                        required: true,
                        maxLength: { value: 128, message: "Name can't be longer than 128 characters" }
                    }}
                />
                <Button className='mt-8' type='submit' disabled={isLoading || !formProps.formState.isDirty}>
                    {isLoading ? (
                        <>
                            <LoadingSpinner size={18} />
                        </>
                    ) : (
                        'Save'
                    )}
                </Button>
            </form>
        </FormProvider>
    );
}

export default function OrganisationPage() {
    const { data: tenant, isLoading } = useQuery([TENANT_DATA], Tenant);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (!tenant) {
        return (
            <p>
                <strong>Oops!</strong> Something went wrong while loading your organization settings.
            </p>
        );
    }

    return (
        <SettingsTemplate
            title='Organization'
            description='Configure your organization settings.'
        >
            <OrganisationForm tenant={tenant} />
        </SettingsTemplate>
    );
}
