import { debounce } from 'lodash';
import Auth from 'services/Auth';

// Simple utility function to ensure that any issues with sending those events doesn't take down our code!
export default function trackEvent(eventName, payload) {
    try {
        const context = Auth?.user?.tenant ? { groupId: Auth.user.tenant } : {};
        window.analytics?.track(eventName, payload, context); //eslint-disable-line no-undef
    } catch (e) {
        // Never expect to hit this, it would indicate we're mis configured, or someone has messed
        // with index.html, around how we init segment
        console.error('Analytics error', e); //eslint-disable-line no-console
    }
}

export const trackEventDebounced = debounce((eventName, payload) => {
    trackEvent(eventName, payload);
}, 1000);