import { Node } from '@squaredup/graph';
import type { Config } from 'dynamo-wrapper';
import { BuiltInTypes } from 'lib/types';
import { PluginSourceConfig } from 'services/SourceConfigService';

type BuiltInTypeKeys = keyof typeof BuiltInTypes;

export const getNameForSource = (
    datasources: Config<object>[] | PluginSourceConfig[] | undefined,
    node: Node | Record<string, unknown>,
    sourceName: string
) => {
    const typedNode = node as Node;

    if (`${typedNode.sourceName}` === 'Workspace' && typedNode.sourceType?.[0]?.startsWith('squaredup/')) {
        const builtInTypeKey: BuiltInTypeKeys = typedNode.type?.[0] as BuiltInTypeKeys;
        return BuiltInTypes[builtInTypeKey]?.singular || `${typedNode.sourceName}`;
    }

    if (sourceName && sourceName !== 'Custom') {
        return sourceName;
    }

    const configId = typedNode.__configId;
    const datasource = datasources?.find((d) => String(d.id) === configId?.[0]);

    if (!datasource) {
        // Could not find datasource
        return sourceName || `${typedNode.sourceName}`;
    }

    return datasource.displayName;
};
