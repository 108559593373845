import { InfoTip } from '@/components/InfoTip';
import Text from '@/components/Text';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import * as Constants from '@squaredup/constants';
import { Serialised } from '@squaredup/ids';
import Button from 'components/button/Button';
import Copy from 'components/Copy';
import Field from 'components/forms/field/Field';
import Form from 'components/forms/form/Form';
import { AutocompleteOption } from 'components/forms/jsonForms/autocompleteOptions';
import { AgentGroupWithPlatform } from 'components/hooks/useAgentGroups';
import { AgentWithRelatedObjects } from 'components/hooks/useAgents';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ifNotOutside, ModalButtons } from 'components/Modal';
import type { Agent, AgentDownload } from 'dynamo-wrapper';
import trackEvent from 'lib/analytics';
import { difference, upperFirst, xor } from 'lodash';
import { FC, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AGENT_GROUPS } from 'services/AgentGroupService';
import {
    AddToGroups,
    Create as AgentCreate,
    AGENTS,
    Update as AgentUpdate,
    RemoveFromGroups
} from 'services/AgentService';
import { APIKEY_DATA, Add as ApiKeyAdd } from 'services/ApiKeyService';
import { AgentDownloadChoice } from './AgentDownloadsModal';

type AgentFormData = Pick<Agent, 'description' | 'name'> & {
    agentGroups: AutocompleteOption[];
    apiKey?: {
        id: string;
    };
};

type AgentAddModalProps = {
    onClose: (apiKeyId: string | undefined) => void;
    onSave: () => void;
    agent: AgentWithRelatedObjects | undefined;
    latestAgent: AgentDownload | undefined;
    groups?: Serialised<AgentGroupWithPlatform>[];
    agentGroupRestrictedToPlatforms: Map<string, string[]> | undefined;
};

export const AgentAddModal: FC<AgentAddModalProps> = ({
    onClose,
    onSave,
    agent,
    groups,
    latestAgent,
    agentGroupRestrictedToPlatforms
}) => {
    const queryClient = useQueryClient();
    const [isCreated, setIsCreated] = useState(false);
    const [tempApiKey, setTempApiKey] = useState<string>();
    const [newApiKey, setNewApiKey] = useState<string>();

    const defaults = agent && {
        name: agent.displayName,
        description: agent.description,
        apiKey: agent.apiKey,
        agentGroups: agent.formattedAgentGroups
    };
    const formattedAgentGroups: AutocompleteOption[] = groups
        ? groups.map((group) => {
              const platforms = agentGroupRestrictedToPlatforms?.get(group.id) ?? [];
              return {
                  label: `${group.displayName ?? group.name} (${upperFirst(group.platformSummary)} platform)`,
                  value: group.id,
                  isDisabled:
                      platforms.length > 0 &&
                      (agent?.platform === undefined || !platforms.includes(agent?.platform.toLowerCase()))
              };
          })
        : [];

    const { mutateAsync: saveAgent } = useMutation(
        async (data: AgentFormData) => {
            setTempApiKey(undefined);
            const { name, description = '' } = data;
            const agentGroupIds = data.agentGroups?.map((option) => option.value) ?? [];

            if (agent?.id) {
                // editing existing agent
                const agentId = agent.id;
                await AgentUpdate(name, description, agentId, data.apiKey?.id);
                trackEvent('Agent Updated', { name });

                const existingIds = agent.agentGroups ?? [];
                const addedGroups = difference(agentGroupIds, existingIds);
                if (addedGroups.length > 0) {
                    await AddToGroups(agentId, addedGroups);
                }

                const removedGroups = difference(existingIds, agentGroupIds);
                if (removedGroups.length > 0) {
                    await RemoveFromGroups(agentId, removedGroups);
                }
            } else {
                // Create the API Key first, so we can specify it with the agent
                const apikey = await ApiKeyAdd(
                    `${name.toLowerCase()}-${Date.now()}`,
                    description,
                    Constants.ApiKeySubTypes.PLUGIN
                );

                // add new agent
                await AgentCreate(name, description, undefined, apikey.id, agentGroupIds);
                trackEvent('Agent Created', { name });

                return apikey.key;
            }
        },
        {
            onSettled: async (createdApiKey, error, data) => {
                // Invalidate queries
                const invalidationPromises: Promise<void>[] = [];
                invalidationPromises.push(queryClient.invalidateQueries([AGENTS]));

                if (createdApiKey || data.apiKey?.id !== agent?.apiKeyId) {
                    invalidationPromises.push(queryClient.invalidateQueries([APIKEY_DATA]));
                }

                const isEdit = Boolean(agent?.id);
                const modifiedAgentGroups = isEdit
                    ? xor(
                          agent?.agentGroups,
                          data.agentGroups.map((option) => option.value)
                      )
                    : data.agentGroups ?? [];

                if (modifiedAgentGroups.length > 0) {
                    invalidationPromises.push(queryClient.invalidateQueries([AGENT_GROUPS]));
                }

                await Promise.allSettled(invalidationPromises);

                if (createdApiKey) {
                    setNewApiKey(createdApiKey);
                    setIsCreated(true);
                } else {
                    onSave();
                }
            }
        }
    );

    const onChanged = (data: AgentFormData) => {
        // Test if Api key has changed
        if (data?.apiKey?.id && data.apiKey.id !== `${agent?.apiKey.id}`) {
            setTempApiKey(data.apiKey.id);
        }
    };

    const handleClose = () => {
        onClose(tempApiKey);
    };

    const isEditing = Boolean(agent);

    return (
        <Modal
            title={isEditing ? 'Edit agent' : 'Add agent'}
            close={ifNotOutside(handleClose)}
            fullWidth
            maxWidth='max-w-3xl'
        >
            <hr className='border-dividerPrimary' />
            {newApiKey ? (
                <>
                    <div
                        className='flex flex-col w-full h-full px-8 pt-5 tile-scroll-overflow gap-7'
                        data-testid='agentApiKeyModal'
                    >
                        <InfoTip className='mb-0' icon={faCircleCheck} iconClassName='text-statusHealthyPrimary'>
                            Agent created successfully
                        </InfoTip>
                        <div className='flex flex-col'>
                            <Text.Body>API key:</Text.Body>
                            <div className='flex mt-2 rounded py-input leading-input px-md text-textIncomplete bg-componentBackgroundPrimary ring-outlinePrimary ring-inset ring-1'>
                                <pre className='mr-auto' data-testid='generatedApiKey'>
                                    {newApiKey}
                                </pre>
                                <Copy value={newApiKey} />
                            </div>
                        </div>
                        <div>
                            <AgentDownloadChoice agentPlatform={undefined} latestAgent={latestAgent} />
                        </div>
                    </div>
                    <ModalButtons>
                        <Button type='submit' onClick={() => onClose(undefined)} data-testid='closeModal'>
                            Done
                        </Button>
                    </ModalButtons>
                </>
            ) : (
                <Form submit={saveAgent} defaultValues={defaults} onChange={onChanged}>
                    {(isValid, isSubmitting, data) => (
                        <>
                            <div className='flex flex-col w-full h-full px-8 pt-5 mb-16 tile-scroll-overflow'>
                                <Field.Input
                                    name='name'
                                    label='Name'
                                    title='Name'
                                    placeholder='Enter a name'
                                    validation={{ required: true, min: 2, max: 128 }}
                                    data-testid='agentNameInput'
                                />
                                <Field.Input
                                    name='description'
                                    label='Description'
                                    title='Description'
                                    placeholder='Enter a description'
                                    validation={{ max: 128 }}
                                />
                                <Field.Input
                                    name='agentGroups'
                                    label='Agent group(s)'
                                    title='Agent group(s)'
                                    placeholder='Select one or more agent groups'
                                    type='autocomplete'
                                    isMulti={true}
                                    options={formattedAgentGroups}
                                    noOptionsMessage={() => 'No groups available'}
                                />
                                {isEditing && (
                                    <>
                                        <Field.Input
                                            type='apiKey'
                                            name='apiKey'
                                            title='API key'
                                            label='API key'
                                            subType={Constants.ApiKeySubTypes.PLUGIN}
                                            disabled={
                                                !(
                                                    data.name !== undefined &&
                                                    data.name.length >= 1 &&
                                                    data.name.length <= 128
                                                )
                                            }
                                            keyDisplayName={data.name + '-' + Date.now()}
                                            keyDescription={data.description}
                                        />
                                        {tempApiKey && (
                                            <p className='mt-4 text-textSecondary'>
                                                Click "Save" for the new API key to take effect.
                                            </p>
                                        )}
                                    </>
                                )}
                                {!isEditing && (
                                    <Field
                                        label='API key'
                                        description='An API key for this agent will be generated when you click Next.'
                                    />
                                )}
                            </div>

                            <ModalButtons>
                                <Button
                                    type='button'
                                    onClick={() => handleClose()}
                                    variant='tertiary'
                                    data-testid='closeModal'
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type='submit'
                                    disabled={isSubmitting || !isValid || isCreated}
                                    data-testid='submitChanges'
                                >
                                    {isSubmitting ? <LoadingSpinner size={18} /> : isEditing ? 'Save' : 'Next'}
                                </Button>
                            </ModalButtons>
                        </>
                    )}
                </Form>
            )}
        </Modal>
    );
};
