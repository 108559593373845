import { DashboardVariable } from 'dashboard-engine/types/Dashboard';
import { DRILLDOWN_NODE, NodeWithCanonical } from 'pages/drilldowns/graph-node/common';
import { fiveMinutes } from 'queries/constants';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { QueryClient, UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { GetVariableObjects, ListVariablesForDashboard } from 'services/VariableService';

type VariableDefault = 'all' | 'none';

type QueryReturnType = {
    variable: {
        id: string;
        displayName: string;
        data: {
            default: VariableDefault;
            allowMultipleSelection: boolean;
        };
    };
    scope: { id: string };
}[];
type QuerySelectReturnType = DashboardVariable[];

const resolveInitialObjects = async (
    queryClient: QueryClient,
    objects?: DashboardVariable['selectedObjects']
): Promise<DashboardVariable['selectedObjects']> => {
    if (!objects || objects.length === 0) {
        return Promise.resolve([]);
    }

    // Objects already have name, return them unchanged
    if (objects[0].name) {
        return Promise.resolve(objects);
    }

    // Get object name from cache, if available
    if (objects.length === 1) {
        const cachedData = queryClient.getQueryData<NodeWithCanonical>([DRILLDOWN_NODE, objects[0]?.id]);

        if (cachedData) {
            return [
                {
                    id: objects[0]?.id,
                    name: cachedData.name?.[0] ?? ''
                }
            ];
        }
    }

    // As a last resort, fetch the object names from the API
    return GetVariableObjects({
        nodeIds: objects.map((o) => o.id)
    });
};

/**
 * useQuery returning a specific dashboard's variable
 * @param options The react-query options e.g. refreshInterval
 * @returns An array of the dashboard's variables
 */
export const useDashboardVariables = (
    dashboardId?: string,
    initial?: {
        selectedObjects: DashboardVariable['selectedObjects'];
        selectedAll?: boolean;
    },
    options?: Omit<
        UseQueryOptions<QuerySelectReturnType, unknown, QuerySelectReturnType, string[]>,
        'enabled' | 'initialData'
    >
) => {
    const queryClient = useQueryClient();
    return useQuery(
        dashboardQueryKeys.variables(dashboardId, initial),
        async () => {
            const [variables, initialObjects] = await Promise.all([
                ListVariablesForDashboard(dashboardId!) as Promise<QueryReturnType>,
                resolveInitialObjects(queryClient, initial?.selectedObjects)
            ]);

            return variables.map(({ variable, scope }) => ({
                id: variable.id,
                name: variable.displayName,
                scopeId: scope.id,
                default: variable.data?.default,
                allowMultipleSelection: variable.data?.allowMultipleSelection,
                selectedObjects: initialObjects.length ? initialObjects : [],
                selectedAll: initial?.selectedAll || (variable.data?.default === 'all' && initialObjects.length === 0)
            }));
        },
        {
            enabled: Boolean(dashboardId),
            staleTime: fiveMinutes,
            cacheTime: fiveMinutes,
            ...options
        }
    );
};
