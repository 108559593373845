import { fiveMinutes } from 'queries/constants';
import { datasourceConfigQueryKeys } from 'queries/queryKeys/datasourceConfigKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { ListPluginSourceConfigsForAdmin, ListPluginSourceConfigsForAllWorkspaces } from 'services/SourceConfigService';

type QueryReturnType = Awaited<ReturnType<typeof ListPluginSourceConfigsForAllWorkspaces>>;
type QuerySelectReturnType = QueryReturnType;

/**
 * useQuery returning the data source configs within the tenant
 * @param options The react-query options e.g. refreshInterval
 * @returns All data source configs within the tenant
 */
export const useDatasourceConfigs = (
    options?: UseQueryOptions<QueryReturnType, unknown, QuerySelectReturnType, string[]>
) => 
    useQuery(
        datasourceConfigQueryKeys.list, 
        ListPluginSourceConfigsForAdmin, 
        {
            staleTime: fiveMinutes,
            cacheTime: fiveMinutes,
            keepPreviousData: true,
            ...options
        }
    );
