import { buildQuery } from '@squaredup/graph';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import stringify from 'fast-json-stable-stringify';
import { useQuery } from 'react-query';
import { Query } from 'services/GraphService';
import { propertyExclusionList } from '../../constants';
import { FilterQueryParameters } from '../../utilities/getObjectFilters';

/**
 * Gets all properties for a datastream based on filtered sources, types, and properties
 */
export const useObjectFilterProperties = ({
    scopeBaseQuery,
    queryParams,
    isFilterQueryReady
}: {
    scopeBaseQuery: string;
    queryParams: FilterQueryParameters;
    isFilterQueryReady: boolean;
}) => {
    const { isGlobal } = useDataStreamWorkspaceContext();

    return useQuery(
        ['properties', scopeBaseQuery, stringify(queryParams), stringify(isGlobal)],
        async () => {
            const propertiesQuery =
                scopeBaseQuery +
                `.properties()
                .where(__.not(has('id', within(${propertyExclusionList.map((t) => `'${t}'`).join(',')}))))
                .key()
                .dedup()`;

            const { gremlinQueryResults } = await Query(
                buildQuery(queryParams, propertiesQuery),
                isGlobal ? 'directOrAnyWorkspaceLinks' : undefined
            );

            return (gremlinQueryResults as string[]).sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1));
        },
        {
            enabled: isFilterQueryReady,
            keepPreviousData: true
        }
    );
};
