import * as Popover from '@radix-ui/react-popover';
import { buttonVariants } from '@/components/Button';
import { cn } from '@/lib/cn';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface WorkspaceDropdownTriggerProps {
    open: boolean;
};

export const WorkspaceDropdownTrigger: React.FC<WorkspaceDropdownTriggerProps> = ({ open }) => {
    return (
        <Popover.Trigger asChild>
            <button 
                data-testid='workspace-selector' 
                className={cn(
                    buttonVariants({ variant: 'secondary' }), 
                    'px-xs py-xs ring-0 rounded-none hover:bg-secondaryButtonBackground focus:bg-secondaryButtonBackground h-full bg-transparent'
                )}
            >
                <FontAwesomeIcon
                    icon={open ? faChevronUp : faChevronDown}
                    className='flex-none text-base transition text-[14px] w-[14px] h-[14px]'
                />
            </button>
        </Popover.Trigger>
    );
};