import Tooltip from 'components/tooltip/Tooltip';
import useOverflowing from 'lib/useOverflowing';

interface DrilldownTabLabelProps {
    dashboardName: string;
    workspaceName: string;
}

export const DrilldownTabLabel: React.FC<DrilldownTabLabelProps> = ({ dashboardName, workspaceName }) => {
    const [overflowRef, overflowing] = useOverflowing<HTMLSpanElement>();

    const tooltipTitle = overflowing
        ? `${dashboardName} from the ${workspaceName} workspace`
        : `From the ${workspaceName} workspace`;

    return (
        <Tooltip title={tooltipTitle} variant='light'>
            <span ref={overflowRef} className='block max-w-[300px] truncate'>
                {dashboardName}
            </span>
        </Tooltip>
    );
};
