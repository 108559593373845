import DropdownMenu from 'components/dropdownMenu';
import React, { useState } from 'react';
import Select, { type CSSObjectWithLabel } from 'react-select';
import { useTags } from 'services/TagService';

export interface TagMenuProps {
    selectedTags?: string[];
    setSelectedTags: (tags: string[]) => void;
}

export default function TagMenu({ selectedTags = [], setSelectedTags }: TagMenuProps) {
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);
    const { data: tags } = useTags();

    return (
        <DropdownMenu onOpenChange={setIsDropdownOpened}>
            <DropdownMenu.Button active={selectedTags.length > 0} className='whitespace-nowrap' dropdownOpen={isDropdownOpened} showChevron useFilterStyle>
                Tags
                {selectedTags.length > 0 && (
                    <span className='px-2 ml-2 rounded-full bg-textDisabled' aria-label='numFiltersApplied'>{selectedTags.length}</span>
                )}
            </DropdownMenu.Button>
            <DropdownMenu.Menu align='start'>
                <div className='w-[27rem]' aria-label='tagsAutocomplete'>
                    <Select
                        isMulti
                        isClearable
                        styles={{
                            menu: (provided) => ({
                                ...provided,
                                marginTop: 8
                            }) as CSSObjectWithLabel
                        }}
                        components={{
                            ClearIndicator: () => <React.Fragment />
                        }}
                        hideSelectedOptions
                        placeholder='Search for tags'
                        value={selectedTags.map((tag) => ({ value: tag, label: tag })) as any}
                        onChange={(newTags) => setSelectedTags(newTags.map((tag) => tag.value))}
                        isSearchable
                        options={tags?.map((tag) => ({ value: tag, label: tag }))}
                        name='color'
                        classNamePrefix='autocomplete'
                        noOptionsMessage={() => 'No tags were found'}
                    />
                </div>
                <DropdownMenu.Separator />
                <DropdownMenu.SecondaryItem disabled={selectedTags.length === 0} onSelect={() => setSelectedTags([])}>
                    Clear filter
                </DropdownMenu.SecondaryItem>
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
}
