import { TooltipButton } from '@/components/Button';
import DropdownMenu from '@/components/DropdownMenu';
import { faCodeFork, faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetGraphNodes, useGetNetworkNode, useMapId } from 'components/map/context/NetworkMapStoreContext';
import { groupSizeThreshold } from 'components/map/data/utils/rewriteLargeNodeGroups';
import { NodeAction, useHandleNodeAction } from 'components/map/hooks/useHandleNodeAction';
import { TruncatedText } from 'components/TruncatedText';
import { sortBy } from 'lodash';
import { useCallback, useMemo } from 'react';

const ungroupAllLimit = 10;

interface GroupNodeObjectSelectorProps {
    id: string;
    isPinned?: boolean;
}

export const GroupNodeObjectSelector: React.FC<GroupNodeObjectSelectorProps> = ({ id, isPinned }) => {
    const getGraphNodes = useGetGraphNodes();
    const handleNodeAction = useHandleNodeAction();
    const mapId = useMapId();
    const { sourceNodeIds } = useGetNetworkNode()(id)?.data ?? {};

    const groupNodes = useMemo(() => {
        const allGraphNodes = getGraphNodes();
        if (!sourceNodeIds) {
            return [];
        }
        
        return sortBy(
            allGraphNodes
                .filter(({ id: graphNodeId }) => sourceNodeIds.includes(graphNodeId))
                .map((node) => ({
                    id: node.id,
                    name: Array.isArray(node.name) ? node.name[0] : node.name
                })),
            ({ name }) => name.toLowerCase()
        );
    }, [sourceNodeIds, getGraphNodes]);

    const handleSelection = useCallback((ids: string[]) => {
        handleNodeAction(
            isPinned ? NodeAction.pinAndExpand : NodeAction.ungroup, { nodeIds: ids, groupNodeId: id }
        );
    }, [handleNodeAction, isPinned, id]);

    if (!sourceNodeIds?.length) {
        return null;
    }

    return (
        <DropdownMenu>
            <DropdownMenu.Trigger>
                <TooltipButton 
                    variant='tertiary'
                    onClick={(e) => e.stopPropagation()}
                    title={'Ungroup object'}
                    className='p-xxxs'
                >
                    <FontAwesomeIcon 
                        icon={faCodeFork} 
                        fixedWidth 
                    />
                </TooltipButton>
            </DropdownMenu.Trigger>

            <DropdownMenu.Menu 
                className='w-full p-xxxs min-w-[100px] max-w-[260px] bg-tileBackground overflow-auto max-h-72 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'
                align='start'
                container={document.getElementById(mapId) ?? document.body}
            >
                {groupNodes.length <= ungroupAllLimit && (
                    <>
                        <DropdownMenu.Item
                            key='ungroupAll'
                            onClick={(e) => e.stopPropagation()}
                            onSelect={() => handleSelection(groupNodes.map((node) => node.id))}
                            className='w-full px-2 text-sm font-bold p-xxxs'
                        >

                            <TruncatedText 
                                title='Ungroup all objects' 
                                className='w-full'
                                tooltipClassName='!z-[100]'
                            />
                        </DropdownMenu.Item>

                        <DropdownMenu.Separator className='my-xxxs' />
                    </>
                )}

                {groupNodes.length !== groupSizeThreshold ? groupNodes.map((node) => {
                    return (
                        <DropdownMenu.Item
                            key={node.id}
                            onClick={(e) => e.stopPropagation()}
                            onSelect={() => handleSelection([node.id])}
                            className='w-full px-2 text-sm p-xxxs'
                        >
                            <TruncatedText 
                                title={node.name} 
                                className='w-full'
                                tooltipClassName='!z-[100]'
                            />
                        </DropdownMenu.Item>
                    );
                }) : (
                    <DropdownMenu.Item
                        key={'ungroupMessage'}
                        icon={<FontAwesomeIcon icon={faQuestionCircle} />}
                        disabled
                        className='w-full px-2 text-sm p-xxxs text-textDisabled'
                    >
                        Groups consist of {groupSizeThreshold} or more objects, ungrouping a single object will 
                        result in all objects being ungrouped.
                    </DropdownMenu.Item>
                )}
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
};
