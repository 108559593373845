import Text from '@/components/Text';
import { Serialised } from '@squaredup/ids';
import { isFeatureEnabled, isUnderLimit } from '@squaredup/tenants';
import LoadingSpinner from 'components/LoadingSpinner';
import { ModalButtons } from 'components/Modal';
import { AccessControl } from 'components/accessControl/AccessControl';
import { PermissionOption } from 'components/accessControl/CustomACLEditor';
import ToggleField from 'components/accessControl/ToggleField';
import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import DisplayJsonUi, { getVisibleFields } from 'components/forms/jsonForms/DisplayJsonUi';
import { ResolvedUIConfig } from 'components/forms/jsonForms/resolveAutocompleteOptions';
import { FeatureUnavailableBanner } from 'components/plans/FeatureUnavailableBanner';
import { LimitReachedBanner } from 'components/plans/LimitReachedBanner';
import PluginContext from 'contexts/PluginContext';
import type { AccessControlEntryModel, Plugin } from 'dynamo-wrapper';
import { pickBy } from 'lodash';
import { useDatasourceConfigsCount } from 'queries/hooks/useDatasourceConfigsCount';
import { useTier } from 'queries/hooks/useTier';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { TestOutput } from './PluginTest';
import SubmitButton from './SubmitButton';
import { PluginContextValue, usePluginContext } from './components/PluginConfigContext';
import { PluginDetailPanel } from './components/PluginDetailPanel';
import { getEditMode } from './components/getEditMode';
import { useInteractiveTester } from './components/useInteractiveTester';
import { PluginMutationDetails, useMutatePlugin } from './components/useMutatePlugin';
import { usePlugin } from './components/usePlugin';
import { usePluginDefaultValues } from './components/usePluginDefaultValues';
import { PluginConfigFormData } from './types';

interface PluginConfigFormProps {
    pluginSubmitHandler: PluginSubmitHandler;
    cancelHandler: CancelHandler;
}

export type PluginSubmitHandler = (details: PluginMutationDetails) => void;
export type CancelHandler = () => void;

export function InstallSampleDashboards() {
    const { config, workspaceToLinkTo, plugin, setInstallSampleDashboards, installSampleDashboards } =
        usePluginContext();

    const editMode = getEditMode(config);

    useEffect(() => {
        if (editMode !== 'existing' && (plugin as any)?.defaultContent && workspaceToLinkTo !== '') {
            setInstallSampleDashboards(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (editMode === 'existing' || !(plugin as any)?.defaultContent || workspaceToLinkTo === '') {
        return null;
    }

    return (
        <div className='mt-8'>
            <ToggleField
                label='Install sample dashboards'
                onToggle={setInstallSampleDashboards}
                toggleDefaultValue={installSampleDashboards}
            />
        </div>
    );
}

/**
 * Get the properties from the default values object which are not set in the form data.
 *
 * If a field was previously hidden but is now visible we need to reset the value because
 * react-hook-form will remove form data properties that don't have a corresponding field,
 * and so the default value we initially set will have been lost.
 */
const getMissingDefaultValues = (
    formFields: ResolvedUIConfig[] | undefined,
    formData: PluginConfigFormData,
    defaultValues: Record<string, unknown>
) => {
    const hasFormData = Object.keys(formData).length > 0;

    if (!hasFormData) {
        return undefined;
    }

    const visibleFields = getVisibleFields(formFields ?? [], formData);
    const defaultValueFieldNames = Object.keys(defaultValues).filter((k) => defaultValues[k] != null);

    const missingDefaults = visibleFields.filter(
        (f) => formData[f.name] === undefined && defaultValueFieldNames.includes(f.name)
    );

    if (missingDefaults.length === 0) {
        return undefined;
    }

    return pickBy(defaultValues, (_v, k) => missingDefaults.some((f) => f.name === k));
};

const filterAgentGroupsByPlatform = (
    agentGroups: PluginContextValue['agentGroups'],
    plugin: Serialised<Plugin> | undefined
) => {
    if (plugin === undefined) {
        return [];
    }

    // Fallback to windows only for backwards compatibility reasons
    const pluginPlatforms = plugin.restrictedToPlatforms ?? ['windows'];

    // Filter out agent groups with platforms that do not match the plugin's supported platforms, if any
    const filteredAgentGroups = agentGroups.filter(
        (ag) => pluginPlatforms.length === 0 || (
            ag.platforms.length > 0 &&
            ag.platforms.every((p) => pluginPlatforms.includes(p))
        )
    );

    return filteredAgentGroups;
};

const getAgentGroupsFormField = (
    agentGroups: PluginContextValue['agentGroups'],
    plugin: Serialised<Plugin>
): ResolvedUIConfig => {
    const commonProps = {
        name: 'agentGroupId',
        label: 'Agent Group',
        validation: { required: true },
        title: '',
        help: 'An agent group is required to stream data from an on-premise data source. Agent groups are managed under Settings > Relay Agents.'
    };

    if (agentGroups.length === 0) {
        const pluginPlatforms = plugin.restrictedToPlatforms ?? ['windows'];
        const message =
            pluginPlatforms.length > 0 ? (
                <>
                    {' '}
                    (platform required: <span className='capitalize'>{pluginPlatforms.join(', ')})</span>
                </>
            ) : (
                <></>
            );
        return {
            ...commonProps,
            type: 'custom',
            required: true,
            children: (
                <div>
                    <Text.Body className='text-textSecondary'>
                        There are no compatible agent groups{message}.
                        <br />
                        Go to{' '}
                        <NavLink to='/settings/relay' className='text-textLink hover:underline focus-visible:underline'>
                            Settings &gt; Relay Agents
                        </NavLink>
                    </Text.Body>
                    <Field.Hidden {...commonProps} />
                </div>
            )
        };
    }

    return {
        ...commonProps,
        type: 'autocomplete',
        placeholder: 'Select an Agent Group',
        isMulti: false,
        options: agentGroups,
        noOptionsMessage: () => 'No Agent groups available.'
    };
};

export const PluginConfigForm: FC<PluginConfigFormProps> = ({ pluginSubmitHandler, cancelHandler, children }) => {
    const [triggerIndex, setTriggerIndex] = useState(false);

    const underValidationElement = useRef<HTMLDivElement>(null);
    const docsIframeElement = useRef<HTMLIFrameElement>(null);

    const {
        config,
        plugin: selectedPlugin,
        agentGroups: allAgentGroups,
        workspaceToLinkTo,
        installSampleDashboards
    } = usePluginContext();

    const [acl, setACL] = useState<AccessControlEntryModel[]>();
    const editMode = getEditMode(config);

    const { id: pluginId } = selectedPlugin ?? { id: '' };
    const { data: plugin, isLoading: isLoadingPlugin } = usePlugin(pluginId);
    const { data: datasourceConfigCount, isLoading: isLoadingConfigCount } = useDatasourceConfigsCount();
    const { data: tier, isLoading: isLoadingTier } = useTier();

    const agentGroups = filterAgentGroupsByPlatform(allAgentGroups, plugin);

    const { error, isSubmitting, submit } = useMutatePlugin({
        config,
        selectedPlugin,
        workspaceToLinkTo,
        acl,
        installSampleDashboards,
        onSubmit: pluginSubmitHandler
    });

    const {
        defaultValues,
        formFields,
        isLoading: isLoadingDefaults
    } = usePluginDefaultValues({
        config,
        selectedPlugin,
        agentGroups
    });

    const methods = useForm<PluginConfigFormData>({ defaultValues, shouldUnregister: true, mode: 'all' });

    const {
        handleSubmit,
        formState: { isDirty, isValid, errors, dirtyFields, isSubmitted },
        reset,
        setValue,
        watch
    } = methods;
    const formData = methods.watch();

    const { testLoading, handleInteractiveTest, handleTest, testData } = useInteractiveTester({
        config,
        selectedPlugin,
        triggerReset: () => reset(formData)
    });

    const onError = useCallback(() => {
        docsIframeElement.current?.contentWindow?.postMessage('scrollToHelp', '*');
    }, []);

    useEffect(() => {
        watch(() => {
            if (!isSubmitted) {
                setTriggerIndex(Object.keys(dirtyFields).some((key) => key !== 'displayName'));
            }
        });
    }, [dirtyFields, isSubmitted, watch]);


    useEffect(() => {
        // reset form if default values get updated
        reset(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formFields, reset]);

    const missingDefaults = getMissingDefaultValues(formFields, formData, defaultValues);
    if (missingDefaults != null) {
        Object.entries(missingDefaults).forEach(([k, v]) => setValue(k, v));
    }

    const isLoading = isLoadingPlugin || isLoadingDefaults || isLoadingConfigCount || isLoadingTier;

    const onSubmit: SubmitHandler<PluginConfigFormData> = (data) => {
        if (plugin?.supportsConfigValidation && (isDirty || !testData)) {
            handleTest(
                { data, wait: 1000 },
                {
                    onSuccess: async (result) => {
                        if (result.testState === 'success') {
                            submit({ data: { ...data, triggerIndex } });
                        }
                    }
                }
            );
        } else {
            submit({ data: { ...data, triggerIndex } });
        }
    };

    // We want to scroll the validation into view when its contents change
    useEffect(() => {
        if (!testLoading) {
            underValidationElement.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }, [testLoading, testData, underValidationElement]);

    const agentGroupField: ResolvedUIConfig | undefined = plugin?.onPrem
        ? getAgentGroupsFormField(agentGroups, plugin)
        : undefined;

    const isOverLimit =
        tier === undefined ||
        (editMode !== 'existing' && !isUnderLimit(tier, 'dataSources', datasourceConfigCount ?? 0));

    const isOnPremUnavailable = plugin?.onPrem === true && editMode !== 'existing' && (tier === undefined || !isFeatureEnabled(tier, 'relayAgents'));
    const tierLimitsInvalid = isOverLimit || isOnPremUnavailable;

    return (
        <PluginContext.Provider value={{ plugin, config, testFunction: handleInteractiveTest }}>
            <div className='flex flex-col flex-1 min-h-0 col-span-2 col-start-1 col-end-2 overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                {isLoading || !Array.isArray(formFields) ? (
                    <LoadingSpinner className='mx-8 mt-5' />
                ) : (
                    <FormProvider {...methods}>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className='flex flex-col flex-1 w-full min-h-0 grid-span'
                            data-testid='form'
                            autoComplete='off'
                        >
                            <div className='flex flex-col flex-1 w-full min-h-0 px-8 pt-5 tile-scroll-overflow'>

                                {editMode !== 'existing' && (
                                    <>
                                        {isOnPremUnavailable ? (
                                            <FeatureUnavailableBanner
                                                featureKey='relayAgents'
                                                summary='Relay agents allow you to securely connect to data sources inside your own network.'
                                                className='mb-5'
                                                container='modal'
                                            />
                                        ) : (
                                            <LimitReachedBanner
                                                currentUsage={datasourceConfigCount}
                                                featureKey='dataSources'
                                                className='mb-5'
                                                content='descriptive'
                                                container='modal'
                                            />
                                        )}
                                    </>
                                )}

                                <DisplayJsonUi
                                    formFields={formFields}
                                    extraFirstField={agentGroupField}
                                    data={formData as Record<string, string>}
                                    disabled={tierLimitsInvalid}
                                />

                                {!tierLimitsInvalid && (
                                    <>
                                        <div className='mt-8'>
                                            <AccessControl
                                                entityId={config?.id}
                                                entityName={config?.displayName}
                                                customACLEditorTitle={
                                                    'Access Control: ' + (config?.displayName || 'New Data Source')
                                                }
                                                permissionOptions={permissionOptions}
                                                defaultPermissionsForNewACE={['RO']}
                                                onACLChange={setACL}
                                                toggleTitle='Restrict access to this data source'
                                                allowUnlinkAll={true}
                                                helpMessage='Enable this to manage user and group access to this data source'
                                                description='Everyone in your organization currently has access to this data source'
                                            />
                                        </div>

                                        {/* Extra controls go here */}
                                        {children}

                                        <InstallSampleDashboards />
                                    </>
                                )}

                                {(testData || testLoading) && (
                                    <TestOutput
                                        loading={testLoading}
                                        helpLink={testData?.result?.link}
                                        messages={testData?.result?.messages}
                                        onPrem={plugin?.onPrem}
                                        underRef={underValidationElement}
                                        result={testData?.testState!}
                                        reRun={() => handleTest({ data: formData as PluginConfigFormData })}
                                        onError={onError}
                                    />
                                )}

                                {error && (
                                    <div className='w-full mt-8 text-statusErrorPrimary'>
                                        {(error as any)?.response?.data?.error || 'An error occurred'}
                                    </div>
                                )}
                            </div>

                            <ModalButtons>
                                <Button
                                    type='button'
                                    onClick={cancelHandler}
                                    variant='tertiary'
                                    data-testid='cancelPluginConfigModalButton'
                                >
                                    Cancel
                                </Button>
                                <SubmitButton
                                    {...{
                                        isSubmitting,
                                        testLoading,
                                        isDirty,
                                        testData,
                                        editMode,
                                        isValid: isValid && !tierLimitsInvalid,
                                        errors
                                    }}
                                    supportsValidation={plugin?.supportsConfigValidation}
                                />
                            </ModalButtons>
                        </form>
                    </FormProvider>
                )}
            </div>

            <PluginDetailPanel 
                pluginId={pluginId} 
                ref={docsIframeElement}
            />
        </PluginContext.Provider>
    );
};

const permissionOptions: PermissionOption[] = [
    {
        value: 'RO',
        label: 'Link to workspace'
    },
    {
        value: 'AD',
        label: 'Full control'
    }
];
