import { Toggle } from '@/components/forms/Toggle';
import Field from 'components/forms/field/Field';
import { Controller } from 'react-hook-form';
import type { ShapeConfigFieldsComponent } from './ShapeConfigFields';

export const TimeShapeConfigFields: ShapeConfigFieldsComponent = ({ name }) => (
    <Field.Label label='Format as Duration'>
        <Controller
            name={`${name}.formatDuration`}
            render={({ field: { value, onChange } }) => (
                <Toggle checked={value} onCheckedChange={() => onChange(!value)} />
            )}
        />
    </Field.Label>
);
