import { InputHTMLAttributes, forwardRef } from 'react';

/**
 * Generic text input field for the following input types:
 * `email`, `hidden`, `number`, `password`, `search`, `tel`, `text`, `url`
 */
export const Text = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>((props, ref) => (
    <input 
        ref={ref}
        className='w-full border-none ring-inset ring-1 ring-outlinePrimary text-textPrimary rounded-input leading-input focus:ring-0 focus:outline-none focus-visible:ring-inset focus-visible:ring-1 py-input px-md bg-componentBackgroundPrimary focus:ring-outlineSecondary hover:ring-outlineSecondary placeholder:text-textIncomplete disabled:text-textDisabled disabled:pointer-events-none'
        {...props}
    />
));
