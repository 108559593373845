import * as TWEEN from '@tweenjs/tween.js';
import stringify from 'fast-json-stable-stringify';
import { sortBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useReactFlow } from 'reactflow';
import { Layout } from 'webcola';
import { useGetNetworkEdges, useGetNetworkNodes, useLayoutType, useSetNetworkNodes } from '../context/NetworkMapStoreContext';
import { LayoutTypes, PositionedPinnableNode } from '../layout/types';
import { WORKSPACE_NODE } from '../components/nodes/WorkspaceNode';

const animationDuration = 350;
const frameMs = 16.7;
const targetLength = 130;

export const useAnimateLayout = () => {
    const { fitView } = useReactFlow();
    const setNodes = useSetNetworkNodes();
    const getNodes = useGetNetworkNodes();
    const getEdges = useGetNetworkEdges();
    const layoutType = useLayoutType();

    const nodeStateString = stringify(
        sortBy(getNodes().filter(({ hidden }) => !hidden), 'id')
            .map(({ id, data }) => [id, data.expanded, data.pinned])
    );

    const layouts = useMemo(() => new Map([
        [
            LayoutTypes.hierarchyVertical,
            () => new Layout()
                .stop()
                .flowLayout('y', 110)
                .symmetricDiffLinkLengths(40)
                .avoidOverlaps(true)
        ],
        [
            LayoutTypes.hierarchyHorizontal,
            () => new Layout()
                .stop()
                .flowLayout('x', 110)
                .symmetricDiffLinkLengths(40)
                .avoidOverlaps(true)
        ],
        [
            LayoutTypes.network,
            () => new Layout()
                .stop()
                .jaccardLinkLengths(targetLength)
                .avoidOverlaps(true)
        ]
    ]), []); 

    useEffect(() => {
        const layout = layouts.get(layoutType)?.();

        if (!layout) {
            return;
        }

        let animationFrame = 0;

        const nodesForAnimation = getNodes().filter(({ hidden }) => !hidden) as PositionedPinnableNode[];
        const positionedNodes = nodesForAnimation.map((node) => ({ 
            id: node.id,
            width: node.type === WORKSPACE_NODE ? node.width + 20 : node.width,
            height: node.type === WORKSPACE_NODE ? node.height + 20 : node.height,
            fixed: node.data.fixedPosition ? 1 : 0,
            x: node.position.x, 
            y: node.position.y
        }));

        const edges = getEdges().filter(({ source, target }) => 
            positionedNodes.find(({ id }) => source === id) &&
            positionedNodes.find(({ id }) => target === id)
        ).map((edge) => {
            const source = positionedNodes.find(({ id }) => edge.source === id)!;
            const target = positionedNodes.find(({ id }) => edge.target === id)!;

            return {
                ...edge,
                source,
                target
            };
        });

        const tweenNodePositionsMap = new Map(positionedNodes.map(({ id, x, y }) => [id, {x, y}]));

        layout
            .stop()
            .nodes(positionedNodes)
            .links(edges)
            .start()
            .stop();
    
        const layoutNodePositionsMap = new Map(
            layout.nodes()
                .map(({ id, x, y, width, height }: any) => [id, { 
                    x: x - (width / 2), 
                    y: y - (height / 2) 
                }])
        );
    
        tweenNodePositionsMap.forEach((position, id) => {
            const targetPosition = layoutNodePositionsMap.get(id);
    
            if (targetPosition) {
                TWEEN.add(
                    new TWEEN.Tween(position)
                        .to(targetPosition, animationDuration)
                        .easing(TWEEN.Easing.Circular.Out)
                        .start()
                );
            }
        });
    
        const animate = (time = 0) => {
            TWEEN.update();
    
            setNodes(currentNodes => currentNodes.map((node) => ({
                ...node,
                ...tweenNodePositionsMap.has(node.id) && {
                    position: tweenNodePositionsMap.get(node.id)
                }
            })));
    
            if (time > (animationDuration * 1.25)) {
                TWEEN.removeAll();
                cancelAnimationFrame(animationFrame);
            } else {
                animationFrame = requestAnimationFrame(() => animate(time + frameMs));
            }

            fitView({ padding: 0.2 });
        };
    
        animate();

        return () => cancelAnimationFrame(animationFrame);
    }, [layouts, nodeStateString, layoutType, fitView, getNodes, setNodes, getEdges]);
};