import { cn } from '@/lib/cn';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { HTMLAttributes, useRef, useState } from 'react';

export interface ExpandableProps extends HTMLAttributes<HTMLDetailsElement> {
    summary: React.ReactNode;
    children: React.ReactNode;
    className?: string;
    initiallyOpen?: boolean;
    summaryStyles?: string;
    childrenStyles?: string;
    scrollIntoViewOnExpand?: boolean;
    summaryDataTestId?: string;
    helpIcon?: unknown;
};

export const Expandable = ({
    summary,
    children,
    className,
    initiallyOpen,
    summaryStyles = '',
    childrenStyles = 'bg-tileBackground',
    scrollIntoViewOnExpand,
    summaryDataTestId = 'expandable',
    helpIcon,
    ...props
}: ExpandableProps) => {
    const detailsElement = useRef<HTMLDivElement>(null);
    const [isExpanded, setExpanded] = useState(initiallyOpen);

    return (
        <details
            ref={detailsElement}
            className={cn('mb-2 overflow-hidden list-none group last:mb-0', className)}
            open={initiallyOpen}
            onToggle={() => {
                if (!isExpanded && scrollIntoViewOnExpand) {
                    detailsElement?.current?.scrollIntoView({ block: 'end' });
                }
                setExpanded(!isExpanded);
            }}
            {...props}
        >
            <summary
                className={cn('flex items-center w-full px-6 py-3 cursor-pointer select-none shrink-0', summaryStyles)}
                data-testid={summaryDataTestId}
            >
                {summary}
                {helpIcon}
                <FontAwesomeIcon className='hidden ml-4 shrink-0 group-open:block' icon={faChevronUp} />
                <FontAwesomeIcon className='block ml-4 shrink-0 group-open:hidden' icon={faChevronDown} />
            </summary>
            <div className={cn('h-full px-6 py-4 overflow-auto tile-scroll-overflow', childrenStyles)}>
                {children}
            </div>
        </details>
    );
};
