import { faBroadcastTower } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/tooltip/Tooltip';
import { useQuery } from 'react-query';
import { KPI_TYPES, ListTypes } from 'services/KPIService';

interface KpiConfig {
    name?: string;
    type?: string;
}

export const KPIIcon: React.FC<{ kpiConfig?: KpiConfig }> = ({ kpiConfig }) => {
    const hasKpi = kpiConfig?.name && kpiConfig?.type;

    const { data: kpiTypes } = useQuery(KPI_TYPES, ListTypes, { 
        enabled: Boolean(hasKpi),
        staleTime: Number.POSITIVE_INFINITY, // Only reload if we invalidate, e.g. when new type added by us.
        cacheTime: Number.POSITIVE_INFINITY
    });

    if (!hasKpi) {
        return null;
    }

    const kpiTypeName = kpiTypes?.find((t) => t.id === kpiConfig.type)?.displayName;

    const tooltipContent =
        kpiTypeName == null ? (
            <span>Publishing as {kpiConfig.name}</span>
        ) : (
            <span>
                Publishing to {kpiTypeName} ({kpiConfig.name})
            </span>
        );

    return (
        <Tooltip title={tooltipContent} className={'flex flex-row'}>
            <div className='flex flex-row items-center cursor-default select-none text-textSecondary'>
                <FontAwesomeIcon fixedWidth icon={faBroadcastTower} className='mr-1' />
                <span className='text-sm font-bold tracking-wider'> KPI</span>
            </div>
        </Tooltip>
    );
};
