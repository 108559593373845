import {
    FormattedStreamData,
    FormattedStreamValue,
    FoundColumn,
    StreamData,
    StreamDataColumn,
    date,
    findColumn,
    number,
    preferred,
    required,
    state,
    string
} from '@squaredup/data-streams';
import { dataMatchCriteria } from 'dashboard-engine/dataStreams/dataMatchCriteria';
import { hasUniqueValues, validateNonEmptyData } from 'dashboard-engine/dataStreams/dataMatchingUtils';
import { makeFormatter } from 'dashboard-engine/util/valueToString';
import { DataStreamDonutConfig } from './Config';

export interface DonutComposition {
    label: string;
    value: number;
}

export interface Donut {
    id: number;
    index: number;
    label: string;
    value: number;
    composition?: DonutComposition[];
    rows: FormattedStreamValue[][];
}

export type DonutColumns = {
    valueColumn: FoundColumn;
    labelColumn: FoundColumn;
};

export const getAllDonutData = (data: StreamData, donutColumns: DonutColumns): Donut[] => {
    const { valueColumn, labelColumn } = donutColumns;

    const formatLabel = makeFormatter(labelColumn.column.shapeName);

    return Array.isArray(data.rows)
        ? data.rows
              // values in a number column are not always numbers (they can be missing values
              // e.g. if the external system returned no data because a VM was turned off etc.)
              .filter((r) => typeof r[valueColumn.dataIndex].value === 'number')
              .map((r, index) => {
                  return {
                      id: index,
                      index: index,
                      label: formatLabel(r[labelColumn.dataIndex], { appendUtcOffset: true }),
                      value: r[valueColumn.dataIndex].value as number,
                      rows: [r]
                  };
              })
        : [];
};

export const validateDonutData = (data: FormattedStreamData, columns: DonutColumns) => {
    const series = getAllDonutData(data, columns);
    return validateNonEmptyData(series);
};

export const findDonutValueColumn = (columns: StreamDataColumn[]) =>
    findColumn(columns, required('valueShapeName', number.name), preferred('role', 'value'));

export const findDonutLabelColumn = (columns: StreamDataColumn[]) =>
    findColumn(
        columns,
        required('valueShapeName', string.name),
        preferred.not('shapeName', state.name),
        preferred.not('shapeName', date.name),
        preferred('role', 'label')
    );

export const getDonutColumns = (columns: StreamDataColumn[], config?: DataStreamDonutConfig) => {
    const donutCriteria = dataMatchCriteria<DonutColumns>();

    let valueColumn = findDonutValueColumn(columns);
    let labelColumn = findDonutLabelColumn(columns);

    if (config?.valueColumn) {
        const column = findColumn(columns, required('name', config.valueColumn));
        if (column.succeeded) {
            valueColumn = column;
        }
    }

    if (config?.labelColumn) {
        const column = findColumn(columns, required('name', config.labelColumn));
        if (column.succeeded) {
            labelColumn = column;
        }
    }

    if (valueColumn.failed) {
        donutCriteria.fail('Missing Value column', valueColumn.reason);
    } else {
        donutCriteria.pass('Automatically selected Value', {
            valueColumn: valueColumn.value
        });
    }

    if (labelColumn.failed) {
        donutCriteria.fail('Missing Label column', labelColumn.reason);
    } else {
        donutCriteria.pass('Automatically selected Label', {
            labelColumn: labelColumn.value
        });
    }

    return donutCriteria;
};

export const checkSingleRowForEachLabel = (rows: FormattedStreamValue[][], visualisationColumns: DonutColumns) => {
    const { labelColumn } = visualisationColumns;

    return hasUniqueValues(rows, labelColumn.dataIndex);
};

export const matchesData = (data: FormattedStreamData, config?: DataStreamDonutConfig) => {
    const columns = data?.metadata?.columns ?? [];
    const rows = data?.rows ?? [];
    const donutCriteria = getDonutColumns(columns, config);

    if (data == null || rows.length === 0) {
        donutCriteria.fail('At least 1 row of data is required');
    }

    if (
        data != null &&
        rows.length > 0 &&
        donutCriteria.value.labelColumn &&
        !checkSingleRowForEachLabel(rows, donutCriteria.value)
    ) {
        donutCriteria.fail('Duplicate Label values detected, should be unique');
    }

    if (
        data != null &&
        rows.length > 0 &&
        donutCriteria.value.valueColumn &&
        donutCriteria.value.labelColumn &&
        !validateDonutData(data, donutCriteria.value)
    ) {
        donutCriteria.fail('At least 1 row of non-zero data is required');
    }

    return donutCriteria;
};

// These must eb changed to system colours once new design system colours can use opacity
export const stateColors = {
    success: '#2BB660',
    warning: '#FFAA00',
    error: '#F2164D',
    unknown: '#76767E'
};

export function convertToRgba(color: string, opacity: number) {
    const tempElement = document.createElement('div');

    tempElement.style.color = color;

    document.body.appendChild(tempElement);
    const rgbColor = window.getComputedStyle(tempElement).color.replace(')', `, ${opacity})`).replace('rgb', 'rgba');
    document.body.removeChild(tempElement);

    return rgbColor;
}
