import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { buildQuery } from '@squaredup/graph';
import { defaultTimeframeEnum } from '@squaredup/timeframes';
import { DashboardContextValue } from 'contexts/DashboardContext';

/**
 * Constructs a suitable scope for the tile editor config based on the given object ID
 * @param id Object ID
 * @returns
 */
export const buildScope = (id: string) => {
    const scopeContents = { ids: [id] };

    const scope = buildQuery(scopeContents, '');

    return {
        query: scope.gremlinQuery,
        bindings: scope.bindings,
        queryDetail: scopeContents
    };
};

/**
 * Creates a broadly fake dashboard context with the given tile config
 * Most values are not intended for use
 * @param tileConfig Tile configuration
 * @returns
 */
export const buildContext = (tileConfig: DataStreamBaseTileConfig): DashboardContextValue => ({
    editing: true,
    showDetailedErrors: true,
    timeframe: defaultTimeframeEnum,
    setIsNarrowLayout: () => undefined,
    dashboard: {
        id: '1',
        displayName: 'Dummy',
        workspaceId: '',
        content: {
            _type: 'layout/grid',
            columns: 1,
            contents: [
                {
                    h: 2,
                    w: 4,
                    i: '1',
                    x: 0,
                    y: 0,
                    config: tileConfig
                }
            ],
            version: 1
        }
    }
});
