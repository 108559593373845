import { cn } from '@/lib/cn';
import * as SwitchPrimitives from '@radix-ui/react-switch';
import { VariantProps, cva } from 'class-variance-authority';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

const toggleVariants = cva(
    'group/toggle inline-flex w-toggleTrack shrink-0 cursor-pointer items-center rounded-full border-[2px] border-transparent box-content outline-none focus:ring-0 focus:outline-none disabled:cursor-not-allowed disabled:pointer-events-none  data-[state=unchecked]:bg-toggleBody data-[state=unchecked]:hover:bg-toggleBodyHover data-[state=unchecked]:focus-visible:bg-toggleBodyHover',
    {
        variants: {
            color: {
                primary:
                    'data-[state=checked]:bg-primaryButtonBackground data-[state=checked]:hover:bg-primaryButtonBackgroundHover data-[state=checked]:focus-visible:bg-primaryButtonBackgroundHover disabled:bg-toggleBodyHover',
                warning:
                    'data-[state=checked]:bg-statusWarningPrimary data-[state=checked]:hover:bg-statusWarningSecondary data-[state=checked]:focus-visible:bg-statusWarningSecondary',
                error: 'data-[state=checked]:bg-statusErrorPrimary data-[state=checked]:hover:bg-statusErrorSecondary data-[state=checked]:focus-visible:bg-statusErrorSecondary'
            }
        },
        defaultVariants: {
            color: 'primary'
        }
    }
);

export type ToggleProps = ComponentPropsWithoutRef<typeof SwitchPrimitives.Root & VariantProps<typeof toggleVariants>>

export const Toggle = forwardRef<
    ElementRef<typeof SwitchPrimitives.Root>,
    ComponentPropsWithoutRef<typeof SwitchPrimitives.Root & VariantProps<typeof toggleVariants>>
>(({ className, color, ...props }, ref) => (
    <SwitchPrimitives.Root
        role='checkbox'
        className={cn(toggleVariants({ color } as VariantProps<typeof toggleVariants>), className)}
        aria-label={props.name}
        {...props}
        ref={ref}
        id={props.id ?? props.name}
    >
        <SwitchPrimitives.Thumb className='pointer-events-none block h-toggleThumb w-toggleThumb rounded-full bg-componentActive shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-toggleThumb data-[state=unchecked]:translate-x-0 group-disabled/toggle:bg-componentDisabled aria-[disabled="true"]:opacity-75' />
    </SwitchPrimitives.Root>
));
