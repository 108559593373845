import { FeatureKey, getFeatureDisplay, isFeatureEnabled } from '@squaredup/tenants';
import { useTenant } from 'queries/hooks/useTenant';
import { useTier } from 'queries/hooks/useTier';
import { IsTenantAdmin } from 'services/AccessControlService';
import { UpgradeMessageContainer } from './UpgradeMessageContainer';
import { VariantProps, cva } from 'class-variance-authority';
import { FeatureUnavailableContent, NarrowFeatureUnavailableContent } from './common';

const variants = cva(
    undefined,
    {
        variants: {
            container: {
                modal: '',
                page: 'min-w-[400px] w-fit'
            },
            content: {
                narrow: '',
                normal: ''
            }
        },
        defaultVariants: {
            container: 'page',
            content: 'normal'
        }
    }
);

export interface FeatureUnavailableBannerProps extends VariantProps<typeof variants> {
    featureKey: FeatureKey;
    summary?: string;
    title?: string;
    className?: string;
}

export const FeatureUnavailableBanner: React.FC<FeatureUnavailableBannerProps> = ({
    featureKey,
    className,
    summary,
    title,
    container,
    content
}) => {
    const { data: tenant } = useTenant();
    const canUpgrade = IsTenantAdmin(tenant) ?? false;
    // useTier handles validating the tier, and coercing items like dates from strings
    const { data: tier } = useTier();

    const feature = getFeatureDisplay(featureKey);
    if (!tier || feature === undefined) {
        return <></>;
    }

    const isEnabled = isFeatureEnabled(tier, featureKey);
    if (isEnabled) {
        return <></>;
    }

    return (
        <UpgradeMessageContainer
            data-testid='feature-unavailable-banner'
            className={variants({ container, className })}
            variant='normal'
        >
            {content === 'narrow' ? (
                <NarrowFeatureUnavailableContent
                    feature={feature}
                    canUpgrade={canUpgrade}
                    title={title}
                    summary={summary}
                />
            ) : (
                <FeatureUnavailableContent
                    feature={feature}
                    canUpgrade={canUpgrade}
                    title={title}
                    summary={summary}
                />
            )}
        </UpgradeMessageContainer>
    );
};
