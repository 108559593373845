import Text from '@/components/Text';
import Field from 'components/forms/field/Field';
import { format } from 'date-fns';
import type { ShapeConfigFieldsComponent } from './ShapeConfigFields';

export const DateShapeConfigFields: ShapeConfigFieldsComponent = ({ name }) => (
    <>
        <Field.Input
            label='Format'
            name={`${name}.format`}
            type='text'
            className='w-60'
            help={
                <>
                    Enter a custom format string for date values. e.g.{' '}
                    <pre className='inline font-mono whitespace-normal'>dd/MM/yy</pre> formats today's date as{' '}
                    {format(new Date(), 'dd/MM/yy')}
                </>
            }
        />
        <Text.Body className='mt-2 text-sm'>
            <a
                className='text-textLink'
                href='https://date-fns.org/v2.28.0/docs/format'
                target='_blank'
                rel='noreferrer noopener'
            >
                Learn more about date formatting
            </a>
        </Text.Body>
    </>
);
