import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClientDataStreamRequest, FormattedStreamValue } from '@squaredup/data-streams';
import { ColumnDef } from '@tanstack/react-table';
import LoadingSpinner from 'components/LoadingSpinner';
import NoDataPlaceholder from 'components/NoDataPlaceholder';
import { ObjectTooltip } from 'components/tooltip/commonTooltips/ObjectTooltip';
import { StateIndicator } from 'components/ui/state/StateIndicator';
import { AppContext } from 'contexts/AppContext';
import { useDataStream } from 'dashboard-engine/hooks/useDataStream';
import DataStreamDonut from 'dashboard-engine/visualisations/DataStreamDonut/DataStreamDonut';
import { getIconForNode, getNameForType } from 'lib/types';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import TruncateWithTooltip from 'pages/settings/TruncateWithTooltip';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { DetailHeader } from './DetailHeader';
import { useInfiniteObjectQuery } from './useInifiniteObjectQuery';

const objectColumns: ColumnDef<FormattedStreamValue[]>[] = [
    {
        id: 'name',
        header: 'Name',
        accessorKey: '',
        size: 300,
        cell: ({ row }) => (
            <div className='flex items-center gap-2'>
                <StateIndicator state={row?.original?.[2]?.formatted} />
                <TruncateWithTooltip title={row?.original?.[1].formatted ?? ''}>
                    <NavLink
                        to={`/drilldown/node/${row.original[0].formatted}`}
                        className='truncate cursor-pointer text-textLink hover:underline'
                    >
                        {row.original[1].formatted}
                    </NavLink>
                </TruncateWithTooltip>
            </div>
        )
    },
    {
        id: 'type',
        header: 'Type',
        cell: ({ row }) => (
            <span className='flex items-center gap-2'>
                <FontAwesomeIcon
                    icon={getIconForNode({
                        type: [row.original[4].formatted],
                        sourceType: [row.original[5].formatted]
                    })}
                    className='flex-shrink-0'
                />
                {row.original[4].formatted === '-' ? (
                    <span>Unknown</span>
                ) : (
                    <span>{getNameForType(row.original[4].formatted, 'singular') ?? 'Unknown'}</span>
                )}
            </span>
        ),
        accessorKey: '',
        size: 250
    }
];

const ObjectDetailsApplicationTable = ApplicationTable<FormattedStreamValue[], string>();

const buildTemplateTypeRequest = (configId: string): ClientDataStreamRequest => ({
    dataSourceConfig: {
        gremlin: `g.V().has('__configId', '${configId}').hasNot('__canonicalType').has('sourceType', neq('squaredup/data-source')).groupCount().by('sourceType').unfold().project('sourceType', 'count').by(select(keys)).by(select(values))`
    },
    dataStreamId: 'datastream-gremlin',
    options: {
        group: { keyColumns: [], aggregations: [] },
        sort: { criteria: [] },
        format: { noNumberFormatting: true },
        noAccessControlCache: true // This data is often displayed directly after changing data source links in workspace
    },
    scope: [],
    timeframe: 'last24hours'
});

export const OverviewObjectDetails = ({ configId, importStatus }: { configId: string; importStatus: string }) => {
    const typeRequest = buildTemplateTypeRequest(configId);
    const { currentWorkspaceID } = useContext(AppContext);
    const {
        data: typeData,
        isLoadingOrPreviousData: loadingTypes,
        error: typeError
    } = useDataStream(typeRequest, currentWorkspaceID, { extraKeys: [importStatus] });
    const { flatResults, isFetching, setSearchQuery, fetchMoreOnBottomReached, isError } = useInfiniteObjectQuery(
        configId,
        [importStatus]
    );

    if (loadingTypes) {
        return <></>; // Should be short, don't need spinner
    }

    if (typeError) {
        return <NoDataPlaceholder />;
    }

    //Set typedata decimal count to avoid superflous decimals
    if (typeData.metadata && typeData.metadata.columns[1]) {
        typeData.metadata.columns[1].rawShapeConfig.decimalPlaces = 0;
    }

    const objectCount: number = typeData.rows.reduce((acc, cur) => acc + (cur[1]?.raw as number) ?? 0, 0);

    return (
        <div className='flex flex-col h-full' aria-label='overviewObjectDetails'>
            <div>
                <DetailHeader name='Objects' tooltip={<ObjectTooltip placement='top' />} count={objectCount} />
            </div>
            <hr className='h-px mx-4 my-4 border-0 bg-dividerPrimary' />
            {typeData.rows.length > 0 && !isError && (
                <div className='relative h-1/3'>
                    <DataStreamDonut data={typeData} config={{}} />
                </div>
            )}

            <div className='relative flex flex-col min-h-0 px-4 basis-2/3'>
                <ObjectDetailsApplicationTable
                    config={{
                        noDataMessage: isError
                            ? 'Unable to retrieve health state.'
                            : isFetching
                            ? 'Loading...'
                            : 'There are no objects indexed.',
                        searchFunction: setSearchQuery,
                        scrollFunction: fetchMoreOnBottomReached,
                        disableSortBy: true
                    }}
                    data={flatResults ?? []}
                    columns={objectColumns}
                />
                {isFetching && (flatResults ?? []).length > 0 && (
                    <span className='absolute bottom-0 left-0 right-0 flex justify-center py-2 bg-tileBackground'>
                        <LoadingSpinner className='h-8 shrink-0' />
                    </span>
                )}
            </div>
        </div>
    );
};
