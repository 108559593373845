import Tooltip from 'components/tooltip/Tooltip';
import { ReactNode, useEffect, useRef, useState } from 'react';

type TruncateWithTooltipProps = {
    title: string;
    children: ReactNode;
};

const TruncateWithTooltip = ({ title, children }: TruncateWithTooltipProps) => {
    const wrapperRef = useRef<any>(null);
    const childRef = useRef<any>(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        setIsTruncated(childRef.current.offsetWidth >= wrapperRef.current.offsetWidth);
    }, [wrapperRef, childRef]);

    return (
        <div className='flex items-center truncate' ref={wrapperRef}>
            <Tooltip title={title} className='truncate' disabled={!isTruncated}>
                <div className='flex truncate' ref={childRef}>
                    {children}
                </div>
            </Tooltip>
        </div>
    );
};

export default TruncateWithTooltip;
