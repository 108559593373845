import Text from '@/components/Text';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal, { CloseHandler } from 'components/Modal';
import { Presence } from 'components/Presence';
import { ComponentPropsWithoutRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'ui/errorHandling/ErrorFallback';
import { DashboardLinkSharing } from './DashboardLinkSharing';
import { DashboardUsers } from './DashboardUsers';
import { WorkspaceUsers } from './WorkspaceUsers';
import { isFeatureEnabled } from '@squaredup/tenants';
import { useTier } from 'queries/hooks/useTier';

type ShareDashboardModalProps = { close: CloseHandler };

export const ShareDashboardModal = (props: ShareDashboardModalProps) => {
    const { data: tier } = useTier();
    const isAccessControlAvailable = tier !== undefined && isFeatureEnabled(tier, 'accessControl');

    return (
        <Modal close={props.close} fullWidth maxWidth='max-w-[700px]'>
            <Header>
                <Text.H2>Share</Text.H2>
                <CloseButton onClick={() => props.close('exit')}>
                    <FontAwesomeIcon icon={faXmark} />
                </CloseButton>
            </Header>

            <ContentContainer>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Section>
                        <Presence isPresent={!isAccessControlAvailable}>
                            <DashboardUsers />
                        </Presence>
                        <Presence isPresent={isAccessControlAvailable}>
                            <WorkspaceUsers />
                        </Presence>
                    </Section>
                </ErrorBoundary>

                <Divider />

                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Section>
                        <DashboardLinkSharing />
                    </Section>
                </ErrorBoundary>
            </ContentContainer>
        </Modal>
    );
};

const Header = (props: ComponentPropsWithoutRef<'header'>) => {
    return <header className='flex items-center justify-between px-8 py-5 border-b border-dividerPrimary' {...props} />;
};

const CloseButton = (props: ComponentPropsWithoutRef<'button'>) => {
    return (
        <button
            aria-label='closeModal'
            className='text-xl transition-colors text-tertiaryButton hover:text-tertiaryButtonHover'
            {...props}
        />
    );
};

const ContentContainer = (props: ComponentPropsWithoutRef<'div'>) => {
    return <div className='px-8 pt-5 pb-8' {...props} />;
};

const Section = (props: ComponentPropsWithoutRef<'section'>) => {
    return <section className='flex flex-col gap-1' {...props} />;
};

const Divider = (props: ComponentPropsWithoutRef<'hr'>) => {
    return <hr className='mt-4 mb-5 border-dividerPrimary' {...props} />;
};
