import { faCheck, faPencil } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TimeframeEnumValue, defaultTimeframeEnum, getTimeframeLabel } from '@squaredup/timeframes';
import Button from 'components/button/Button';
import { DashboardType } from 'dashboard-engine/types/Dashboard';
import { FC } from 'react';
import { UseMutateFunction } from 'react-query';
import ClipboardToast, { toastIds } from 'ui/notifications/ClipboardToast';
import DashboardTimeframeChooser from 'ui/timeframe/DashboardTimeframeChooser';
import { DashboardOptions } from '../../ui/dashboardOptions/DashboardOptions';
import ShareDashboard from './ShareDashboard';

interface DashboardActionsProps {
    dashboard: DashboardType;
    editingName: boolean;
    editing: boolean;
    timeframe: TimeframeEnumValue;
    isFixedTimeframe?: boolean;
    canWriteToWorkspace: boolean | undefined;
    isNarrowLayout: boolean;
    toggleJsonEditor: () => void;
    updateDashboard: UseMutateFunction<DashboardType | undefined, undefined, DashboardType>;
    setTimeframe: (timeframe: TimeframeEnumValue) => void;
    setEditing: (editing: boolean) => void;
    triggerRefresh: () => void;
}

/**
 * All the Action buttons in our dashboard
 */
export const DashboardActions: FC<DashboardActionsProps> = ({
    dashboard,
    editingName,
    editing,
    timeframe,
    canWriteToWorkspace,
    isNarrowLayout,
    isFixedTimeframe,
    toggleJsonEditor,
    updateDashboard,
    setEditing,
    setTimeframe,
    triggerRefresh
}) => {
    const handleDefaultTimeframeChange = (newTimeframe: TimeframeEnumValue) => {
        setTimeframe(newTimeframe);
        updateDashboard({ ...dashboard, timeframe: newTimeframe });
        ClipboardToast(
            `Default timeframe updated (${getTimeframeLabel(newTimeframe, 'nonrelative')})`,
            false,
            toastIds.SUCCESS
        );
    };

    return (
        <>
            <div className='flex space-x-4'>
                <DashboardTimeframeChooser
                    currentTimeframe={timeframe}
                    defaultTimeframe={dashboard.timeframe || defaultTimeframeEnum}
                    disabled={editingName}
                    isFixedTimeframe={isFixedTimeframe}
                    isNarrowLayout={isNarrowLayout}
                    canChangeTimeframe={Boolean(!dashboard?.builtIn && canWriteToWorkspace)}
                    onCurrentTimeframeChange={setTimeframe}
                    onDefaultTimeframeChange={handleDefaultTimeframeChange}
                />

                {/*  Note the use of collapse-for-image-export here.
                     If you change the layout here please check it still works for export dashboard as image */}
                <div className='flex space-x-4 collapse-for-image-export'>
                    {!isNarrowLayout && (
                        <ShareDashboard disabled={editingName} pageType={'dashboard'} dashboardId={dashboard.id} />
                    )}

                    {canWriteToWorkspace && (
                        <Button
                            onClick={() => setEditing(!editing)}
                            disabled={editingName}
                            aria-pressed={editing}
                            aria-label='Toggle edit mode'
                            variant={!editing ? 'secondary' : 'primary'}
                            className='flex items-center'
                            icon={<FontAwesomeIcon icon={!editing ? faPencil : faCheck} />}
                        >
                            {!editing ? 'Edit' : 'Done'}
                        </Button>
                    )}

                    {canWriteToWorkspace && (
                        <DashboardOptions
                            dashboard={dashboard}
                            toggleJsonEditor={toggleJsonEditor}
                            setEditing={setEditing}
                            editing={editing}
                            isNarrowLayout={isNarrowLayout}
                            triggerRefresh={triggerRefresh}
                        />
                    )}

                    {!canWriteToWorkspace && (
                        <DashboardOptions
                            isDashboardReadOnly={true}
                            isNarrowLayout={isNarrowLayout}
                            dashboard={dashboard}
                            triggerRefresh={triggerRefresh}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
