import { HealthState, stateStrings } from '@squaredup/monitoring';
import { oneMinute } from 'queries/constants';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { ListWorkspaceHealthByIds } from 'services/HealthService';
import { List } from 'services/WorkspaceService';
import { IterableElement } from 'type-fest';
import { useWorkspaces } from './useWorkspaces';

type QueryReturnType = Awaited<ReturnType<typeof ListWorkspaceHealthByIds>>;
type QuerySelectReturnType = (IterableElement<Awaited<ReturnType<typeof List>>> & { state: HealthState })[]

/**
 * useQuery returning workspaces with their respective health states. It uses the WorkspaceService List API
 * to get a full list of workspaces (this is within useWorkspaces). We then leverage the `select` query option 
 * to merge the data.
 * @param options The react-query options e.g. refreshInterval
 * @returns An array of workspaces with their health state
 */
export const useWorkspaceHealthStates = (
    options?: Omit<UseQueryOptions<QueryReturnType, unknown, QuerySelectReturnType, string[]>, 'select' | 'enabled'>
) => {
    const { data: workspaces } = useWorkspaces(); 

    return useQuery(
        workspaceQueryKeys.state(workspaces?.map(({ id }) => id)), 
        async () => ListWorkspaceHealthByIds(workspaces!.map((w) => w.id)),
        {
            enabled: Boolean(workspaces),
            staleTime: oneMinute,
            cacheTime: oneMinute,
            refetchInterval: oneMinute,
            keepPreviousData: true,
            select: ({ workspaceStates }) => {
                const workspaceHealthsLookup = new Map(
                    workspaceStates?.map(({ state, workspaceId }) => [workspaceId, state])
                );
    
                return workspaces!
                    .sort((a: { displayName: string }, b: { displayName: any }) => 
                        a.displayName.localeCompare(b.displayName
                    ))
                    .map((workspace) => {
                        return {
                            ...workspace,
                            state: workspaceHealthsLookup.get(workspace.id) ?? stateStrings.unknown
                        };
                });
            },
            ...options
        }
    );
};
