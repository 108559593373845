import { useAppContext } from 'contexts/AppContext';
import type { DashboardType } from 'dashboard-engine/types/Dashboard';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { isDashboard, recursiveFolderToDashboardIdOrder, type DashboardFolder } from 'queries/utils/dashboardSorted';
import { useMutation, useQueryClient } from 'react-query';
import { UpdateWorkspaceOrder, type Workspace } from 'services/WorkspaceService';

export function useUpdateWorkspaceDashboardOrder() {
    const queryClient = useQueryClient();

    const { currentWorkspaceID } = useAppContext();

    const { data: workspace } = useWorkspace(currentWorkspaceID);

    return useMutation(
        (items: (DashboardFolder | DashboardType)[]) => {
            const newOrder = items.map((item) =>
                isDashboard(item) ? item.id : recursiveFolderToDashboardIdOrder(item)
            );
            return UpdateWorkspaceOrder(workspace!, newOrder);
        },
        {
            async onMutate(items) {
                await queryClient.cancelQueries(workspaceQueryKeys.list);

                const workspaces = queryClient.getQueryData<Workspace[]>(workspaceQueryKeys.list);

                const newWorkspaces = workspaces?.map((space) => {
                    if (space.id !== currentWorkspaceID) {
                        return space;
                    }
                    space.data.properties = {};
                    space.data.properties.dashboardIdOrder = items.map((item) =>
                        isDashboard(item) ? item.id : recursiveFolderToDashboardIdOrder(item)
                    );
                    return space;
                });

                queryClient.setQueryData(workspaceQueryKeys.list, newWorkspaces);

                return workspaces;
            },
            onError(_, __, oldOrder) {
                queryClient.setQueriesData(workspaceQueryKeys.list, oldOrder);
            },
            onSettled() {
                queryClient.invalidateQueries(workspaceQueryKeys.list);
            }
        }
    );
}
