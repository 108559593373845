import { faAsterisk, faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/tooltip/Tooltip';
import { ReactNode } from 'react';
import FieldCheckbox from './field-checkbox/FieldCheckbox';
import FieldCheckboxes from './field-checkbox/FieldCheckboxes';
import FieldHidden from './field-hidden/FieldHidden';
import FieldInput from './field-input/FieldInput';
import FieldRadio from './field-radio/FieldRadio';
import { FieldTestOutput } from './field-test-output/FieldTestOutput';
import clsx from 'clsx';
import { cn } from '@/lib/cn';
import { FieldMarkdown } from './field-markdown/FieldMarkdown';

export type FormFieldProps = {
    label?: string;
    description?: ReactNode;
    required?: boolean;
    help?: ReactNode;
    helpAlign?: 'Left' | 'Right';
    htmlFor?: string;
    indent?: boolean;
    children?: ReactNode;
    tooltipAlign?: 'Left' | 'Right';
    descriptionAbove?: boolean;
    className?: string;
    labelClassName?: string;
};

/**
 * Field
 * Component used as section within a form displaying label and description.
 * This can be used directly, but you might find `<Field.Input>` easier to use.
 *
 * @example
 * ```
 * return (<Field label='example'><Input/></Field>)
 * ```
 */
function Field({
    label,
    description,
    required,
    help,
    htmlFor,
    children,
    helpAlign,
    descriptionAbove,
    className,
    labelClassName
}: FormFieldProps) {
    return (
        <div className={cn('mt-6 relative first:mt-0', className)} data-testid='fieldTitle'>
            {label ? (
                <>
                    <label htmlFor={htmlFor} className={cn('flex items-end mb-2 font-medium', labelClassName)}>
                        <span className='flex'>{label} {required && ' *'}</span>

                        {help && (
                            <Tooltip title={help} className={cn(
                                'text-textSecondary cursor-pointer shrink-0 ml-2',
                                helpAlign !== 'Left' && 'px-2',
                                helpAlign === 'Left' && required && 'px-1',
                                helpAlign === 'Left' && !required && 'px-2'
                            )}>
                                <FontAwesomeIcon className='text-base' icon={faCircleQuestion} />
                            </Tooltip>
                        )}
                    </label>
                    {description && descriptionAbove && <div className='mb-4 text-sm text-textSecondary'>{description}</div>}
                    {children}
                </>
            ) : (
                <>
                    {description && descriptionAbove && <div className='mb-4 text-sm text-textSecondary'>{description}</div>}
                    {children}
                </>
            )}
            {description && !descriptionAbove && <div className='mt-4 text-sm text-textSecondary'>{description}</div>}
        </div>
    );
}
/**
 * Field Label
 *
 * Arranges content as if it was on a form, with the given label, help text, description etc.
 *
 * Set `spacing='none'` to disable external spacing (e.g. the top margin usually used to lay out fields on a form)
 *
 * @example
 * ```
 * return (<Field.Label label='example'><div>Some content</div></Field.Label>)
 * ```
 */
function FieldLabel({
    label,
    description,
    required,
    help,
    children,
    className,
    labelClassName,
    spacing = 'default'
}: FormFieldProps & { labelClassName?: string; spacing?: 'default' | 'none' }) {
    /*
     * The main differences between this and Field above are:
     *   - Label has the spacing option to stop it trying to position itself
     *   - Label doesn't use a <label> element, which can cause weird focus behaviour on click
     */

    const defaultCss = spacing === 'default' ? 'mt-8 relative first:mt-0' : '';

    return (
        <div className={clsx(defaultCss, className)}>
            <div className='flex items-end mb-2 font-medium'>
                <span className={clsx('flex w-full', labelClassName)}>
                    {label}
                    {required && (
                        <FontAwesomeIcon
                            title='required'
                            className='ml-1'
                            style={{ fontSize: '0.5rem' }}
                            icon={faAsterisk}
                        />
                    )}
                </span>

                {help && (
                    <span className='cursor-pointer text-textSecondary'>
                        <Tooltip title={help}>
                            <FontAwesomeIcon className='text-base' icon={faCircleQuestion} />
                        </Tooltip>
                    </span>
                )}
            </div>
            
            {children}

            {description && <div className='mt-4 text-sm text-textSecondary'>{description}</div>}
        </div>
    );
}

Field.Input = FieldInput;
Field.Checkbox = FieldCheckbox;
Field.Checkboxes = FieldCheckboxes;
Field.Hidden = FieldHidden;
Field.Radio = FieldRadio;
Field.TestOutput = FieldTestOutput;
Field.Label = FieldLabel;
Field.Markdown = FieldMarkdown;

export default Field;
