import { Toggle } from '@/components/forms/Toggle';
import { cn } from '@/lib/cn';
import { ToggleStyleWrapper } from 'components/ToggleStyleWrapper';
import { ReactNode } from 'react';

export type ToggleFieldProps = {
    label: string;
    description?: string;
    title?: string;
    help?: string;
    htmlFor?: string;
    children?: ReactNode;
    onToggle?: (toggleState: boolean) => void;
    toggleDefaultValue?: boolean;
    wrapperClass?: string;
    toggleTestId?: string;
    toggleDisabled?: boolean;
    upgradePill?: ReactNode;
};

/**
 * ToggleField
 * Component used as section within a form displaying label and description with a toggle
 * to show or hide child components.
 * Designed for use in the WorkspaceAddEditModal
 *
 * @example
 * ```
 * return (<ToggleField label='example'><Input/></ToggleField>)
 * ```
 */
function ToggleField({
    label,
    description,
    title,
    help,
    htmlFor,
    children,
    onToggle,
    toggleDefaultValue,
    wrapperClass,
    toggleTestId,
    toggleDisabled,
    upgradePill
}: ToggleFieldProps) {
    const props = { name: htmlFor ?? 'toggle', label, title, help, upgradePill };
    return (
        <div className='flex flex-col w-full'>
            <ToggleStyleWrapper
                {...props}
                wrapperClassName={cn(wrapperClass, Boolean(toggleDisabled) && '[&_label]:text-textDisabled')}
            >
                {onToggle && toggleDefaultValue !== undefined && (
                    <div className='my-auto ml-auto' aria-label={label}>
                        <Toggle
                            checked={toggleDefaultValue}
                            onCheckedChange={() => onToggle(!toggleDefaultValue)}
                            tabIndex={0}
                            data-testid={toggleTestId}
                            disabled={toggleDisabled}
                        />
                    </div>
                )}
            </ToggleStyleWrapper>
            {description && <div className='mt-1 text-sm text-textSecondary pl-md'>{description}</div>}
            {children}
        </div>
    );
}

export default ToggleField;
