import { cn } from '@/lib/cn';
import { faCircleCheck, faXmarkCircle, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { emailRegex } from 'lib/validation';
import React, { FC } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { FieldType } from '../field/getFormFieldComponent';
import buildErrorMessage from './buildErrorMessage';
import buildInput from './buildInput';

export const defaultWrapperClassName =
    'w-full border-0 bg-componentBackgroundPrimary ring-inset ring-1 ring-outlinePrimary focus:ring-inset focus:ring-1 rounded-input focus:ring-outlineSecondary py-input leading-input px-md text-textPrimary placeholder-textIncomplete disabled:text-textDisabled disabled:pointer-events-none';
export interface InputProps {
    type?: FieldType;
    name: string;
    label?: string;
    prepend?: React.ReactNode;
    append?: React.ReactNode;
    className?: string;
    wrapperClassName?: string;
    validation?: RegisterOptions;
    background?: string;
    validateOnLoad?: boolean;
    [key: string]: unknown;
}

const noValidationIcon: FieldType[] = ['range', 'radio', 'checkbox', 'key-value', 'slider', 'code']; // Hide the validation icons for the following types of input

/**
 * Input
 * Component used for text input, usually used within a form. Typically you would use Field.* rather
 * than this component directly, but there may be use cases to use this.
 *
 * @example
 * ```
 * return (<Input placeholder='example' help='This is some help text' />)
 * ```
 */
function Input({
    type = 'text',
    name,
    label,
    prepend,
    append,
    className,
    wrapperClassName,
    validation,
    background,
    validateOnLoad,
    ...inputProps
}: InputProps) {
    const formContext = useFormContext();

    if (!formContext) {
        throw new Error('Cannot use Input outside of a Form');
    }

    if (!name) {
        throw new Error('You must specify a name');
    }

    function formatRegex(regex: string) {
        if (regex.startsWith('/')) {
            regex = regex.slice(1);
        }
        //matches with all
        // cspell:disable-next-line
        const flagsMatch = /\/[igmsuy]+$/iu;
        let flags = regex.match(flagsMatch);
        if (flags != null) {
            const firstFlag = flags[0];
            regex = regex.slice(0, -firstFlag.length);
            const extractedFlags = firstFlag.slice(1);
            return { flags: extractedFlags, pattern: regex };
        }
        return { pattern: regex };
    }
    // For plugins we need to convert from string patterns to regex
    if (validation?.pattern) {
        if (typeof validation.pattern === 'string') {
            const { flags, pattern } = formatRegex(validation.pattern);
            validation.pattern = new RegExp(pattern, flags ?? 'u');
        } else if ('value' in validation.pattern && typeof validation.pattern.value === 'string') {
            const { flags, pattern } = formatRegex(validation.pattern.value);
            validation.pattern.value = new RegExp(pattern, flags ?? 'u');
        }
    } else if (type === 'url') {
        validation = {
            ...validation,
            pattern: {
                value: /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/u,
                message: 'Please enter a valid URL'
            }
        };
    } else if (type === 'email') {
        validation = {
            ...validation,
            pattern: {
                value: emailRegex,
                message: 'Please enter a valid email'
            }
        };
    }

    const wrapperClasses = {
        success: 'text-statusHealthyPrimary',
        error: 'text-statusErrorPrimary'
    };

    const {
        register,
        formState: { dirtyFields, errors }
    } = formContext;
    let icon: IconDefinition | undefined;
    let colour: 'error' | 'success' | undefined;

    // Get form state for this input
    const error = errors[name];
    const dirty = dirtyFields[name] || validateOnLoad;

    if (validation && dirty) {
        // don't show any validation styles if validation is not set
        if (error) {
            // If there's an error show it
            icon = faXmarkCircle;
            colour = 'error';

            if (!error.message) {
                error.message = buildErrorMessage(type, error.type?.toString() ?? '', label ?? name, validation);
            }
        } else {
            // If the field has been modified and there are no errors, show success
            icon = faCircleCheck;
            colour = 'success';
        }
    }

    // We don't want the browser validation overruling ours so ensure URL fields are type='text'
    const inputType = type === 'url' ? 'text' : type;

    // Build the specified type of input, e.g. text, select, checkbox etc.
    const input = buildInput(inputType, name, label ?? name, register, validation || {}, inputProps);
    const inputWrapperClass = ![
        'radio',
        'checkbox',
        'apiKey',
        'oAuth2',
        'key-value',
        'slider',
        'payloadViewer',
        'showConfigValue'
    ].includes(type)
        ? cn(
              'w-full flex items-center ring-inset ring-1 ring-outlinePrimary focus-within:ring-outlineSecondary rounded-input',
              background || 'bg-componentBackgroundPrimary',
              className
          )
        : className || '';

    const errorClassName = cn(
        colour === 'success' && 'text-statusHealthyPrimary',
        colour === 'error' && 'text-statusErrorPrimary'
    );

    return (
        <div className={cn(wrapperClassName, colour && wrapperClasses[colour])}>
            {inputType !== 'hidden' && (
                <div className={inputWrapperClass} data-testid='input'>
                    {prepend && <span className='ml-md'>{prepend}</span>}
                    {input}
                    {icon && !noValidationIcon.includes(type) && (
                        <FontAwesomeIcon className={cn('mr-md', errorClassName)} icon={icon} fixedWidth />
                    )}
                    {append && append}
                </div>
            )}
            {error && dirty && (
                <div className={cn('mt-xxxs', errorClassName)}>
                    <p>{error.message || 'This value is invalid.'}</p>
                </div>
            )}
        </div>
    );
}

export interface InputRawProps {
    type?: FieldType;
    name: string;
    label?: string;
    validation?: RegisterOptions;
    [key: string]: unknown;
}

/**
 * An input without any of the surrounding validation or wrapper CSS
 */
const RawInput: FC<InputRawProps> = ({ type = 'text', name, label, validation, ...inputProps }) => {
    const formContext = useFormContext();

    if (!formContext) {
        throw new Error('Cannot use Input outside of a Form');
    }

    if (!name) {
        throw new Error('You must specify a name');
    }

    const { register } = formContext;

    // We don't want the browser validation overruling ours so ensure URL fields are type='text'
    const inputType = type === 'url' ? 'text' : type;

    if (inputType === 'hidden') {
        return <></>;
    }

    // Build the specified type of input, e.g. text, select, checkbox etc.
    const input = buildInput(inputType, name, label ?? name, register, validation || {}, inputProps);

    return input;
};

Input.Raw = RawInput;

export default Input;
