import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

type ChoiceChipButtonProps = {
    option: { value: string; label: string };
    handleClick: (value: string) => void;
} & (
    | { isMulti: true; currentValue: string[] }
    | {
          isMulti?: false;
          currentValue: string;
      }
);

export const ChoiceChipButton: React.FC<ChoiceChipButtonProps> = ({ isMulti, option, currentValue, handleClick }) => {
    const isEnabled = isMulti ? currentValue.includes(option.value) : currentValue === option.value;
    return (
        <button
            className={clsx(
                'px-sm py-xs mr-xs mb-xs align-top bg-componentBackgroundPrimary border rounded border-outlinePrimary text-textPrimary leading-none',
                {
                    'border-outlineSecondary': isEnabled
                }
            )}
            type='button'
            onClick={() => handleClick(option.value)}
        >
            <div className='flex flex-row'>
                {isMulti && (
                    <div
                        className={clsx(
                            'flex items-center justify-center w-4 h-4 mr-3 rounded-sm border border-outlineSecondary',
                            {
                                'bg-primaryButtonBackground': isEnabled,
                                'border-primaryButtonBackground': isEnabled
                            }
                        )}
                    >
                        {isEnabled && <FontAwesomeIcon icon={faCheck} size='xs' className='text-textPrimary' />}
                    </div>
                )}
                {option.label}
            </div>
        </button>
    );
};
