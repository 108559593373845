import { Divider } from '@/components/Divider';
import { findColumn, preferred, required, StreamDataColumn } from '@squaredup/data-streams';
import { isDefined } from '@squaredup/utilities';
import Field from 'components/forms/field/Field';
import { defaultWrapperClassName } from 'components/forms/input/Input';
import { OptionDropdown } from 'components/visualisationOptions/OptionDropdown';
import { OptionToggle } from 'components/visualisationOptions/OptionToggle';
import { VisualisationOption } from 'dashboard-engine/types/Visualisation';
import { uniq } from 'lodash';
import { ColumnDropdown } from '../sharedComponents/ColumnDropdown';
import { VisualisationConfigAccordion } from '../VisualisationConfigAccordion';
import { DataStreamLineGraphConfig } from './Config';
import {
    findLabelColumns,
    findLineGraphLabelColumn,
    findLineGraphLabelColumns,
    findLineGraphUnitLabelColumn,
    findLineGraphValueColumns
} from './LineGraphColumns';
import { matchesLineGraphData } from './LineGraphDataValidation';

const getTimeColumns = (columns: StreamDataColumn[]) => {
    const defaultTimeColumn = findColumn(columns, required('shapeName', 'shape_date'), preferred('role', 'timestamp'));

    if (defaultTimeColumn.succeeded) {
        const defaultTimeColumnValue = defaultTimeColumn.value;

        const roleTimestampColumns = columns.filter((column) => column.role === 'timestamp');
        const shapeDateColumns = columns.filter((column) => column.shapeName === 'shape_date');

        const allTimeColumns = uniq([...roleTimestampColumns, ...shapeDateColumns]);

        return allTimeColumns.map((column) => {
            if (defaultTimeColumnValue.column.name === column.name) {
                return { label: `Auto - ${column.displayName}`, value: column.name };
            } else {
                return { label: column.displayName, value: column.name };
            }
        });
    } else {
        return [];
    }
};

const getValueColumns = (columns: StreamDataColumn[]) => {
    // Don't pass the config, or else we won't get any options other than those already selected
    const allValueColumns = findLineGraphValueColumns(columns, undefined);

    if (allValueColumns.failed) {
        return [];
    }

    return allValueColumns.value.map(({ column }) => ({ label: column.displayName, value: column.name }));
};

const DataStreamLineGraphOptions: VisualisationOption<DataStreamLineGraphConfig> = {
    initialPanels: (config?: DataStreamLineGraphConfig) => {
        const panels = [{ name: 'mapping', isOpen: true }];

        if (config?.xAxisLabel) {
            panels.push({ name: 'xAxis', isOpen: true });
        }

        if (config?.yAxisLabel || (config?.yAxisRangeMode && config?.yAxisRangeMode !== 'auto')) {
            panels.push({ name: 'yAxis', isOpen: true });
        }

        if (config?.showLegend) {
            panels.push({ name: 'legend', isOpen: true });
        }

        if (
            config?.dataPoints ||
            (config?.shading !== undefined && !config.shading) ||
            (config?.showGrid !== undefined && !config.showGrid) ||
            config?.showTrendLine ||
            config?.cumulative
        ) {
            panels.push({ name: 'options', isOpen: true });
        }

        return panels;
    },
    dataMappingComponent: ({ columns, config, onChange }) => {
        const xAxisColumnOptions = getTimeColumns(columns);
        const yAxisColumnOptions = getValueColumns(columns);

        const yAxisDefaultOptions = Array.isArray(config.yAxisColumn)
            ? config.yAxisColumn.map((c) => yAxisColumnOptions.find((option) => option.value === c)).filter(isDefined)
            : yAxisColumnOptions.find((option) => option.value === config.yAxisColumn) ?? [];

        return (
            <>
                <Field label='X-Axis' help='Select the column to use for the X-Axis of the chart'>
                    <OptionDropdown
                        options={xAxisColumnOptions}
                        defaultValue={
                            xAxisColumnOptions.find((option) => option.value === config.xAxisColumn) ??
                            xAxisColumnOptions[0]
                        }
                        onChange={onChange}
                        action='x-axis-column'
                    />
                </Field>
                <Field label='Y-Axis' help='Select the column to use for the Y-Axis of the chart'>
                    <OptionDropdown
                        options={yAxisColumnOptions}
                        defaultValue={yAxisDefaultOptions}
                        placeholder={yAxisColumnOptions[0] ? `Auto - ${yAxisColumnOptions[0].label}` : undefined}
                        isMulti={true}
                        onChange={onChange}
                        action='y-axis-column'
                    />
                </Field>
                <ColumnDropdown
                    label='Series'
                    name='seriesColumn'
                    help='Select the column to use to split the data into series'
                    showNoneOption={true}
                    columns={findLabelColumns(findLineGraphLabelColumns(columns)).map((f) => f.column)}
                    defaultColumn={findLineGraphLabelColumn(columns)}
                    config={config}
                    onChange={onChange}
                />
                <ColumnDropdown
                    label='Unit'
                    name='unitLabelColumn'
                    help='Select the column to use for the unit label'
                    showNoneOption={true}
                    columns={columns}
                    defaultColumn={findLineGraphUnitLabelColumn(columns)}
                    config={config}
                    onChange={onChange}
                />
            </>
        );
    },
    configurationComponent: ({ config, accordionControls, tileData, onChange }) => {
        const yAxisRangeOptions = [
            {
                label: 'Auto',
                value: 'auto'
            },
            {
                label: 'Percentage',
                value: 'percentage'
            },
            {
                label: 'Fit to data, from zero',
                value: 'fit'
            },
            {
                label: 'Custom',
                value: 'custom'
            }
        ];

        const legendPositionOptions = [
            { label: 'Bottom', value: 'bottom' },
            { label: 'Top', value: 'top' },
            { label: 'Left', value: 'left' },
            { label: 'Right', value: 'right' }
        ];

        const allY: number[] = tileData.rows.flatMap((row) =>
            row.map((v) => v.value).filter((val) => typeof val === 'number')
        ) as number[];

        const minY = Math.min(...allY);
        const maxY = Math.max(...allY);

        return (
            <>
                <VisualisationConfigAccordion value='xAxis' label='X-Axis' accordionControls={accordionControls}>
                    <Field label='Label' htmlFor='x-axis-label'>
                        <input
                            name='x-axis-label'
                            type='text'
                            className={defaultWrapperClassName}
                            onChange={(e) => onChange({ action: 'x-axis-label', data: e.target.value })}
                            maxLength={250}
                            defaultValue={config?.xAxisLabel}
                            placeholder='Auto'
                        />
                    </Field>
                </VisualisationConfigAccordion>

                <Divider />

                <VisualisationConfigAccordion value='yAxis' label='Y-Axis' accordionControls={accordionControls}>
                    <Field label='Label' htmlFor='y-axis-label'>
                        <input
                            name='y-axis-label'
                            type='text'
                            className={defaultWrapperClassName}
                            onChange={(e) => onChange({ action: 'y-axis-label', data: e.target.value })}
                            maxLength={250}
                            defaultValue={config?.yAxisLabel}
                            placeholder='Auto'
                        />
                    </Field>

                    <Field label='Range'>
                        <OptionDropdown
                            options={yAxisRangeOptions}
                            defaultValue={
                                yAxisRangeOptions.find((option) => option.value === config.yAxisRangeMode) ??
                                yAxisRangeOptions[0]
                            }
                            onChange={onChange}
                            action='y-axis-range-mode'
                        />

                        {config?.yAxisRangeMode === 'custom' && (
                            <div className='flex items-center justify-between mt-6 space-x-4'>
                                <input
                                    name='y-axis-range-from'
                                    type='number'
                                    className={defaultWrapperClassName}
                                    onChange={(e) => onChange({ action: 'y-axis-range-from', data: e.target.value })}
                                    defaultValue={config?.yAxisRangeFrom ?? minY}
                                    placeholder='0'
                                />
                                <p>to</p>
                                <input
                                    name='y-axis-range-to'
                                    type='number'
                                    className={defaultWrapperClassName}
                                    onChange={(e) => onChange({ action: 'y-axis-range-to', data: e.target.value })}
                                    defaultValue={config?.yAxisRangeTo ?? maxY}
                                    placeholder='0'
                                />
                            </div>
                        )}
                    </Field>
                </VisualisationConfigAccordion>

                <Divider />

                <VisualisationConfigAccordion value='legend' label='Legend' accordionControls={accordionControls}>
                    <OptionToggle
                        name='label'
                        label='Show legend'
                        onChange={(data) => onChange({ action: 'line-graph-legend-toggle', data })}
                        checked={config.showLegend ?? false}
                    />

                    {config.showLegend && (
                        <div className='flex items-center mt-3 space-x-4'>
                            <label>Position</label>
                            <div className='flex-1'>
                                <OptionDropdown
                                    options={legendPositionOptions}
                                    defaultValue={
                                        legendPositionOptions.find(({ value }) => value === config.legendPosition) ??
                                        legendPositionOptions[0]
                                    }
                                    onChange={onChange}
                                    action='line-graph-legend-position'
                                />
                            </div>
                        </div>
                    )}
                </VisualisationConfigAccordion>

                <Divider />

                <VisualisationConfigAccordion value='options' label='Options' accordionControls={accordionControls}>
                    <OptionToggle
                        name='dataPoints'
                        label='Data points'
                        onChange={(data) => onChange({ action: 'line-graph-data-points-toggle', data })}
                        checked={config.dataPoints ?? false}
                    />

                    <OptionToggle
                        name='shading'
                        label='Shading'
                        onChange={(data) => onChange({ action: 'line-graph-shading-toggle', data })}
                        wrapperClassName='mt-3'
                        checked={config.shading ?? true}
                    />

                    <OptionToggle
                        name='gridLines'
                        label='Grid lines'
                        onChange={(data) => onChange({ action: 'line-graph-grid-lines-toggle', data })}
                        wrapperClassName='mt-3'
                        checked={config.showGrid ?? true}
                    />

                    <OptionToggle
                        name='trendline'
                        label='Trend line'
                        onChange={(data) => onChange({ action: 'line-graph-trend-line-toggle', data })}
                        wrapperClassName='mt-3'
                        checked={config.showTrendLine ?? false}
                    />

                    <OptionToggle
                        name='cumulative'
                        label='Cumulative'
                        onChange={(data) => onChange({ action: 'line-graph-cumulative-toggle', data })}
                        wrapperClassName='mt-3'
                        checked={config.cumulative ?? false}
                    />
                </VisualisationConfigAccordion>
            </>
        );
    },
    handlers: {
        'x-axis-column': (config, data) => {
            return { ...config, xAxisColumn: data };
        },
        'y-axis-column': (config, data) => {
            return { ...config, yAxisColumn: data };
        },
        'unitLabelColumn-select': (config, data) => {
            return { ...config, unitLabelColumn: data };
        },
        'seriesColumn-select': (config, data) => {
            return { ...config, seriesColumn: data };
        },
        'line-graph-data-points-toggle': (config, data) => {
            return { ...config, dataPoints: data };
        },
        'line-graph-shading-toggle': (config, data) => {
            return { ...config, shading: data };
        },
        'line-graph-grid-lines-toggle': (config, data) => {
            return { ...config, showGrid: data };
        },
        'line-graph-cumulative-toggle': (config, data) => {
            return { ...config, cumulative: data };
        },
        'line-graph-trend-line-toggle': (config, data) => {
            return { ...config, showTrendLine: data };
        },
        'x-axis-label': (config, data) => {
            return { ...config, xAxisLabel: data };
        },
        'y-axis-label': (config, data) => {
            return { ...config, yAxisLabel: data };
        },
        'y-axis-range-mode': (config, data) => {
            return { ...config, yAxisRangeMode: data };
        },
        'y-axis-range-from': (config, data) => {
            return { ...config, yAxisRangeFrom: parseInt(data) };
        },
        'y-axis-range-to': (config, data) => {
            return { ...config, yAxisRangeTo: parseInt(data) };
        },
        'line-graph-legend-toggle': (config, data) => {
            return { ...config, showLegend: data, legendPosition: config.legendPosition ?? 'bottom' };
        },
        'line-graph-legend-position': (config, data) => {
            return { ...config, legendPosition: data };
        }
    },
    validate: (_, config) => config,
    matchesData: matchesLineGraphData
};

export default DataStreamLineGraphOptions;
