import { DashboardIdFolder } from '@squaredup/tenants';
import { isDefined } from '@squaredup/utilities';
import { DashboardType } from 'dashboard-engine/types/Dashboard';
import { flatMap } from 'lodash';

export function flattenDashboardIdOrder(
    order: (string | DashboardIdFolder)[],
    folderPath: string[] = []
): { id: string; folderPath: string[] }[] {
    return flatMap(order, (item) =>
        typeof item === 'string'
            ? [{ id: item, folderPath }]
            : flattenDashboardIdOrder(item.dashboardIdOrder, [...folderPath, item.name])
    );
}

export type DashboardTypeWithFolderPath = DashboardType & { folderPath: string[] };

// Sorts the dashboards by the order specified on the workspace and ignores the folder structure.
// This will be the order shown in the navbar as a 1D array.
export const flattenedDashboardsSortedByWorkspaceOrder = (nestedOrder?: (string | DashboardIdFolder)[]) => {
    return (data: DashboardType[]): DashboardTypeWithFolderPath[] => {
        const flattenedIdOrder = flattenDashboardIdOrder(nestedOrder || []);

        const dashboardsWithAssignedOrder: DashboardTypeWithFolderPath[] =
            flattenedIdOrder
                ?.map(({ id, folderPath }) => {
                    const dash = data.find((d) => d.id === id);
                    return dash ? { ...dash, folderPath } : undefined;
                })
                ?.filter(isDefined) ?? [];

        const dashboardsWithUnassignedOrder: DashboardTypeWithFolderPath[] = data
            .filter(({ id }) => !dashboardsWithAssignedOrder.some((d) => d.id === id))
            .sort(
                // Secondary sort by ID to ensure dashboards with the same name are consistently ordered.
                (a: any, b: any) => a.displayName.localeCompare(b.displayName) || a.id.localeCompare(b.id)
            )
            .map((dash) => ({ ...dash, folderPath: [] }));

        return [...dashboardsWithAssignedOrder, ...dashboardsWithUnassignedOrder];
    };
};

export interface DashboardFolder {
    name: string;
    id: string;
    children: (DashboardType | DashboardFolder)[];
}

export const dashboardsSortedByWorkspaceOrder =
    (nestedOrder?: (string | DashboardIdFolder)[]) =>
    (data: DashboardType[]): (DashboardType | DashboardFolder)[] => {
        const organised: Record<string, boolean> = {};

        const organise = (order: (string | DashboardIdFolder)[]): (DashboardType | DashboardFolder)[] =>
            order
                .map((item) => {
                    if (typeof item === 'string') {
                        organised[item] = true;
                        return data.find((dash) => dash.id === item)!;
                    }
                    return {
                        id: item.id,
                        name: item.name,
                        children: organise(item.dashboardIdOrder)
                    } as DashboardFolder;
                })
                .filter((item) => item !== undefined);

        const ordered = organise(nestedOrder || []);

        return [
            ...ordered,
            ...data
                .filter((dash) => !organised[dash.id])
                .sort((a, b) => a.displayName.localeCompare(b.displayName) || a.id.localeCompare(b.id))
        ];
    };

export function isDashboard(item: DashboardType | DashboardFolder): item is DashboardType {
    return 'workspaceId' in item;
}

export function isFolder(item: DashboardType | DashboardFolder): item is DashboardFolder {
    return !isDashboard(item);
}

export function recursiveFolderToDashboardIdOrder(folder: DashboardFolder) {
    const items: (DashboardIdFolder | string)[] = [];

    for (const item of folder.children) {
        if (isDashboard(item)) {
            items.push(item.id);
        } else {
            items.push(recursiveFolderToDashboardIdOrder(item));
        }
    }

    return {
        id: folder.id,
        name: folder.name,
        dashboardIdOrder: items
    } as DashboardIdFolder;
}
