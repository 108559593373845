import { ClientDataStreamRequest } from '@squaredup/data-streams';
import { buildQuery } from '@squaredup/graph';
import { getTimeframe } from '@squaredup/timeframes';
import { useState, useCallback } from 'react';
import { useInfiniteQuery } from 'react-query';
import { requestData } from 'services/DataStreamService';

const FETCH_LIMIT = 50; // Health data stream is expensive, don't fetch too many at a time.

const buildObjectTemplateRequest = (
    configId: string,
    searchQuery: string,
    rangeStart: number
): ClientDataStreamRequest => {
    const { gremlinQuery: gremlin, bindings } = buildQuery(
        {
            plugins: [{ value: configId }],
            booleanQuery: searchQuery
        },
        `
    .not(has('sourceType', 'squaredup/data-source'))
    .order().by('__configId')
    .by('__search')
    .valueMap(true)
    .fold()
    .as('objects', 'count')
    .select('objects','count')
    .by(range(local, ${rangeStart * FETCH_LIMIT}, ${(rangeStart + 1) * FETCH_LIMIT}))
    .by(count(local))
    .select('objects')
    .unfold()
    `
    );

    return {
        dataStreamId: 'datastream-health',
        scope: {
            query: '',
            gremlin,
            bindings,
            literalGremlin: true
        },
        options: { 
            group: { keyColumns: [], aggregations: [] }, 
            sort: { criteria: [] },
            noAccessControlCache: true // This data is often displayed directly after changing data source links in workspace
        },
        timeframe: getTimeframe('last24hours')
    };
};

const buildBooleanSearch = (searchQuery: unknown) => {
    return (searchQuery ?? '') !== '' ? `${searchQuery} OR type:${searchQuery}` : '';
};

export const useInfiniteObjectQuery = (configId: string, extraKeys: string[] = []) => {
    const [searchQuery, setSearchQuery] = useState<unknown>('');

    const {
        data: pagedData,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isError
    } = useInfiniteQuery([configId, searchQuery, 'PAGED_OBJECT_LIST', ...extraKeys], {
        queryFn: async ({ pageParam = 0 }) => {
            const clientRequest = buildObjectTemplateRequest(configId, buildBooleanSearch(searchQuery), pageParam);
            const response = await requestData(clientRequest);
            return { data: response.rows, nextPage: pageParam + 1 };
        },
        getNextPageParam: (lastPage) => (lastPage.data.length > 0 ? lastPage.nextPage : undefined)
    });

    const flatResults = pagedData?.pages.flatMap((v) => v.data);

    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement && hasNextPage) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (scrollHeight - scrollTop - clientHeight < 300) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, hasNextPage]
    );

    return {
        setSearchQuery,
        flatResults,
        isFetching,
        fetchMoreOnBottomReached,
        isError
    };
};
