import { findColumn, required, StreamData, ClientDataStreamRequest } from '@squaredup/data-streams';
import { memoize, orderBy } from 'lodash';
import { requestData } from 'services/DataStreamService';
import { AutocompleteOption, getAutoCompleteLabelText, ensureOptions, OptionsLoader } from './autocompleteOptions';

/**
 * Convert data stream data with value and (optionally) label columns to autocomplete options
 */
const streamDataToOptions = (streamData: StreamData) => {
    const valueColumn = findColumn(streamData.metadata.columns, required('role', 'value'));
    const labelColumn = findColumn(streamData.metadata.columns, required('role', 'label'));

    if (valueColumn.failed) {
        throw new Error('No value column in option data stream');
    }

    const valueIndex = valueColumn.value.dataIndex;
    const labelIndex = labelColumn.succeeded ? labelColumn.value.dataIndex : valueIndex;

    return streamData.rows.reduce((acc, row) => {
        const value = row[valueIndex].formatted;
        const label = row[labelIndex].formatted;

        acc.push({ value, label });

        return acc;
    }, [] as AutocompleteOption[]);
};

export const nullOptionsLoader: OptionsLoader = {
    canLoad: false,
    getOption: (x) => x,
    loadOptions: async () => [],
    onLoad: () => undefined
};

export const readStreamAsAutocompleteOptions = (
    request: ClientDataStreamRequest,
    defaultOptions: AutocompleteOption[]
): OptionsLoader => {
    const getOptions = memoize(async () => streamDataToOptions(await requestData(request)));
    // The options which have been loaded, scoped so that getOption can access them.
    let loaded: { resolvedOptions: AutocompleteOption[] } = { resolvedOptions: [] };
    // A function called once the options have been loaded.
    let onLoad: (() => void) | undefined;

    const optionsLoader: OptionsLoader = {
        loadOptions: async (search: string) => {
            const options = await getOptions();

            // Options for values that don't exist in the data,
            // i.e. were previously added manually by the user
            const extraOptions = defaultOptions.filter(({ value: v }) => !options.some(({ value: v2 }) => v === v2));

            // Always expose all the options (not filtered by the search criteria).
            loaded.resolvedOptions = orderBy(options.concat(extraOptions), (o) => getAutoCompleteLabelText(o).toLowerCase());

            // Now that the options are loaded we can trigger onLoad.
            onLoad?.();
            return loaded.resolvedOptions.filter((o) => getAutoCompleteLabelText(o).toLowerCase().includes(search.toLowerCase()));
        },
        getOption: (x: unknown) => ensureOptions(loaded.resolvedOptions, x),
        onLoad: (f: () => void) => {
            onLoad = f;
        },
        canLoad: true
    };

    return optionsLoader;
};
