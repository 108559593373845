import Template from '@/components/Template';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getWorkspaceIdFromConfigId } from '@squaredup/ids';
import { TimeframeEnumValue, defaultTimeframeEnum } from '@squaredup/timeframes';
import Tooltip from 'components/tooltip/Tooltip';
import { AppContext } from 'contexts/AppContext';
import { checkIfAllTilesUseFixedTimeframe } from 'dashboard-engine/util/dashboard';
import { getNodeTitle } from 'dashboard-engine/util/graphDataToNetworkData';
import { getTypeNameForNode } from 'lib/types';
import { usePageTitle } from 'lib/usePageTitle';
import PerspectiveDashboard from 'pages/dashboard/PerspectiveDashboard';
import { useDashboard } from 'queries/hooks/useDashboard';
import { useDashboardVariables } from 'queries/hooks/useDashboardVariables';
import { usePerspectives } from 'queries/hooks/usePerspectives';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Tabs } from 'ui/Tabs';
import DashboardTimeframeChooser from 'ui/timeframe/DashboardTimeframeChooser';
import { DrilldownTabLabel } from './DrilldownTabLabel';
import { ExternalLinks } from './ExternalLinks';
import { GraphNodeDrilldownIcon } from './GraphNodeDrilldownIcon';
import GraphNodeDrilldownOverview from './GraphNodeDrilldownOverview';
import { NodeDataSourceStatePill } from './NodeDataSourceStatePill';
import {
    DRILLDOWN_NODE,
    GraphNodeLoading,
    NodeNotFound,
    NodeWithCanonical,
    queryForGraphNode,
    useTags
} from './common';

const OVERVIEW_TAB = 'overview';

const getNodeType = (node: NodeWithCanonical) => {
    if (!node) {
        return undefined;
    }
    const preferSourceType = !node.isCanonical && node.sourceType?.[0]?.startsWith('squaredup/') !== true;
    return getTypeNameForNode(node, 'singular', preferSourceType);
};

function GraphNodeDrilldown() {
    const { id: nodeId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const { setCurrentWorkspaceID, currentWorkspaceID } = useContext(AppContext);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [timeframe, setTimeframe] = useState<TimeframeEnumValue>(defaultTimeframeEnum);

    const { perspectives, isLoading: isLoadingPerspectives } = usePerspectives(nodeId);

    const currentPerspective = perspectives[selectedTabIndex];

    // Get full context of the passed node
    const { data: node, isLoading: nodeLoading } = useQuery<NodeWithCanonical>(
        [DRILLDOWN_NODE, nodeId],
        () => queryForGraphNode('g.V().has("id", id).valueMap(true)', { id: nodeId }),
        // don't refetch the data if we used drilldown search
        { staleTime: 3000 }
    );

    const title = getNodeTitle(node);
    usePageTitle(title);

    const { tags, isLoading: isGraphTagsLoading } = useTags(node?.id, node);

    const dashboardId = currentPerspective?.dashboardId;

    const initialVariable = node
        ? {
              selectedObjects: [
                  {
                      id: node.id,
                      name: title
                  }
              ]
          }
        : undefined;

    const { data: dashboard, isLoading: isLoadingDashboard } = useDashboard(dashboardId);
    const { data: dashboardVariables, isLoading: isLoadingVariables } = useDashboardVariables(
        dashboardId,
        initialVariable
    );

    const allTilesUseFixedTimeframe = checkIfAllTilesUseFixedTimeframe(dashboard?.content?.contents);

    const searchPerspective = searchParams.get('perspective');

    // When the perspectives have loaded, set the tab to the perspective specified in the search params
    useEffect(() => {
        if (perspectives.length > 0) {
            if (searchPerspective === OVERVIEW_TAB) {
                setSelectedTabIndex(perspectives.length);
                return;
            }
            const index = perspectives.findIndex((p) => p.dashboardId === searchPerspective);
            if (index >= 0) {
                setSelectedTabIndex(index);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perspectives.length]);

    useEffect(() => {
        // When the perspective dashboard changes, reset the timeframe to the dashboard's default timeframe
        if (dashboard?.timeframe && dashboard.timeframe !== timeframe) {
            setTimeframe(dashboard.timeframe);
        } else if (timeframe !== defaultTimeframeEnum) {
            setTimeframe(defaultTimeframeEnum);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardId]);

    useEffect(() => {
        // Update the URL search params with the current perspective ID
        if (perspectives.length > 0) {
            searchParams.set('perspective', dashboardId ?? OVERVIEW_TAB);
            setSearchParams(searchParams, { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTabIndex]);

    const configId = node?.__configId?.[0];
    const isWorkspaceNode =
        node && node.sourceType?.[0]?.startsWith('squaredup/') && node.sourceName?.[0] === 'Workspace';
    // If the drilldown node is a workspace node be sure to change the currentWorkspace if required
    useEffect(() => {
        if (isWorkspaceNode && configId) {
            const newWorkspaceId = getWorkspaceIdFromConfigId(configId);
            if (newWorkspaceId && newWorkspaceId !== currentWorkspaceID) {
                setCurrentWorkspaceID({ id: newWorkspaceId, performRedirect: false });
            }
        }
    }, [setCurrentWorkspaceID, isWorkspaceNode, configId, currentWorkspaceID]);

    // The component is loading if any of the queries are loading
    const isLoading = nodeLoading || isGraphTagsLoading || isLoadingPerspectives;

    const isLoadingPerspectiveDashboard = isLoadingDashboard || isLoadingVariables;

    if (isLoading) {
        return <GraphNodeLoading />;
    }

    if (!node) {
        return <NodeNotFound />;
    }

    const tabs = [
        ...perspectives.map((p) => ({
            key: p.dashboardId,
            label: <DrilldownTabLabel {...p} />,
            component:
                !isLoadingPerspectiveDashboard && dashboard && dashboard.content && dashboardVariables ? (
                    <PerspectiveDashboard
                        key={p.dashboardId}
                        workspace={p.workspaceId}
                        timeframe={timeframe}
                        dashboard={dashboard}
                        dashboardVariables={dashboardVariables}
                    />
                ) : (
                    <GraphNodeLoading />
                ),
            className: 'pt-1'
        })),
        {
            label: 'Overview',
            key: OVERVIEW_TAB,
            component: <GraphNodeDrilldownOverview node={node} timeframe={timeframe} />,
            className: 'pt-1'
        }
    ];

    return (
        <Template
            flex
            key={nodeId}
            icon={<GraphNodeDrilldownIcon node={node} />}
            tags={tags}
            title={title}
            actions={
                <>
                    {node && <ExternalLinks node={node} />}

                    <DashboardTimeframeChooser
                        onCurrentTimeframeChange={setTimeframe}
                        onDefaultTimeframeChange={() => undefined}
                        currentTimeframe={timeframe}
                        canChangeTimeframe={false}
                        isFixedTimeframe={allTilesUseFixedTimeframe}
                    />
                </>
            }
            equalPadding
            iconAlignment='title'
            type={getNodeType(node)}
            statusPill={node?.isCanonical !== true ? <NodeDataSourceStatePill node={node} /> : undefined}
        >
            <Tabs
                tabs={tabs}
                tabsID='drilldownTabs'
                selectedIndex={selectedTabIndex}
                onSelect={setSelectedTabIndex}
                className='flex flex-1 h-auto pb-4'
                additionalControl={
                    currentPerspective ? (
                        <div className='flex ml-2 pt-1'>
                            <Tooltip title='View this dashboard' variant='light'>
                                <Link to={`/dashboard/${currentPerspective.dashboardId}?nodeId=${nodeId}`}>
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </Link>
                            </Tooltip>
                        </div>
                    ) : (
                        <></>
                    )
                }
            />
        </Template>
    );
}

export default GraphNodeDrilldown;
