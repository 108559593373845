import { Serialised } from '@squaredup/ids';
import { UIConfig } from '@squaredup/utilities';
import { getDefaultValues } from 'components/forms/jsonForms/DisplayJsonUi';
import { resolveFormFields } from 'components/forms/jsonForms/resolveAutocompleteOptions';
import { useVariableContext } from 'dashboard-engine/hooks/useVariableContext';
import type { ProjectedDataStreamDefinitionEntity } from 'dynamo-wrapper';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { useResolvedScope } from './useResolvedScope';

interface UseDataStreamFormProps {
    formTemplate: Serialised<UIConfig[]> | undefined;
    defaultValues?: Record<string, any>;
    streamDefinitions: Serialised<ProjectedDataStreamDefinitionEntity>[];
    pluginConfigId: `config-${string}` | undefined;
    pluginId: `plugin-${string}` | undefined;
}

export const useDataStreamForm = ({
    formTemplate,
    defaultValues: initialDefaultValues,
    streamDefinitions,
    pluginId,
    pluginConfigId
}: UseDataStreamFormProps) => {
    const { config } = useDatasetContext();

    const defaultValues: Record<string, any> = {
        ...getDefaultValues(formTemplate, { includeHidden: true }),
        ...initialDefaultValues,
        ...config.dataStream?.dataSourceConfig
    };

    const form = useForm({ mode: 'all', defaultValues, shouldUnregister: true });

    const formData = form.watch();

    const scope = useResolvedScope();

    const context = useVariableContext(config);

    const formFields = useMemo(() => {
        if (formTemplate && streamDefinitions.length > 0 && pluginId) {
            return resolveFormFields(
                formTemplate,
                formData,
                streamDefinitions,
                {
                    scope: scope ?? [],
                    pluginConfigId,
                    context
                },
                pluginId
            );
        }
    }, [streamDefinitions, pluginId, formTemplate, formData, scope, pluginConfigId, context]);

    return { form, formFields };
};
