import { AppLoadingSpinner } from 'components/AppLoadingSpinner';
import Config from 'config';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryClient } from 'react-query';
import OpenAccessAppRoutes from 'routes/OpenAccessAppRoutes';
import API from 'services/API';
import ErrorFallback from 'ui/errorHandling/ErrorFallback';
import { useForcedTheme } from 'ui/hooks/useForcedTheme';
import { socketCleanup, socketConnect } from 'ui/notifications/WebSocketNotifications';
import AppContextWrapper from './AppContextWrapper';
import { useEnsureAppConfiguration } from 'ui/hooks/useEnsureAppConfiguration';

function OpenAccessWrapper() {
    const queryClient = useQueryClient();
    const { tenant } = useEnsureAppConfiguration();
    const forcedTheme = useForcedTheme();

    // Force dark mode
    document.body.setAttribute('data-theme', 'dark');

    useEffect(() => {
        if (tenant) {
            socketConnect(tenant.id, Config.Websocket, queryClient);
            return () => {
                socketCleanup();
            };
        }
    }, [tenant, queryClient]);

    useEffect(() => {
        if (tenant) {
            const debugEnabled = tenant.debugEnabledUntil ? tenant.debugEnabledUntil > Date.now() : false;
            if (debugEnabled) {
                // eslint-disable-next-line no-console
                console.debug(
                    `Debug logging enabled until ${new Date(tenant.debugEnabledUntil || '').toString()}`
                );
            }
    
            // Pass the tenant debug flag to the server, so it doesn't need to look it up.
            API.defaults.headers.common['squp-tenant-flag-debug'] = tenant.debugEnabledUntil ?? 0;    
        }
    }, [tenant]);

    if (!tenant) {        
        // Wait until tenant data is loaded so we know we're on the right app version.
        return <AppLoadingSpinner message={'Loading...'} />;
    }

    return (
        <AppContextWrapper workspaceLoadingElement={<AppLoadingSpinner message={'Loading...'} />}>
            <div 
                id='wrapper' 
                className='relative z-0 w-full h-full overflow-hidden bg-backgroundPrimary text-textPrimary'
                data-theme={forcedTheme ?? 'dark'}
            >
                <div className='flex w-full h-full overflow-hidden'>
                    <div className='relative flex flex-col flex-grow w-full h-full min-w-0 transition-colors willChangeWidth scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <OpenAccessAppRoutes />
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
            <div id='graphTooltip'></div>
        </AppContextWrapper>
    );
}

OpenAccessWrapper.propTypes = {};

export default OpenAccessWrapper;
