import Button from 'components/button/Button';
import Text from '@/components/Text';
import type { OrganisationHomeConfig } from 'dynamo-wrapper';
import { useQueryClient } from 'react-query';
import { defaultGlobalViewConfig } from '../StatusPage';
import { STATUS_CONFIG, useSaveStatusConfig } from '../utils/useStatusConfig';
import { StatusRequestType } from './StatusOverview';

const useClearGlobalViewFilters = () => {
    const queryClient = useQueryClient();
    const statusConfig = queryClient.getQueryData<OrganisationHomeConfig>([STATUS_CONFIG]) ?? defaultGlobalViewConfig;

    const saveStatusConfig = useSaveStatusConfig();

    const clearFilters = () => {
        saveStatusConfig.mutate({ ...statusConfig, filter: [], workspaceTypes: [], tags: [] });
    };

    return clearFilters;
};

export function GlobalViewEmptyMessage(props: { type: StatusRequestType; monitorCount: number; dashCount: number }) {
    const clearFilters = useClearGlobalViewFilters();

    if (props.type === 'dash' && props.dashCount > 0) {
        return (
            <>
                <Text.Body>There are no dashboards matching the filter criteria.</Text.Body>
                <Text.Body>
                    Try adjusting your filters or{' '}
                    <Button variant='link' onClick={clearFilters}>
                        clear filters
                    </Button>
                </Text.Body>
            </>
        );
    }

    if (props.type === 'dash') {
        return <Text.Body>There are no dashboards configured. Dashboards can be added inside workspaces</Text.Body>;
    }

    if (props.type === 'monitor' && props.monitorCount > 0) {
        return (
            <>
                <Text.Body>There are no monitors matching the filter criteria.</Text.Body>
                <Text.Body>
                    Try adjusting your filters or{' '}
                    <Button variant='link' onClick={clearFilters}>
                        clear filters
                    </Button>
                </Text.Body>
            </>
        );
    }

    if (props.type === 'monitor') {
        return (
            <>
                <Text.Body>
                    There are no monitors in your organization. 
                </Text.Body>
                <Text.Body>
                    Monitors are enabled on dashboard tiles inside of workspaces.{' '}
                    <Button variant='link' href='https://squaredup.com/cloud/monitoring'>
                        Learn more
                    </Button>
                </Text.Body>
            </>
        );
    }

    if (props.type === 'space') {
        return (
            <>
                <Text.Body>There are no workspaces matching the filter criteria.</Text.Body>
                <Text.Body>
                    Try adjusting your filters or{' '}
                    <Button variant='link' onClick={clearFilters}>
                        clear filters
                    </Button>
                </Text.Body>
            </>
        );
    }

    throw new Error(`Unknown statusRequestType: ${props.type}, in GlobalViewEmptyMessage`);
}
