import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StreamDataColumn, type DataStreamGroupingConfig } from '@squaredup/data-streams';
import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import stringify from 'fast-json-stable-stringify';
import { useCallback, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import type { PartialDeep } from 'type-fest';
import EditorSelect from 'ui/editor/components/EditorSelect';
import { useFormChange } from 'ui/editor/dataStream/hooks/useFormChange';
import {
    GroupingUpdateData,
    getAllRequiredColumnOptions,
    getBucketByOptions,
    getGroupingColumns,
    toGroupingConfig,
    validateAggregations
} from 'ui/editor/dataStream/utilities/grouping';
import { AggregationRow } from './AggregationRow';

interface GroupingFormProps {
    defaultValues: PartialDeep<GroupingUpdateData>;
    columns: StreamDataColumn[];
    onChange?: (
        event:
            | { isValid: true; formData: DataStreamGroupingConfig['group'] }
            | { isValid: false; formData: PartialDeep<GroupingUpdateData> }
    ) => void;
}

export const GroupingForm: React.FC<GroupingFormProps> = ({ defaultValues, columns, onChange }) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columnOptions = useMemo(() => getGroupingColumns(columns), [stringify(columns)]);

    const formProps = useForm<GroupingUpdateData>({ defaultValues });

    const { watch, control, setValue } = formProps;

    const {
        fields: aggregationFields,
        append,
        remove
    } = useFieldArray({
        control,
        name: 'aggregations'
    });

    const handleChange = useCallback(
        (values: PartialDeep<GroupingUpdateData>) => {
            const isAggregationsAlreadySet = (values?.aggregations?.length ?? 0) > 0;
            const isGroupingAlreadySet = defaultValues.groupBy != null;

            if (!isGroupingAlreadySet && !isAggregationsAlreadySet && values.groupBy) {
                values.aggregations = [{ aggregationType: 'count' }];
                append({ aggregationType: 'count' });
            }

            const validGroup = toGroupingConfig(values);

            if (validGroup == null) {
                onChange?.({ isValid: false, formData: values });
                return;
            }

            onChange?.({ isValid: true, formData: validGroup });
        },
        [append, onChange, defaultValues]
    );

    useFormChange(watch, handleChange);

    const groupBy = watch('groupBy');
    const currentColumnShape = columns.find((c) => c.name === groupBy)?.shapeName;

    const bucketOptions = useMemo(() => getBucketByOptions(currentColumnShape), [currentColumnShape]);
    const bucketBy = watch('bucketBy');

    if (currentColumnShape != null && !bucketOptions.some((b) => b.value === bucketBy)) {
        // Default to the first bucketing method, if the current column being grouped supports them,
        // or clear the value if there are no options available (which means the field isn't being
        // displayed, but we need to keep the config valid)
        setValue('bucketBy', bucketOptions[0]?.value ?? null);
    }

    const aggregations = watch('aggregations', []);
    const validAggregatorColumns = useMemo(() => getAllRequiredColumnOptions(columnOptions), [columnOptions]);

    validateAggregations(setValue, validAggregatorColumns, aggregations);

    return (
        <FormProvider {...formProps}>
            <form>
                <div className='grid grid-cols-[minmax(0,1fr)_minmax(0,1fr)_1.5rem] gap-2'>
                    <Field.Label label='Group by' spacing='none' className='col-start-1'>
                        <EditorSelect name='groupBy' placeholder='Group by' options={columnOptions} />
                    </Field.Label>
                    {bucketOptions.length > 1 && (
                        <Field.Label
                            label='Bucket by'
                            spacing='none'
                            help='Groups data based on the date, e.g. grouped by day, month or year.'
                        >
                            <EditorSelect
                                name='bucketBy'
                                placeholder='Select column'
                                options={bucketOptions}
                                isClearable={false}
                            />
                        </Field.Label>
                    )}

                    {aggregationFields.length > 0 && (
                        <>
                            <Field.Label label='Aggregation type' spacing='none' className='col-start-1 mt-2 -mb-2' />
                            <Field.Label label='Aggregation column' spacing='none' className='mt-2 -mb-2' />
                        </>
                    )}

                    {aggregationFields.map((field, index) => (
                        <AggregationRow
                            key={field.id}
                            fieldIndex={index}
                            aggregations={aggregations}
                            deleteAggregation={() => remove(index)}
                            requiredColumns={validAggregatorColumns.get(aggregations[index]?.aggregationType) ?? []}
                        />
                    ))}

                    <Button
                        onClick={() => append({ aggregationType: 'count' })}
                        variant='secondary'
                        className='col-start-1 mt-2 w-fit'
                        data-testid='grouping-addAggregation'
                    >
                        <FontAwesomeIcon icon={faPlus} className='mr-3' /> <span>Add aggregation</span>
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};
