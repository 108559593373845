import * as SelectPrimitive from '@radix-ui/react-select';
import clsx from 'clsx';
import { forwardRef } from 'react';

export const SelectContent = forwardRef<
    React.ElementRef<typeof SelectPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => (
    <SelectPrimitive.Portal>
        <SelectPrimitive.Content
            ref={ref}
            className={clsx(
                'relative min-w-[8rem] overflow-hidden bg-componentBackgroundPrimary text-textPrimary border border-outlinePrimary p-xxxs animate-in fade-in-80 z-30',
                className
            )}
            position={position}
            {...props}
        >
            <SelectPrimitive.Viewport className='h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]'>
                {children}
            </SelectPrimitive.Viewport>
        </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
));
