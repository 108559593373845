import { TimeframeEnumValue } from '@squaredup/timeframes';
import DashboardContext from 'contexts/DashboardContext';
import RefreshContext from 'contexts/RefreshContext';
import { GlobalDataStreamContext } from 'dashboard-engine/dataStreams/GlobalDataStreamContext';
import { FullScreenWrapper } from 'dashboard-engine/fullscreen/FullScreenWrapper';
import RenderDynamic from 'dashboard-engine/render/RenderDynamic';
import { DashboardType, DashboardVariable } from 'dashboard-engine/types/Dashboard';
import { useRefresh } from 'lib/useRefresh';

interface PerspectiveDashboardProps {
    dashboard: DashboardType;
    dashboardVariables: DashboardVariable[];
    timeframe: TimeframeEnumValue;
    workspace: string;
}

const PerspectiveDashboard: React.FC<PerspectiveDashboardProps> = ({
    workspace,
    dashboard,
    dashboardVariables,
    timeframe
}) => {
    const { refreshCount, forceRefresh } = useRefresh();

    return (
        <GlobalDataStreamContext workspace={workspace}>
            <DashboardContext.Provider
                value={{
                    editing: false,
                    setEditing: undefined,
                    dashboard,
                    timeframe,
                    currentWorkspace: undefined,
                    showDetailedErrors: false,
                    variables: dashboardVariables,
                    setVariables: undefined,
                    updateTile: undefined
                }}
            >
                <div className='py-4'>
                    <RefreshContext.Provider
                        value={{
                            name: 'dashboard',
                            refreshCount,
                            forceRefresh
                        }}
                    >
                        <FullScreenWrapper isEditingEnabled={false}>
                            <RenderDynamic config={dashboard.content} key={dashboard.id} />
                        </FullScreenWrapper>
                    </RefreshContext.Provider>
                </div>
            </DashboardContext.Provider>
        </GlobalDataStreamContext>
    );
};

PerspectiveDashboard.propTypes = {};

export default PerspectiveDashboard;
