import { FC } from 'react';
import Tooltip from 'components/tooltip/Tooltip';
import useOverflowing from 'lib/useOverflowing';

interface EasyEditOverflowWrapperProps {
    value: string;
}

/**
 * Wrapper for the EasyEdit component
 *
 * Example ussage displayComponent={<OverflowWrapper value={title} />}
 */
const EasyEditOverflowWrapper: FC<EasyEditOverflowWrapperProps> = ({ value }) => {
    const [ref, overflowing] = useOverflowing<HTMLDivElement>();

    return (
        <Tooltip title={value} disabled={!overflowing}>
            <div ref={ref} className='truncate'>
                {value}
            </div>
        </Tooltip>
    );
};

export default EasyEditOverflowWrapper;
