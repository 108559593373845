/**
 * Anything in here is here because it should be removed or refactored, or placed somewhere meaningful
 */
import { stateStrings } from '@squaredup/monitoring';
import { DashboardType } from 'dashboard-engine/types/Dashboard';
import { flattenedDashboardsSortedByWorkspaceOrder } from 'queries/utils/dashboardSorted';
import { ListDashboardHealthByIds } from 'services/HealthService';
import { ListDashboards, Workspace } from 'services/WorkspaceService';

export type DashboardWithHealth = DashboardType & {
    link: string;
    state: string;
};

/**
 * @deprecated If you're using this helper function find a better way of leveraging what's already
 * in react-query or create a new hook
 */
export const fetchDashboardsWithHealth = async (workspace: Workspace): Promise<DashboardWithHealth[]> => {
    if (!workspace) {
        return [];
    }

    const dashboardsUnsorted = await ListDashboards(workspace.id);

    const sortedDashboards = flattenedDashboardsSortedByWorkspaceOrder(workspace.data.properties?.dashboardIdOrder)(
        dashboardsUnsorted
    );

    const { dashboardStates } = await ListDashboardHealthByIds(sortedDashboards.map((dash: any) => dash.id));
    const dashboardHealthLookup = new Map(dashboardStates?.map((d) => [d.dashboardId, d.state]));

    return sortedDashboards.map((dashboard: DashboardType) => ({
        ...dashboard,
        link: `/dashboard/${dashboard.id}`,
        state: dashboardHealthLookup.get(dashboard.id) || stateStrings.unknown
    }));
};
