import type { Serialised } from '@squaredup/ids';
import { matchSpec, type UIConfig } from '@squaredup/utilities';
import Field from 'components/forms/field/Field';
import { mapValues, merge } from 'lodash';
import { useWatch } from 'react-hook-form';
import type { Primitive } from 'type-fest';
import { defaultOnCreate } from '../autocomplete/Autocomplete';
import { getFormFieldComponent } from '../field/getFormFieldComponent';
import { getAutocompleteValue, isAutocomplete, type AutocompleteOption } from './autocompleteOptions';
import { ResolvedUIConfig } from './resolveAutocompleteOptions';

export interface DisplayJsonUiProps {
    formFields: ResolvedUIConfig[];
    includeDisplayNameField?: boolean;
    extraFirstField?: ResolvedUIConfig;
    data?: Record<string, string>;
    autoFocus?: boolean;
    disabled?: boolean;
}

type ResolvedUIConfigPlus = ResolvedUIConfig & { testName?: string; testButtonName?: string; testResult?: any };

type FieldFilter = (field: any, defaultValues?: Record<string, unknown>) => boolean;

/**
 * Get the default value of a field, taking the `value` property
 * of any autocomplete option objects found.
 */
const getDefaultValueString = (
    field: ResolvedUIConfig | Serialised<UIConfig> | UIConfig
): undefined | Primitive | string[] => {
    if (!('defaultValue' in field)) {
        return undefined;
    }

    if (Array.isArray(field.defaultValue)) {
        return field.defaultValue.map((d) => d.value);
    }

    return getAutocompleteValue(field.defaultValue);
};

/**
 *
 * @param fields Gets all fields (recursively) for which the supplied predicate returns
 * true (if no predicate is supplied, all fields are returned)
 *
 * @param predicate
 * @returns array of fields
 */
function getFieldsByFilter(fields: any[], predicate: FieldFilter) {
    const filteredFormFields = fields.reduce((acc: ResolvedUIConfigPlus[], field: any) => {
        if (field.type === 'fieldGroup') {
            /**
             * Explicitly set the data so default values of previous fields are taken
             * into account when checking visibility.
             */
            if (predicate(field, getDefaultValues(acc))) {
                const nestedFields = getFieldsByFilter(field.fields, predicate);
                acc.push(...nestedFields);

                // Check if this fieldGroup has a test result component specified to
                // request an interactive test (e.g. PayloadViewer)
                if (field.testResultField) {
                    if (field.testResultField.type === 'fieldGroup') {
                        throw new Error('testResultField of a fieldGroup may not be another fieldGroup');
                    }
                    acc.push({
                        ...field.testResultField,
                        testButtonName: field.testButtonName,
                        testName: field.name
                    });
                }
            }
        } else {
            acc.push(field);
        }
        return acc;
    }, []);
    return filteredFormFields;
}

export const getVisibleFields = (fields: any, data: Record<string, unknown>) => {
    const dataAsStringValues = mapValues(data, (v) =>
        Array.isArray(v) ? v.map(getAutocompleteValue) : getAutocompleteValue(v as AutocompleteOption | Primitive)
    );

    const predicate: FieldFilter = (field, defaultValues) => {
        const match = matchSpec(field.visible, merge({}, defaultValues, dataAsStringValues));
        return match.succeeded;
    };
    const filteredFormFields = getFieldsByFilter(fields, predicate);
    return filteredFormFields;
};

export function getAllFieldsByName(fields: any) {
    const allFields = getFieldsByFilter(fields, () => true);
    return allFields.reduce((acc, val) => {
        acc.set(val.name, val);
        return acc;
    }, new Map<string, any>());
}

/**
 * Get the default values of the given fields.
 * Set `includeHidden` to get default values for all fields. This
 * can be useful for forms where `shouldUnregister: true` is set
 * to avoid getting values for hidden fields in the form values.
 */
export function getDefaultValues(
    formFields: ResolvedUIConfig[] | Serialised<UIConfig[]> = [],
    { includeHidden }: { includeHidden: boolean } = { includeHidden: false }
) {
    const result: Record<string, any> = {};

    function getDefaults(field: ResolvedUIConfig | Serialised<UIConfig> | UIConfig) {
        if (field.type === 'fieldGroup') {
            field.fields.forEach((f) => getDefaults(f));
            return;
        }

        if (!('defaultValue' in field)) {
            return;
        }

        result[field.name] = getDefaultValueString(field);
    }

    if (includeHidden) {
        formFields.forEach((f) => getDefaults(f));
    } else {
        (getVisibleFields(formFields, {}) ?? []).forEach((f) => getDefaults(f));
    }

    return result;
}

export function isDefaultValid(formFields: ResolvedUIConfig[] | Serialised<UIConfig[]> = []) {
    let result = true;

    // Only look at initially visible fields in the form
    const visibleFields = getVisibleFields(formFields, {});

    function getDefaults(field: any) {
        if (field.type === 'fieldGroup') {
            field.fields.forEach((f: { name: string; defaultValue?: any }) => getDefaults(f));
        } else {
            // If the field is required and it doesn't have a default value, it won't be valid
            if (field.validation?.required && !Object.prototype.hasOwnProperty.call(field, 'defaultValue')) {
                result = false;
            }
        }
    }

    (visibleFields ?? []).forEach((f) => getDefaults(f));

    return result;
}

const DisplayJsonUi: React.FC<DisplayJsonUiProps> = ({
    formFields,
    includeDisplayNameField = true,
    extraFirstField,
    data = {},
    autoFocus = false,
    disabled = false
}) => {
    const formData = useWatch();

    const visibleFields = getVisibleFields(formFields, formData);
    if (extraFirstField) {
        visibleFields.splice(0, 0, extraFirstField);
    }

    return (
        <>
            {includeDisplayNameField && (
                <Field.Input
                    name='displayName'
                    label='Display Name'
                    title='Display Name'
                    placeholder='Enter the Display Name'
                    disabled={disabled}
                    validation={{
                        required: true,
                        maxLength: { value: 128 } as { value: number; message: string },
                        minLength: { value: 1 } as { value: number; message: string }
                    }}
                />
            )}

            {visibleFields?.map((field, idx) => {
                // After retrieving an encrypted password value, we may find that our ciphertext
                // exceeds the original maxLength validation rules! So we'll remove that
                // validation rule if it exists.
                if (field.type === 'password' && data[field.name]?.startsWith('squp_encrypted')) {
                    if (field.validation?.maxLength) {
                        delete field.validation.maxLength;
                    }
                }
                const FormField = getFormFieldComponent(field.type);
                return (
                    // Our pattern validation has the pattern validation as a string,
                    // but the react-hook-form types want it to be a RegExp
                    // @ts-expect-error
                    <FormField
                        autoFocus={idx === 0 && autoFocus}
                        key={field.name}
                        {...field}
                        disabled={disabled}
                        {...(isAutocomplete(field) && {
                            onCreate: field.allowCustomValues !== false ? defaultOnCreate : undefined,
                            //AutoComplete uses isDisabled instead of disabled
                            isDisabled: disabled
                        })}
                    />
                );
            })}
        </>
    );
};

export default DisplayJsonUi;
