import { Serialised } from '@squaredup/ids';
import Search, { SearchResult } from 'components/Search';
import { AppContext } from 'contexts/AppContext';
import type { ProjectedDataStreamDefinitionEntity } from 'dynamo-wrapper';
import { useDatasourceConfigs } from 'queries/hooks/useDatasourceConfigs';
import { useContext } from 'react';
import { injectSqUpPluginSource } from 'utilities/injectSqUpDataSource';
import { MenuComponent } from './MenuComponent';
import { globalSearchHandler } from './globalSearchHandler';
import { useWorkspaces } from 'queries/hooks/useWorkspaces';

export type GlobalSearchResult = SearchResult & {
    dataStream?: Serialised<ProjectedDataStreamDefinitionEntity>;
    availableDataStreams?: Serialised<ProjectedDataStreamDefinitionEntity>[];
};

function GlobalSearch() {
    const { searchOpen, setSearchOpen } = useContext(AppContext);

    const { data: datasources } = useDatasourceConfigs({
        select: injectSqUpPluginSource
    });

    const { data: workspaces } = useWorkspaces();

    const handleOnSearch = (searchQuery: string) => {
        return globalSearchHandler(datasources, workspaces)(searchQuery);
    };

    const handleOnClose = () => {
        setSearchOpen(false);
    };

    return (
        <>
            {searchOpen && (
                <div className='absolute inset-0 z-[90] flex justify-center backdrop-filter backdrop-blur-md'>
                    <div className='absolute inset-0 bg-backgroundPrimary opacity-80' onClick={handleOnClose} />
                    <div className='max-w-full mx-4 mt-20 w-[650px] text-textPrimary'>
                        <Search
                            MenuComponent={MenuComponent}
                            focused={searchOpen}
                            onSearch={handleOnSearch}
                            onClose={handleOnClose}
                            onFocus={() => setSearchOpen(true)}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default GlobalSearch;
