import { Skeleton } from '@/components/Skeleton';
import { cn } from '@/lib/cn';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSize from '@react-hook/size';
import clsx from 'clsx';
import { Presence } from 'components/Presence';
import { TruncatedText } from 'components/TruncatedText';
import { Pill, PillsWrapper } from 'components/pill/Pill';
import Tooltip from 'components/tooltip/Tooltip';
import { WorkspaceStateIndicator } from 'components/ui/state/WorkspaceStateIndicator';
import { useAppContext } from 'contexts/AppContext';
import type { BlockData } from 'dashboard-engine/visualisations/DataStreamBlocks/types';
import type { OrganisationHomeConfig, ViewOption } from 'dynamo-wrapper';
import { workspacePredefinedIconKey } from 'lib/fontawesome/fontawesome';
import { clamp } from 'lodash';
import PluginIcon, { PluginIconWrapperTruncate } from 'pages/scope/PluginIcon';
import { defaultGlobalViewConfig } from 'pages/status/StatusPage';
import { STATUS_CONFIG } from 'pages/status/utils/useStatusConfig';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { useWorkspaceAvatar } from 'queries/hooks/useWorkspaceAvatar';
import React, { ReactNode, Suspense, useLayoutEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { NavLink, useNavigate } from 'react-router-dom';

interface PluginIconForBlockProps {
    plugin: StatusBlockPlugin;
    marginRight: number;
}

interface BlockLinkWrapperProps {
    link?: string;
    className?: string;
    children?: ReactNode;
}

interface BlockSublabelProps {
    sublabel: string;
    fontSize: number;
}

export type StatusBlockDataItem = BlockData & { id: string };

interface StatusBlockPlugin {
    id: string;
    workspaceId: string;
    name: string;
    displayName: string;
}

export interface StatusBlockData {
    type?: string;
    tags?: string[];
    plugins?: StatusBlockPlugin[];
}

interface StatusBlockProps {
    blockData: StatusBlockDataItem;
    extraData?: StatusBlockData;
    showFooter?: boolean;
    viewOptions?: ViewOption[];
}

const iconBackground = {
    success: 'bg-statusHealthySecondary',
    error: 'bg-statusErrorSecondary',
    warning: 'bg-statusWarningSecondary',
    unknown: 'bg-statusUnknownSecondary'
};

const blockBackground = {
    success: 'bg-statusHealthyPrimary',
    error: 'bg-statusErrorPrimary',
    warning: 'bg-statusWarningPrimary',
    unknown: 'bg-statusUnknownPrimary'
};

const getSizes = (containerHeight: number) => ({
    titleSize: clamp(containerHeight * 0.12, 12, 31),
    tableSize: clamp(containerHeight * 0.1, 10, 15),
    barWidth: clamp(containerHeight * 0.4, 30, 60),
    footerHeight: clamp(containerHeight * 0.15, 24, 48),
    footerPadding: clamp(containerHeight * 0.02, 1, 6),
    pillTopMargin: clamp(containerHeight * 0.15, 2, 5),
    pillBottomMargin: clamp(containerHeight * 0.15, 3, 8),
    paddingX: clamp(containerHeight * 0.1, 16, 24),
    paddingY: clamp(containerHeight * 0.1, 6, 24)
});

const PluginIconForBlock: React.FC<PluginIconForBlockProps> = ({ plugin, marginRight }) => {
    const { setCurrentWorkspaceID } = useAppContext();
    const navigate = useNavigate();

    return (
        <Tooltip className='cursor-pointer aspect-square shrink-0' style={{ marginRight }} title={plugin.displayName}>
            <div
                data-testid='pluginicon'
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setCurrentWorkspaceID({ id: plugin.workspaceId, performRedirect: false });
                    navigate(`/datasource/${plugin.id}`);
                }}
            >
                <PluginIcon className='h-full shrink-0' pluginName={plugin.name} />
            </div>
        </Tooltip>
    );
};

const BlockLinkWrapper: React.FC<BlockLinkWrapperProps> = ({ link, className, children }) => {
    if (link) {
        return (
            <NavLink to={link} className={className}>
                {children}
            </NavLink>
        );
    }

    return <>{children}</>;
};

const BlockSublabel: React.FC<BlockSublabelProps> = ({ sublabel, fontSize }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const textRef = useRef<HTMLDivElement>(null);
    const [overflowing, setOverflowing] = useState(false);
    const [, labelHeight] = useSize(containerRef);

    useLayoutEffect(() => {
        const scrollHeight = textRef.current?.scrollHeight || 0;

        setOverflowing(Math.round(labelHeight) < scrollHeight);
    }, [textRef, labelHeight]);

    const labelLineHeight = 1.25;
    const labelLines = Math.floor(labelHeight / (fontSize * labelLineHeight));

    return (
        <div ref={containerRef} className='flex-1 overflow-hidden'>
            <Tooltip title={sublabel} disabled={!overflowing}>
                <p
                    ref={textRef}
                    className='overflow-hidden font-light text-ellipsis line-clamp-1'
                    aria-label={sublabel}
                    style={{
                        WebkitLineClamp: labelLines,
                        fontSize: fontSize,
                        lineHeight: `${fontSize * labelLineHeight}px`
                    }}
                >
                    {sublabel}
                </p>
            </Tooltip>
        </div>
    );
};

export const StatusBlock: React.FC<StatusBlockProps> = ({ blockData, extraData, showFooter, viewOptions }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [, containerHeight] = useSize(containerRef);

    const { name, state, link, sublabel } = blockData;
    const {
        titleSize,
        tableSize,
        barWidth,
        footerHeight,
        footerPadding,
        pillTopMargin,
        pillBottomMargin,
        paddingX,
        paddingY
    } = getSizes(containerHeight);

    const showType = (viewOptions?.find((o: any) => o.name === 'type') as any)?.value ?? true;
    const showTags = (viewOptions?.find((o: any) => o.name === 'tags') as any)?.value ?? true;
    const showDataSources = (viewOptions?.find((o: any) => o.name === 'dataSources') as any)?.value ?? true;
    const showSublabel = (viewOptions?.find((o: any) => o.name === 'sublabel') as any)?.value ?? true;
    const showAvatar = (viewOptions?.find((o: any) => o.name === 'avatar') as any)?.value ?? true;

    const queryClient = useQueryClient();
    const config = queryClient.getQueryData<OrganisationHomeConfig>([STATUS_CONFIG]) ?? defaultGlobalViewConfig;

    return (
        <BlockLinkWrapper link={link}>
            <div
                ref={containerRef}
                className={cn(
                    'relative flex h-full text-textPrimary overflow-hidden font-semibold',
                    iconBackground[state || 'unknown'],
                    Boolean(link) && 'status-block-hover',
                    state !== 'unknown' && 'text-primaryButtonText'
                )}
                data-testid='block'
                data-teststate={state}
                role='listitem'
                data-theme={state !== 'unknown' && 'dark'}
            >
                <div
                    style={{ width: barWidth }}
                    className={clsx('shrink-0 flex justify-center', blockBackground[state])}
                />
                <div className='flex flex-col flex-1 w-full min-w-0'>
                    <div className='flex h-full gap-2' style={{ padding: `${paddingY}px ${paddingX}px` }}>
                        <div className='flex flex-col flex-1 h-full min-w-0 min-h-0 overflow-hidden'>
                            <span style={{ fontSize: titleSize }} className='leading-normal'>
                                <TruncatedText
                                    title={name}
                                    element='h6'
                                    className='block overflow-hidden font-semibold truncate'
                                />
                            </span>
                            {((extraData?.type && showType) ||
                                (extraData?.tags && extraData.tags?.length > 0 && showTags)) && (
                                <span
                                    className='flex w-full gap-2'
                                    style={{ marginTop: pillTopMargin, marginBottom: pillBottomMargin }}
                                >
                                    {extraData?.type && showType && <Pill>{extraData.type}</Pill>}
                                    {extraData?.tags && extraData.tags?.length > 0 && showTags && (
                                        <div className='flex-1 w-full min-w-0'>
                                            <PillsWrapper.Truncate
                                                items={extraData.tags
                                                    .sort((a, b) => a.localeCompare(b))
                                                    .map((t, index) => (
                                                        <Pill variant='outline' key={`${t}-${index}`}>
                                                            {t}
                                                        </Pill>
                                                    ))}
                                            />
                                        </div>
                                    )}
                                </span>
                            )}
                            {sublabel && showSublabel && <BlockSublabel sublabel={sublabel} fontSize={tableSize} />}
                        </div>
                        <Presence isPresent={showAvatar && config?.type === 'space'}>
                            <Suspense fallback={<StatusBlockAvatarSkeleton titleSize={titleSize} />}>
                                <StatusBlockAvatar id={blockData.id} titleSize={titleSize} />
                            </Suspense>
                        </Presence>
                    </div>
                    {((extraData?.plugins && extraData.plugins?.length > 0) || showFooter) && showDataSources && (
                        <div
                            className='flex w-full cursor-default bg-statusBlockFaded shrink-0'
                            style={{ height: footerHeight, padding: `${footerPadding}px ${paddingX}px` }}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            data-testid='footer'
                        >
                            {extraData?.plugins && extraData.plugins?.length > 0 && (
                                <PluginIconWrapperTruncate
                                    iconWidth={footerHeight - footerPadding * 2 + footerPadding}
                                    iconPadding={footerPadding}
                                    items={extraData.plugins.map((p) => (
                                        <PluginIconForBlock key={p.id} plugin={p} marginRight={footerPadding} />
                                    ))}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </BlockLinkWrapper>
    );
};

const StatusBlockAvatar = (props: Pick<Parameters<typeof useWorkspaceAvatar>[0], 'id'> & { titleSize: number }) => {
    const size = `clamp(${props.titleSize}px, ${props.titleSize ** 0.3}vmax, 20rem)`;
    const workspace = useWorkspace(props.id);
    const workspaceAvatar = useWorkspaceAvatar({
        suspense: true,
        id: props.id,
        enabled: !workspace.data?.data.properties?.avatarIconName
    });

    if (!workspaceAvatar.data && !workspace.data?.data?.properties?.avatarIconName) {
        return (
            <div className='border border-workspaceIconOutline p-1 h-min bg-workspaceIconBackground rounded-md'>
                <WorkspaceStateIndicator id='' style={{ height: size, width: size }} />
            </div>
        );
    }

    if (workspace.data?.data?.properties?.avatarIconName) {
        return (
            <div className='border border-workspaceIconOutline p-1 h-min bg-workspaceIconBackground rounded-md'>
                <FontAwesomeIcon
                    className='text-textPrimary'
                    icon={
                        workspace.data?.data?.properties?.avatarIconName.replace(
                            workspacePredefinedIconKey,
                            ''
                        ) as IconProp
                    }
                    style={{
                        height: `clamp(${props.titleSize}px, ${props.titleSize ** 0.3}vmax, 20rem)`,
                        minHeight: `clamp(${props.titleSize}px, ${props.titleSize ** 0.3}vmax, 20rem)`,
                        width: `clamp(${props.titleSize}px, ${props.titleSize ** 0.3}vmax, 20rem)`,
                        minWidth: `clamp(${props.titleSize}px, ${props.titleSize ** 0.3}vmax, 20rem)`
                    }}
                />
            </div>
        );
    }

    return (
        <div className='border border-workspaceIconOutline p-1 h-min rounded-md bg-workspaceIconBackground aspect-square flex'>
            <Presence isPresent={!workspaceAvatar.data?.startsWith(workspacePredefinedIconKey)}>
                <img
                    src={workspaceAvatar.data}
                    alt='workspace avatar'
                    className='object-contain aspect-square rounded-[4px] bg-white'
                    style={{
                        height: `clamp(${props.titleSize}px, ${props.titleSize ** 0.3}vmax, 20rem)`,
                        minHeight: `clamp(${props.titleSize}px, ${props.titleSize ** 0.3}vmax, 20rem)`,
                        width: `clamp(${props.titleSize}px, ${props.titleSize ** 0.3}vmax, 20rem)`,
                        minWidth: `clamp(${props.titleSize}px, ${props.titleSize ** 0.3}vmax, 20rem)`
                    }}
                />
            </Presence>
            <Presence isPresent={Boolean(workspaceAvatar.data?.startsWith(workspacePredefinedIconKey))}>
                <FontAwesomeIcon
                    className='text-textPrimary'
                    icon={workspaceAvatar.data?.replace(workspacePredefinedIconKey, '') as IconProp}
                    style={{
                        height: `clamp(${props.titleSize}px, ${props.titleSize ** 0.3}vmax, 20rem)`,
                        minHeight: `clamp(${props.titleSize}px, ${props.titleSize ** 0.3}vmax, 20rem)`,
                        width: `clamp(${props.titleSize}px, ${props.titleSize ** 0.3}vmax, 20rem)`,
                        minWidth: `clamp(${props.titleSize}px, ${props.titleSize ** 0.3}vmax, 20rem)`
                    }}
                />
            </Presence>
        </div>
    );
};

const StatusBlockAvatarSkeleton = (props: { titleSize: number }) => {
    const size = `clamp(${props.titleSize}px, ${props.titleSize ** 0.3}vmax, 20rem)`;

    return (
        <Skeleton
            className='border-4 border-workspaceIconOutline outline outline-1 outline-workspaceIconBackground rounded-md aspect-square'
            style={{ height: size, width: size }}
        />
    );
};
