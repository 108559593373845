import React from 'react';

interface StatusBlocksGridProps {
    columns: number;
    hasSublabels: boolean;
    blockHeight?: number;
    limitHeight?: boolean;
}

export const StatusBlocksGrid: React.FC<StatusBlocksGridProps> = ({
    hasSublabels,
    columns,
    children,
    blockHeight,
    limitHeight = true
}) => {
    const gridRowHeight = blockHeight
        ? `${blockHeight}px`
        : `minmax(${hasSublabels ? 180 : 130}px, ${limitHeight ? '360px' : '1fr'})`;

    return (
        <div
            className='grid h-full gap-2'
            style={{
                gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
                gridAutoRows: gridRowHeight
            }}
            aria-colcount={columns}
        >
            {children}
        </div>
    );
};
