import { getStateInfo } from './svgUtils';

interface HealthStateFilterProps {
    id: string;
    state: string;
}

export const HealthStateFilter: React.FC<HealthStateFilterProps> = ({ id, state }) => {
    const { colour, opacity } = getStateInfo(state);
    return (
        <>
            <filter
                id={`health-filter-fill-${id}`}
                x='0%'
                y='0%'
                width='100%'
                height='100%'
                filterUnits='objectBoundingBox'
                primitiveUnits='userSpaceOnUse'
                colorInterpolationFilters='linearRGB'
            >
                {/* Makes element greyscale by desaturating it */}
                <feColorMatrix
                    type='saturate'
                    values='0'
                    x='0%'
                    y='0%'
                    width='100%'
                    height='100%'
                    in='SourceGraphic'
                    result='colormatrix'
                />
                <feFlood
                    floodColor='#ffffff'
                    floodOpacity='0.2'
                    x='0%'
                    y='0%'
                    width='100%'
                    height='100%'
                    result='flood1'
                />
                {/* Blend greyscale and white flood to brighten the element slightly */}
                <feBlend
                    mode='saturation'
                    x='0%'
                    y='0%'
                    width='100%'
                    height='100%'
                    in='colormatrix'
                    in2='flood1'
                    result='blend1'
                />
                {/* Health state flood color (e.g. green, amber, red) */}
                <feFlood
                    floodColor={colour}
                    floodOpacity='1'
                    x='0%'
                    y='0%'
                    width='100%'
                    height='100%'
                    result='flood2'
                />
                {/* Blend greyscaled element with health state color */}
                <feBlend
                    mode='overlay'
                    x='0%'
                    y='0%'
                    width='100%'
                    height='100%'
                    in='flood2'
                    in2='blend1'
                    result='blend2'
                />
                {/* Composite blended color element over original element, this keeps the original whites as white */}
                <feComposite
                    in='blend2'
                    in2='SourceGraphic'
                    operator='in'
                    x='0%'
                    y='0%'
                    width='100%'
                    height='100%'
                    result='composite'
                />
            </filter>
            <filter id={`health-filter-glow-${id}`} height='300%' width='300%' x='-75%' y='-75%'>
                {/* Thicken out the original shape */}
                <feMorphology operator='dilate' radius='5' in='SourceAlpha' result='thicken' />
                {/* Use a gaussian blur to create the soft blurriness of the glow */}
                <feGaussianBlur in='thicken' stdDeviation='9' result='blurred' />
                {/* Change the colour */}
                <feFlood floodColor={colour} floodOpacity={opacity} result='glowColor' />
                {/* Colour in the glows */}
                <feComposite in='glowColor' in2='blurred' operator='in' result='softGlow_colored' />
                {/* Layer the effects together */}
                <feMerge>
                    <feMergeNode in='softGlow_colored' />
                    <feMergeNode in='SourceGraphic' />
                </feMerge>
            </filter>
        </>
    );
};
