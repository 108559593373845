import Template from '@/components/Template';
import { Serialised } from '@squaredup/ids';
import { useLinkedPluginConfigs } from 'components/hooks/useLinkedPluginConfigs';
import { usePluginPermissions } from 'components/hooks/usePluginPermissions';
import { AppContext } from 'contexts/AppContext';
import { differenceInMinutes } from 'date-fns';
import PluginIcon from 'pages/scope/PluginIcon';
import { FC, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useWorkspaceCanWrite } from 'services/AccessControlService';
import { ImportInfo } from './components/ImportInfo';
import { LinkingButton } from './components/LinkingButton';
import { ManageDatasourceButton } from './components/ManageDatasourceButton';
import { OverviewData } from './components/OverviewData';
import { useImportTrigger } from './components/useImportTrigger';
import { NotFoundPanel } from 'pages/components/Panels/NotFoundPanel';
import { usePageTitle } from 'lib/usePageTitle';
interface OverviewDashboardProps {
    configId: string;
    workspaceId: string;
}

export interface PluginConfig {
    pluginId: string;
    name: string;
}

export const PluginConfigOverviewDashboard: FC<OverviewDashboardProps> = ({ configId, workspaceId }) => {
    const { data: canWrite, isLoading: isLoadingPermissions } = useWorkspaceCanWrite(workspaceId);
    const { canEdit, isLoading: isLoadingPluginPermissions } = usePluginPermissions();

    const { data: linkedPlugins = [], isLoading, isFetching } = useLinkedPluginConfigs(workspaceId, {
        refetchInterval: canEdit(configId) ? 5_000 : false // Needs common refetch for updated import status
    });
    const canEditPlugin = (!isLoadingPluginPermissions && canEdit(configId)) ?? false;
    const { triggerImport } = useImportTrigger(configId, workspaceId);

    const data = linkedPlugins.find((v) => v.id === configId);

    usePageTitle(data?.displayName);
    const waitingForDataSourceToBeLinkedToWorkspace = data === undefined && isFetching;

    if (isLoading || waitingForDataSourceToBeLinkedToWorkspace) {
        return <></>; // Should be short, don't need spinner.
    }

    if (data === undefined) {
        return <NotFoundPanel 
            title='Data source not accessible'
            body='The data source does not exist or you do not have permission to access it.'
        />;
    }
    

    const pluginDetails = data?.plugin as Serialised<PluginConfig> | undefined;

    const isRefreshing =
        ['waitingForData', 'started', 'running'].includes(data?.importStatus?.status ?? 'notRun') &&
        differenceInMinutes(Date.now(), data?.importStatus?.started ?? 0) < 20;

    return (
        <Template
            title={data?.displayName}
            flex
            equalPadding
            icon={<PluginIcon pluginName={pluginDetails?.name || ''} className='w-24 h-24' />}
            status={
                <>
                    {data?.importStatus && (
                        <ImportInfo
                            pluginId={pluginDetails?.pluginId}
                            isRefreshing={isRefreshing}
                            importStatus={data?.importStatus}
                            refreshImport={triggerImport}
                            canRefresh={canEdit(configId)}
                            pluginName={pluginDetails?.name}
                        />
                    )}
                </>
            }
            actions={
                <>
                    {!isLoadingPermissions && canWrite && configId && pluginDetails?.pluginId && workspaceId && (
                        <div className='flex gap-4'>
                            {canEditPlugin && (
                                <ManageDatasourceButton workspaceID={workspaceId} configID={configId} />
                            )}
                            <LinkingButton
                                datasourceId={configId}
                                datasourceName={data?.displayName ?? ''}
                                workspaceId={workspaceId}
                                pluginId={pluginDetails?.pluginId}
                            />
                        </div>
                    )}
                </>
            }
        >
            <OverviewData
                configId={configId}
                pluginId={pluginDetails?.pluginId ?? ''}
                importStatus={data?.importStatus?.status ?? ''}
            />
        </Template>
    );
};

export const DatasourceOverview: FC = () => {
    const { id } = useParams();
    const { currentWorkspaceID, setCurrentWorkspaceID } = useContext(AppContext);

    if (!id) {
        return <div className='text-textIncomplete'>No id provided</div>;
    }

    if (!currentWorkspaceID) {
        setCurrentWorkspaceID({ pickBest: true });

        return <div className='text-textIncomplete'>One workspace must exist</div>;
    }

    return (
        <PluginConfigOverviewDashboard configId={id} workspaceId={currentWorkspaceID} />
    );
};
