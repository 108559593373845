import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import ToggleField from 'components/accessControl/ToggleField';
import Button from 'components/button/Button';
import { FC, useState } from 'react';
import { useHasDefaultContent } from './useHasDefaultContent';

interface LinkImportModalCancelModalProps {
    dataSourceName: string;
    sourcePluginId: string;
    onClose: () => void;
    onSubmit: (addDashboards: boolean) => Promise<void>;
}

export const LinkImportCancelModal: FC<LinkImportModalCancelModalProps> = ({
    dataSourceName,
    sourcePluginId,
    onClose,
    onSubmit
}) => {
    const { hasDefaultContent, isLoading } = useHasDefaultContent(sourcePluginId);
    const [importSampleDashboards, setImportSampleDashboards] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    return (
        <Modal title={`Add data source: ${dataSourceName}`} close={onClose}>
            <div className='px-8 min-w-[800px] text-textPrimary'>
                <p className=''>Are you sure you want to add this data source to this workspace?</p>
                {!isLoading && hasDefaultContent && (
                    <div className='pt-6'>
                        <ToggleField
                            label='Install sample dashboards'
                            toggleDefaultValue={importSampleDashboards}
                            onToggle={setImportSampleDashboards}
                        />
                    </div>
                )}
            </div>
            <ModalButtons>
                <Button type='button' variant='tertiary' onClick={onClose} disabled={isLoading}>
                    Cancel
                </Button>

                <Button
                    type='button'
                    onClick={async () => {
                        setIsSubmitting(true);
                        await onSubmit(importSampleDashboards && (hasDefaultContent ?? false));
                        // Submitting this modal should always result in a navigate
                    }}
                    disabled={isLoading || isSubmitting}
                >
                    {isSubmitting ? <LoadingSpinner size={18} /> : 'Add'}
                </Button>
            </ModalButtons>
        </Modal>
    );
};
