import { cn } from '@/lib/cn';
import { scopeLimitMaximum, scopeLimitWarning } from '@squaredup/constants';
import { useTileEditorObjectsFilterContext } from 'ui/editor/dataStream/contexts/TileEditorObjectsFilterContext';

const LimitMessage = ({ message, className }: { message: string; className: string }) => (
    <div className={cn('pb-2', className)}>
        {message}{' '}
        <a className='text-textLink' href='https://squaredup.com/cloud/scope' target='_blank' rel='noreferrer noopener'>
            Learn more.
        </a>
    </div>
);

export const ScopeObjectCountLimitMessage = () => {
    const { selectedObjectsCount, dynamicObjectsCount, isDynamic, isFetchingObjects } =
        useTileEditorObjectsFilterContext();

    const scopeLimitWarningMessage = (
        <LimitMessage
            message='This collection is large and may impact dashboard performance.'
            className='text-statusWarningPrimary'
        />
    );

    const fixedScopeMaximumMessage = (
        <LimitMessage message='You have selected the maximum number of objects.' className='text-statusErrorPrimary' />
    );

    const scopeLimitMaximumMessage = (
        <LimitMessage
            message={`This collection is too large and will be limited to ${scopeLimitMaximum} objects.`}
            className='text-statusErrorPrimary'
        />
    );

    return (
        <>
            {!isDynamic &&
                selectedObjectsCount > scopeLimitWarning &&
                selectedObjectsCount < scopeLimitMaximum &&
                scopeLimitWarningMessage}
            {!isDynamic && selectedObjectsCount === scopeLimitMaximum && fixedScopeMaximumMessage}
            {!isDynamic && selectedObjectsCount > scopeLimitMaximum && scopeLimitMaximumMessage}
            {isDynamic &&
                !isFetchingObjects &&
                dynamicObjectsCount > scopeLimitWarning &&
                dynamicObjectsCount < scopeLimitMaximum &&
                scopeLimitWarningMessage}
            {isDynamic && !isFetchingObjects && dynamicObjectsCount >= scopeLimitMaximum && scopeLimitMaximumMessage}
        </>
    );
};
