import { cn } from '@/lib/cn';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isFeatureEnabled } from '@squaredup/tenants';
import { useModal } from 'components/Modal';
import { Presence } from 'components/Presence';
import { FeatureUnavailablePill } from 'components/plans/FeatureUnavailablePill';
import { CreateWorkspaceModal } from 'components/workspaceModals/CreateWorkspaceModal';
import { useTier } from 'queries/hooks/useTier';

export const CreateWorkspaceButton: React.FC<{ 
    workspaceCount?: number,
    setOpen: (o: boolean) => void
}> = ({ workspaceCount, setOpen }) => {
    const workspaceModal = useModal();
    const { data: tier } = useTier();
    const isWorkspacesAvailable = (tier && isFeatureEnabled(tier, 'workspaces')) || workspaceCount === 0;

    const closeWorkspaceDropdown = () => setOpen(false);

    return (
        <div className='flex items-center border-t mt-xxxs border-dividerPrimary'>
            <button
                role='menuitem'
                onClick={workspaceModal.open}
                className={cn('flex items-center px-4 text-textSecondary hover:text-textPrimary leading-input hover:bg-dividerTertiary focus:bg-dividerTertiary py-autocomplete focus:ring-0 focus:outline-none disabled:text-textDisabled disabled:bg-transparent', isWorkspacesAvailable && 'cursor-pointer flex-grow')}
                disabled={!isWorkspacesAvailable}
            >
                <FontAwesomeIcon className='mr-xxs' fixedWidth icon={faPlus} />
                Add workspace
            </button>
            {!isWorkspacesAvailable && (
                <FeatureUnavailablePill
                    featureKey='workspaces'
                    title='Organize your dashboards and data'
                    onUpgradeClicked={closeWorkspaceDropdown}
                />
            )}

            <Presence isPresent={workspaceModal.isOpen}>
                <CreateWorkspaceModal
                    close={() => {
                        workspaceModal.close();
                        closeWorkspaceDropdown();
                    }}
                />
            </Presence>
        </div>
    );
};
