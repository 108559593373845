import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { getTimeframeLabel } from '@squaredup/timeframes';
import DashboardContext from 'contexts/DashboardContext';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import { useContext } from 'react';
import { useTileTimeframes } from 'ui/editor/dataStream/TileEditor/hooks/useTileTimeframes';
import { TileWarning, TileWarningPopover } from './TileWarningPopover';

// Duplicated in https://github.com/squaredup/plugin-common/index.js
export const DATASTREAM_MESSAGES = {
    AUTHENTICATION_FAILED:
        'Authentication failures occurred, please verify the settings are correct in your data source.',
    NOT_FOUND: 'A resource was not found, please perform an import on your data source.',
    RATE_LIMITTED: 'The data source has throttled the request, please try again later.',
    INTERNAL_ERROR: 'The data source has encountered an internal error, please try again later.',
    UNKNOWN_ERROR: 'The data source has encountered an error.',
    SCOPE_NOT_FOUND: 'The selected collection was not found, it may have been removed.'
};

export const warningMessages = [
    {
        heading: 'Authentication failed',
        message: DATASTREAM_MESSAGES.AUTHENTICATION_FAILED
    },
    {
        heading: 'Resource not found',
        message: DATASTREAM_MESSAGES.NOT_FOUND
    },
    {
        heading: 'Rate limit reached',
        message: DATASTREAM_MESSAGES.RATE_LIMITTED
    },
    {
        heading: 'Internal error',
        message: DATASTREAM_MESSAGES.INTERNAL_ERROR
    },
    {
        heading: 'Collection not found',
        message: DATASTREAM_MESSAGES.SCOPE_NOT_FOUND
    }
];

interface TileWarningsProps {
    config: DataStreamBaseTileConfig;
}

export const TileWarnings: React.FC<TileWarningsProps> = ({ config }) => {
    const dashboardContext = useContext(DashboardContext);
    const { warnings } = useDataStreamConfig(config, {}, 'dashboard');

    const configs =
        config.dataStream?.id === 'datastream-sql'
            ? config.dataStream?.dataSourceConfig?.tables?.map((c) => c?.config)
            : [config];

    const analyticsTimeframes = useTileTimeframes(configs);

    const warningsToShow: TileWarning[] = (warnings || []).map((warning) => {
        const warningMessage = warningMessages.find((w) => warning.startsWith(w.message));

        if (warningMessage) {
            return warningMessage;
        }

        return {
            heading: 'An unexpected error occurred',
            message: warning,
            raw: true
        };
    });

    if (analyticsTimeframes) {
        configs?.forEach((c, i) => {
            const timeframe = analyticsTimeframes[i];
            const tileTimeframe = c?.timeframe || dashboardContext.timeframe;
            if (timeframe && tileTimeframe && timeframe !== tileTimeframe) {
                const previousTimeframe = getTimeframeLabel(tileTimeframe, 'nonrelative');
                const currentTimeframe = getTimeframeLabel(timeframe, 'nonrelative');
                warningsToShow.push({
                    heading: 'Timeframe not supported',
                    message: `The selected page timeframe (${previousTimeframe}) is not supported by this data stream, auto-selected next available timeframe (${currentTimeframe}).`
                });
            }
        });
    }

    return warningsToShow.length > 0 ? <TileWarningPopover warnings={warningsToShow} /> : null;
};
