import type { KPIValue } from 'dynamo-wrapper';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { ListWorkspaceHealthByIds } from 'services/HealthService';
import { List as ListKPIs } from 'services/KPIService';
import { Workspace } from 'services/WorkspaceService';

type Props = UseQueryOptions<Array<KPIValue>, unknown, Array<KPIValue>, Array<string>> & Pick<Workspace, 'id'>;

export const useWorkspaceKpis = ({ id, ...options }: Props) => {
    return useQuery({
        queryKey: workspaceQueryKeys.kpis(id),
        queryFn: async () => {
            const kpis = await ListKPIs(id, undefined, { includeValues: true });
            const workspaceHealth = await ListWorkspaceHealthByIds([id], true, true);

            const kpisWithState = kpis
                .map((kpi) => {
                    const tile = workspaceHealth?.tileStates?.find(
                        (state) => state.dashId === kpi.dashboardId && state.tileId === kpi.tileId
                    );

                    return { ...kpi, status: tile?.state ?? 'unknown' };
                })
                .sort((a, b) => a.name.localeCompare(b.name));

            return kpisWithState;
        },
        ...options
    });
};
