import { PoweredByLogo } from './PoweredByLogo';

export const AppLoadingSpinner: React.FC<{
    message: string;
}> = ({ message }) => {
    return (
        <div 
            className='flex flex-col items-center justify-center w-full h-full space-y-10 bg-backgroundPrimary text-textPrimary'
            data-theme='dark'
        >
            <PoweredByLogo isLoading={true} loadingMessage={message} />
        </div>
    );
};
