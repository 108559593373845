import { Dialog } from '@/components/Dialog';
import { useDashboardContext } from 'contexts/DashboardContext';
import FullscreenContext, { FullScreenTileConfig } from 'contexts/FullscreenContext';
import RefreshContext from 'contexts/RefreshContext';
import TileContext from 'contexts/TileContext';
import { baseDataStreamConfig } from 'dashboard-engine/constants';
import { useRefresh } from 'lib/useRefresh';
import { useState } from 'react';
import { TileEditor } from 'ui/editor/TileEditor';
import { FullscreenTile } from './FullscreenTile';
import { SaveToDashboardModal } from 'pages/explore/SaveToDashboardModal';
import { useParams } from 'react-router';
import { DashboardId } from '@squaredup/ids';

interface FullScreenWrapperProps {
    isEditingEnabled: boolean;
}

export const FullScreenWrapper: React.FC<FullScreenWrapperProps> = ({ children, isEditingEnabled }) => {
    const { id: dashboardId } = useParams();
    const context = useDashboardContext();
    const { updateTile } = useDashboardContext();

    const [isEditing, setIsEditing] = useState(false);
    const [isCopyingTo, setIsCopyingTo] = useState(false);
    const [fullscreenConfig, setFullScreenConfig] = useState<FullScreenTileConfig | undefined>(undefined);

    const { tileId, health, ...config } = fullscreenConfig ?? { ...baseDataStreamConfig };
    const { refreshCount, forceRefresh } = useRefresh();

    const onClose = () => {
        setIsEditing(false);
        setFullScreenConfig(undefined);
    };

    return (
        <FullscreenContext.Provider
            value={{
                isEditing,
                isEditingEnabled,
                fullscreenConfig,
                setIsEditing,
                setIsCopyingTo,
                setFullScreenConfig
            }}
        >
            {!isEditing && !isCopyingTo && (
                <Dialog
                    open={Boolean(fullscreenConfig)}
                    onOpenChange={(isOpen) => {
                        if (!isOpen) {
                            setFullScreenConfig(undefined);
                        }
                    }}
                >
                    {children}
                    {fullscreenConfig && (
                        <RefreshContext.Provider
                            value={{
                                name: `tile-${tileId}`,
                                refreshCount,
                                forceRefresh
                            }}
                        >
                            <FullscreenTile />
                        </RefreshContext.Provider>
                    )}
                </Dialog>
            )}

            {isEditing && config && (
                <TileContext.Provider
                    value={{
                        config: fullscreenConfig || baseDataStreamConfig,
                        tileId,
                        health,
                        preview: false,
                        onClose,
                        onEdit: onClose,
                        onChange: (updated) => updateTile?.(updated, tileId!)
                    }}
                >
                    <TileEditor
                        config={config}
                        tileType={config._type}
                        tileId={tileId!} // We can enforce the tileId as we only enable editing when we're on a dashboard
                        context={context}
                        onClose={onClose}
                    />
                </TileContext.Provider>
            )}

            {isCopyingTo && config && (                
                <SaveToDashboardModal
                    close={() => setIsCopyingTo(false)}
                    config={config}
                    currentDashboardId={
                        dashboardId?.startsWith('dash-') ? (dashboardId as DashboardId['value']) : undefined
                    }
                />
            )}
        </FullscreenContext.Provider>
    );
};
