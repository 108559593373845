import { StackedInitialAvatars, StackedInitialAvatarsSkeleton } from '@/components/InitialAvatar';
import Text from '@/components/Text';
import { faChevronLeft, faChevronRight, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal, { CloseHandler, useModal } from 'components/Modal';
import { Presence } from 'components/Presence';
import CustomACLEditor from 'components/accessControl/CustomACLEditor';
import Button from 'components/button';
import { EditWorkspaceForm } from 'components/workspaceModals/EditWorkspaceForm';
import {
    EditWorkspaceModalProvider,
    useEditWorkspaceModalContext
} from 'components/workspaceModals/EditWorkspaceModal';
import { permissionOptions, useWorkspaceACLUtils } from 'components/workspaceModals/WorkspaceModalAccessControlTab';
import { useAppContext } from 'contexts/AppContext';
import { useWorkspaceDetailedPermissions } from 'pages/settings/workspaces/useWorkspaceDetailedPermissions';
import { useUsersNamesInWorkspace } from 'queries/hooks/useUsersInWorkspace';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { ComponentProps, ComponentPropsWithoutRef, Suspense, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { GetUsers, TENANT_USERS_QUERY_KEY } from 'services/UserService';

export const WorkspaceUsers = () => {
    const workspacePermissionsModal = useModal();
    const appCtx = useAppContext();
    const workspace = useWorkspace(appCtx.currentWorkspaceID);

    const workspaceDetailedPermissions = useWorkspaceDetailedPermissions(workspace.data);
    const canOpenPermissionModal = Boolean(workspaceDetailedPermissions.canConfigureAccessControl);

    return (
        <>
            <Text.H4 className='font-semibold'>Workspace users</Text.H4>

            <Text.Body className='text-textSecondary'>
                Users with access to this workspace can view this dashboard.
            </Text.Body>

            <Presence isPresent={canOpenPermissionModal}>
                <WorkspacePermissionButton onClick={workspacePermissionsModal.open} />
            </Presence>

            <Presence isPresent={!canOpenPermissionModal}>
                <Suspense fallback={<StackedInitialAvatarsSkeleton />}>
                    <TenantUsersInitials />
                </Suspense>
            </Presence>

            <Presence isPresent={workspacePermissionsModal.isOpen}>
                <WorkspacePermissionsModal close={workspacePermissionsModal.close} />
            </Presence>
        </>
    );
};

const WorkspacePermissionButton = (props: ComponentProps<typeof Button>) => {
    // prefetching the users. So that there are no loading spinner in the acl editor
    useQuery(TENANT_USERS_QUERY_KEY, GetUsers);

    return (
        <Button {...props} className='py-[6px] mt-1 gap-3' aria-label='manage access' variant='choice'>
            <Suspense fallback={<StackedInitialAvatarsSkeleton />}>
                <TenantUsersInitials />
            </Suspense>

            <Text.Body className='font-normal'>Manage access</Text.Body>

            <FontAwesomeIcon icon={faChevronRight} className='ml-auto' />
        </Button>
    );
};

const TenantUsersInitials = () => {
    const appCtx = useAppContext();
    const usersInWorkspace = useUsersNamesInWorkspace(appCtx.currentWorkspaceID ?? '');

    if (!usersInWorkspace?.length) {
        return null;
    }

    return <StackedInitialAvatars texts={usersInWorkspace} />;
};

const WorkspacePermissionsModal = (props: { close: CloseHandler }) => {
    const appCtx = useAppContext();

    const workspace = useWorkspace(appCtx.currentWorkspaceID);

    if (!workspace.data) {
        return null;
    }

    return (
        <Modal modalClassName='w-[700px] h-[700px] flex flex-col'>
            <Header>
                <BackButton onClick={() => props.close('back')}>
                    <FontAwesomeIcon icon={faChevronLeft} className='mr-2' />
                    <span>Back</span>
                </BackButton>
                <CloseButton onClick={() => props.close('exit')}>
                    <FontAwesomeIcon icon={faXmark} />
                </CloseButton>
                <Text.H2>Manage workspace access</Text.H2>
            </Header>
            <EditWorkspaceModalProvider workspace={workspace.data} close={props.close}>
                <EditWorkspaceForm>
                    <ContentContainer>
                        <WorkspaceACLTable />
                    </ContentContainer>
                </EditWorkspaceForm>
            </EditWorkspaceModalProvider>
        </Modal>
    );
};

const WorkspaceACLTable = () => {
    const formCtx = useFormContext();
    const editFormCtx = useEditWorkspaceModalContext();

    const workspaceACLUtils = useWorkspaceACLUtils();

    useEffect(() => formCtx.setValue('accessControlToggle', true), [formCtx]);

    return (
        <>
            <Controller
                name='acl'
                rules={{
                    validate: (updatedACL) =>
                        workspaceACLUtils.isACLValid(updatedACL) || 'At least one user or group must have full control.'
                }}
                render={(props) => (
                    <CustomACLEditor
                        acl={props.field.value}
                        entityId={editFormCtx.workspace.id}
                        permissionOptions={permissionOptions}
                        defaultPermissionsForNewACE={['RO']}
                        onChange={props.field.onChange}
                    />
                )}
            />

            <Presence isPresent={Boolean(formCtx.formState.errors['acl']?.message)}>
                <p className='ml-2 mt-2 text-statusErrorPrimary'>{formCtx.formState.errors['acl']?.message}</p>
            </Presence>
        </>
    );
};

const Header = (props: ComponentPropsWithoutRef<'header'>) => {
    return (
        <header
            {...props}
            className='px-8 py-5 border-dividerPrimary border-b grid grid-cols-[1fr_auto] justify-between items-center'
        />
    );
};

const CloseButton = (props: ComponentPropsWithoutRef<'button'>) => {
    return (
        <button
            {...props}
            aria-label='closeModal'
            className='text-xl text-tertiaryButton transition-colors hover:text-tertiaryButtonHover'
        />
    );
};

const BackButton = (props: ComponentPropsWithoutRef<'button'>) => {
    return (
        <button
            {...props}
            className='w-max flex items-center gap-1 text-md text-tertiaryButton hover:text-tertiaryButtonHover transition-colors'
        />
    );
};

const ContentContainer = (props: ComponentPropsWithoutRef<'div'>) => {
    return <div {...props} className='px-6 pb-6 tile-scroll-overflow' />;
};
