import { DashboardVariable } from 'dashboard-engine/types/Dashboard';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const setVariableParamsOnUrlSearchParams = (search: URLSearchParams, variables: DashboardVariable[]) => {
    search.delete('nodeId');
    search.delete('selectedAllNodes');
    variables.flatMap((v) => v.selectedObjects).forEach((o) => search.append('nodeId', o.id));

    if (variables[0]?.selectedAll) {
        search.set('selectedAllNodes', 'true');
    }
};

export const useSetVariableSearchParams = (variables: DashboardVariable[]) => {
    const [search, setSearchParams] = useSearchParams();

    useEffect(() => {
        setVariableParamsOnUrlSearchParams(search, variables);

        setSearchParams(search, { replace: true });
    }, [variables, search, setSearchParams]);
};
