import { TimeframeEnumValue, defaultTimeframeEnum, supportedTimeframes } from '@squaredup/timeframes';
import { AppLoadingSpinner } from 'components/AppLoadingSpinner';
import { AppContext } from 'contexts/AppContext';
import DashboardContext from 'contexts/DashboardContext';
import RenderDynamic from 'dashboard-engine/render/RenderDynamic';
import { DashboardTileContent, DashboardVariable } from 'dashboard-engine/types/Dashboard';
import { motion } from 'framer-motion';
import { usePageTitle } from 'lib/usePageTitle';
import { useDashboard } from 'queries/hooks/useDashboard';
import { useDashboardVariables } from 'queries/hooks/useDashboardVariables';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useDashboardId } from 'ui/hooks/useDashboardId';
import { TileError } from 'ui/tile/TileError';

/**
 * Renders a specific tile using a shared/transient link
 * Supports specifying timeframe by URL parameter
 */
export const OpenAccessTile: React.FC = () => {
    const dashboardId = useDashboardId();
    const [params] = useSearchParams();
    const { tileId } = useParams();

    // Support specifying a timeframe via URL parameter, e.g. ?timeframe=last7days
    const paramTimeframe = params.get('timeframe');
    const initialTimeframe: TimeframeEnumValue = (
        paramTimeframe && (supportedTimeframes as string[]).includes(paramTimeframe)
            ? paramTimeframe
            : defaultTimeframeEnum
    ) as TimeframeEnumValue;
    
    const [timeframe, setTimeframe] = useState(initialTimeframe);    
    const [variables, setVariables] = useState<DashboardVariable[]>([]);

    const { setCurrentWorkspaceID } = useContext(AppContext);

    const { data: dashboard, isLoading: isLoadingDashboard } = useDashboard(dashboardId);
    const { data: dashboardVariables, isLoading: isLoadingVariables } = useDashboardVariables(dashboardId);

    const isLoading = isLoadingDashboard || isLoadingVariables;

    const tile = dashboard?.content?.contents?.find((t: DashboardTileContent) => t.i === tileId);

    useEffect(() => {
        setCurrentWorkspaceID({
            id: dashboard?.workspaceId,
            force: true
        });
    }, [dashboard?.workspaceId, setCurrentWorkspaceID]);

    // Ensure timeframe reflects dashboard timeframe (if set)
    useEffect(() => {
        if(!paramTimeframe) { // Only if not being set by URL parameter
            setTimeframe(dashboard?.timeframe || defaultTimeframeEnum);
        }
    }, [dashboard?.timeframe, paramTimeframe]);
    
    usePageTitle([tile?.config.title, dashboard?.displayName].filter(Boolean).join(' | '));

    if (isLoading) {
        return <AppLoadingSpinner message={'Loading...'} />;
    }

    //TODO: Allow specify dashboard variable value by URL parameter SAAS-5883
    if(dashboardVariables && dashboardVariables.some(d => d.default !== 'all')) {
        return <TileError
            heading='Missing variable'
            detail='This dashboard uses a variable without a default value and cannot be displayed'
            showDetail={true}
        />;
    }

    if(!tile) {
        return <TileError
            heading='Invalid tile'
            detail='This specified tile/dashboard was not found, please check the dashboard and try again.'
            showDetail={true}
        />;
    }

    return (
        <div className='flex flex-col w-full h-full min-h-0 bg-tileBackground'>
            <motion.div
                className='w-full h-full'
                initial={{
                    opacity: 0,
                    y: 192
                }}
                transition={{
                    ease: 'easeOut',
                    duration: 0.5
                }}
                animate={{
                    opacity: 1,
                    y: 0
                }}
            >
                <div className='w-full h-full'>
                    <DashboardContext.Provider
                        value={{
                            editing: false,
                            showDetailedErrors: false,
                            dashboard: dashboard!,
                            timeframe,
                            variables,
                            setVariables
                        }}
                    >
                        <RenderDynamic config={{
                            ...tile?.config,
                            noBackground: true,
                            noHeader: true
                        }} key={tile?.i} />
                    </DashboardContext.Provider>
                </div>
            </motion.div>
        </div>
    );
};
