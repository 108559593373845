import { HealthState } from '@squaredup/monitoring';
import { applyEdgeChanges, applyNodeChanges, Edge, EdgeChange, NodeChange, OnEdgesChange, OnNodesChange } from 'reactflow';
import { create } from 'zustand';
import { NodesAndEdges, PinnableNode } from './data/types';
import { LayoutTypes } from './layout/types';

 
export type NetworkMapStoreInstance = ReturnType<typeof createNetworkMapStore>

export interface NetworkMapStoreProps {
    mapId: string;
    background: string;
    layoutType: LayoutTypes;
    nodes: PinnableNode[];
    edges: Edge[];
    graphNodes: NodesAndEdges['nodes'];
    graphEdges: NodesAndEdges['edges'];
    expandedNodeIds: string[];
    pinnedNodeIds: string[];
    pinnedGroupsWithMemberIds: Map<string, string[]>;
    ungroupedNodeIds: string[];
};

type StateSetter<T> = T | ((previousValue: T) => T)

export interface NetworkMapStoreState extends NetworkMapStoreProps {
    getLayoutType: () => LayoutTypes;
    setLayoutType: (layoutType: LayoutTypes) => void;
    getExpandedNodeIds: () => string[];
    setExpandedNodeIds: (newState: StateSetter<string[]>) => void;
    getUngroupedNodeIds: () => string[];
    setUngroupedNodeIds: (newState: StateSetter<string[]>) => void;
    getpinnedGroupsWithMemberNodeIds: () => Map<string, string[]>;
    setpinnedGroupsWithMemberNodeIds: (newState: StateSetter<Map<string, string[]>>) => void;
    getPinnedNodeIds: () => string[];
    setPinnedNodeIds: (newState: StateSetter<string[]>) => void;
    getNode: (nodeId: string) => PinnableNode | undefined;
    getNodes: () => PinnableNode[];
    getEdges: () => Edge[];
    getGraphNodes: () => NodesAndEdges['nodes'];
    getGraphEdges: () => NodesAndEdges['edges'];
    setNodes: (newState: StateSetter<PinnableNode[]>) => void;
    setEdges: (newState: StateSetter<Edge[]>) => void;
    setNodesAndEdges: (nodesState: StateSetter<PinnableNode[]>, edgesState: StateSetter<Edge[]>) => void;
    setGraphNodes: (newState: StateSetter<NodesAndEdges['nodes']>) => void;
    setGraphEdges: (newState: StateSetter<NodesAndEdges['edges']>) => void;
    getNodeHealthState: (nodeId: string) => HealthState | undefined;
    setNodeHealthState: (nodeId: string, healthState?: HealthState) => void;
    onNodesChange: OnNodesChange;
    onEdgesChange: OnEdgesChange;
};

export const createNetworkMapStore = ({ 
    mapId,
    background, 
    layoutType, 
    nodes, 
    edges, 
    graphNodes,
    graphEdges,
    expandedNodeIds,
    pinnedNodeIds,
    pinnedGroupsWithMemberIds,
    ungroupedNodeIds
}: NetworkMapStoreProps) => {
    return create<NetworkMapStoreState>((set, get) => ({
        mapId,
        background,
        layoutType,
        nodes,
        edges,
        graphNodes,
        graphEdges,
        expandedNodeIds,
        pinnedNodeIds,
        pinnedGroupsWithMemberIds,
        ungroupedNodeIds,
        getLayoutType() {
            return get().layoutType;
        },
        setLayoutType(layout: LayoutTypes) {
            set({
                layoutType: layout
            });
        },
        getExpandedNodeIds() {
            return get().expandedNodeIds;
        },
        setExpandedNodeIds(newState: StateSetter<string[]>) {
            set(
                (state: NetworkMapStoreState) => ({ 
                    expandedNodeIds: typeof newState === 'function' ? newState(state.expandedNodeIds) : newState 
                })
            );
        },
        getPinnedNodeIds() {
            return get().pinnedNodeIds;
        },
        setPinnedNodeIds(newState: StateSetter<string[]>) {
            set(
                (state: NetworkMapStoreState) => ({ 
                    pinnedNodeIds: typeof newState === 'function' ? newState(state.pinnedNodeIds) : newState 
                })
            );
        },
        getUngroupedNodeIds() {
            return get().ungroupedNodeIds;
        },
        setUngroupedNodeIds(newState: StateSetter<string[]>) {
            set(
                (state: NetworkMapStoreState) => ({ 
                    ungroupedNodeIds: typeof newState === 'function' ? newState(state.ungroupedNodeIds) : newState 
                })
            );
        },
        getpinnedGroupsWithMemberNodeIds() {
            return get().pinnedGroupsWithMemberIds;
        },
        setpinnedGroupsWithMemberNodeIds(newState: StateSetter<Map<string, string[]>>) {
            set(
                (state: NetworkMapStoreState) => ({ 
                    pinnedGroupsWithMemberIds: typeof newState === 'function' ? newState(state.pinnedGroupsWithMemberIds) : newState 
                })
            );
        },
        getNode(nodeId: string) {
            return get().nodes.find(({ id }) => id === nodeId);
        },
        getNodes() {
            return get().nodes;
        },
        getEdges() {
            return get().edges;
        },
        setNodes(newState: StateSetter<PinnableNode[]>) {
            set(
                (state: NetworkMapStoreState) => ({ 
                    nodes: typeof newState === 'function' ? newState(state.nodes) : newState 
                })
            );
        },
        setEdges(newState: StateSetter<Edge[]>) {
            set(
                (state: NetworkMapStoreState) => ({ 
                    edges: typeof newState === 'function' ? newState(state.edges) : newState
                })
            );
        },
        setNodesAndEdges(
            nodesState: StateSetter<PinnableNode[]>,
            edgesState: StateSetter<Edge[]>
        ) {
            set(
                (state: NetworkMapStoreState) => ({ 
                    nodes: typeof nodesState === 'function' ? nodesState(state.nodes) : nodesState,
                    edges: typeof edgesState === 'function' ? edgesState(state.edges) : edgesState
                })
            );
        },
        getGraphNodes() {
            return get().graphNodes;
        },
        getGraphEdges() {
            return get().graphEdges;
        },
        setGraphNodes(newState: StateSetter<NodesAndEdges['nodes']>) {
            set(
                (state: NetworkMapStoreState) => ({ 
                    graphNodes: typeof newState === 'function' ? newState(state.graphNodes) : newState 
                })
            );
        },
        setGraphEdges(newState: StateSetter<NodesAndEdges['edges']>) {
            set(
                (state: NetworkMapStoreState) => ({ 
                    graphEdges: typeof newState === 'function' ? newState(state.graphEdges) : newState
                })
            );
        },
        getNodeHealthState(nodeId: string) {
            return get().nodes.find(({ id }) => id === nodeId)?.data.healthState;
        },
        setNodeHealthState(nodeId: string, healthState?: HealthState) {
            set(
                (state: NetworkMapStoreState) => ({ 
                    nodes: state.nodes.map(
                        node => node.id === nodeId ? 
                            {
                                ...node,
                                data: {
                                    ...node.data,
                                    healthState
                                }
                            } : 
                            node
                    ) 
                })
            );
        },
        onNodesChange(changes: NodeChange[]) {
            set({
                nodes: applyNodeChanges(changes, get().nodes)
            });
        },
        onEdgesChange(changes: EdgeChange[]) {
            set({
                edges: applyEdgeChanges(changes, get().edges)
            });
        }
    }));
};