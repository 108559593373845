import { Row } from '@tanstack/react-table';
import { FormattedStreamValue, isNone } from '@squaredup/data-streams';

export const sortDataStreamValue = (
    rowA: Row<FormattedStreamValue[]>,
    rowB: Row<FormattedStreamValue[]>,
    columnId: string
): number => {
    const valueA: FormattedStreamValue['value'] = (rowA.getValue(columnId) as FormattedStreamValue).value;
    const valueB: FormattedStreamValue['value'] = (rowB.getValue(columnId) as FormattedStreamValue).value;

    const isNoneA = isNone(valueA);
    const isNoneB = isNone(valueB);
    const neitherIsNone = !isNoneA && !isNoneB;

    if (valueA === valueB || (isNoneA && isNoneB)) {
        return 0;
    }

    const isHigher = (!isNoneA && isNoneB) || (neitherIsNone && valueA > valueB);
    const asNumber = isHigher ? 1 : -1;

    return asNumber;
};
