import Text from '@/components/Text';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { buildQuery } from '@squaredup/graph';
import { Serialised } from '@squaredup/ids';
import { TimeframeEnumValue } from '@squaredup/timeframes';
import { Expandable, ExpandableProps } from 'components/Expandable';
import LoadingSpinner from 'components/LoadingSpinner';
import Tooltip from 'components/tooltip/Tooltip';
import DataStreamData, { VisualisationState } from 'dashboard-engine/dataStreams/DataStreamData';
import type { DataStreamDefinitionEntity } from 'dynamo-wrapper';
import PluginIcon, { sanitizePluginName } from 'pages/scope/PluginIcon';
import { useDatasourceConfigs } from 'queries/hooks/useDatasourceConfigs';
import { FC, useCallback, useMemo, useReducer } from 'react';
import { useQuery } from 'react-query';
import { ListForAllWorkspaces } from 'services/DataStreamDefinitionService';
import { matchDataStreamsToNodes } from 'services/DataStreamService';
import { PluginSourceConfig } from 'services/SourceConfigService';
import { injectSqUpPluginSource } from 'utilities/injectSqUpDataSource';
import { DrilldownPanel } from './DrilldownPanel';
import { NodeWithCanonical } from './common';

// react-query keys
const DATASTREAMS = 'datasteams';
const DRILLDOWN_NODE = 'drilldownnode';

type stateChange = { dataStreamId: string; state: VisualisationState };

interface DataStream {
    index: number;
    nodeId: string;
    dataStreamId: string;
    dataStreamName?: string;
    dataStreamDescription?: string;
    dataStreamDisplayName: string;
    sourceId?: string;
    sourceName?: string;
    sourceDisplayName?: string;
    timeframe: TimeframeEnumValue;
    onStateChange: (statechange: stateChange) => void;
}

const visualisationOrderReducer = (
    current: Record<string, VisualisationState>,
    { dataStreamId, state }: stateChange
) => {
    // Don't move the viz if it had a state previously and is now loading
    if (state === 'loading' && current[dataStreamId]) {
        return current;
    }

    return {
        ...current,
        [dataStreamId]: state
    };
};

interface GroupedStreams {
    noData: DataStream[];
    error: DataStream[];
    dataOrLoading: DataStream[];
}

const getStreamGroup = (groups: GroupedStreams, state: VisualisationState) =>
    state === 'hasError' ? groups.error : state === 'noData' ? groups.noData : groups.dataOrLoading;

const sortDataStreams = (a: DataStream, b: DataStream) => {
    // By default order the displayed data streams by data source name, then datastream name

    const bySource = (a.sourceDisplayName ?? '').localeCompare(b.sourceDisplayName ?? '', undefined, {
        sensitivity: 'base'
    });

    if (bySource !== 0) {
        return bySource;
    }

    return a.dataStreamDisplayName.localeCompare(b.dataStreamDisplayName, undefined, { sensitivity: 'base' });
};

export type DataStreamVisualisationPanelProps = {
    node?: NodeWithCanonical;
    timeframe: TimeframeEnumValue;
};

export const DataStreamVisualisationPanel: FC<DataStreamVisualisationPanelProps> = ({ timeframe, node }) => {
    // Get data streams for the node
    const { data: dataStreams, isLoading: dataStreamsLoading } = useQuery(
        [DRILLDOWN_NODE, node?.id, DATASTREAMS],
        async () => {
            if (!node) {
                return undefined;
            }

            // Get matched data streams for the nodes in scope
            const { matches } = await matchDataStreamsToNodes([node], 'directOrAnyWorkspaceLinks');
            const allDatastreams = await ListForAllWorkspaces();

            const matchedDataStreams = matches.flatMap(({ nodeId, dataStreamIds }) =>
                dataStreamIds.map((id) => ({ nodeId, id }))
            );

            const availableStreams = new Map(
                allDatastreams
                    .filter(
                        (dataStream) =>
                            dataStream.definition.options?.excludeFromDrilldown !== true &&
                            matchedDataStreams.some((match) => match.id === dataStream.id)
                    )
                    .map((ds) => [ds.id, ds])
            );

            return {
                matchedDataStreams,
                dataStreams: availableStreams
            };
        },
        {
            enabled: Boolean(node),
            cacheTime: Number.POSITIVE_INFINITY,
            staleTime: Number.POSITIVE_INFINITY
        }
    );

    const { data: sources, isLoading: sourcesLoading } = useDatasourceConfigs({
        select: injectSqUpPluginSource,
        cacheTime: Number.POSITIVE_INFINITY,
        staleTime: Number.POSITIVE_INFINITY
    });

    const isLoading = sourcesLoading || dataStreamsLoading;

    return (
        <DrilldownPanel title='Data Streams'>
            {isLoading ? (
                <div className='w-full mt-5 text-center'>
                    <LoadingSpinner />
                </div>
            ) : !dataStreams || dataStreams.matchedDataStreams.length === 0 ? (
                <p className='mt-4 text-sm text-textSecondary'>No datastreams available for this object.</p>
            ) : (
                <DataStreamVisualisations
                    timeframe={timeframe}
                    availableDataStreams={dataStreams.dataStreams}
                    matchedDataStreams={dataStreams.matchedDataStreams}
                    sources={sources}
                />
            )}
        </DrilldownPanel>
    );
};

interface DataStreamVisualisationsProps {
    timeframe: TimeframeEnumValue;
    matchedDataStreams: { nodeId: string; id: string }[];
    availableDataStreams: Map<string, Serialised<DataStreamDefinitionEntity>>;
    sources?: PluginSourceConfig[];
}

const DataStreamVisualisations: FC<DataStreamVisualisationsProps> = ({
    matchedDataStreams,
    availableDataStreams,
    sources,
    timeframe
}) => {
    const [stateByStreamId, dispatch] = useReducer(visualisationOrderReducer, {});

    const visualisations: GroupedStreams = matchedDataStreams.reduce(
        (acc, { nodeId, id }, index) => {
            const dataStream = availableDataStreams.get(id);
            if (dataStream) {
                const source = sources?.find((s) => s.plugin?.pluginId === dataStream?.pluginId);
                const group = getStreamGroup(acc, stateByStreamId[dataStream.id]);

                group.push({
                    nodeId,
                    dataStreamId: dataStream.id,
                    dataStreamName: dataStream.definition?.name,
                    dataStreamDisplayName: dataStream.displayName,
                    dataStreamDescription: dataStream.description,
                    sourceId: source?.id,
                    sourceName: source?.plugin?.name,
                    sourceDisplayName: sanitizePluginName(source?.plugin?.displayName),
                    index,
                    timeframe,
                    onStateChange: dispatch
                });
            }
            return acc;
        },
        {
            noData: [],
            error: [],
            dataOrLoading: []
        }
    );

    const { dataOrLoading, error: errorStreams, noData } = visualisations;

    return (
        <>
            <DataStreamVisualisationList dataStreams={dataOrLoading} />

            <DrilldownVisualisationExpandable
                dataStreams={noData}
                helpText='No data to show for the selected timeframe.'
                title='Data streams with no data'
                initiallyOpen={dataOrLoading.length === 0}
            >
                <DataStreamVisualisationList dataStreams={noData} />
            </DrilldownVisualisationExpandable>

            <DrilldownVisualisationExpandable
                dataStreams={errorStreams}
                helpText='Check your data source or custom data stream configuration.'
                title='Data streams with errors'
                initiallyOpen={dataOrLoading.length + noData.length === 0}
            >
                <DataStreamVisualisationList dataStreams={errorStreams} />
            </DrilldownVisualisationExpandable>
        </>
    );
};

const DataStreamVisualisationList: FC<{ dataStreams: DataStream[] }> = ({ dataStreams }) => {
    return (
        <div className='grid grid-flow-row-dense grid-cols-1 gap-2.5 grid-auto-rows'>
            {dataStreams.sort(sortDataStreams).map((dataStream) => (
                <DrilldownVisualisation {...dataStream} key={`${dataStream.nodeId}-${dataStream.dataStreamId}`} />
            ))}
        </div>
    );
};

type DrilldownVisualisationExpandableProps = Omit<ExpandableProps, 'summary' | 'helpIcon'> & {
    title: string;
    helpText: string;
    dataStreams: DataStream[];
};

const DrilldownVisualisationExpandable: FC<DrilldownVisualisationExpandableProps> = ({
    dataStreams,
    helpText,
    title,
    ...rest
}) => {
    if (!dataStreams || dataStreams.length === 0) {
        return <></>;
    }

    return (
        <Expandable
            summaryStyles='border border-dividerTertiary bg-tagBackground border-dividerTertiary pl-4'
            scrollIntoViewOnExpand={false}
            summary={<Text.H4>{`${title} (${dataStreams.length})`}</Text.H4>}
            helpIcon={
                <Tooltip title={helpText}>
                    <FontAwesomeIcon className='ml-3 text-textSecondary' icon={faCircleQuestion} />
                </Tooltip>
            }
            {...rest}
        />
    );
};

interface DrilldownVisualisationProps {
    index: number;
    nodeId: string;
    dataStreamId: string;
    timeframe: TimeframeEnumValue;
    dataStreamDescription?: string;
    dataStreamDisplayName?: string;
    dataStreamName?: string;
    sourceId?: string;
    sourceName?: string;
    sourceDisplayName?: string;
    config?: Record<string, unknown>;
    onStateChange: (statechange: stateChange) => void;
}

const DrilldownVisualisation: FC<DrilldownVisualisationProps> = ({
    dataStreamDisplayName,
    dataStreamName,
    dataStreamDescription,
    nodeId,
    sourceId,
    sourceName,
    sourceDisplayName,
    dataStreamId,
    timeframe,
    config,
    index,
    onStateChange
}) => {
    const tileId = `${nodeId}-${index}`;
    const scope = useMemo(() => buildQuery({ ids: [nodeId] }, ''), [nodeId]);

    // ensure we don't trigger unnecessary effect calls during re-renders
    const notifyStateChange = useCallback(
        (state: VisualisationState) => {
            onStateChange({ dataStreamId, state });
        },
        [dataStreamId, onStateChange]
    );

    return (
        <div
            id={`tile-${tileId}`}
            className='flex flex-col w-full h-full py-2 group/tile'
            data-tile={`${sourceDisplayName} / ${dataStreamDisplayName}`}
        >
            <div className='flex items-center mb-1 text-base font-semibold shrink-0' data-testid='tileTitle'>
                <div className='w-6 h-6 my-0.5 mr-3'>
                    <PluginIcon pluginName={sourceName} />
                </div>

                <Tooltip title={dataStreamDescription} className='mr-4'>
                    {sourceDisplayName} / {dataStreamDisplayName}
                </Tooltip>

                <div id={`${tileId}Toolbar`} className='flex self-start ml-auto gap-x-xxs hide-for-image-export' />
            </div>

            <div className='relative flex-1'>
                <div className='absolute inset-0 top-4'>
                    <DataStreamData
                        title={`${sourceDisplayName} / ${dataStreamDisplayName}`}
                        tileId={tileId}
                        dataStreamId={dataStreamId}
                        dataStreamName={dataStreamName}
                        pluginConfigId={sourceId}
                        sourceId={sourceId}
                        timeframe={timeframe}
                        scope={{
                            query: scope.gremlinQuery,
                            bindings: scope.bindings,
                            queryDetail: { ids: [nodeId] }
                        }}
                        options={{
                            // For drilldown the node could have come from any workspace we have access to.
                            accessControlType: 'directOrAnyWorkspaceLinks'
                        }}
                        config={config}
                        onStateChange={notifyStateChange}
                    />
                </div>
            </div>
        </div>
    );
};
