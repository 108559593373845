import Text from '@/components/Text';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import { AgentGroupWithPlatform, useAgentGroups } from 'components/hooks/useAgentGroups';
import { AgentWithRelatedObjects, useAgentsWithRelatedObjects } from 'components/hooks/useAgents';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { AGENT_GROUP_RESTRICTIONS, ListRestrictedPlatforms } from 'services/AgentGroupService';
import { GetLatestAgentDownload, LATEST_AGENT } from 'services/AgentService';
import { Delete as DeleteApiKey } from 'services/ApiKeyService';
import { SettingsTemplate } from '../SettingsTemplate';
import { AgentAddModal } from './AgentAddModal';
import AgentDeleteModal from './AgentDeleteModal';
import { AgentDownloadsModal } from './AgentDownloadsModal';
import { AgentGroupAddModal } from './AgentGroupAddModal';
import AgentGroupDeleteModal from './AgentGroupDeleteModal';
import AgentGroupsTable from './AgentGroupsTable';
import AgentsTable from './AgentsTable';
import { useTier } from 'queries/hooks/useTier';
import { isFeatureEnabled } from '@squaredup/tenants';
import { FeatureUnavailableBanner } from 'components/plans/FeatureUnavailableBanner';

const Agents = () => {
    const [addEditAgent, setAddEditAgent] = useState<{ agent: AgentWithRelatedObjects | undefined }>();
    const [addEditAgentGroup, setAddEditAgentGroup] = useState<{ group: AgentGroupWithPlatform | undefined }>();

    const [agentBeingDeleted, setAgentBeingDeleted] = useState<AgentWithRelatedObjects>();
    const [agentGroupBeingDeleted, setAgentGroupBeingDeleted] = useState<AgentGroupWithPlatform>();
    const [agentToDownload, setAgentToDownload] = useState<AgentWithRelatedObjects | undefined>();

    const { data: latestAgent } = useQuery([LATEST_AGENT], GetLatestAgentDownload);
    const { data: agentGroupRestrictedPlatforms, isLoading: isLoadingRestrictedPlatforms } = useQuery(
        [AGENT_GROUP_RESTRICTIONS],
        ListRestrictedPlatforms,
        {
            select: (data) => new Map(data.map(({ id, restrictedToPlatforms }) => [id, restrictedToPlatforms]))
        }
    );
    const { agents, isLoadingAgents } = useAgentsWithRelatedObjects({ refetchInterval: 30_000 });
    const { agentGroups, isLoadingAgentGroups } = useAgentGroups({ refetchInterval: 30_000 });
    const { data: tier, isLoading: isTierLoading } = useTier();

    const isLoading = isLoadingAgents || isLoadingAgentGroups || isLoadingRestrictedPlatforms || isTierLoading;
    const isFeatureAvailable = tier !== undefined && isFeatureEnabled(tier, 'relayAgents');

    return (
        <SettingsTemplate
            title='Relay Agents'
            description={isFeatureAvailable ? 'Relay agents allow you to securely connect to data sources inside your own network.' : undefined}
            learnMoreLink='https://squaredup.com/cloud/relay'
            flex
        >
            <FeatureUnavailableBanner 
                featureKey='relayAgents'
                container='page'
                title='Connect to on-prem data sources'
                summary='Relay agents allow you to securely connect to data sources inside your own network.'
                className='mb-9'
            />
            <div className='flex flex-col flex-1 min-h-0'>
                <Text.H2>Agents</Text.H2>
                <Text.Body className='mb-sm text-textSecondary'>
                    An agent is a service that runs on a host inside your own network.
                </Text.Body>
                <div>
                    <Button
                        onClick={() => setAddEditAgent({ agent: undefined })}
                        variant='primary'
                        disabled={isLoading || !isFeatureAvailable}
                        data-testid='addAgentButton'
                    >
                        Add agent
                    </Button>
                </div>
                <div className='flex flex-col min-h-0 mt-4 mb-xs'>
                    {isLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <AgentsTable
                            isFeatureAvailable={isFeatureAvailable}
                            agents={agents}
                            latestAgentVersion={latestAgent?.version ?? ''}
                            onDownload={setAgentToDownload}
                            onEdit={(agent) => setAddEditAgent({ agent })}
                            onDelete={setAgentBeingDeleted}
                        />
                    )}
                </div>
                <Text.Body className='mb-5 text-textSecondary'>
                    Check the{' '}
                    <Button
                        variant='link'
                        href='https://squaredup.com/cloud/relay-agent-release-notes'
                        rel='noreferrer'
                    >
                        Agent release notes
                    </Button>{' '}
                    for more information including installation and upgrade instructions.
                </Text.Body>
            </div>
            <div className='flex flex-col flex-1 min-h-0'>
                <Text.H2>Agent Groups</Text.H2>
                <Text.Body className='mb-sm text-textSecondary'>
                    Agent groups dynamically determine which agents are used to retrieve data.
                </Text.Body>
                <div>
                    <Button
                        onClick={() => setAddEditAgentGroup({ group: undefined })}
                        variant='primary'
                        disabled={isLoading || !isFeatureAvailable}
                        data-testid='addAgentGroupButton'
                    >
                        Add agent group
                    </Button>
                </div>
                <div className='flex flex-col min-h-0 mt-4 mb-8'>
                    {isLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <AgentGroupsTable
                            isFeatureAvailable={isFeatureAvailable}
                            agentGroups={agentGroups}
                            onEdit={(group) => setAddEditAgentGroup({ group })}
                            onDelete={setAgentGroupBeingDeleted}
                        />
                    )}
                </div>
            </div>

            {addEditAgent && (
                <AgentAddModal
                    onSave={() => setAddEditAgent(undefined)}
                    onClose={(apiKeyId: string | undefined) => {
                        if (apiKeyId) {
                            DeleteApiKey(apiKeyId);
                        }
                        setAddEditAgent(undefined);
                    }}
                    agent={addEditAgent.agent}
                    groups={agentGroups}
                    latestAgent={latestAgent}
                    agentGroupRestrictedToPlatforms={agentGroupRestrictedPlatforms}
                />
            )}

            {addEditAgentGroup && (
                <AgentGroupAddModal
                    onSave={() => setAddEditAgentGroup(undefined)}
                    onClose={() => setAddEditAgentGroup(undefined)}
                    group={addEditAgentGroup.group}
                    agents={agents}
                    restrictedToPlatforms={
                        addEditAgentGroup.group ? agentGroupRestrictedPlatforms?.get(addEditAgentGroup.group.id) : []
                    }
                />
            )}

            {agentBeingDeleted && (
                <AgentDeleteModal agent={agentBeingDeleted} onClose={() => setAgentBeingDeleted(undefined)} />
            )}

            {agentGroupBeingDeleted && (
                <AgentGroupDeleteModal
                    group={agentGroupBeingDeleted}
                    onClose={() => setAgentGroupBeingDeleted(undefined)}
                />
            )}

            {agentToDownload && (
                <AgentDownloadsModal
                    latestAgent={latestAgent}
                    onClose={() => setAgentToDownload(undefined)}
                    agentPlatform={agentToDownload.platform}
                />
            )}
        </SettingsTemplate>
    );
};

export default Agents;
