import Modal from 'components/Modal';
import { DashboardVariable } from 'dashboard-engine/types/Dashboard';
import { DashboardVariableForm } from './DashboardVariableForm';

interface DashboardVariableCreateModalProps {
    existingVariable?: DashboardVariable;
    onClose: () => void;
}

export const DashboardVariableCreateEditModal: React.FC<DashboardVariableCreateModalProps> = ({
    existingVariable,
    onClose
}) => {
    return (
        <Modal
            title={existingVariable ? `Update dashboard variable: ${existingVariable.name}` : 'Add dashboard variable'}
            modalClassName='w-full max-w-[644px]'
            headerBorder={true}
            close={onClose}
        >
            <DashboardVariableForm existingVariable={existingVariable} onClose={onClose} />
        </Modal>
    );
};
