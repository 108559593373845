import Modal, { ModalButtons } from 'components/Modal';
import Button from 'components/button/Button';
import TileContext from 'contexts/TileContext';
import { TileConfig } from 'dashboard-engine/types/Tile';
import stableStringify from 'fast-json-stable-stringify';
import { useContext, useState } from 'react';
import TileCodeEditor from '../components/TileCodeEditor';

/**
 * Editor shown for non-standard tiles, e.g. script/graph
 * @param config Tile JSON config
 * @param onClose Handler to trigger closing
 * @returns
 */
export const JsonTileEditor: React.FC<{
    config: TileConfig;
    onClose: () => void;
}> = ({ config, onClose }) => {
    const [newConfig, setNewConfig] = useState(config);
    const { onChange } = useContext(TileContext);

    /**
     * Save & close
     */
    const onSave = () => {
        onChange(newConfig);
        onClose();
    };

    return (
        <Modal title='Edit JSON' maxWidth='w-3/4' maxHeight='h-[720px]'>
            <div className='flex-1 min-h-0 pl-5 pr-5'>
                <TileCodeEditor 
                    tileConfig={config} 
                    update={setNewConfig} 
                />
            </div>
            <ModalButtons>
                <Button variant='secondary' onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    variant='primary'
                    disabled={stableStringify(config) === stableStringify(newConfig)}
                    onClick={onSave}
                >
                    Save
                </Button>
            </ModalButtons>
        </Modal>
    );
};
