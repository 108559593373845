import { baseDataStreamConfig } from 'dashboard-engine/constants';
import { TileConfig } from 'dashboard-engine/types/Tile';
import { TileState } from 'queries/types/types';
import React, { createContext, useContext } from 'react';

interface TileContextValue {
    config: TileConfig;
    tileId?: string;
    preview: boolean;
    tileReference?: React.MutableRefObject<Element | null>;
    health?: TileState;
    onEdit: (editorType?: 'new' | 'old') => void;
    onChange: (config: TileConfig) => void;
    onClose?: () => void;
    onClone?: () => void;
    onCopy?: () => void;
    onDelete?: () => void;
    setTileData?: (tileData: unknown[]) => void;
    setTileProcessedData?: (processedTileData: unknown[]) => void;
    setTileToolbar?: (content: React.ReactNode[] | React.ReactNode) => void;
    [key: string]: unknown;
}

const TileContext = createContext<TileContextValue>({
    config: baseDataStreamConfig,
    tileId: '',
    preview: false,
    tileReference: undefined,
    health: undefined,
    onEdit: () => undefined,
    onClose: () => undefined,
    onChange: () => undefined,
    setTileData: () => undefined,
    setTileProcessedData: () => undefined,
    setTileToolbar: () => undefined
});

TileContext.displayName = 'TileContext';

export const useTileContext = () => useContext(TileContext);

export default TileContext;
