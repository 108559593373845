import type { OrganisationHomeConfig } from 'dynamo-wrapper';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { GetTenantUserProperties, SetUserForTenantProperty } from 'services/UserService';

export const STATUS_CONFIG = 'STATUS_CONFIG';

export const useStatusConfig = () =>
    useQuery([STATUS_CONFIG], ({ signal }) =>
        GetTenantUserProperties(signal).then((data) => data?.organisationHomeConfig as OrganisationHomeConfig)
    );

export const useSaveStatusConfig = () => {
    const queryClient = useQueryClient();
    return useMutation((config: OrganisationHomeConfig) => SetUserForTenantProperty('organisationHomeConfig', config), {
        mutationKey: [STATUS_CONFIG],
        async onMutate(config) {
            await queryClient.cancelQueries([STATUS_CONFIG]);

            const previousConfig = queryClient.getQueryData([STATUS_CONFIG]) as OrganisationHomeConfig | undefined;

            queryClient.setQueryData([STATUS_CONFIG], config);

            return { previousConfig };
        },
        onError(_err, _config, context) {
            if (
                !queryClient.isMutating({
                    mutationKey: [STATUS_CONFIG]
                })
            ) {
                queryClient.setQueryData([STATUS_CONFIG], context?.previousConfig);
            }
        },
        onSettled() {
            if (
                !queryClient.isMutating({
                    mutationKey: [STATUS_CONFIG]
                })
            ) {
                queryClient.invalidateQueries([STATUS_CONFIG]);
            }
        }
    });
};
