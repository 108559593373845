import { cn } from '@/lib/cn';
import { buttonVariants } from 'components/button';

interface ColumnButtonProps {
    onClick: () => void;
    className?: string;
}

export const ColumnButton: React.FC<ColumnButtonProps> = ({ onClick, className, children }) => (
    <div
        onClick={onClick}
        className={cn(
            buttonVariants({ variant: 'secondary' }),
            'cursor-pointer flex justify-center items-start h-full z-10 py- px-xxxs',
            className
        )}
    >
        <span className='sticky top-0 py-4'>{children}</span>
    </div>
);
