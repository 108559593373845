import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useVirtualizer } from '@tanstack/react-virtual';
import clsx from 'clsx';
import { useMemo, useRef, useState } from 'react';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { DataStreamFilterOption } from '../hooks/useDataStreamFilters';
import { useRecentDataStreamStore } from '../hooks/useRecentDataStreamStore';
import { DataStreamOption } from './DataStreamOption';
import { useDataStreamSortOrder } from '../hooks/useDataStreamSortOrder';

interface DataStreamVirtualListProps {
    dataStreams?: DataStreamFilterOption[];
    allowSort?: boolean;
}

const icons: Record<number, IconProp> = {
    1: 'chevron-up',
    2: 'chevron-down'
};

export const DataStreamVirtualList: React.FC<DataStreamVirtualListProps> = ({
    dataStreams = [],
    allowSort = false
}) => {
    const parentRef = useRef<HTMLDivElement>(null);
    const { config, setConfig } = useDatasetContext();
    const [{ id: dataStreamId }] = useState(config.dataStream || {});
    const { sort, nextSortOrder, sortOrder } = useDataStreamSortOrder();
    const { recentDataStreams } = useRecentDataStreamStore();

    const topRecent = useMemo(() => {
        const streamIds = dataStreams?.map((stream) => stream.id);
        return recentDataStreams?.filter((id) => streamIds?.includes(id as `datastream-${string}`)).slice(0, 5);
    }, [dataStreams, recentDataStreams]);

    const sortedStreams = allowSort ? sort(dataStreams, dataStreamId, topRecent) : dataStreams;

    const rowVirtualizer = useVirtualizer({
        count: sortedStreams?.length || 0,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 35 // Height of each row in px
    });

    return (
        <div
            ref={parentRef}
            className='w-full h-full overflow-auto text-sm scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary !overflow-x-scroll'
            role='list'
        >
            <div
                className={clsx(
                    'py-2 px-2 rounded-input select-none grid grid-cols-[minmax(7rem,_1fr),1rem,minmax(7rem,_2fr),minmax(5rem,_1.2fr),3rem] min-w-fit w-full gap-6 text-textPrimary font-semibold'
                )}
            >
                <span
                    onClick={() => allowSort && nextSortOrder()}
                    className='cursor-pointer'
                >
                    Name
                    {allowSort && icons[sortOrder] && <FontAwesomeIcon className='ml-4' icon={icons[sortOrder]} />}
                </span>
                <span></span>
                <span>Description</span>
                <span>Type</span>
                <span></span>
            </div>
            <div className='relative h-full min-h-full' style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                    const dataStream = sortedStreams?.[virtualItem.index];

                    return (
                        <div
                            key={virtualItem.key}
                            className='absolute top-0 left-0 w-full'
                            style={{
                                height: `${virtualItem.size}px`,
                                transform: `translateY(${virtualItem.start}px)`
                            }}
                        >
                            {dataStream && (
                                <DataStreamOption dataStream={dataStream} 
                                    recent={topRecent.includes(dataStream.id)} 
                                    config={config}
                                    setConfig={setConfig} />
                            )}
                        </div>
                    );
                })}

                {dataStreams && dataStreams.length === 0 && (
                    <div className='text-textIncomplete'>No search results were found.</div>
                )}
            </div>
        </div>
    );
};
