import { Serialised } from '@squaredup/ids';
import type { ProjectedPlugin } from 'dynamo-wrapper';
import { PluginCard } from './PluginCard';
import { gridResponsiveClassName } from './common';

const suggestedNames = ['AWS', 'Azure', 'Azure DevOps', 'Sample Data'];

interface SuggestedPluginsPageProps {
    onSelect?: (plugin: Serialised<ProjectedPlugin>) => void;
    plugins: Serialised<ProjectedPlugin>[];
}

export function SuggestedPluginsPage({ onSelect, plugins }: SuggestedPluginsPageProps) {
    const suggestedPlugins = plugins
        ?.filter((plugin) => suggestedNames.includes(plugin.displayName || ''))
        .sort((a, b) => suggestedNames.indexOf(a.displayName || '') - suggestedNames.indexOf(b.displayName || ''));
    return (
        <section className='flex-col w-full'>
            <h4 className='pb-2 text-2xl font-semibold'>Suggested</h4>
            <ol className={gridResponsiveClassName}>
                {suggestedPlugins.map((plugin) => (
                    <li className='h-36' key={plugin.id}>
                        <PluginCard plugin={plugin} onClick={onSelect} />
                    </li>
                ))}
            </ol>
        </section>
    );
}
