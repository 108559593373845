import LoadingSpinner from 'components/LoadingSpinner';
import Autocomplete from 'components/forms/autocomplete/Autocomplete';
import Field from 'components/forms/field/Field';
import Input from 'components/forms/input/Input';
import type { KPIType } from 'dynamo-wrapper';
import deepEqual from 'fast-deep-equal/es6';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTileEditorContext } from '../../contexts/TileEditorContext';

interface DataStreamTileEditorKPIFormProps {
    types: KPIType[] | undefined;
}

export const DataStreamTileEditorKPIForm: React.FC<DataStreamTileEditorKPIFormProps> = ({ types }) => {
    const { tileConfig, setTileConfig } = useTileEditorContext();

    const kpi = tileConfig?.kpi;

    const defaultValues = {
        name: kpi?.name,
        type: kpi?.type
    };

    const formProps = useForm({ mode: 'onChange', defaultValues });
    const {
        watch,
        formState: { errors }
    } = formProps;
    const fields = watch();

    const kpiTypeOptions = useMemo(() => {
        return types?.map(({ displayName, id }) => ({
            label: displayName,
            value: id
        }));
    }, [types]);

    useEffect(() => {
        if (!deepEqual(fields, kpi) && Object.keys(errors || {}).length === 0) {
            setTileConfig((currentConfig) => ({ ...currentConfig, kpi: fields }));
        }
    }, [fields, kpi, errors, setTileConfig]);

    /**
     * Perform form validation immediately once the data has loaded
     */
    useEffect(() => {
        formProps.trigger();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [types]);

    return (
        <FormProvider {...formProps}>
            <form>
                <div className='max-w-2xl mx-4 space-y-5 overflow-y-auto text-sm scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                    <Field label='Name'>
                        <Input
                            type='text'
                            name='name'
                            label='Name'
                            validation={{ required: true }}
                            validateOnLoad={true}
                        />
                    </Field>
                    {types ? (
                        <Field label='Type'>
                            <div className='w-full'>
                                <Autocomplete
                                    name='type'
                                    placeholder='Select type'
                                    className='ring-1 ring-inset ring-outlinePrimary focus-within:ring-outlineSecondary rounded-input bg-componentBackgroundPrimary'
                                    options={kpiTypeOptions}
                                    validation={{ required: true }}
                                    isMulti={false}
                                    selectOptionsAs='valueString'
                                    menuShouldBlockScroll
                                    menuShouldScrollIntoView
                                />
                            </div>
                        </Field>
                    ) : (
                        <LoadingSpinner />
                    )}
                </div>
            </form>
        </FormProvider>
    );
};
