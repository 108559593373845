import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { useAppContext } from 'contexts/AppContext';
import { useDashboardsForWorkspace } from 'queries/hooks/useDashboardsForWorkspace';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { dashboardsSortedByWorkspaceOrder } from 'queries/utils/dashboardSorted';
import { useEffect, useRef } from 'react';
import { useMatch } from 'react-router';
import { useWorkspaceCanWrite } from 'services/AccessControlService';
import { AttachedInstruction } from './DashboardTreeHitbox';
import { GhostTreeItem, TreeItem as TreeItemComponent } from './DashboardTreeItem';
import { tree } from './DashboardTreeUtils';
import { useApplyDashboardInstruction } from './useApplyDashboardInstruction';
import { useFoldersOpen, useSetFolderOpen } from './useFoldersOpen';

export const DashboardTree: React.FC = () => {
    const { currentWorkspaceID } = useAppContext();
    const { data: workspace } = useWorkspace(currentWorkspaceID);

    const { data: dashboards = [], isFetched } = useDashboardsForWorkspace(currentWorkspaceID, {
        suspense: true,
        enabled: Boolean(workspace),
        select: dashboardsSortedByWorkspaceOrder(workspace?.data.properties?.dashboardIdOrder)
    });

    const treeRef = useRef<HTMLDivElement>(null);

    const applyInstruction = useApplyDashboardInstruction();

    const { data: canWrite } = useWorkspaceCanWrite(currentWorkspaceID ?? '', { suspense: true });

    useEffect(() => {
        return monitorForElements({
            canMonitor: ({ source }) => source.data.treeId === 'navbar-dashboard-tree' && Boolean(canWrite),
            onDrop(args) {
                const { location, source } = args;
                // didn't drop on anything
                if (!location.current.dropTargets.length) {
                    return;
                }

                const itemId = source.data.id as string;

                const { id, instruction } = location.current.dropTargets[0].data as AttachedInstruction;

                if (instruction) {
                    applyInstruction({
                        instruction,
                        itemId,
                        targetId: id
                    });
                }
            }
        });
    }, [applyInstruction, canWrite]);

    const { data: openFolders = {} } = useFoldersOpen({ suspense: true });

    const dashboardPage = useMatch('/dashboard/:id');

    const setOpen = useSetFolderOpen();

    useEffect(() => {
        if (dashboardPage?.params?.id && isFetched) {
            setOpen(
                tree
                    .getPathToItem({
                        fullItem: true,
                        targetId: dashboardPage.params.id!,
                        current: dashboards
                    })
                    ?.map((item) => ({ item, open: true })) || []
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardPage, isFetched]);

    if (dashboards.length === 0) {
        return <p className='pl-9'>No dashboards</p>;
    }

    const { item: finalItem, depth: finalDepth } = tree.getLastExpandedItemWithDepth(
        openFolders[currentWorkspaceID || ''] ?? {},
        dashboards.at(-1)!
    );

    return (
        <div className='flex flex-col relative' id='dashboard-folder-tree' ref={treeRef}>
            {dashboards.map((item, index) => (
                <TreeItemComponent
                    item={item}
                    level={0}
                    isLastOfGroup={index === dashboards.length - 1}
                    key={item.id}
                    index={index}
                    treeId={'navbar-dashboard-tree'}
                />
            ))}
            <GhostTreeItem level={finalDepth} item={finalItem} treeId='navbar-dashboard-tree' />
        </div>
    );
};
