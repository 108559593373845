import { Button, buttonVariants } from '@/components/Button';
import Text from '@/components/Text';
import { FeatureDisplay, FeatureKey, getFeatureDisplay, getFeatureLimit } from '@squaredup/tenants';
import { useTenant } from 'queries/hooks/useTenant';
import { useTier } from 'queries/hooks/useTier';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { IsTenantAdmin } from 'services/AccessControlService';
import { UpgradeMessageContainer } from './UpgradeMessageContainer';
import { cva, VariantProps } from 'class-variance-authority';

const variants = cva(
    undefined,
    {
        variants: {
            content: {
                descriptive: '',
                subtle: ''
            },
            container: {
                modal: '',
                page: 'min-w-[450px] w-fit'
            }
        },
        defaultVariants: {
            container: 'modal',
            content: 'descriptive'
        }
    }
);

export interface LimitReachedBannerProps extends VariantProps<typeof variants> {
    featureKey: FeatureKey;
    currentUsage: number | undefined;
    className?: string;
    thresholdVerb?: string;
}

export const LimitReachedBanner: React.FC<LimitReachedBannerProps> = ({
    featureKey,
    currentUsage,
    className,
    thresholdVerb = 'reached',
    container,
    content
}) => {
    const { data: tenant } = useTenant();
    const showUpgradeButton = IsTenantAdmin(tenant) ?? false;
    // useTier handles validating the tier, and coercing items like dates from strings
    const { data: tier } = useTier();

    const feature = getFeatureDisplay(featureKey);
    if (!tier || feature === undefined || currentUsage === undefined) {
        return <></>;
    }

    const limit = getFeatureLimit(tier, featureKey);
    if ('isUnlimited' in limit) {
        return <></>;
    }

    if (limit.value > currentUsage) {
        return <></>;
    }

    const isSubtle = content === 'subtle';
    
    return (
        <UpgradeMessageContainer
            data-testid='limit-reached-banner'
            className={variants({ container, content, className })}
            variant={isSubtle ? 'short' : 'normal'}
        >
            {isSubtle ? (
                <SubtleContent
                    feature={feature}
                    limit={limit}
                    userCanUpgrade={showUpgradeButton}
                    thresholdVerb={thresholdVerb}
                />
            ) : (
                <DescriptiveContent
                    feature={feature}
                    limit={limit}
                    userCanUpgrade={showUpgradeButton}
                    thresholdVerb={thresholdVerb}
                />
            )}
            
        </UpgradeMessageContainer>
    );
};

type contentProps = {
    feature: FeatureDisplay;
    thresholdVerb: string;
    limit: { value: number };
    userCanUpgrade: boolean;
};

const SubtleContent: React.FC<contentProps> = ({ feature, thresholdVerb, limit, userCanUpgrade }) => (
    <>
        <Text.H3 className='mb-1 mr-3 text-textPrimary'>
            {feature.singular ?? feature.displayName} limit {thresholdVerb} ({limit.value})
        </Text.H3>
        <Text.Body className='text-textSecondary'>
            {userCanUpgrade ? (
                <>
                    <NavLink to='/usage' className={buttonVariants({ variant: 'link' })}>
                        Upgrade
                    </NavLink>{' '}
                    to increase your limit.
                </>
            ) : (
                <>Please contact your administrator to increase your limit.</>
            )}
        </Text.Body>
    </>
);

const DescriptiveContent: React.FC<contentProps> = ({ feature, thresholdVerb, limit, userCanUpgrade }) => {
    const nav = useNavigate();
    return (
        <>
            <div className='flex'>
                <div className='mr-9'>
                    <Text.H3 className='mb-2 text-textPrimary'>
                        {feature.singular ?? feature.displayName} limit {thresholdVerb} ({limit.value})
                    </Text.H3>
                    <Text.Body className='text-textPrimary'>
                        {userCanUpgrade ? 'You can upgrade to increase your limit and' : 'Please contact your administrator to increase your limit and'} {feature.upgradeMessage}
                    </Text.Body>
                </div>
                <div className='ml-auto'>
                    {userCanUpgrade && (
                        <Button 
                            variant={'upgrade'}
                            onClick={() => nav('/usage')}
                        >
                            Upgrade
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
};