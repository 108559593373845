import { cn } from '@/lib/cn';
import { useAppContext } from 'contexts/AppContext';
import Logo from 'images/squaredup-logo-full.svg?react';
import { FC, useState } from 'react';
import { NavLink, useLocation, useSearchParams } from 'react-router-dom';
import SettingsControls from './SettingsControls';
import { SettingsNavigation } from './SettingsNavigation';
import { SidebarToggle } from './SidebarToggle';
import UpgradeControl from './UpgradeControl';
import { SearchTrigger } from './components/SearchTrigger';
import { WorkspaceNavigation } from './components/workspaces/WorkspaceNavigation';

interface NavBarProps {}

const NavBar: FC<NavBarProps> = () => {
    const { pathname } = useLocation();
    const [params] = useSearchParams();
    const { isNarrowLayout } = useAppContext();

    const [navOpen, setNavOpen] = useState<boolean>(isNarrowLayout ? false : !params.get('hideNav'));

    const isSettingsPage = pathname.includes('/settings/');

    return (
        <>
            <SidebarToggle navOpen={navOpen} setNavOpen={setNavOpen} />

            <nav
                id='navbar'
                data-theme='dark'
                className={cn(
                    'relative flex text-sm flex-col shrink-0 max-w-[250px] text-textPrimary h-full select-none isolate bg-tileBackground z-50 border-r border-dividerPrimary transition-all duration-200 willChangeWidth w-full',
                    !navOpen && 'max-w-0 overflow-hidden border-r-0'
                )}
                data-testid='nav-bar'
            >
                <div
                    className={cn(
                        'flex flex-col w-full h-full transition-opacity pt-[20px] opacity-0 duration-75',
                        navOpen && 'opacity-100 duration-200 delay-200'
                    )}
                >
                    <NavLink
                        to='/status?skip-single-workspace-redirect=true'
                        className='w-0 pl-[1.125rem] mb-5 text-textPrimary'
                    >
                        <Logo className='h-[40.5px]' data-testid='logo' />
                    </NavLink>

                    {isSettingsPage ? (
                        <SettingsNavigation />
                    ) : (
                        <>
                            <SearchTrigger />
                            <WorkspaceNavigation />
                        </>
                    )}

                    <SettingsControls isSettingsPage={isSettingsPage} />
                    <UpgradeControl />
                </div>
            </nav>
        </>
    );
};

NavBar.propTypes = {};

export default NavBar;
