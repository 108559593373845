import { buttonVariants } from '@/components/Button';
import { Skeleton } from '@/components/Skeleton';
import Text from '@/components/Text';
import { faBuildings, faGlobe, faLink, faMoonStars, faSunBright } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSharingEnvironment, isShareRestricted, isSharingEnabledForShare } from '@squaredup/open-access';
import Copy from 'components/Copy';
import { Presence } from 'components/Presence';
import DropdownMenu from 'components/dropdownMenu';
import Input from 'components/forms/input/Input';
import { FeatureUnavailablePill } from 'components/plans/FeatureUnavailablePill';
import Tooltip from 'components/tooltip/Tooltip';
import { useAppContext } from 'contexts/AppContext';
import { useDashboardContext } from 'contexts/DashboardContext';
import type { OpenAccessShare } from 'dynamo-wrapper';
import { useAuthenticatedDomains } from 'queries/hooks/useAuthenticatedDomains';
import { useCreateDashboardSharingLink } from 'queries/hooks/useCreateDashboardSharingLink';
import { useDashboardSharingLink } from 'queries/hooks/useDashboardSharingLink';
import { useTenant } from 'queries/hooks/useTenant';
import { useUpdateDashboardSharingLink } from 'queries/hooks/useUpdateDashboardSharingLink';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { ComponentPropsWithoutRef, FormEvent, MouseEvent, PropsWithChildren, Suspense, useState } from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useIsMutating } from 'react-query';
import { NavLink } from 'react-router-dom';
import { IsTenantAdmin, useWorkspaceCanWrite } from 'services/AccessControlService';
import { useDashboardId } from 'ui/hooks/useDashboardId';
import { z } from 'zod';
import { setVariableParamsOnUrlSearchParams } from '../components/utils/useSetVariableSearchParams';

export const DashboardLinkSharing = () => {
    return (
        <>
            <Text.H4 className='font-semibold'>
                Link sharing <ProPill /> <DisabledText />
            </Text.H4>

            <Text.Body className='text-textSecondary'>
                View-only link to this dashboard without requiring a paid user license. <SharingSettingLink />
            </Text.Body>

            <Suspense fallback={<LinkSharingFormSkeleton />}>
                <LinkSharingForm />
            </Suspense>
        </>
    );
};

const ProPill = () => (
    <FeatureUnavailablePill
        featureKey='openAccess'
        title='Unlimited dashboard viewers'
        summary='Configure and manage unlimited sharing of view-only dashboards.'
        className='ml-3'
    />
);

const DisabledText = () => {
    const tenant = useTenant();
    const sharingEnv = getSharingEnvironment(tenant.data);
    const appCtx = useAppContext();
    const workspace = useWorkspace(appCtx.currentWorkspaceID);

    const dashboardSharingOn = Boolean(workspace.data?.data.properties?.openAccessEnabled);
    const publicSharingOn = sharingEnv.tenantSharingSettings.publicSharesEnabled;
    const orgSharingOn = sharingEnv.tenantSharingSettings.authenticatedSharesEnabled;

    if (sharingEnv.isSharingInPlan && (orgSharingOn || publicSharingOn) && !dashboardSharingOn) {
        return <>(disabled for this workspace)</>;
    }

    if (sharingEnv.isSharingInPlan && !publicSharingOn && !orgSharingOn) {
        return <>(disabled by admin)</>;
    }

    return null;
};

const SharingSettingLink = () => {
    const tenant = useTenant();
    const sharingEnv = getSharingEnvironment(tenant.data);
    const { currentWorkspaceID: workspaceId } = useAppContext();
    const workspace = useWorkspace(workspaceId);

    const isTenantAdmin = Boolean(IsTenantAdmin(tenant.data));
    const dashboardSharingOn = Boolean(workspace.data?.data.properties?.openAccessEnabled);
    const publicSharingOn = sharingEnv.tenantSharingSettings.publicSharesEnabled;
    const orgSharingOn = sharingEnv.tenantSharingSettings.authenticatedSharesEnabled;

    if (!isTenantAdmin) {
        return null;
    }

    if (sharingEnv.isSharingInPlan && (orgSharingOn || publicSharingOn) && !dashboardSharingOn) {
        return (
            <NavLink
                to={`/workspace/${workspace.data?.id}?workspace-settings-tab=access-control`}
                className={buttonVariants({ variant: 'link' })}
                target='_blank'
            >
                Settings
            </NavLink>
        );
    }

    return (
        <NavLink to='/settings/openaccess' className={buttonVariants({ variant: 'link' })} target='_blank'>
            Settings
        </NavLink>
    );
};

const sharingOptions = z.enum(['anyone', 'organization', 'off']);
type SharingOption = z.infer<typeof sharingOptions>;
type LinkSharingFormData = {
    linkSharingGroup: SharingOption;
    theme: 'dark' | 'light';
};

const useLinkSharingForm = () => {
    const appCtx = useAppContext();
    const tenant = useTenant();
    const workspace = useWorkspace(appCtx.currentWorkspaceID);
    const sharingEnv = getSharingEnvironment(tenant.data);
    const workspaceCanWrite = useWorkspaceCanWrite(workspace.data?.id);

    const publicSharingOn = sharingEnv.tenantSharingSettings.publicSharesEnabled;
    const orgSharingOn = sharingEnv.tenantSharingSettings.authenticatedSharesEnabled;
    const dashboardSharingOn = Boolean(workspace.data?.data.properties?.openAccessEnabled);
    const viewer = !workspaceCanWrite.data;
    const dashboardId = useDashboardId();
    const dashboardSharingLink = useDashboardSharingLink({ id: dashboardId ?? '', suspense: true });

    const getDefaultFormValues = (): LinkSharingFormData => {
        if (!workspace.data) {
            return { linkSharingGroup: 'off', theme: 'dark' };
        }

        if (!isSharingEnabledForShare(sharingEnv, workspace.data, dashboardSharingLink.data).isEnabled) {
            return { linkSharingGroup: 'off', theme: 'dark' };
        }

        if (isShareRestricted(dashboardSharingLink.data, sharingEnv.tenantSharingSettings)) {
            return { linkSharingGroup: 'organization', theme: 'dark' };
        }

        return { linkSharingGroup: 'anyone', theme: 'dark' };
    };

    return {
        defaultValues: getDefaultFormValues(),
        isAnyoneOptionDisabled: viewer || !publicSharingOn || !dashboardSharingOn,
        isOrganizationOptionDisabled: viewer || !orgSharingOn || !dashboardSharingOn,
        isOffOptionDisabled: viewer || !dashboardSharingOn || (!publicSharingOn && !orgSharingOn)
    };
};

const useDashboardSharingManager = () => {
    const placeholderDashboardUrl = 'https://app.squaredup.com/a-special-link-to-share-with-anyone';
    const { variables = [] } = useDashboardContext();

    const dashboardId = useDashboardId();
    const dashboardSharingLink = useDashboardSharingLink({ id: dashboardId ?? '', suspense: true });

    const createSharingLink = useCreateDashboardSharingLink();
    const updateSharingLink = useUpdateDashboardSharingLink();

    const appCtx = useAppContext();
    const formCtx = useFormContext<LinkSharingFormData>();
    const workspace = useWorkspace(appCtx.currentWorkspaceID);

    const generateLink = (selectedSharingOption: SharingOption) => {
        if (!workspace.data || !dashboardId) {
            return;
        }

        if (!dashboardSharingLink.data) {
            createSharingLink.mutate({
                workspaceId: workspace.data.id,
                dashboardId: dashboardId,
                requireAuth: selectedSharingOption === 'organization'
            });
        } else {
            updateSharingLink.mutate({
                id: dashboardSharingLink.data.id,
                requireAuth: selectedSharingOption === 'organization',
                enabled: selectedSharingOption !== 'off'
            });
        }
    };

    const getShareUrl = (shareId: OpenAccessShare['id']['value'] | undefined) => {
        if (!shareId) {
            return null;
        }

        const rawOpenAccessId = shareId.replace('openacc-', '');
        const dashboardTheme = formCtx?.getValues('theme') ?? 'dark';

        const urlParams = new URLSearchParams();

        urlParams.set('theme', dashboardTheme);

        setVariableParamsOnUrlSearchParams(urlParams, variables);

        const { protocol, host } = window.location;

        return `${protocol}//${host}/openaccess/${rawOpenAccessId}?${urlParams.toString()}`;
    };

    return {
        generateLink,
        placeholderLink: placeholderDashboardUrl,
        shareLink: getShareUrl(dashboardSharingLink.data?.id)
    };
};

const LinkSharingForm = () => {
    const linkSharingForm = useLinkSharingForm();
    const dashboardSharingManager = useDashboardSharingManager();
    const form = useForm<LinkSharingFormData>({ defaultValues: linkSharingForm.defaultValues });
    const selectedLink = form.getValues('linkSharingGroup');

    const changeHandler = (e: FormEvent) => {
        if (!(e.target instanceof HTMLInputElement)) {
            return;
        }

        const selectedSharingOption = sharingOptions.parse(e.target.value);

        dashboardSharingManager.generateLink(selectedSharingOption);
    };

    return (
        <FormProvider {...form}>
            <Form onChange={changeHandler}>
                <Input
                    type='radio'
                    name='linkSharingGroup'
                    value='anyone'
                    label='Anyone with the link'
                    className='leading-input bg-componentBackgroundSecondary [&>label]:py-radioWrapper [&>label]:px-md [&>label]:w-full flex items-center'
                    icon={faGlobe}
                    disabled={linkSharingForm.isAnyoneOptionDisabled}
                    aria-checked={selectedLink === 'anyone'}
                />
                <Input
                    type='radio'
                    name='linkSharingGroup'
                    value='organization'
                    labelNode={
                        <>
                            Anyone in my organization (restricted by{' '}
                            <AuthenticatedDomainsTooltip>email domains</AuthenticatedDomainsTooltip>)
                        </>
                    }
                    className='leading-input bg-componentBackgroundSecondary [&>label]:py-radioWrapper [&>label]:px-md [&>label]:w-full flex items-center'
                    icon={faBuildings}
                    disabled={linkSharingForm.isOrganizationOptionDisabled}
                    aria-checked={selectedLink === 'organization'}
                />
                <Input
                    type='radio'
                    name='linkSharingGroup'
                    value='off'
                    label='Off'
                    className='leading-input bg-componentBackgroundSecondary [&>label]:py-radioWrapper [&>label]:px-md'
                    disabled={linkSharingForm.isOffOptionDisabled}
                    aria-checked={selectedLink === 'off'}
                />
            </Form>

            <CopyDashboardLink />
        </FormProvider>
    );
};

const CopyDashboardLink = () => {
    const dashboardSharingManager = useDashboardSharingManager();
    const tenant = useTenant();
    const sharingEnv = getSharingEnvironment(tenant.data);

    const formCtx = useFormContext<LinkSharingFormData>();
    const isCopyLinkDisabled = formCtx.getValues('linkSharingGroup') === 'off';

    const creatingNewLink = Boolean(useIsMutating({ mutationKey: dashboardQueryKeys.openAccessNewLink }));

    const dashboardLink = isCopyLinkDisabled
        ? dashboardSharingManager.placeholderLink
        : dashboardSharingManager.shareLink;

    if (!sharingEnv.isSharingInPlan) {
        return null;
    }

    return (
        <div className='flex gap-5 mt-3' data-testid='copyDashboardLink'>
            <Input
                type='text'
                name='dashboardUrl'
                value={dashboardLink}
                disabled={isCopyLinkDisabled || creatingNewLink}
                wrapperClassName='flex-1'
                onClick={(e: MouseEvent<HTMLInputElement>) => e.currentTarget?.select()}
                append={<ThemeDropdown />}
            />

            <Copy
                value={dashboardLink}
                variant='primary'
                icon={faLink}
                disabled={isCopyLinkDisabled || creatingNewLink}
                isLoading={creatingNewLink}
            >
                Copy link
            </Copy>
        </div>
    );
};

const LinkSharingFormSkeleton = () => {
    const tenant = useTenant();
    const sharingEnv = getSharingEnvironment(tenant.data);

    return (
        <>
            <Skeleton className='h-36' />

            <Presence isPresent={sharingEnv.isSharingInPlan}>
                <div className='flex gap-5 mt-3 group'>
                    <Skeleton className='flex-1 h-12' />

                    <Copy value={null} variant='primary' icon={faLink} disabled={true}>
                        Copy link
                    </Copy>
                </div>
            </Presence>
        </>
    );
};

const Form = (props: ComponentPropsWithoutRef<'form'>) => {
    return (
        <form {...props} className='flex flex-col mt-1 overflow-hidden divide-y rounded-md divide-dividerTertiary' />
    );
};

const ThemeDropdown = () => {
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);
    const formCtx = useFormContext<LinkSharingFormData>();

    return (
        <Controller<Pick<LinkSharingFormData, 'theme'>>
            name='theme'
            render={({ field }) => (
                <DropdownMenu onOpenChange={setIsDropdownOpened}>
                    <DropdownMenu.Button
                        dropdownOpen={isDropdownOpened}
                        className='bg-transparent mr-2 ring-0 hover:bg-outlinePrimary p-2 aria-expanded:bg-outlinePrimary [&>span]:flex ml-auto'
                        aria-label='change theme'
                        disabled={formCtx.getValues('linkSharingGroup') === 'off'}
                    >
                        <FontAwesomeIcon icon={field.value === 'dark' ? faMoonStars : faSunBright} />
                    </DropdownMenu.Button>
                    <DropdownMenu.Menu align='end'>
                        <DropdownMenu.CheckItem
                            checked={field.value === 'dark'}
                            onSelect={() => field.onChange('dark')}
                        >
                            <FontAwesomeIcon icon={faMoonStars} />
                            <span className='ml-3 mr-7'>Dark mode</span>
                        </DropdownMenu.CheckItem>
                        <DropdownMenu.CheckItem
                            checked={field.value === 'light'}
                            onSelect={() => field.onChange('light')}
                        >
                            <FontAwesomeIcon icon={faSunBright} />
                            <span className='ml-3 mr-7'>Light mode</span>
                        </DropdownMenu.CheckItem>
                    </DropdownMenu.Menu>
                </DropdownMenu>
            )}
        />
    );
};

const AuthenticatedDomainsTooltip = (props: PropsWithChildren<object>) => {
    const domains = useAuthenticatedDomains();

    return (
        <Tooltip
            title={
                <>
                    <p>Requires authentication with an email from the following domain(s):</p>
                    <ul>
                        {domains.map((domain) => (
                            <li key={domain}>{domain}</li>
                        ))}
                    </ul>
                </>
            }
            className='inline'
            tooltipClassName='max-w-none -translate-x-1'
        >
            <span className='underline decoration-dashed decoration-textDisabled underline-offset-2'>
                {props.children}
            </span>
        </Tooltip>
    );
};
