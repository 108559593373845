import { EntityTypes } from '@squaredup/constants';
import { dashboardToNode } from './dashboardToNode';
import { objectToNode } from './objectToNode';
import { workspaceToNode } from './workspaceToNode';
import { monitorToNode } from './monitorToNode';

/**
 * utility wrapper to correctly select the type of node being added to the graph (based on type)
 */
export const entityToNode = (node: any) => {
    switch (node.type?.[0]) {
        case EntityTypes.DASHBOARD:
            return dashboardToNode(node);
        case EntityTypes.WORKSPACE:
            return workspaceToNode(node);
        case EntityTypes.MONITOR:
            // We only want squaredup moinitors to show state and be treated as an internal monitor
            if (node.sourceType?.[0] === 'squaredup/monitor') {
                return monitorToNode(node);
            }
            return objectToNode(node);
        default:
            return objectToNode(node);
    }
};
