import { AccessControlType } from '@squaredup/constants';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { DashboardId } from '@squaredup/ids';
import { useLinkedPluginConfigs } from 'components/hooks/useLinkedPluginConfigs';
import { usePluginPermissions } from 'components/hooks/usePluginPermissions';
import { useWorkspacePermissions } from 'components/hooks/useWorkspacePermissions';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import { DashboardType } from 'dashboard-engine/types/Dashboard';
import { groupBy } from 'lodash';
import { useWorkspaces } from 'queries/hooks/useWorkspaces';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { Create, Get, List, Update } from 'services/DashboardService';
import { NO_ACTIVE_WORKSPACE } from 'services/WorkspaceUtil';
import useAddDataSources from 'ui/editor/dataStream/TileEditor/steps/DataSourceModal/useAddDatasources';
import { generateDashboardForTile, generateTileForConfig } from './generateTileConfig';
import { useTileDatasources } from './useTileDatasources';

export const CREATE_NEW_DASHBOARD = 'create_new_dashboard' as const;

type SaveToDashboardFormData = {
    targetWorkspace: string;
    targetDashboard: string;
};

type WorkspaceOption = {
    label: string;
    value: string;
};

/**
 * Encapsulates the form logic and state for saving to a dashboard
 * @param config Tile config
 * @returns Form state and data
 */
export const useSaveToDashboardForm = (
    config: DataStreamBaseTileConfig,
    currentDashboard?: DashboardId['value'],
    workspaceOverride?: string,
    isGlobal?: boolean
) => {
    const queryClient = useQueryClient();
    const { workspace: dataStreamWorkspace } = useDataStreamWorkspaceContext();
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();

    const workspace = workspaceOverride ?? dataStreamWorkspace;

    // Fetch workspaces & permissions
    const { canWrite, isLoading: isLoadingWorkspacePermissions } = useWorkspacePermissions();
    const { canRead, isLoading: isLoadingPluginPermissions } = usePluginPermissions();
    const { data: workspaceWithWritePermissions, isLoading: isLoadingWorkspaces } = useWorkspaces({
        enabled: Boolean(!isLoadingWorkspacePermissions && canWrite),
        select: (data) => data?.filter((w) => canWrite(w))
    });

    const workspaceOptions = workspaceWithWritePermissions
        ?.map((w) => ({
            label: w.id === workspace ? `Current workspace (${w.displayName})` : w.displayName,
            value: w.id
        }))
        ?.sort((a: WorkspaceOption, b: WorkspaceOption) => a.label.localeCompare(b.label));

    const tileDatasources = useTileDatasources(config, isGlobal ? NO_ACTIVE_WORKSPACE : workspace);
    const currentWorkspace = workspaceWithWritePermissions?.find(({ id }) => id === workspace);
    const canWriteToCurrentWorkspace = currentWorkspace ? canWrite(currentWorkspace) : undefined;

    // Setup form
    const form = useForm<SaveToDashboardFormData>({
        shouldUnregister: true,
        mode: 'all',
        defaultValues: {
            targetWorkspace: canWriteToCurrentWorkspace ? workspace ?? '' : undefined,
            targetDashboard: CREATE_NEW_DASHBOARD
        }
    });

    const {
        formState: { isValid, errors, isSubmitting },
        handleSubmit,
        watch,
        resetField
    } = form;

    // Setup watcher for selected workspace ID
    const selectedWorkspace = watch('targetWorkspace', workspace || undefined);
    const selectedWorkspaceDisplayName = workspaceOptions?.find(({ value }) => value === selectedWorkspace)?.label;

    const { addDataSources } = useAddDataSources({ workspaceId: selectedWorkspace });

    useEffect(() => resetField('targetDashboard'), [selectedWorkspace, resetField]);

    // Load linked plugins
    const { data: linkedPlugins, isLoading: isLoadingLinkedPluginConfigs } = useLinkedPluginConfigs(selectedWorkspace);

    const areDatasourcesLinked = tileDatasources?.every(
        (configDatasource) => linkedPlugins?.some((l) => !configDatasource || l.id === configDatasource)
    );

    const areDatasourcesPermitted = tileDatasources.every(
        (tileDatasource) => !tileDatasource || canRead(tileDatasource)
    );

    // Fetch dashboards for the given workspace ID
    const { data: dashboards, isLoading: isLoadingDashboards } = useQuery(['DASHBOARDS'], List, {
        select: (data) => {
            const availableDashboards = data.filter((dashboard) =>
                currentDashboard ? dashboard.id !== currentDashboard : true
            );

            return (
                groupBy(availableDashboards, 'workspaceId')?.[selectedWorkspace]?.map((d: DashboardType) => ({
                    label: d.displayName,
                    value: d.id
                })) || []
            );
        }
    });

    // Setup mutation handler for saving
    const handleSave = useMutation(async ({ targetWorkspace, targetDashboard }: SaveToDashboardFormData) => {
        /**
         * Remove any access control permissions as the addition of datasources to the target
         * workspace removes the need for it
         */
        const { accessControlType, ...dataStreamConfig } =
            config.dataStream as DataStreamBaseTileConfig['dataStream'] & { accessControlType: AccessControlType };
        const configToSave = { ...config, dataStream: dataStreamConfig };

        setIsSaving(true);
        if (targetDashboard === CREATE_NEW_DASHBOARD) {
            // Create new dashboard
            const created = await Create(targetWorkspace, undefined, generateDashboardForTile(configToSave));

            // Add any missing data sources
            if (!areDatasourcesLinked && tileDatasources.length) {
                await addDataSources(tileDatasources);
            }

            navigate('/dashboard/' + created.id);
        } else {
            // Fetch and update existing dashboard
            const existing = (await Get(targetDashboard)) as DashboardType;
            const y = Math.max(...existing.content.contents.map((t) => t.y + t.h));
            const tile = generateTileForConfig(configToSave);
            tile.y = y;
            existing.content.contents.push(tile);

            //Save, update cached data and navigate
            const updated = await Update(targetDashboard, existing);
            queryClient.setQueryData(dashboardQueryKeys.detail(targetDashboard), updated);

            // Add any missing data sources
            if (!areDatasourcesLinked && tileDatasources.length) {
                await addDataSources(tileDatasources);
            }

            navigate(`/dashboard/${targetDashboard}?tile=${tile.i}`);
        }
    });

    const isLoading =
        isLoadingWorkspaces || isLoadingWorkspacePermissions || isLoadingPluginPermissions || isLoadingDashboards;

    const isDisabled =
        isLoading ||
        isSubmitting ||
        !isValid ||
        (!areDatasourcesPermitted && !areDatasourcesLinked) ||
        Object.keys(errors).length > 0;

    const formHandler = handleSubmit((data) => handleSave.mutateAsync(data));

    return {
        isLoading,
        isLoadingDashboards,
        isLoadingLinkedPluginConfigs,
        isSubmitting,
        isDisabled,
        isSaving,
        form,
        workspaces: workspaceOptions,
        selectedWorkspace,
        selectedWorkspaceDisplayName,
        dashboards,
        areDatasourcesLinked,
        areDatasourcesPermitted,
        formHandler
    };
};
