export interface LinkInfo {
    isExternal: boolean,
    relativeURL: string
}

/**
 * Get basic info about the link, including whether it's internal or external,
 * and the relative URL (regardless of whether it's internal or external).
 * 
 * Relative URLs are always internal, e.g. /foo/var.
 * 
 * Absolute URLs can be internal or external, depending on whether the host matches the
 * current browser host.
 */
export function getLinkInfo(link: string, externalRelativeToOrigin = window.location.origin): LinkInfo {
    return link ? {
        isExternal: isExternalURL(link.trim(), externalRelativeToOrigin),
        relativeURL: link ? getRelativeURL(link.trim()) : ''
    } : {
        isExternal: false,
        relativeURL: ''
    };
}

function isExternalURL(url: string, externalRelativeToOrigin = window.location.origin) {
    try {
        return isAbsoluteURL(url) && new URL(url).origin !== externalRelativeToOrigin;
    } catch {
        return false;
    }
}

function isAbsoluteURL(url: string) {
    return url?.indexOf('://') > 0;
}

function getRelativeURL(link: string) {
    try {
        if (isAbsoluteURL(link)) {
            const url = new URL(link);
            return `${url.pathname}${url.search}`;
        } 
        return link;
    } catch {
        return link;
    }
}
