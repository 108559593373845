import { faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { KPI_TYPES, ListTypes } from 'services/KPIService';
import { SettingsTemplate } from '../SettingsTemplate';
import KPITypeDeleteModal from './KPITypeDeleteModal';
import KPITypeModal from './KPITypeModal';

function KPITypes() {
    const [typeBeingDeleted, setTypeBeingDeleted] = useState(null);
    const [typeAddEditModalOpen, setTypeAddEditModalOpen] = useState(false);
    const [typeBeingEdited, setTypeBeingEdited] = useState(false);

    const { isLoading, data: types, refetch } = useQuery(KPI_TYPES, ListTypes);

    const handleClose = async (callback) => {
        refetch();
        callback();
    };

    return (
        <SettingsTemplate
            title='KPI Types'
            description='Configure types to be used for KPIs'
        >
            {isLoading && (
                <span className='flex justify-center'>
                    <LoadingSpinner />
                </span>
            )}
            {!isLoading && (
                <div>
                    <Button onClick={() => setTypeAddEditModalOpen(true)} data-testid='addKPIType'>
                        Add new KPI type
                    </Button>

                    <div className='max-w-4xl'>
                        {types.map((type) => (
                            <KPIType
                                key={type.id}
                                type={type}
                                onEdit={() => {
                                    setTypeBeingEdited(type);
                                    setTypeAddEditModalOpen(true);
                                }}
                                onDelete={() => setTypeBeingDeleted(type)}
                            />
                        ))}
                    </div>
                </div>
            )}

            {typeBeingDeleted && (
                <KPITypeDeleteModal
                    type={typeBeingDeleted}
                    close={() => handleClose(() => setTypeBeingDeleted(null))}
                />
            )}

            {typeAddEditModalOpen && (
                <KPITypeModal
                    type={typeBeingEdited}
                    close={() =>
                        handleClose(() => {
                            setTypeBeingEdited(null);
                            setTypeAddEditModalOpen(false);
                        })
                    }
                />
            )}
        </SettingsTemplate>
    );
}

function KPIType({ type, onEdit, onDelete }) {
    return (
        <>
            <div className='p-5 pb-4 my-4 border rounded-input bg-componentBackgroundSecondary border-outlinePrimary' data-testid='kpiType'>
                <div className='flex items-center'>
                    <span className='mr-3 text-base font-semibold truncate'>{type.displayName}</span>

                    <span className='flex items-center ml-auto text-xl text-textSecondary'>
                        {onEdit && (
                            <FontAwesomeIcon
                                icon={faPencil}
                                title='Edit'
                                className='mx-2 cursor-pointer hover:text-textPrimary'
                                onClick={onEdit}
                                data-testid='editKPIType'
                            />
                        )}
                        {onDelete && (
                            <FontAwesomeIcon
                                icon={faTrash}
                                title='Delete'
                                className='ml-2 cursor-pointer hover:text-textPrimary'
                                onClick={onDelete}
                                data-testid='deleteKPIType'
                            />
                        )}
                    </span>
                </div>
            </div>
        </>
    );
}

export default KPITypes;
