import { Switch } from '@/components/Switch';
import { faComputer, faMoonStars, faSunBright } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import trackEvent from 'lib/analytics';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { SetUserForTenantProperty } from 'services/UserService';
import { THEME, ThemeOptions } from 'ui/hooks/useTheme';

const themeSwitchOptions = [
    {
        value: ThemeOptions.system,
        label: (
            <span className='inline-flex items-center space-x-2 align-middle'>
                <FontAwesomeIcon size='lg' icon={faComputer} />
                <span>System</span>
            </span>
        )
    },
    {
        value: ThemeOptions.dark,
        label: (
            <span className='inline-flex items-center space-x-2 align-middle'>
                <FontAwesomeIcon size='lg' icon={faMoonStars} />
                <span>Dark</span>
            </span>
        )
    },
    {
        value: ThemeOptions.light,
        label: (
            <span className='inline-flex items-center space-x-2 align-middle'>
                <FontAwesomeIcon size='lg' icon={faSunBright} />
                <span>Light</span>
            </span>
        )
    }
];

export const ThemeChooser: React.FC = () => {
    const [params] = useSearchParams();
    const queryClient = useQueryClient();
    const forcedTheme = ['dark', 'light'].includes(params.get('theme') || '');

    const [theme, setTheme] = useState<ThemeOptions>(() => queryClient.getQueryData([THEME]) as ThemeOptions);

    const { mutate: saveTheme } = useMutation((newTheme: ThemeOptions) => SetUserForTenantProperty('theme', newTheme), {
        async onMutate(newTheme) {
            // Cancel previous queries and set the theme in the cache
            // Note: I'm not returning the old theme for error handling as I don't want the UI to jump around.
            queryClient.cancelQueries([THEME]);
            queryClient.setQueryData([THEME], newTheme);

            setTheme(newTheme);

            trackEvent('Theme Changed', { theme: newTheme });
        }
    });

    return (
        <Switch
            value={theme}
            options={themeSwitchOptions}
            disabled={forcedTheme}
            onValueChange={(selectedTheme) => {
                if (selectedTheme) {
                    saveTheme(selectedTheme as ThemeOptions);
                }
            }}
        />
    );
};
