import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faRotate } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Popover from '@radix-ui/react-popover';
import LoadingSpinner from 'components/LoadingSpinner';
import Button, { buttonVariants } from 'components/button/Button';
import { Pill, pillStatusText } from 'components/pill/Pill';
import { formatDistanceToNow } from 'date-fns';
import type { ImportStatus } from 'dynamo-wrapper';
import { useDatasourceDocumentationLink } from 'queries/hooks/useDatasourceDocumentationLink';

export type ImportStatusType = ImportStatus['status'] | 'warnings';

interface ErrorDetailProps {
    message?: string;
    warnings?: string[];
    correlationId?: string;
    originalError?: string;
    pluginId?: string;
    pluginName?: string;
    triggerClassOverride?: string;
    className?: string;
}

export function ErrorDetails({
    warnings,
    message,
    correlationId,
    originalError,
    pluginId,
    className
}: ErrorDetailProps) {
    const pluginLinkRequired = Boolean(message);
    const { data: pluginLink } = useDatasourceDocumentationLink(pluginLinkRequired ? pluginId : undefined);
    const hasWarnings = warnings && warnings.length > 0;

    return (
        <dl className={cn(className)}>
            {message && (
                <>
                    <dd className='overflow-auto break-words max-h-36 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                        ERROR: {message}
                        {correlationId && correlationId !== 'unknown' && ` (Support Code: ${correlationId})`}
                        {originalError && (
                            <pre className='block pl-4 mt-2 whitespace-normal border-l-4 border-dividerPrimary'>
                                {originalError}
                            </pre>
                        )}
                    </dd>

                    <div className={cn('flex space-x-4', hasWarnings ? 'mb-2' : 'mt-2')}>
                        <a
                            className={buttonVariants({ variant: 'link' })}
                            href={pluginLink || 'https://squaredup.com/cloud/plugins'}
                            target='_blank'
                            rel='noreferrer'
                        >
                            Help article
                        </a>
                        <a
                            className={buttonVariants({ variant: 'link' })}
                            href='https://docs.squaredup.com/submit-ticket'
                            target='_blank'
                            rel='noreferrer'
                        >
                            Contact support
                        </a>
                    </div>
                </>
            )}

            {hasWarnings && (
                <>
                    {warnings.map((w, i) => (
                        <dd
                            className='overflow-auto break-words max-h-36 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'
                            key={`warning-${i}`}
                        >
                            WARNING: {w}
                        </dd>
                    ))}
                </>
            )}
        </dl>
    );
}

export const DetailsPopover: React.FC<ErrorDetailProps> = ({
    message,
    warnings,
    correlationId,
    originalError,
    pluginId,
    pluginName,
    triggerClassOverride
}) => {
    return (
        <Popover.Root>
            <Popover.Trigger className={cn('text-textSecondary hover:text-textPrimary', triggerClassOverride)}>
                <Text.Body>More Info</Text.Body>
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content
                    align='center'
                    sideOffset={5}
                    className='z-20 max-w-lg p-4 text-sm rounded-input text-primaryButtonText bg-tooltipBackground'
                >
                    <ErrorDetails
                        message={message}
                        warnings={warnings}
                        correlationId={correlationId}
                        originalError={originalError}
                        pluginId={pluginId}
                        pluginName={pluginName}
                    />
                    <Popover.Arrow className='fill-tooltipBackground' height={8} width={16} />
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};

interface ImportStatusProps {
    importStatus: ImportStatus;
    refreshImport: () => void;
    canRefresh?: boolean;
    pluginName?: string;
    pluginId?: string;
    isRefreshing: boolean;
}

export function getSupportingText(status: ImportStatus, noPrefix?: boolean) {
    if (!status) {
        status = { status: 'notRun', started: 0 } as ImportStatus;
    }

    switch (status.status) {
        case 'succeeded':
            return `${noPrefix ? 'Succeeded' : 'Last object indexing succeeded'} ${formatDistanceToNow(
                new Date(status.ended),
                {
                    addSuffix: true
                }
            )}`;
        case 'failed':
            return `${noPrefix ? 'Failed' : 'Last object indexing failed'} ${formatDistanceToNow(
                new Date(status.ended),
                {
                    addSuffix: true
                }
            )}`;
        case 'notRun':
            return noPrefix ? 'Not yet run' : 'Object indexing not yet run';

        default:
            return `${noPrefix ? 'Started' : 'Object indexing started'} ${formatDistanceToNow(
                new Date(status.started),
                {
                    addSuffix: true
                }
            )}`;
    }
}

export function ImportInfo({ importStatus, refreshImport, canRefresh, isRefreshing, pluginId }: ImportStatusProps) {
    const status = importStatus?.status
        ? (importStatus.status === 'succeeded' && (importStatus.totalWarningCount ? 'warnings' : 'succeeded')) ||
          importStatus.status
        : 'notRun';

    return (
        <div className='flex items-center space-x-4'>
            <Pill.Status data-testid='importStatusPill' status={status}>
                {pillStatusText[status]}
            </Pill.Status>
            {((Array.isArray(importStatus.warnings) && importStatus.totalWarningCount > 0) ||
                importStatus?.message) && (
                <DetailsPopover
                    triggerClassOverride='underline ml-4'
                    warnings={importStatus?.warnings}
                    message={importStatus?.message}
                    correlationId={importStatus?.correlationId}
                    originalError={importStatus?.originalError}
                    pluginId={pluginId}
                />
            )}
            <Text.Body className='text-textSecondary'>{getSupportingText(importStatus)}</Text.Body>
            {canRefresh && !isRefreshing && (
                <Button
                    onClick={() => refreshImport()}
                    variant='tertiary'
                    title='Refresh index'
                    className='inline-block'
                >
                    <FontAwesomeIcon icon={faRotate} />
                </Button>
            )}
            {isRefreshing && <LoadingSpinner size={18} />}
        </div>
    );
}
