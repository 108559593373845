import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { LoadingWrapper } from 'components/LoadingWrapper';
import { DataStreamErrors } from 'dashboard-engine/dataStreams/DataStreamErrors';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import DataStreamTable from 'dashboard-engine/visualisations/DataStreamTable/DataStreamTable';
import { useTileEditorContext } from '../../contexts/TileEditorContext';

interface DataTableProps {
    config?: DataStreamBaseTileConfig;
    rawHeaderLabels?: boolean;
}

export const DataTable: React.FC<DataTableProps> = ({ config, rawHeaderLabels }) => {
    const { tileConfig } = useTileEditorContext();
    const tableConfig = config ?? tileConfig;

    const { data, isLoading, isError, error } = useDataStreamConfig(tableConfig);

    return (
        <div className='h-full p-4'>
            <LoadingWrapper loading={isLoading}>
                <>
                    {isError && (
                        <div className='flex flex-col justify-center h-full'>
                            <DataStreamErrors error={error} />
                        </div>
                    )}

                    {data && (
                        <DataStreamTable
                            data={data}
                            config={{
                                showShapeInTooltip: true,
                                useAccessorHeaderLabels: rawHeaderLabels
                            }}
                        />
                    )}
                </>
            </LoadingWrapper>
        </div>
    );
};
