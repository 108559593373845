import clsx from 'clsx';
import { forwardRef } from 'react';

export const TableHead = forwardRef<HTMLTableCellElement, React.ThHTMLAttributes<HTMLTableCellElement>>(
    ({ className, ...props }, ref) => (
        <th
            ref={ref}
            className={clsx('relative group bg-dividerPrimary px-4 text-left align-middle font-bold', className)}
            {...props}
        />
    )
);
