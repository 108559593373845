import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useInitialVariableSelection = (dashboardId?: string) => {
    const [searchParams] = useSearchParams();

    const nodeIdsParam = searchParams.getAll('nodeId');
    const selectedAll = searchParams.get('selectedAllNodes');

    const initial = useMemo(() => {
        const initialSelection: {
            selectedObjects: { id: string; name: string }[];
            selectedAll?: boolean;
        } = {
            selectedObjects: nodeIdsParam ? nodeIdsParam.map((id) => ({ id, name: '' })) : []
        };

        if (nodeIdsParam?.length > 0) {
            initialSelection.selectedAll = false;
        } else if (selectedAll) {
            initialSelection.selectedAll = true;
        }

        return initialSelection;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardId]);

    return initial;
};
