import { faArrowDownShortWide, faArrowDownWideShort } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedStreamValue } from '@squaredup/data-streams';
import { Column } from '@tanstack/react-table';
import clsx from 'clsx';
import { TruncatedText } from 'components/TruncatedText';
import Button from 'components/button/Button';
import Tooltip from 'components/tooltip/Tooltip';

interface DataTableColumnHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
    column: Column<FormattedStreamValue[], unknown>;
    title: string;
    showShapeInTooltip?: boolean;
    tooltipText: string;
    columnShapeName?: `shape_${string}`;
    columnRole?: string;
}

export const DataTableColumnHeader: React.FC<DataTableColumnHeaderProps> = ({
    column,
    title,
    showShapeInTooltip,
    tooltipText,
    columnShapeName,
    columnRole
}) => {
    if (!column.getCanSort()) {
        return <TruncatedText title={title} className='truncate' />;
    }

    const hasShapeData = columnShapeName || columnRole;

    return (
        <Button
            variant='tertiary'
            className='flex items-center w-full py-2 space-x-2 text-textPrimary hover:text-textPrimary focus:text-textPrimary'
            onClick={() => (column.getIsSorted() !== 'asc' ? column.toggleSorting(false) : column.toggleSorting(true))}
        >
            {showShapeInTooltip ? (
                <Tooltip
                    title={
                        <div className='font-normal'>
                            <p className={clsx('font-bold', showShapeInTooltip && hasShapeData && 'mb-2')}>
                                {tooltipText}
                            </p>

                            <p>
                                <em className='inline-block w-16 '>shape:</em> {columnShapeName}
                            </p>

                            {columnRole !== 'none' && (
                                <p>
                                    <em className='inline-block w-16 '>role:</em> {columnRole}
                                </p>
                            )}
                        </div>
                    }
                    className='truncate'
                >
                    {title}
                </Tooltip>
            ) : (
                <TruncatedText title={title} className='truncate' />
            )}

            {column.getIsSorted() === 'desc' && (
                <FontAwesomeIcon icon={faArrowDownWideShort} className='flex-shrink-0 text-textSecondary' />
            )}
            {column.getIsSorted() === 'asc' && (
                <FontAwesomeIcon icon={faArrowDownShortWide} className='flex-shrink-0 text-textSecondary' />
            )}
        </Button>
    );
};
