import { CSSProperties, ReactElement, ReactNode, memo } from 'react';
import { Slot } from '@radix-ui/react-slot';
import { renderToString } from 'react-dom/server';
import { PlacesType, VariantType } from 'react-tooltip';

export interface TooltipProps {
    title: string | ReactNode;
    placement?: PlacesType;
    disabled?: boolean;
    variant?: VariantType;
    delayShow?: number;
    delayHide?: number;
    offset?: number;
    className?: string;
    tooltipClassName?: string;
    style?: CSSProperties;
    asChild?: boolean;
}

/**
 * @param title string/ReactNode
 * @param children ReactNode
 * @param placement 'bottom' | 'top' | 'left' | 'right'
 * @param disabled boolean
 * @param variant 'dark' | 'light' | 'success' | 'warning' | 'error' | 'info'
 * @param delayShow number
 * @param delayHide number
 * @param offset number
 * @param className css that wraps your tooltip, see StatusIndicator.tsx for example
 * @param tooltipClassName css class name that wraps your tooltip popover
 */
const Tooltip: React.FC<TooltipProps> = memo(
    ({
        title = '',
        children,
        placement = 'bottom',
        disabled = false,
        variant = 'dark',
        delayShow = 200,
        delayHide = 200,
        offset = 13,
        className,
        tooltipClassName = 'max-w-[400px]',
        style,
        asChild = false
    }) => {
        const isRichTooltip = typeof title !== 'string';

        const Comp = asChild ? Slot : 'div';

        return (
            <Comp
                data-tooltip-id='tooltip'
                data-tooltip-content={!isRichTooltip ? title : ''}
                data-tooltip-html={isRichTooltip ? renderToString(title as ReactElement) : undefined}
                data-tooltip-place={placement}
                data-tooltip-hidden={disabled}
                data-tooltip-variant={variant}
                data-tooltip-delay-show={delayShow}
                data-tooltip-delay-hide={delayHide}
                data-tooltip-offset={offset}
                data-tooltip-class-name={tooltipClassName}
                data-testid='tooltip'
                className={className}
                style={style}
            >
                {children}
            </Comp>
        );
    }
);

export default Tooltip;
