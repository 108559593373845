import { cn } from '@/lib/cn';
import Logo from 'images/squaredup-logo-full.svg?react';

export const PoweredByLogo: React.FC<{ isLoading?: boolean; loadingMessage?: string }> = ({
    isLoading,
    loadingMessage
}) => {
    return (
        <div 
            className={cn('flex flex-col justify-center text-textPrimary', isLoading && 'space-y-4' )}
            data-theme='dark'
        >
            <div className={cn('flex items-center justify-center', isLoading && 'scale-150' )}>
                <Logo />
            </div>
            <div className='flex items-center justify-center'>
                {isLoading && <h1 className='mt-1 text-xl'>{loadingMessage}</h1>}
            </div>
        </div>
    );
};
