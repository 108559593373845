import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Popover from '@radix-ui/react-popover';
import Tooltip from 'components/tooltip/Tooltip';
import { useState } from 'react';

export type TileWarning = {
    heading: string;
    message: React.ReactNode;
    raw?: boolean;
};

interface TileWarningPopoverProps {
    warnings: TileWarning[];
}

export const TileWarningPopover: React.FC<TileWarningPopoverProps> = ({ warnings }) => {
    const [popupIsOpen, setPopupIsOpen] = useState(false);

    return (
        <Tooltip
            disabled={popupIsOpen}
            title={`${warnings.length} data stream warning${warnings.length > 1 ? 's' : ''}`}
        >
            <Popover.Root onOpenChange={setPopupIsOpen}>
                <Popover.Trigger asChild>
                    <button>
                        <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            className='z-10 cursor-pointer text-statusWarningPrimary'
                        />
                    </button>
                </Popover.Trigger>

                <Popover.Portal>
                    <Popover.Content sideOffset={5} align='end' className='z-modal'>
                        <div className='p-5 border rounded-input bg-componentBackgroundSecondary border-outlinePrimary w-96 max-h-1/2'>
                            <div className='pr-2 space-y-4 overflow-scroll max-h-72 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                                {warnings.map((warning, i) => (
                                    <div key={`${warning.heading}${i}`}>
                                        <div className='flex flex-col mb-3 space-y-2 items-left text-textPrimary'>
                                            <h5 className='font-semibold'>{warning.heading}</h5>
                                            {!warning.raw ? (
                                                <p>{warning.message}</p>
                                            ) : (
                                                <pre className='whitespace-normal px-sm py-xs bg-tagBackground rounded-input leading-input '>
                                                    {warning.message}
                                                </pre>
                                            )}
                                        </div>
                                        <div className='h-px mt-4 font-normal bg-dividerPrimary' />
                                    </div>
                                ))}
                            </div>
                            <div className='flex justify-between pt-2'>
                                <a
                                    href='https://squaredup.com/cloud/TroubleshootingTiles'
                                    target='_blank'
                                    rel='noreferrer'
                                    className='text-sm text-textLink'
                                >
                                    Learn more about tile warnings.
                                </a>
                            </div>
                        </div>
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        </Tooltip>
    );
};
