import { faRotateLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HealthState } from '@squaredup/monitoring';
import LoadingSpinner from 'components/LoadingSpinner';
import { WorkspaceNetworkContext } from 'contexts/WorkspaceNetworkContext';
import stringify from 'fast-json-stable-stringify';
import { FilterCriteria } from 'pages/monitoring/common';
import type { StatusOverviewProps } from 'pages/status/ui/StatusOverview';
import { useFilteredWorkspaces } from 'pages/status/utils/useFilteredWorkspaces';
import { useState } from 'react';
import { ControlButton } from 'reactflow';
import { NetworkFlow } from './AlternativeNetwork';
import { useDatasourceDetails } from './utils/useDatasourceDetails';
import { useFetchGraphData } from './utils/useFetchGraphData';

type GraphProps = {
    data: string[];
    config: any;
    filterCriteria: FilterCriteria;
    editingEnabled?: boolean;
    stateFilters: HealthState[];
};

export const TenantNetwork: React.FC<
    {
        config: any;
        editingEnabled?: boolean;
        filterCriteria: FilterCriteria;
    } & StatusOverviewProps
> = ({ tags, workspaceTypes, stateFilters, config, filterCriteria, editingEnabled = false }) => {
    const { data: typeTagFilteredWorkspaces, isLoading } = useFilteredWorkspaces(tags, workspaceTypes);

    if ((typeTagFilteredWorkspaces?.length ?? 0) === 0) {
        return (
            <div>
                {isLoading ? (
                    <p className='mt-5 text-textIncomplete'>Loading...</p>
                ) : (
                    <p className='mt-5 text-textIncomplete'>
                        There are no workspaces matching the filter criteria. Try adjusting your filters.
                    </p>
                )}
            </div>
        );
    }

    return (
        <TenantGraphRender
            data={typeTagFilteredWorkspaces?.map((v) => v.id) ?? []}
            config={config}
            filterCriteria={filterCriteria}
            editingEnabled={editingEnabled}
            stateFilters={stateFilters}
        />
    );
};

const TenantGraphRender: React.FC<GraphProps> = ({
    data,
    config,
    filterCriteria,
    stateFilters,
    editingEnabled = false
}) => {
    const [expandedNodeIds, setExpandedNodeIds] = useState<string[]>([]);

    const {
        workspaceNetworkData,
        workspaceNetworkLayout,
        workspacesWithDependencies,
        workspacesWithDependenciesByNodeId,
        isLoading
    } = useFetchGraphData(data, config, filterCriteria, expandedNodeIds, stateFilters);

    const { data: datasourceDetails } = useDatasourceDetails(workspacesWithDependencies ?? []);

    if (isLoading) {
        return (
            <div className='flex items-center justify-center w-full h-full'>
                <LoadingSpinner />
            </div>
        );
    }

    // Return early loading or there is no data (should never happen!)
    if (!isLoading && !workspaceNetworkData) {
        return null;
    }

    const { nodes, edges } = workspaceNetworkLayout || {};

    return (
        <div className='flex flex-col h-full'>
            <WorkspaceNetworkContext.Provider
                value={{
                    workspacesWithDependencies,
                    workspacesWithDependenciesByNodeId,
                    editingEnabled,
                    filterCriteria,
                    setExpandedNodeIds,
                    pluginDetails: datasourceDetails
                }}
            >
                <NetworkFlow
                    key={stringify(data)}
                    nodes={nodes}
                    edges={edges}
                    onClick={undefined}
                    nodeType='kpi'
                    extraControls={
                        <>
                            {expandedNodeIds?.length > 0 && (
                                <ControlButton
                                    onClick={() => {
                                        setExpandedNodeIds([]);
                                    }}
                                    title='Reset nodes'
                                >
                                    <FontAwesomeIcon icon={faRotateLeft} />
                                </ControlButton>
                            )}
                        </>
                    }
                />
            </WorkspaceNetworkContext.Provider>
        </div>
    );
};
