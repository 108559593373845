import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBrowser, faChartMixed, faImage, faText } from '@fortawesome/pro-solid-svg-icons';
import type { DataStreamBaseTileConfig } from '@squaredup/data-streams';

export enum TileTypes {
    dataStream = 'tile/data-stream',
    image = 'tile/image',
    text = 'tile/text',
    embed = 'tile/iframe'
}

export interface TileType {
    icon: IconDefinition;
    displayName: string;
    name: TileTypes;
}

export const tileTypes: (embedFlagEnabled: boolean) => TileType[] = (embedFlagEnabled) => [
    {
        icon: faChartMixed,
        displayName: 'Data',
        name: TileTypes.dataStream
    },
    {
        icon: faImage,
        displayName: 'Image',
        name: TileTypes.image
    },
    {
        icon: faText,
        displayName: 'Text',
        name: TileTypes.text
    },
    ...(embedFlagEnabled
        ? [
              {
                  icon: faBrowser,
                  displayName: 'Embed',
                  name: TileTypes.embed
              }
          ]
        : [])
];

export enum DataStreamTypes {
    scoped = 'scoped',
    scopedConfigurable = 'scopedConfigurable',
    matchesNone = 'matchesNone',
    matchesNoneConfigurable = 'matchesNoneConfigurable',
    SQL = 'sql'
}

export const matchesNoneDataStreamTypes = [DataStreamTypes.matchesNone, DataStreamTypes.matchesNoneConfigurable];
export const configurableDataStreamTypes = [
    DataStreamTypes.matchesNoneConfigurable,
    DataStreamTypes.scopedConfigurable
];

export const baseDataStreamConfig = {
    _type: 'tile/data-stream' as const
} as DataStreamBaseTileConfig;
