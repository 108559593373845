import useSize from '@react-hook/size';
import {
    DataStreamScalarValueConfig,
    FormattedStreamValue,
    StreamData,
    StreamDataColumn,
    findColumn,
    findValueColumn,
    getScalarFormattedValue,
    isNoData,
    required
} from '@squaredup/data-streams';
import type { HealthState } from '@squaredup/monitoring';
import { Text } from '@visx/text';
import clsx from 'clsx';
import { healthStateTextColors } from 'constants/state';
import { DataStreamVisualisation } from 'dashboard-engine/types/Visualisation';
import { formatTickAsColumn } from 'dashboard-engine/util/tickFormatter';
import { useMemo, useRef } from 'react';
import { DataStreamGaugeConfig } from './Config';
import { getValidColumns } from './DataStreamGaugeChartOptionsForm';
import { getTickAlignment } from './utils';

// These have to be static strings, otherwise tailwind won't be able to extract them
const strokeStatusClasses: Record<HealthState, string> = {
    error: 'stroke-statusErrorPrimary',
    warning: 'stroke-statusWarningPrimary',
    success: 'stroke-statusHealthyPrimary',
    unknown: 'stroke-statusUnknownPrimary'
};

/**
 * Get the value column as selected by the user or by us as a default.
 * Returns undefined if the value is not tied to a single specific column (e.g. when counting rows, or when
 * more than one column is involved in producing the value).
 */
const getValueColumn = (
    columns: StreamDataColumn[],
    rowCount: number,
    valueColumnConfig?: DataStreamScalarValueConfig
): StreamDataColumn | undefined => {
    const defaultValueColumn = findValueColumn(columns, rowCount);

    if (valueColumnConfig == null) {
        return defaultValueColumn.column?.column;
    }

    if (typeof valueColumnConfig === 'string') {
        // User has selected a specific column, fall back to the default column if it isn't available
        return findColumn(columns, required('name', valueColumnConfig)).getValue(defaultValueColumn.column)?.column;
    }

    if (valueColumnConfig.type === 'count') {
        return undefined;
    }

    const [selectedColumn, ...otherColumns] = valueColumnConfig.columns;

    if (otherColumns.length === 0) {
        // We're summing/averaging etc. across a single column, so that single column is our value column
        return findColumn(columns, required('name', selectedColumn)).getValue(defaultValueColumn.column)?.column;
    }

    // Multiple columns involved in producing the value, so no single value column
    return undefined;
};

const formatTick = (valueColumn: StreamDataColumn | undefined, data: StreamData, value: number) => {
    if (valueColumn == null) {
        return value.toString();
    }

    return formatTickAsColumn(valueColumn, { data, valueColumn })(value);
};

const DataStreamGaugeChart: DataStreamVisualisation<DataStreamGaugeConfig> = ({
    data,
    config,
    healthState,
    monitorConditions
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [width, height] = useSize(ref);

    const validColumns = getValidColumns(data.metadata.columns);
    const valueColumn = getValueColumn(validColumns, data?.rows.length ?? 0, config?.value);

    const value = useMemo(() => {
        // If there's no data return null
        if (isNoData(data)) {
            return null;
        }

        // Otherwise get the value based on the config
        const { value: scalarValue } = getScalarFormattedValue(data, valueColumn?.name ?? { type: 'count' as const });

        // If we couldn't get the value return null
        if (!scalarValue) {
            return null;
        }

        // If the value is an array, get the count
        if (Array.isArray(scalarValue)) {
            return getScalarFormattedValue(data, { type: 'count' as const }).value as FormattedStreamValue;
        }

        return scalarValue;
    }, [data, valueColumn]);

    const min = config?.minimum ?? 0;
    const max = config?.maximum ?? 100;

    const minFormatted = formatTick(valueColumn, data, min);
    const maxFormatted = formatTick(valueColumn, data, max);

    const rawValue = (value?.raw as number) ?? 0;
    const percent = ((rawValue - min) / (max - min)) * 100;

    // An example of width/height for the visualization from the designs, which we need to preserve the aspect ratio
    const originalWidth = 290;
    const originalHeight = 195;

    const isExtraSmall = height < originalHeight / 2 || width < originalWidth / 2;

    // Calculate the scale that will take up the most space within the container
    const scale = Math.max(0.25, Math.min(width / originalWidth, height / originalHeight));

    // Set the minimum size of the visualization
    const size = Math.max(originalWidth / 4, originalWidth * scale);

    // Constant margin around visualization
    const marginX = isExtraSmall ? 20 : Math.max(20, size * 0.14);
    const marginY = isExtraSmall ? 10 : 20;

    const radius = (size - marginX * 2) / 2;
    const strokeWidth = radius * 0.15;
    const innerRadius = radius - strokeWidth / 2;

    const circumference = innerRadius * 2 * Math.PI;
    const arc = innerRadius * Math.PI;
    const dashArray = `${arc} ${circumference}`;

    const percentClamped = Math.min(Math.max(percent, 0), 100);
    const arcDashOffset = arc - (percentClamped / 100) * arc;

    const cx = radius + marginX;
    const cy = Math.min(height, size) / 2 + radius / 2;
    const labelFontSize = 20 * scale;
    const transform = `rotate(180, ${cx}, ${cy})`;

    const showTicks = !isExtraSmall;

    // Show ticks from current monitoring config, if available
    const ticks = [monitorConditions?.warning?.value, monitorConditions?.error?.value]
        .filter((a): a is number => a != null)
        .filter((a) => a >= min && a <= max);

    // Use Polar Coordinates then convert to Cartesian Coordinates to calculate the x/y positions of the ticks
    const tickCoordinates = ticks.map((tick) => {
        const angle = Math.PI + Math.PI * ((tick - min) / (max - min));

        return {
            valueFormatted: formatTick(valueColumn, data, tick),
            angle,
            radius,
            x: (radius + 15) * Math.cos(angle),
            y: (radius + 15) * Math.sin(angle),
            tickX2: radius * Math.cos(angle),
            tickY2: radius * Math.sin(angle),
            tickX1: (radius + 7) * Math.cos(angle),
            tickY1: (radius + 7) * Math.sin(angle)
        };
    });

    const strokeColor = strokeStatusClasses[healthState as HealthState] ?? 'stroke-statusAction';
    const valueColor = healthStateTextColors[healthState as HealthState] ?? 'text-textPrimary';

    const tickFontSize = Math.min(24 * scale, 14);

    // All fixed numbers below are relative to the originalWidth/originalHeight, then scaled
    return (
        <div ref={ref} className={clsx('flex w-full h-full justify-center')}>
            <div className='flex flex-col items-center justify-center w-full overflow-hidden'>
                <div className='relative w-full overflow-hidden' style={{ marginTop: -25 * scale }}>
                    <div className='absolute top-0 bottom-0 left-0 right-0'>
                        <div className='flex flex-col items-center w-full h-full'>
                            <p
                                className='overflow-hidden font-semibold leading-normal text-center -translate-y-1/2 text-textSecondary text-ellipsis whitespace-nowrap max-w-[80%]'
                                style={{
                                    fontSize: labelFontSize,
                                    marginTop: cy + marginY + 22 * scale
                                }}
                            >
                                {config?.label ?? ''}
                            </p>
                        </div>
                    </div>
                    <svg
                        height={size}
                        width={size}
                        className='mx-auto overflow-visible font-semibold font-inter'
                        style={{
                            marginTop: config?.label ? 0 : 15 * scale
                        }}
                    >
                        {/* Monitoring Ticks */}
                        {showTicks &&
                            tickCoordinates.map((e) => (
                                <g key={`${e.x}-${e.y}-${e.valueFormatted}`}>
                                    <Text
                                        alignmentBaseline='middle'
                                        className='fill-outlineSecondary'
                                        fontWeight='600'
                                        x={e.x + radius + marginX}
                                        y={e.y + cy + 4}
                                        textAnchor={getTickAlignment(e.angle)}
                                        fontSize={tickFontSize * 0.8}
                                    >
                                        {e.valueFormatted}
                                    </Text>
                                    <line
                                        className='stroke-outlineSecondary'
                                        x1={e.tickX1 + radius + marginX}
                                        y1={e.tickY1 + cy}
                                        x2={e.tickX2 + radius + marginX}
                                        y2={e.tickY2 + cy}
                                        strokeWidth='2'
                                    />
                                </g>
                            ))}
                        <circle
                            className='stroke-dividerTertiary'
                            cx={cx}
                            cy={cy}
                            fill='transparent'
                            r={innerRadius}
                            strokeWidth={strokeWidth}
                            strokeDasharray={dashArray}
                            transform={transform}
                        />
                        <circle
                            className={strokeColor}
                            cx={cx}
                            cy={cy}
                            fill='transparent'
                            r={innerRadius}
                            strokeWidth={strokeWidth}
                            strokeDashoffset={arcDashOffset}
                            strokeDasharray={dashArray}
                            transform={transform}
                        />
                        {/* Central value */}
                        <Text
                            className={`fill-current ${valueColor}`}
                            alignmentBaseline='middle'
                            x={radius + marginX}
                            y={cy - 5 * scale}
                            textAnchor='middle'
                            scaleToFit='shrink-only'
                            style={{
                                fontSize: `${60 * scale}px`
                            }}
                            width={100 * scale}
                        >
                            {value?.formatted ?? 0}
                        </Text>
                        {/* Min-Max labels */}
                        <text
                            alignmentBaseline='middle'
                            textAnchor='middle'
                            fill='var(--textSecondary)'
                            fontSize={tickFontSize}
                            fontWeight='600'
                            x={marginX + strokeWidth / 2}
                            y={cy + tickFontSize}
                        >
                            {minFormatted}
                        </text>
                        <text
                            alignmentBaseline='middle'
                            textAnchor='middle'
                            fill='var(--textSecondary)'
                            fontSize={tickFontSize}
                            fontWeight='600'
                            x={marginX + radius * 2 - strokeWidth / 2}
                            y={cy + tickFontSize}
                        >
                            {maxFormatted}
                        </text>
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default DataStreamGaugeChart;
