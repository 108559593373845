import { ClientDataStreamRequest, FormattedStreamData } from '@squaredup/data-streams';
import { getConfigIdFromWorkspaceId } from '@squaredup/ids';
import { HealthState } from '@squaredup/monitoring';
import { isFeatureEnabled } from '@squaredup/tenants';
import LoadingSpinner from 'components/LoadingSpinner';
import useDataStream from 'dashboard-engine/hooks/useDataStream';
import { DashboardType } from 'dashboard-engine/types/Dashboard';
import type { ViewOption } from 'dynamo-wrapper';
import { cloneDeep } from 'lodash';
import { useDashboards } from 'queries/hooks/useDashboards';
import { useTier } from 'queries/hooks/useTier';
import { useDataCount } from '../utils/useDataCount';
import { useFilteredWorkspaces } from '../utils/useFilteredWorkspaces';
import { GlobalViewEmptyMessage } from './GlobalViewEmptyMessage';
import { StatusBlocks } from './blocks/StatusBlocks';

export type SortValue = 'state' | 'name';

export type StatusRequestType = 'space' | 'dash' | 'monitor';

function getQuery(type: StatusRequestType) {
    if (type === 'space') {
        return "g.V().has('sourceType', 'squaredup/space').has('__configId', within(taggedConfigIds)).valueMap(true)";
    }

    if (type === 'dash') {
        return "g.V().has('sourceType', 'squaredup/dash').has('__configId', within(taggedConfigIds)).valueMap(true)";
    }

    return "g.V().has('sourceType', 'squaredup/monitor').has('__configId', within(taggedConfigIds)).valueMap(true)";
}

interface BuildRequest {
    sort: {
        value: SortValue;
        direction: 'asc' | 'desc';
    };
    stateFilters?: HealthState[];
    type: StatusRequestType;
}

const buildRequest = (
    { sort: { value, direction }, stateFilters, type }: BuildRequest,
    taggedConfigIds?: string[]
): ClientDataStreamRequest => ({
    dataStreamId: 'datastream-health',
    timeframe: 'last12hours',
    options: {
        sort:
            value === 'state'
                ? {
                      criteria: [
                          ['data.state', direction],
                          ['data.name', 'asc']
                      ],
                      valueMap: { unknown: direction === 'asc' ? '4' : '0', error: '3', warning: '2', success: '1' }
                  }
                : {
                      criteria: [['data.name', direction]]
                  },
        filter: {
            filters:
                stateFilters
                    ?.sort((a, b) => a.localeCompare(b)) // Sort to ensure we always get the same user query cache key
                    .map((filterValue) => ({
                        columnName: 'data.state',
                        type: 'equals',
                        value: filterValue
                    })) || [],
            multiOperation: 'or'
        }
    },
    scope: {
        query: getQuery(type),
        bindings: {
            taggedConfigIds
        }
    }
});

function processData(
    type: StatusRequestType,
    data: FormattedStreamData,
    dashboards: DashboardType[] | undefined
): FormattedStreamData {
    if (data?.rows.length === 0) {
        return data;
    }

    const dashboardNameMap = new Map<string, string>((dashboards ?? []).map((dash) => [dash.id, dash.displayName]));
    const idCol = data.metadata.columns.findIndex(({ name }) => name === 'data.id');
    const sourceIdCol = data.metadata.columns.findIndex(({ name }) => name === 'data.sourceId');
    const newData = cloneDeep(data);

    // We want to redirect users to the dashboard containing the monitor
    if (type === 'monitor') {
        const dashIdCol = data.metadata.columns.findIndex(({ name }) => name === 'data.sourceId');
        const urlCol = data.metadata.columns.findIndex(({ name }) => name === 'data.link');
        const nameCol = data.metadata.columns.findIndex(({ name }) => name === 'data.name');

        newData.rows.forEach((row) => {
            const dashId = row[dashIdCol].formatted.split('/')[0];
            const url = `/dashboard/${dashId}`;
            row[urlCol] = {
                value: url,
                formatted: url,
                raw: url
            };
            row[idCol] = row[sourceIdCol];
            const dashName = dashboardNameMap.get(dashId);
            if (dashName) {
                // Temporarily restore the old 'Tile X of dashboard Y' format until we
                // can work on making the monitor status block show the dashboard in a nicer way.
                const tileName = row[nameCol]?.value || 'Untitled';
                const fullName = `Tile '${tileName}' of dashboard '${dashName}'`;
                row[nameCol] = {
                    value: fullName,
                    formatted: fullName,
                    raw: fullName
                };
            }
        });
    } else {
        newData.rows.forEach((row) => {
            row[idCol] = row[sourceIdCol];
        });
    }

    return newData;
}

export interface StatusOverviewProps {
    sort: {
        value: SortValue;
        direction: 'asc' | 'desc';
    };
    stateFilters: HealthState[];
    type: StatusRequestType;
    openCreateWorkspace?: () => void;
    fillBlocksGrid?: boolean;
    tags?: string[];
    workspaceId?: string;
    maxColumns?: number;
    workspaceTypes?: string[];
    viewOptions?: ViewOption[];
}

export default function StatusOverview({
    openCreateWorkspace,
    maxColumns = 3,
    fillBlocksGrid,
    workspaceId,
    tags,
    workspaceTypes,
    viewOptions,
    ...requestParams
}: StatusOverviewProps) {
    const { data: taggedWorkspaces } = useFilteredWorkspaces(tags, workspaceTypes, {
        enabled: workspaceId === undefined
    });

    const { data: dashboards, isLoading: isLoadingDashboards } = useDashboards();
    const { data: tier } = useTier();

    const taggedConfigIds = !workspaceId
        ? taggedWorkspaces?.map((workspace) => workspace.configId) || []
        : [getConfigIdFromWorkspaceId(workspaceId!)];

    const request = buildRequest(requestParams, taggedConfigIds);

    const { data: rawData, isLoading: isLoadingDataStream } = useDataStream(request, null, {
        enabled: taggedConfigIds.length > 0,
        keepPreviousData: false // Opt out of improved loading flow at this time, causes issues with filters
    });

    const { workspaceCount, dashboardCount, monitorCount } = useDataCount();

    const data =
        rawData && dashboards
            ? processData(requestParams.type, rawData, dashboards)
            : undefined;

    const isLoading = isLoadingDashboards || isLoadingDataStream;

    const showAddWorkspaceButton =
        !isLoading && data && data.rows.length < 3 && workspaceCount < 3 && requestParams.type === 'space';

    const isWorkspacesAvailable = (tier && isFeatureEnabled(tier, 'workspaces')) || workspaceCount === 0;

    return (
        <div className='flex space-x-3 grow'>
            <div className='w-full grow'>
                {!isLoading && data && <StatusBlocks
                    data={data}
                    type={requestParams.type}
                    columns={maxColumns}
                    fillGrid={fillBlocksGrid}
                    viewOptions={viewOptions}
                    showAddWorkspace={Boolean(showAddWorkspaceButton)}
                    onAddWorkspaceClick={openCreateWorkspace}
                    isWorkspaceFeatureEnabled={isWorkspacesAvailable}
                    noBlocksMessage={
                        /** No data found & we aren't showing an add workspace button */
                        !isLoading &&
                        data &&
                        data.rows.length === 0 &&
                        (workspaceCount > 2 || requestParams.type !== 'space') && (
                            <div className='flex flex-col items-center justify-center h-full gap-4 m-auto text-center text-textSecondary'>
                                <GlobalViewEmptyMessage
                                    type={requestParams.type}
                                    dashCount={dashboardCount}
                                    monitorCount={monitorCount}
                                />
                            </div>
                        )
                    }
                />}
                {isLoading && <LoadingSpinner />}
            </div>     
        </div>
    );
}
