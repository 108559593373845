import { createContext, useContext, useRef } from 'react';
import { useStore } from 'zustand';
import { createNetworkMapStore, NetworkMapStoreInstance, NetworkMapStoreProps, NetworkMapStoreState } from '../NetworkMapStore';

type StoreProviderProps = React.PropsWithChildren<NetworkMapStoreProps>

const NetworkMapStoreContext = createContext<NetworkMapStoreInstance | null>(null);

export function NetworkMapStoreProvider({ children, ...props }: StoreProviderProps) {
    const storeRef = useRef<NetworkMapStoreInstance>();

    if (!storeRef.current) {
        storeRef.current = createNetworkMapStore(props);
    }

    return (
        <NetworkMapStoreContext.Provider value={storeRef.current}>
            {children}
        </NetworkMapStoreContext.Provider>
    );
};

export function useNetworkMapStoreContext<T>(selector: (state: NetworkMapStoreState) => T) {
    const store = useContext(NetworkMapStoreContext);
    
    if (!store) {
        throw new Error('Missing NetworkMapStoreContext.Provider in the tree');
    }
    
    return useStore(store, selector);
};

export function useNetworkMapStore() {
    const store = useContext(NetworkMapStoreContext);
    
    if (!store) {
        throw new Error('Missing NetworkMapStoreContext.Provider in the tree');
    }
    
    return store;
};

const mapIdSelector = (state: NetworkMapStoreState) => state.mapId;
const backgroundSelector = (state: NetworkMapStoreState) => state.background;
const layoutTypeSelector = (state: NetworkMapStoreState) => state.layoutType;
const getlayoutTypeSelector = (state: NetworkMapStoreState) => state.getLayoutType;
const setlayoutTypeSelector = (state: NetworkMapStoreState) => state.setLayoutType;
const nodesSelector = (state: NetworkMapStoreState) => state.nodes;
const edgesSelector = (state: NetworkMapStoreState) => state.edges;
const getExpandedNodeIdsSelector = (state: NetworkMapStoreState) => state.getExpandedNodeIds;
const setExpandedNodeIdsSelector = (state: NetworkMapStoreState) => state.setExpandedNodeIds;
const getPinnedNodeIdsSelector = (state: NetworkMapStoreState) => state.getPinnedNodeIds;
const setPinnedNodeIdsSelector = (state: NetworkMapStoreState) => state.setPinnedNodeIds;
const getUngroupedNodeIdsSelector = (state: NetworkMapStoreState) => state.getUngroupedNodeIds;
const setUngroupedNodeIdsSelector = (state: NetworkMapStoreState) => state.setUngroupedNodeIds;
const getPinnedGroupsWithMemberIdsSelector = (state: NetworkMapStoreState) => state.getpinnedGroupsWithMemberNodeIds;
const setPinnedGroupsWithMemberIdsSelector = (state: NetworkMapStoreState) => state.setpinnedGroupsWithMemberNodeIds;
const getNodeSelector = (state: NetworkMapStoreState) => state.getNode;
const getNodesSelector = (state: NetworkMapStoreState) => state.getNodes;
const getEdgesSelector = (state: NetworkMapStoreState) => state.getEdges;
const getGraphNodesSelector = (state: NetworkMapStoreState) => state.getGraphNodes;
const getGraphEdgesSelector = (state: NetworkMapStoreState) => state.getGraphEdges;
const setNodesSelector = (state: NetworkMapStoreState) => state.setNodes;
const setEdgesSelector = (state: NetworkMapStoreState) => state.setEdges;
const setNodesAndEdgesSelector = (state: NetworkMapStoreState) => state.setNodesAndEdges;
const setGraphNodesSelector = (state: NetworkMapStoreState) => state.setGraphNodes;
const setGraphEdgesSelector = (state: NetworkMapStoreState) => state.setGraphEdges;
const getNodeHealthStateSelector = (state: NetworkMapStoreState) => state.getNodeHealthState;
const setNodeHealthStateSelector = (state: NetworkMapStoreState) => state.setNodeHealthState;
const onNodesChangeSelector = (state: NetworkMapStoreState) => state.onNodesChange;
const onEdgesChangeSelector = (state: NetworkMapStoreState) => state.onEdgesChange;

export const useMapId = () => useNetworkMapStoreContext(mapIdSelector);
export const useNetworkBackground = () => useNetworkMapStoreContext(backgroundSelector);
export const useLayoutType = () => useNetworkMapStoreContext(layoutTypeSelector);
export const useGetLayoutType = () => useNetworkMapStoreContext(getlayoutTypeSelector);
export const useSetLayoutType = () => useNetworkMapStoreContext(setlayoutTypeSelector);
export const useNetworkNodes = () => useNetworkMapStoreContext(nodesSelector);
export const useNetworkEdges = () => useNetworkMapStoreContext(edgesSelector);
export const useGetExpandedNodeIds = () => useNetworkMapStoreContext(getExpandedNodeIdsSelector);
export const useSetExpandedNodeIds = () => useNetworkMapStoreContext(setExpandedNodeIdsSelector);
export const useGetPinnedNodeIds = () => useNetworkMapStoreContext(getPinnedNodeIdsSelector);
export const useSetPinnedNodeIds = () => useNetworkMapStoreContext(setPinnedNodeIdsSelector);
export const useGetUngroupedNodeIds = () => useNetworkMapStoreContext(getUngroupedNodeIdsSelector);
export const useSetUngroupedNodeIds = () => useNetworkMapStoreContext(setUngroupedNodeIdsSelector);
export const useGetPinnedGroupsWithMemberIds = () => useNetworkMapStoreContext(getPinnedGroupsWithMemberIdsSelector);
export const useSetPinnedGroupsWithMemberIds = () => useNetworkMapStoreContext(setPinnedGroupsWithMemberIdsSelector);
export const useGetNetworkNode = () => useNetworkMapStoreContext(getNodeSelector);
export const useGetNetworkNodes = () => useNetworkMapStoreContext(getNodesSelector);
export const useGetNetworkEdges = () => useNetworkMapStoreContext(getEdgesSelector);
export const useGetGraphNodes = () => useNetworkMapStoreContext(getGraphNodesSelector);
export const useGetGraphEdges = () => useNetworkMapStoreContext(getGraphEdgesSelector);
export const useSetNetworkEdges = () => useNetworkMapStoreContext(setEdgesSelector);
export const useSetNetworkNodes = () => useNetworkMapStoreContext(setNodesSelector);
export const useSetNetworkNodesAndEdges = () => useNetworkMapStoreContext(setNodesAndEdgesSelector);
export const useSetGraphNodes = () => useNetworkMapStoreContext(setGraphNodesSelector);
export const useSetGraphEdges = () => useNetworkMapStoreContext(setGraphEdgesSelector);
export const useGetNetworkNodeHealthState = () => useNetworkMapStoreContext(getNodeHealthStateSelector);
export const useSetNetworkNodeHealthState = () => useNetworkMapStoreContext(setNodeHealthStateSelector);
export const useNetworkNodesChange = () => useNetworkMapStoreContext(onNodesChangeSelector);
export const useNetworkEdgesChange = () => useNetworkMapStoreContext(onEdgesChangeSelector);
