import { InfoTip } from '@/components/InfoTip';
import Text from '@/components/Text';
import { Toggle } from '@/components/forms/Toggle';
import { faWarning } from '@fortawesome/pro-solid-svg-icons';
import LoadingSpinner from 'components/LoadingSpinner';
import { useDashboardContext } from 'contexts/DashboardContext';
import type { KPIType } from 'dynamo-wrapper';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { KPI_TYPES, ListTypes } from 'services/KPIService';
import { useTileEditorContext } from '../../contexts/TileEditorContext';
import { useDataStreamSupportedTimeframes } from '../hooks/useDataStreamSupportedTimeframes';
import { determineBestTimeframe } from '../monitoring/DataStreamTileEditorMonitoring';
import { DataStreamTileEditorKPIForm } from './DataStreamTileEditorKPIForm';

const KPI = () => {
    const { timeframe: dashboardTimeframe, variables = [] } = useDashboardContext();
    const { data: kpiTypes, isLoading } = useQuery(KPI_TYPES, ListTypes);

    const types: KPIType[] | undefined = kpiTypes;

    const { tileConfig, savedTileConfig, setTileConfig } = useTileEditorContext();

    const supportedTimeframes = useDataStreamSupportedTimeframes(tileConfig?.dataStream?.id);

    const [kpiEnabled, setKpiEnabled] = useState(Boolean(tileConfig?.kpi));

    const handleKpiToggle = () => {
        setTileConfig((currentConfig) => {
            const timeframe = determineBestTimeframe(supportedTimeframes, dashboardTimeframe, currentConfig.timeframe);

            return {
                ...currentConfig,
                kpi: !kpiEnabled ? savedTileConfig?.kpi : undefined,
                timeframe: !kpiEnabled ? timeframe : currentConfig.timeframe
            };
        });
        setKpiEnabled(!kpiEnabled);
    };

    const tileVariables = variables.filter((v) => (tileConfig.variables || []).includes(v.id));
    const variablesPreventingKpis = tileVariables.length > 0 && tileVariables.every((v) => v.default !== 'all');

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div className='overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary h-max'>
            <div className='flex items-center justify-between px-4 mt-5 shrink-0'>
                <Text.H3 className='flex-shrink-0'>KPI</Text.H3>

                <div>
                    <Toggle
                        checked={kpiEnabled && !variablesPreventingKpis}
                        onCheckedChange={handleKpiToggle}
                        disabled={variablesPreventingKpis}
                    />
                </div>
            </div>

            {variablesPreventingKpis && (
                <div className='px-4'>
                    <InfoTip icon={faWarning} iconClassName='text-statusWarningPrimary'>
                        KPI is not available as this tile uses a variable without a default value.{' '}
                        <a
                            className='text-textLink'
                            href='https://squaredup.com/cloud/variables'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Learn more.
                        </a>
                    </InfoTip>
                </div>
            )}

            {kpiEnabled && !variablesPreventingKpis && (
                <div className='mt-[29px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                    <DataStreamTileEditorKPIForm types={types} />
                </div>
            )}
        </div>
    );
};

export default KPI;
