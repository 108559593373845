import { faSliders } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownMenu from 'components/dropdownMenu';
import type { ViewOption } from 'dynamo-wrapper';
import { useState } from 'react';

export interface ViewSettingsMenuProps {
    options: ViewOption[];
    selectedOptions: ViewOption[];
    setViews: (newOptions: ViewOption[]) => void;
}

export default function ViewSettingsMenu({ options, selectedOptions, setViews }: ViewSettingsMenuProps) {
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    return (
        <DropdownMenu onOpenChange={setIsDropdownOpened}>
            <DropdownMenu.Button className='whitespace-nowrap' dropdownOpen={isDropdownOpened} showChevron useFilterStyle icon={<FontAwesomeIcon icon={faSliders} />}>
                View settings
            </DropdownMenu.Button>
            <DropdownMenu.Menu align='end'>
                <DropdownMenu.Group>
                    {options.map((view, index) => {
                        switch (view.type) {
                            case 'boolean': {
                                return (
                                    <DropdownMenu.EyeCheckboxItem
                                        key={view.name}
                                        checked={(selectedOptions.find((o: any) => o.name === view.name) as any)?.value as boolean ?? view.value}
                                        onCheckedChange={() => {
                                            const optionsCopy = [ ...options ];

                                            const option: any = optionsCopy.find((o: any) => o.name === view.name);
                                            if (option) {
                                                option.value = !view.value;
                                            }

                                            setViews(optionsCopy);
                                        }}
                                    >
                                        {view.displayName}
                                    </DropdownMenu.EyeCheckboxItem>
                                );
                            }
                            case 'number': {
                                return (
                                    <DropdownMenu.NumberItem
                                        key={view.name}
                                        value={(selectedOptions.find((o: any) => o.name === view.name) as any)?.value ?? view.value}
                                        minimum={view?.minimum}
                                        maximum={view?.maximum}
                                        onValueChange={(value) => {
                                            const optionsCopy = [ ...options ];

                                            const option: any = optionsCopy.find((o: any) => o.name === view.name);
                                            if (option) {
                                                if (view?.minimum !== undefined) {
                                                    if (value < view.minimum) {
                                                        value = view.minimum;
                                                    }
                                                }

                                                if (view?.maximum !== undefined) {
                                                    if (value > view.maximum) {
                                                        value = view.maximum;
                                                    }
                                                }

                                                option.value = isNaN(value) ? 3 : value;
                                            }

                                            setViews(optionsCopy);
                                        }}
                                    >
                                        {view.displayName}
                                    </DropdownMenu.NumberItem>
                                );
                            }
                            case 'separator': {
                                return (
                                    <DropdownMenu.Separator key={`Separator-${index}`} />
                                );
                            }
                            default: {
                                break;
                            }
                        }
                    })}
                </DropdownMenu.Group>
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
}
