import React from 'react';
import { useController } from 'react-hook-form';
import Field from '../field/Field';
import { ChoiceChipButton } from './ChoiceChipButton';

export interface ChoiceChipsProps {
    name: string;
    label?: string;
    defaultValue?: string | string[];
    isMulti?: boolean;
    options: { label: string; value: string }[];
    isDisabled?: boolean;
}

export const ChoiceChips: React.FC<ChoiceChipsProps> = ({ name, label, options, isMulti }) => {
    const { field } = useController({
        name
    });

    const handleOptionClick = (value: string | number) => {
        if (isMulti) {
            const current = [...(field.value || [])];
            if (current.includes(value)) {
                field.onChange(current.filter((f) => f !== value));
            } else {
                field.onChange([...current, value]);
            }
        } else {
            field.onChange(value);
        }
    };

    return (
        <div>
            <div>{label && <Field.Label label={label} />}</div>
            {options.map((option) => (
                <ChoiceChipButton
                    key={option.value}
                    option={option}
                    currentValue={isMulti ? field.value || [] : field.value}
                    isMulti={isMulti}
                    handleClick={handleOptionClick}
                />
            ))}
        </div>
    );
};
