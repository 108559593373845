import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { Visualisation } from 'dashboard-engine/types/Visualisation';
import { useFitTextWithBuffer } from 'lib/useFitTextWithBuffer';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { ScalarData } from '../../types/data/ScalarData';
import { TextConfig } from './Config';
import { LinkWrapper } from 'components/LinkWrapper';

const SimpleText: React.FC<{ data: ScalarData }> = ({ data }) => {
    const stringify = typeof data === 'string' || typeof data === 'number' ? false : true;
    if (stringify) {
        const value = JSON.stringify(data, null, 4);
        return (
            <div className='tile-scroll-overflow'>
                <pre className='p-3 font-mono text-sm text-left bg-tileBackground' data-testid='text'>
                    {value}
                </pre>
            </div>
        );
    }

    return (
        <p className='p-3 text-lg font-medium text-center font-inter bg-tileBackground' data-testid='text'>
            {data}
        </p>
    );
};

const AutoSizedText: React.FC<{ content: string; className: string }> = ({ content, className }) => {
    const [finishedSizing, setFinishedSizing] = useState(false);

    // Use max font size 2000 for a maximum of 20x (2000%) scaling
    const { fontSize, ref } = useFitTextWithBuffer({
        maxFontSize: 2000,
        onFinish: () => setFinishedSizing(true)
    }, 0.2);

    return (
        <div ref={ref} style={{ fontSize, opacity: finishedSizing ? 1 : 0 }} className={className}>
            <div className='w-full my-auto'>
                <ScaledMarkdown content={content} />
            </div>
        </div>
    );
};

/**
 * Get the indentation for a list element based on a given depth
 * @param depth List depth
 * @returns Styles
 */
const getIndentation = (depth: number) => ({marginLeft: depth * 20, ...depth > 0 && {marginTop: 5}});

/**
 * Processes content as markdown, ensuring the resultant content scales based on the parent font size.
 */
const ScaledMarkdown: React.FC<{ content: string; }> = ({ content }) => (<div className='relative w-full my-auto space-y-4'><ReactMarkdown components={{
    a: ({ href, children }) => <LinkWrapper link={href as string} className='text-textLink text-[100%]'>{children}</LinkWrapper>,
    h1: ({ children }) => <Text.H1 className='text-[120%]'>{children}</Text.H1>,
    h2: ({ children }) => <Text.H2 className='text-[110%]'>{children}</Text.H2>,
    h3: ({ children }) => <Text.H3 className='text-[100%]'>{children}</Text.H3>,
    h4: ({ children }) => <Text.H4 className='text-[90%]'>{children}</Text.H4>,
    h5: ({ children }) => <Text.H5 className='text-[80%]'>{children}</Text.H5>,
    p: ({ children }) => <Text.Body className='text-[100%]'>{children}</Text.Body>,
    pre: ({ children }) => <pre className='p-3 text-sm border rounded-md bg-componentBackgroundSecondary border-outlinePrimary text-[100%] leading-[inherit]'>{children}</pre>,
    ol: ({ children, depth }) => <ol className='space-y-1 list-decimal list-inside marker:text-textSecondary marker:mr-2 text-[100%]' style={getIndentation(depth)}>{children}</ol>,
    ul: ({ children, depth }) => <ul className='space-y-1 list-disc list-inside text-[100%]' style={getIndentation(depth)}>{children}</ul>,
    hr: () => <hr className='border-dividerPrimary' />
}}>
    {content}
</ReactMarkdown></div>);

const TextViz: Visualisation<ScalarData, TextConfig> = ({ data, config }) => {
    const isSimpleTextTile = Object.keys(config).length === 0;

    if (isSimpleTextTile) {
        return <SimpleText data={data} />;
    }

    const className = cn(
        'flex w-full h-full scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary leading-snug overflow-y-auto overflow-x-hidden',
        config.align === 'center' && 'content-center text-center',
        config.align === 'right' && '!overflow-auto content-end text-right'
    );

    /**
     * Render auto-sized text using a separate component rather than
     * choosing to use the size from the hook or the config in one component.
     *
     * If we use the hook but choose to use the config font size, and then when we
     * enable auto-sizing, the size generated by the hook doesn't update until
     * the element changes size.
     */
    if (config.autoSize === true) {
        return <AutoSizedText content={config.content} className={className} />;
    }

    return (
        <div style={{ fontSize: `${config.fontSize}px` }} className={className}>
            <ScaledMarkdown content={config.content} />
        </div>
    );
};

export default TextViz;
