import { DashboardTileContent } from 'dashboard-engine/types/Dashboard';
import { range } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const getCellLayout = (cell: DashboardTileContent, layout: DashboardTileContent[], columns: number) => {
    // Get cells that are in/overlap the selected cell's row
    const cellsInRow = layout.filter(({ y, h }) => y === cell.y || (y < cell.y && y + h - 1 >= cell.y));

    // Find the max x value based on the cells in the selected row. If there is nothing in the row we can
    // go to the very end (the columns value).
    const xLimit =
        range(cell.x, columns).find((xPos: number) => {
            return cellsInRow.some(({ x }) => x === xPos);
        }) || columns;

    // Find the max y value based on the cells in the selected column. If there is nothing in the column we can
    // set the height to 2.

    const cellsInNextRow = layout.some(
        ({ x, w, y }) =>
            y === cell.y + 1 && // Check the grid cell is in the row below the new cell.
            x < cell.x + (xLimit - cell.x) && // Check the grid cell is within the horizontal space taken up by the new cell.
            (x >= cell.x || x + w > cell.x) // Check the grid cell is after the new cell .
    );

    const yLimit = cellsInNextRow ? 1 : 2;

    const newCell = {
        i: uuidv4(),
        x: cell.x,
        y: cell.y,
        w: xLimit - cell.x,
        h: yLimit,
        config: {
            title: '',
            description: ''
        }
    };

    if (newCell.w <= 0) {
        // prevent accidentally adding cell with 0 width
        return layout;
    }

    const updatedContents = [...layout, newCell];

    return updatedContents;
};
