/* eslint-disable no-console */
import { Serialised } from '@squaredup/ids';
import { hasProperty } from '@squaredup/utilities';
import type { ImportStatus } from 'dynamo-wrapper';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { datasourceConfigQueryKeys } from 'queries/queryKeys/datasourceConfigKeys';
import { QueryClient } from 'react-query';
import Auth from 'services/Auth';
import { PLUGIN_DETAIL } from 'services/PluginService';
import { PluginSourceConfig } from 'services/SourceConfigService';
import { TENANT_DATA } from 'services/TenantService';
import Config from '../../config';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { DashboardType } from 'dashboard-engine/types/Dashboard';

let webSocket: WebSocket | undefined;
let heartbeatTimer: number;
let lastHeartbeat: number;
let autoReconnectEnabled = false;
let autoReconnectInfo: {
    tenantId: string,
    webSocketUrl: string,
    queryClient: QueryClient
} | undefined = undefined;
let autoReconnectDelaySeconds = 1;
const maxAutoReconnectDelaySeconds = 60;
let autoReconnectTimer: number;

const latestConfigSentTimes = new Map<string, number>();

/**
 * Establish a connection to the server so that we can receive notifications.
 */
export const socketConnect = (tenantId: string, webSocketUrl: string, queryClient: QueryClient) => {
    autoReconnectInfo = { tenantId, webSocketUrl, queryClient };
    autoReconnectEnabled = true;
    closeSocketAndStopTimers();

    webSocket = createSocket(tenantId, webSocketUrl, queryClient);
    heartbeatTimer = initialiseHeartbeat(tenantId, webSocket);
};

/**
 * Tear down the socket connection to the server.
 */
export const socketCleanup = () => {
    try {
        autoReconnectEnabled = false;
        closeSocketAndStopTimers();
    } catch (err) {
        console.error('WebSocket: Cleanup failed', err);
    }
};

const closeSocketAndStopTimers = () => {
    window.clearInterval(heartbeatTimer);
    window.clearTimeout(autoReconnectTimer);
    webSocket?.close();
    webSocket = undefined;
};

// Fetches the latest state for the given config and splices it into the existing cache
async function updateConfigs(
    queryClient: QueryClient,
    configId: string,
    importStatus?: ImportStatus,
    sentTime?: number
) {
    if (configId && sentTime) {
        // Notifications are not guaranteed to arrive in the order they were sent. This matters for
        // import status - we don't want an old 'running' status to overwrite a newer 'succeeded' status,
        // for example.
        const latestSentTime = latestConfigSentTimes.get(configId);
        if (latestSentTime && sentTime < latestSentTime) {
            // This notification is older than one we've already received for this config, so discard.
            return;
        }
        latestConfigSentTimes.set(configId, sentTime);
    }

    const adminConfigs = queryClient.getQueryData<PluginSourceConfig[]>(datasourceConfigQueryKeys.forAdministration);

    if (!adminConfigs) {
        // No existing cache, so just invalidate the query for good measure
        queryClient.invalidateQueries(datasourceConfigQueryKeys.all);
        return;
    }

    const adminConfig = adminConfigs.find((c) => c.id === configId);
    if (adminConfig) {
        const otherAdminConfigs = adminConfigs.filter((c) => c.id !== configId);
        const updatedAdminConfig = { ...adminConfig, importStatus };
        queryClient.setQueryData(datasourceConfigQueryKeys.forAdministration, [...otherAdminConfigs, updatedAdminConfig]);
    } else {
        // Don't have an entry to update, so just update the whole list.
        queryClient.invalidateQueries(datasourceConfigQueryKeys.all);
    }

    let detailConfig: Serialised<Config<object>> = queryClient.getQueryData([PLUGIN_DETAIL, configId]);
    if (detailConfig) {
        detailConfig = { ...detailConfig, importStatus };
        queryClient.setQueryData([PLUGIN_DETAIL, configId], detailConfig);
    }
}

const createSocket = (tenantId: string, webSocketUrl: string, queryClient: QueryClient) => {
    // Do not remove the x_squp_2 QS param - it is required for proper app operation
    const url = `${webSocketUrl}?tenant=${tenantId}&x_squp_2=${Config.x_squp_2}`;
    console.info(`WebSocket: Connecting to ${url}]`);
    const socket = new WebSocket(url);

    socket.onopen = () => {
        lastHeartbeat = Date.now(); // set initial heartbeat
        console.info('WebSocket: Opened');
        autoReconnectDelaySeconds = 1; // Reset after successful open
        // Register the connection and request the current (possibly changed) stage
        socket.send(JSON.stringify({ message: 'register-connection-and-get-stage', tenantId }));
    };

    socket.onmessage = async (message) => {
        const websocketPayload = JSON.parse(message.data);

        // We've received a message from the server, so we know it's alive
        lastHeartbeat = Date.now();

        if (websocketPayload && websocketPayload.message) {
            switch (websocketPayload.message) {
                case 'stageUpdate': {
                    const tenantStage = websocketPayload.tenant?.stage;
                    if (tenantStage) {
                        // Note: We used to handle checking the tenant stage and reloading the app if necessary
                        // here, but that logic has moved to useEnsureAppConfiguration, which happens before this
                        // websocket connection is even initialised. So now the tenant stage should always match 
                        // the client by the time we get here.
                        //
                        // Probably doesn't make a lot of sense for this analytics code to stay here, but 
                        // leaving for now.
                        if (
                            hasProperty(window, 'analytics') &&
                            typeof window.analytics === 'object' &&
                            window.analytics != null
                        ) {
                            (window.analytics as any).identify(Auth.user?.id ?? '<no user id>', {
                                // Include as placeholders for future richer profile capture
                                email: Auth.user?.name,
                                name: Auth.user?.name,
                                username: Auth.user?.name
                            });

                            (window.analytics as any).group(Auth.user?.tenant ?? '<no tenant>', {
                                name: websocketPayload.tenant.displayName,
                                activeStage: tenantStage
                            });
                        }
                    }
                    
                    return;
                }
                case 'tenantUpdate':
                    // We've been notified that the tenant record has changed on the platform,
                    // let react-query know:
                    await queryClient.invalidateQueries(TENANT_DATA);
                    return;
                case 'configStatusUpdate':
                    // We've been notified that the config state has changed on the platform,
                    // we want to fetch it and splice it into our existing cache
                    await updateConfigs(
                        queryClient,
                        websocketPayload.configId,
                        websocketPayload.importStatus,
                        websocketPayload.wsSentTime
                    );
                    return;
                case 'dashboardChange': {                    
                    // Dashboard has changed - particularly useful for Open Access to pick up dashboard updates.
                    await queryClient.invalidateQueries(dashboardQueryKeys.list);

                    // Invalidate the current dashboard if the version from the server is higher
                    const { dashboardId, version = 0 } = websocketPayload;

                    const cachedDashboard = queryClient.getQueryData<DashboardType>(
                        dashboardQueryKeys.detail(dashboardId)
                    );
                    
                    const cachedDashboardVersion = cachedDashboard?.content?.version ?? -1;

                    if (version > cachedDashboardVersion) {
                        await queryClient.invalidateQueries(dashboardQueryKeys.detail(dashboardId));
                    }
                    return;
                }
                case 'datasourceConfigChange':
                    // data source config has changed
                    await queryClient.invalidateQueries(datasourceConfigQueryKeys.all);
                    await queryClient.invalidateQueries(workspaceQueryKeys.all);
                    return;
                case 'workspaceChange':
                    // Workspace has changed
                    await queryClient.invalidateQueries(workspaceQueryKeys.all);
                    return;
                case 'openAccessDisabledForWorkspace':
                    if (websocketPayload.workspaceId === Auth.user?.openAccessWorkspaceId) {
                        Auth.logout();
                    }
                    return;
                case 'openAccessShareRestricted':
                case 'openAccessShareDisabled':
                case 'openAccessShareDeleted':
                    if (websocketPayload.shareId === Auth.user?.openAccessId) {
                        Auth.logout();
                    }
                    return;
                case 'heartbeat':
                    // (Nothing to do - we've already updated our last heartbeat time earlier)
                    return;
                default:
                    console.error(`WebSocket: Unexpected message received (${websocketPayload.message})`);
            }
        }
    };

    socket.onclose = (event: CloseEvent) => {
        console.info(`WebSocket: Connection closed. Code=[${event.code}], Reason=[${event.reason}], wasClean=[${event.wasClean}]`);
        if (autoReconnectEnabled) {
            reconnect('Unexpected close');
        }
    };

    socket.onerror = () => {
        console.error('WebSocket: Error occurred.');

        // No need to do anything here. The error event will be followed by a close event, where
        // we can handle reconnection etc.
    };

    return socket;
};

const initialiseHeartbeat = (tenantId: string, socket: WebSocket) =>
    window.setInterval(() => {
        // Send a heartbeat to the platform every minute. This lets us know if our WS connection
        // is still valid:
        if (socket?.readyState === WebSocket.OPEN) {
            socket?.send(JSON.stringify({ message: 'heartbeat', tenantId }));
        }

        // Now check to see if we've received ANY message from the platform (including the response
        // to our routine heartbeat) in the last few minutes:
        if (socket?.readyState === WebSocket.OPEN && Date.now() - lastHeartbeat > 180_000) {
            reconnect('No recent heartbeat');
        }
    }, 60_000);

const reconnect = (reason: string) => {
    if (!autoReconnectEnabled || !autoReconnectInfo) {
        return;
    }

    autoReconnectEnabled = false;
    closeSocketAndStopTimers();

    // Back-off exponentially, with a cap.
    autoReconnectDelaySeconds = Math.min(autoReconnectDelaySeconds * 2, maxAutoReconnectDelaySeconds);
    console.info(`WebSocket: ${reason} - attempting auto-reconnect in ${autoReconnectDelaySeconds} seconds.`);

    autoReconnectTimer = window.setTimeout(
        function ({
            tenantId,
            webSocketUrl,
            queryClient
        }: {
            tenantId: string;
            webSocketUrl: string;
            queryClient: QueryClient;
        }) {
            socketConnect(tenantId, webSocketUrl, queryClient);
        },
        autoReconnectDelaySeconds * 1000,
        autoReconnectInfo
    );
};