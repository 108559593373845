import Text from '@/components/Text';
import { FormattedStreamData } from '@squaredup/data-streams';
import Button from 'components/button';
import { getRawBlocksData } from 'dashboard-engine/visualisations/DataStreamBlocks/dataUtils';
import type { ViewOption } from 'dynamo-wrapper';
import { upperFirst } from 'lodash';
import React, { useMemo } from 'react';
import { useBlockReason } from '../../utils/useBlockReason';
import { StatusRequestType } from '../StatusOverview';
import { StatusBlock, StatusBlockData, type StatusBlockDataItem } from './StatusBlock';
import { StatusBlocksGrid } from './StatusBlocksGrid';
import { useWorkspaces } from 'queries/hooks/useWorkspaces';
import { useDatasourceConfigs } from 'queries/hooks/useDatasourceConfigs';
import { FeatureUnavailablePill } from 'components/plans/FeatureUnavailablePill';

interface BlockWrapperProps {
    blockData: StatusBlockDataItem;
    extraData: StatusBlockData;
    showFooter?: boolean;
    type: StatusRequestType;
    viewOptions?: ViewOption[];
}

interface StatusBlocksProps {
    data: FormattedStreamData;
    columns: number;
    fillGrid?: boolean;
    viewOptions?: ViewOption[];
    showAddWorkspace: boolean;
    noBlocksMessage?: React.ReactNode;
    onAddWorkspaceClick?: () => void;
    type: StatusRequestType;
    isWorkspaceFeatureEnabled: boolean;
}

const BlockWrapper: React.FC<BlockWrapperProps> = ({ blockData, extraData, showFooter, type, viewOptions }) => {
    const reason = useBlockReason(type, blockData.id, blockData.state);

    blockData.sublabel = reason;

    return (
        <StatusBlock
            blockData={blockData}
            extraData={extraData}
            showFooter={showFooter}
            viewOptions={viewOptions}
        />
    );
};

export const StatusBlocks: React.FC<StatusBlocksProps> = ({
    data,
    columns,
    showAddWorkspace,
    fillGrid,
    viewOptions,
    noBlocksMessage,
    type,
    onAddWorkspaceClick,
    isWorkspaceFeatureEnabled
}) => {
    const { data: workspaces } = useWorkspaces();
    const { data: plugins } = useDatasourceConfigs();

    const blocksData = useMemo(() => {
        return data.rows.length > 0 ? getRawBlocksData(data, {}) : [];
    }, [data]);

    const blocksDataIds = blocksData.map(d => d.id);

    const extraDataMap = useMemo(() => {
        const map = new Map();

        switch (type) {
            case 'space': {
                blocksDataIds?.forEach((workspaceId: any) => {
                    const workspace = workspaces?.find((w: any) => w.id === workspaceId);

                    if (workspace) {
                        map.set(workspace.id, {
                            type: upperFirst(workspace.data?.properties?.type ?? ''),
                            tags: workspace.data?.properties?.tags ?? [],
                            plugins: plugins
                                ?.filter(p => (workspace.data?.links?.plugins ?? []).includes(p.id))
                                .map(p => ({
                                    id: p.id,
                                    workspaceId: workspace.id,
                                    displayName: p.displayName,
                                    name: p.plugin?.name
                                }))
                        });
                    }
                });
                break;
            }
            default: {
                break;
            }
        }

        return map;
    }, [type, workspaces, plugins, blocksDataIds]);

    if (data.rows.length === 0 && !showAddWorkspace) {
        return <>{noBlocksMessage}</>;
    }

    let blocks: React.ReactNode[] = blocksData
        .filter((d): d is typeof d & { id: string } => d.id != null)
        .map((blockData, index) => (
            <BlockWrapper
                key={`${blockData.name}${index}`}
                blockData={blockData}
                extraData={extraDataMap.get(blockData.id)}
                showFooter={Array.from(extraDataMap.values()).some((d) => d?.plugins?.length > 0)}
                type={type}
                viewOptions={viewOptions}
            />
        ));

    if (fillGrid && blocks.length < 9) {
        if (showAddWorkspace) {
            blocks.push(
                <div
                    key='addWorkspace'
                    className='flex flex-col items-center justify-center w-full h-full p-2 border border-outlinePrimary'
                >
                    {!isWorkspaceFeatureEnabled && (
                        <div className='flex items-center mb-8'>
                            <Text.H2>Workspaces</Text.H2>
                            <FeatureUnavailablePill
                                featureKey='workspaces'
                                title='Organize your dashboards and data'
                                className='ml-3'
                            />
                        </div>
                    )}
                    <Button onClick={onAddWorkspaceClick} className='w-full max-w-min' disabled={!isWorkspaceFeatureEnabled}>Add workspace</Button>
                </div>
            );
        }
        // Fill the remaining grid spaces
        blocks.push(
            ...Array(9 - blocks.length)
                .fill(null)
                .map((v, i) => <div key={`placeholder-${i}`} className='flex h-full'></div>)
        );
    }

    return (
        <StatusBlocksGrid columns={columns} hasSublabels={true}>
            {blocks}
        </StatusBlocksGrid>
    );
};
