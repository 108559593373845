import { TooltipButton } from '@/components/Button';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDashboardContext } from 'contexts/DashboardContext';
import React, { useState } from 'react';
import { DashboardVariableCreateEditModal } from './DashboardVariableCreateEditModal';
import { DashboardVariableDropdown } from './DashboardVariableDropdown';

interface DashboardVariablesControlProps {
    canEdit: boolean;
}

export const DashboardVariablesControl: React.FC<DashboardVariablesControlProps> = ({ canEdit }) => {
    const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);

    const { editing, variables = [] } = useDashboardContext();

    const hasVariables = variables.length > 0;

    return (
        <div className='flex items-center text-base'>
            {(hasVariables || editing) && <div className='border-l border-dividerPrimary h-full py-3 mx-6'></div>}

            {hasVariables ? (
                <>
                    {variables.map((variable) => (
                        <DashboardVariableDropdown key={variable.id} variable={variable} canEdit={canEdit} />
                    ))}
                </>
            ) : (
                <>
                    {editing && (
                        <TooltipButton
                            variant='secondary'
                            title='Add dashboard variable'
                            className='text-base px-4 py-3'
                            icon={<FontAwesomeIcon icon={faPlus} />}
                            onClick={() => setIsCreateEditModalOpen(true)}
                            data-testid='dashboardVariableAdd'
                        />
                    )}
                </>
            )}

            {isCreateEditModalOpen && (
                <DashboardVariableCreateEditModal onClose={() => setIsCreateEditModalOpen(false)} />
            )}
        </div>
    );
};
