import { faChartLineUp, faCog } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { StateIndicator } from 'components/ui/state/StateIndicator';
import { useImportStatus } from 'pages/settings/plugins/useImportStatus';
import { useQuery } from 'react-query';
import { TENANT_DATA, Tenant } from 'services/TenantService';
import { IsTenantAdmin } from '../../services/AccessControlService';
import { AccountMenu } from './AccountMenu';
import { HelpMenu } from './HelpMenu';
import { NavTitleLink } from './ui/NavLinks';

export default function SettingsControls({ isSettingsPage }) {
    const { data: tenant } = useQuery(TENANT_DATA, Tenant);
    const { hasFailedImports } = useImportStatus();

    return (
        <div className='pt-4 mt-auto text-sm'>
            <div className='p-4 border-y border-dividerPrimary text-textSecondary'>
                <div className='flex flex-col w-full'>
                    <NavTitleLink to='/settings/plugins' className={clsx(isSettingsPage && 'text-textPrimary')}>
                        <FontAwesomeIcon fixedWidth icon={faCog} className='ml-0 mr-3' />
                        <span>Settings</span>
                        {hasFailedImports && <StateIndicator hideTooltip={true} className='!ml-[12px]' state='error' />}
                    </NavTitleLink>

                    {IsTenantAdmin(tenant) && (
                        <span className='mt-3'>
                            <NavTitleLink to='/usage'>
                                <FontAwesomeIcon fixedWidth icon={faChartLineUp} className='mr-3' />
                                <span>Plan & Usage</span>
                            </NavTitleLink>
                        </span>
                    )}

                    <AccountMenu />

                    <HelpMenu />
                </div>
            </div>
        </div>
    );
}
