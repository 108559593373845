import { sortBy } from 'lodash';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { scopeQueryKeys } from 'queries/queryKeys/scopeKeys';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { DATA_STREAM_DEFINITIONS_FOR_WORKSPACE } from 'services/DataStreamDefinitionService';
import { CreateDefaultDashboards } from 'services/PluginService';

export const useDefaultDashboardCreation = (navigateOnCreate: boolean = false) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation(
        ({ configId, pluginId, workspaceId }: { configId: string; pluginId: string; workspaceId: string }) =>
            CreateDefaultDashboards(configId, pluginId, workspaceId),
        {
            onSuccess: (dashboards) => {
                queryClient.invalidateQueries(dashboardQueryKeys.all);
                queryClient.removeQueries(scopeQueryKeys.all);
                queryClient.removeQueries([DATA_STREAM_DEFINITIONS_FOR_WORKSPACE]);
                if (navigateOnCreate && dashboards.length) {
                    const sortedDashboards = sortBy(
                        dashboards,
                        (dashboard) => dashboard.displayName?.toLowerCase() ?? ''
                    );
                    navigate(`/dashboard/${sortedDashboards[0]?.id}`);
                }
            }
        }
    );
};
