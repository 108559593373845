import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Constants from '@squaredup/constants';
import { ColumnDef } from '@tanstack/react-table';
import Tooltip from 'components/tooltip/Tooltip';
import { StateIndicator } from 'components/ui/state/StateIndicator';
import { formatDistanceToNow } from 'date-fns';
import useOverflowing from 'lib/useOverflowing';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { sortInTime, sortTimeAgo } from 'pages/components/ApplicationTable/ApplicationTableSort';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Monitor } from './Monitoring';

interface ProjectedRow {
    state: string;
    dashId: string;
    dashboardDisplayName: string | undefined;
    tileDisplayName: string | undefined;
    lastChanged: Date | undefined;
    lastEvaluated: Date;
    reason: string;
    consecutiveFailures: number;
    nextEvaluation: Date | undefined;
    dashboardFolderPath?: string[];
}

interface MonitorsTableProps {
    monitors: Monitor[];
}

const DashboardDisplayName: React.FC<{ folderPath?: string[]; title: string; dashID: string }> = ({
    folderPath,
    title,
    dashID
}) => {
    const [titleRef, titleOverflowing] = useOverflowing();
    const [folderRef, folderOverflowing] = useOverflowing();

    return (
        <Tooltip
            title={`${folderPath && folderPath.length > 0 ? folderPath.join(' / ') + ' / ' : ''}${title}`}
            disabled={!(titleOverflowing || folderOverflowing)}
            className='overflow-hidden flex'
        >
            <Link
                to={`/dashboard/${dashID}`}
                className='text-textLink truncate flex hover:underline whitespace-nowrap w-min max-w-full'
            >
                {folderPath && folderPath.length > 0 && (
                    <span
                        ref={folderRef}
                        className='basis-0 flex-1 truncate'
                        style={{ minWidth: `${Math.min(5, folderPath.join(' / ').length - 1)}ch` }}
                    >
                        {folderPath.join(' / ')}
                    </span>
                )}
                <span ref={titleRef} className='truncate'>
                    {folderPath && folderPath.length > 0 && <span className='px-1'>/</span>}
                    {title}
                </span>
            </Link>
        </Tooltip>
    );
};

const formatOptions = { addSuffix: true };
const formatRelativeDate = (date: Date) =>
    date && date.valueOf() ? `${formatDistanceToNow(date, formatOptions)}` : 'Unknown';

const MonitorsApplicationTable = ApplicationTable<ProjectedRow, string | boolean>();

export const MonitorsTable: FC<MonitorsTableProps> = ({ monitors }) => {
    const columns: ColumnDef<ProjectedRow>[] = [
        {
            id: 'name',
            header: 'Name',
            accessorKey: 'tileDisplayName',
            cell: ({ getValue, row }) => (
                <div className='flex items-center '>
                    <StateIndicator state={row.original.state} />
                    <span data-testid='monitorState' className='ml-2'>
                        {getValue()}
                    </span>
                </div>
            ),
            size: 300
        },
        {
            id: 'dashboard',
            header: 'Dashboard',
            accessorKey: 'dashboardDisplayName',
            cell: ({ getValue, row }) => (
                <DashboardDisplayName
                    folderPath={row.original.dashboardFolderPath}
                    title={getValue() as string}
                    dashID={row.original.dashId}
                />
            ),
            size: 250
        },
        {
            id: 'lastChanged',
            header: 'Last State Change',
            cell: ({ row }) => (row.original.lastChanged ? formatRelativeDate(row.original.lastChanged) : 'Unknown'),
            sortingFn: (rowA, rowB, columnId) => sortTimeAgo<ProjectedRow>(rowA, rowB, columnId),
            sortDescFirst: false,
            enableGlobalFilter: false,
            accessorKey: 'lastChanged',
            size: 200
        },
        {
            id: 'reason',
            header: 'Reason',
            accessorKey: 'reason',
            size: 400
        },
        {
            id: 'lastEvaluated',
            header: 'Last Evaluated',
            cell: ({ row }) =>
                row.original.lastEvaluated ? formatRelativeDate(row.original.lastEvaluated) : 'Unknown',
            sortingFn: (rowA, rowB, columnId) => sortTimeAgo<ProjectedRow>(rowA, rowB, columnId),
            sortDescFirst: false,
            enableGlobalFilter: false,
            accessorKey: 'lastEvaluated',
            size: 200
        },
        {
            id: 'nextEvaluation',
            header: 'Next Evaluation',
            accessorKey: 'nextEvaluation',
            cell: ({ row }) => {
                const nextEvaluationFormatted: string = row.original.nextEvaluation
                    ? formatRelativeDate(row.original.nextEvaluation)
                    : 'Unknown';
                if (row.original.consecutiveFailures >= Constants.consecutiveMonitoringFailureThreshold) {
                    return (
                        <Tooltip title={'⚠ Monitoring reduced due to repeated failures.'}>
                            <span>{nextEvaluationFormatted}</span>
                            <FontAwesomeIcon icon={faCircleQuestion} className='ml-2' />
                        </Tooltip>
                    );
                }

                return <span>{nextEvaluationFormatted}</span>;
            },
            sortingFn: (rowA, rowB, columnId) => sortInTime<ProjectedRow>(rowA, rowB, columnId),
            sortDescFirst: false,
            enableGlobalFilter: false,
            size: 200
        }
    ];

    const projectedRows: ProjectedRow[] = monitors.map((m) => ({
        state: m.state,
        dashId: m.dashId,
        dashboardDisplayName: m.dashboardDisplayName,
        tileDisplayName: m.tileDisplayName,
        lastChanged: m.lastChanged,
        lastEvaluated: m.lastEvaluated,
        reason: m.reason || (m.lastChanged ? `Changed from ${m.lastChangedFrom}.` : 'No recent changes.'),
        consecutiveFailures: m.consecutiveFailures,
        nextEvaluation: m.nextEvaluation,
        dashboardFolderPath: m.dashboardFolderPath
    }));

    return (
        <MonitorsApplicationTable
            config={{
                noDataMessage: 'There are no monitors configured.'
            }}
            data={projectedRows}
            columns={columns}
        />
    );
};
