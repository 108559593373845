import stringify from 'fast-json-stable-stringify';
import { DETAIL, LIST, MONITOR, ROLLUP, STATE, COUNT } from './common';

const DASHBOARDS = 'dashboards';
const OPEN_ACCESS_SHARE = 'OA_SHARE';
const NEW = 'new';
const VARIABLES = 'variables';

export const dashboardQueryKeys = {
    all: [DASHBOARDS], // Use sparingly - invalidates data for every individual dashboard - maybe you only need list and/or states?
    list: [DASHBOARDS, LIST],
    states: [DASHBOARDS, STATE],
    monitorCount: [DASHBOARDS, STATE, MONITOR, COUNT],
    stateRollup: (dashboardId?: string) => [DASHBOARDS, STATE, ROLLUP, String(dashboardId)],
    state: (dashboardIds?: string[]) => [DASHBOARDS, STATE, stringify(dashboardIds?.sort())],
    detail: (dashboardId?: string) => [DASHBOARDS, DETAIL, String(dashboardId)],
    variables: (dashboardId?: string, initial = {}) => [DASHBOARDS, VARIABLES, String(dashboardId), stringify(initial)],
    monitorsForDashboard: (dashboardIds?: string[]) => [DASHBOARDS, MONITOR, stringify(dashboardIds?.sort())],
    openAccessForDashboard: (dashboardId: string) => [OPEN_ACCESS_SHARE, dashboardId],
    openAccessNewLink: [OPEN_ACCESS_SHARE, NEW],
    /**
     * Key for storing default tile scopes generated when migrating
     * scopeless global data stream tiles to data source scopes.
     */
    defaultTileScope: (pluginConfigId: string) => ['defaultTileScope', pluginConfigId]
};
