import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import { useState, type FC } from 'react';
import SQLEditor from 'ui/editor/components/SQLEditor';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { useTileEditorContext } from '../../contexts/TileEditorContext';
import { useIsFetchingSQLData } from '../hooks/useIsFetchingSQLData';

export const AnalyticsQueryEditor: FC = () => {
    const { tileConfig, setTileConfig } = useTileEditorContext();
    const { datasets, setActivePreviewTab } = useDatasetContext();

    const [newSQL, setNewSQL] = useState(tileConfig.dataStream?.dataSourceConfig?.sql || '');
    const isFetching = useIsFetchingSQLData(newSQL);

    const hasChangedSQL = tileConfig.dataStream?.dataSourceConfig?.sql !== newSQL;

    const handleSQLUpdate = () => {
        if (newSQL) {
            setTileConfig((currentConfig) => ({
                ...currentConfig,
                dataStream: {
                    ...currentConfig.dataStream,
                    dataSourceConfig: {
                        ...currentConfig.dataStream?.dataSourceConfig,
                        sql: newSQL
                    }
                }
            }));

            // Move to the data tab so the user can see the output
            setActivePreviewTab(datasets.length + 1);
        }
    };

    return (
        <div className='flex flex-col flex-1 h-full min-w-0 pl-6 pr-5 space-y-4 py-7'>
            <div className='flex-1 min-h-0'>
                <SQLEditor content={newSQL} onValidUpdatedContent={(query) => setNewSQL(query || '')} />
            </div>
            <div className='flex items-center flex-shrink-0 pl-4 pr-4 space'>
                <div className='inline-flex items-center mr-4 space-x-2 text-sm'>
                    <FontAwesomeIcon icon={faQuestionCircle} className='text-textSecondary' />
                    <p>
                        Table names, column names, and values are case sensitive and may need to be enclosed with{' '}
                        <code>[]</code>.{' '}
                        <a
                            className='text-textLink'
                            href='https://squaredup.com/cloud/analytics/'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Learn more.
                        </a>
                    </p>
                </div>

                <Button
                    onClick={handleSQLUpdate}
                    className='inline-flex items-center ml-auto space-x-2'
                    disabled={!hasChangedSQL || isFetching}
                >
                    {isFetching ? <LoadingSpinner size={18} /> : 'Execute'}
                </Button>
            </div>
        </div>
    );
};
