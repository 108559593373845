import { WorkspaceId } from '@squaredup/ids';
import LoadingSpinner from 'components/LoadingSpinner';
import { ModalButtons } from 'components/Modal';
import Button from 'components/button';
import type { AccessControlEntryModel } from 'dynamo-wrapper';
import trackEvent from 'lib/analytics';
import { workspacePredefinedIconKey } from 'lib/fontawesome/fontawesome';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { PropsWithChildren, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Create, type Workspace } from 'services/WorkspaceService';
import ClipboardToast from 'ui/notifications/ClipboardToast';
import { useCreateWorkspaceModalContext } from './CreateWorkspaceModal';
import { getWorkspaceACLQueryKey, useWorkspaceACLUtils } from './WorkspaceModalAccessControlTab';

type CreateWorkspaceFormData = {
    displayName: string;
    description: string | undefined;
    type: { label: string; value: string } | undefined;
    tags: Array<{ label: string; value: string }>;
    accessControlToggle: boolean;
    openAccessEnabled: boolean;
    acl: AccessControlEntryModel[];
    avatar: string | undefined;
};

export const CreateWorkspaceForm = (props: PropsWithChildren<object>) => {
    const workspaceMutation = useWorkspaceMutation();
    const defaultFormValues = useDefaultFormValues();

    const form = useForm<CreateWorkspaceFormData>({ defaultValues: defaultFormValues, mode: 'all' });
    const isFormValid = Object.keys(form.formState.errors).length === 0 && form.formState.isValid;

    const workspaceACLUtils = useWorkspaceACLUtils();

    const { close } = useCreateWorkspaceModalContext();

    useEffect(() => {
        if (!form.getValues('acl').length) {
            form.setValue('acl', workspaceACLUtils.getDefaultACLClient());
        }
    }, [form, workspaceACLUtils]);

    const onSubmit = async (newWorkspace: CreateWorkspaceFormData) => {
        const defaultServerACL = workspaceACLUtils.getDefaultACLServer();

        await workspaceMutation.mutateAsync({
            displayName: newWorkspace.displayName.trim(),
            properties: {
                description: newWorkspace.description?.trim(),
                tags: newWorkspace.tags.map((x) => x.value),
                type: newWorkspace.type?.value,
                openAccessEnabled: newWorkspace.openAccessEnabled,
                avatarUploadedAt: newWorkspace.avatar ? Date.now() : undefined,
                avatarIconName: newWorkspace?.avatar?.startsWith(workspacePredefinedIconKey)
                    ? newWorkspace.avatar
                    : undefined
            },
            permissions: newWorkspace.accessControlToggle ? newWorkspace.acl : defaultServerACL,
            avatar: newWorkspace.avatar
        });
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className='flex flex-col flex-1 overflow-auto'>
                {props.children}

                <ModalButtons hideTopMargin>
                    <Button
                        type='button'
                        variant='tertiary'
                        className='capitalize'
                        onClick={() => close('cancel')}
                        data-testid='cancel-create-workspace-form'
                    >
                        cancel
                    </Button>
                    <Button
                        type='submit'
                        className='capitalize'
                        disabled={!isFormValid || form.formState.isSubmitting}
                        data-testid='submit-create-workspace-form'
                    >
                        {form.formState.isSubmitting && <LoadingSpinner size={18} className='mr-3' />}

                        <span>add</span>
                    </Button>
                </ModalButtons>
            </form>
        </FormProvider>
    );
};

const createWorkspace = (
    props: Pick<Workspace, 'displayName'> &
        Pick<Workspace['data'], 'properties'> & { permissions: AccessControlEntryModel[] } & { avatar?: string }
) => {
    return Create(props.displayName, undefined, undefined, props.permissions, props.properties, props.avatar);
};

const useDefaultFormValues = () => {
    return {
        displayName: undefined,
        description: undefined,
        type: undefined,
        tags: [],
        accessControlToggle: false,
        openAccessEnabled: true,
        acl: [],
        avatar: undefined
    };
};

const useWorkspaceMutation = () => {
    const queryClient = useQueryClient();
    const location = useLocation();
    const navigate = useNavigate();

    const modalCtx = useCreateWorkspaceModalContext();

    const workspaceMutation = useMutation(createWorkspace, {
        onMutate: async (newWorkspace) => {
            queryClient.cancelQueries(workspaceQueryKeys.all);
            queryClient.cancelQueries(getWorkspaceACLQueryKey({ id: undefined }));

            const optimisticWorkspace: Workspace = {
                id: new WorkspaceId().value,
                configId: Math.random().toString(),
                displayName: newWorkspace.displayName,
                tenant: '',
                type: '',
                data: {
                    links: { plugins: [], workspaces: [] },
                    properties: { ...newWorkspace.properties }
                }
            };

            queryClient.setQueryData<Workspace[]>(workspaceQueryKeys.list, (data) => {
                if (!data) {
                    return [];
                }
                return [...data, optimisticWorkspace];
            });

            queryClient.setQueryData<Workspace[]>(workspaceQueryKeys.forAdministration, (data) => {
                if (!data) {
                    return [];
                }
                return [...data, optimisticWorkspace];
            });
        },
        onSuccess: (newWorkspaceId, variables) => {
            if (process.env.NODE_ENV === 'production') {
                trackEvent('Workspace Created', { id: newWorkspaceId });
            }

            if (newWorkspaceId) {
                queryClient.setQueryData(workspaceQueryKeys.avatar(newWorkspaceId), variables.avatar);
            }
            modalCtx.close('exit');
        },
        onSettled: async (newWorkspaceId) => {
            await Promise.allSettled([
                queryClient.invalidateQueries(workspaceQueryKeys.all),
                queryClient.invalidateQueries(workspaceQueryKeys.list),
                queryClient.invalidateQueries(getWorkspaceACLQueryKey({ id: undefined }))
            ]);

            const inSettingsPage = location.pathname.includes('/settings/workspaces');
            if (!inSettingsPage && newWorkspaceId) {
                navigate(`/workspace/${newWorkspaceId}`);
            }
        },
        onError: () => {
            ClipboardToast('creating a workspace failed', true);
        }
    });

    return workspaceMutation;
};
