import { cn } from '@/lib/cn';
import { FormattedStreamValue, StreamData } from '@squaredup/data-streams';
import {
    ColumnDef,
    ColumnSizingInfoState,
    FilterFn,
    Row,
    Updater,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table';
import clsx from 'clsx';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useTileContext } from 'contexts/TileContext';
import { useVisualisationContext } from 'contexts/VisualisationContext';
import { getLinkInfo } from 'lib/getLinkInfo';
import { forwardRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Table } from 'ui/table/components/Table';
import { TableBody } from 'ui/table/components/TableBody';
import { TableCell } from 'ui/table/components/TableCell';
import { TableHead } from 'ui/table/components/TableHead';
import { TableHeader } from 'ui/table/components/TableHeader';
import { TablePagination } from 'ui/table/components/TablePagination';
import { TableRow } from 'ui/table/components/TableRow';
import { DataStreamTableGlobalFilter } from './DataStreamTableGlobalFilter';
import { DataStreamTableStyleModes, styles as allStyles } from './styles';
import { DataStreamTableConfig } from './types';

const globalFilterFn: FilterFn<any> = (row: Row<FormattedStreamValue[]>, columnId, value) => {
    const lowerCaseFilterQuery = value.toLowerCase();
    const lowerCaseColumnValue = (row.getValue(columnId) as FormattedStreamValue).formatted.toLowerCase();

    return lowerCaseColumnValue.includes(lowerCaseFilterQuery);
};

const defaultColumn: Partial<ColumnDef<FormattedStreamValue[]>> = {
    minSize: 70,
    enableSorting: true,
    enableGlobalFilter: true
};

interface DataStreamTableContentProps {
    config: DataStreamTableConfig;
    streamData: StreamData['rows'];
    columns: ColumnDef<FormattedStreamValue[], any>[];
    columnVisibility?: Record<string, boolean>;
    columnOrder?: string[];
    columnSizing?: Record<string, number>;
}

export const DataStreamTableContent = forwardRef<HTMLDivElement, DataStreamTableContentProps>(
    ({ config, streamData, columns, columnVisibility, columnOrder }, ref) => {
        const [globalFilter, setGlobalFilter] = useState('');
        const [columnSizingInfo, setColumnSizingInfo] = useState({} as ColumnSizingInfoState);

        const navigate = useNavigate();
        const { editing } = useDashboardContext();
        const { preview } = useTileContext();
        const { updateVisualisationConfig } = useVisualisationContext();

        const { pageSize, rowLinkColumnName } = config;

        const rowLink = rowLinkColumnName?.value !== 'None' ? rowLinkColumnName?.value : undefined;

        const onColumnSizingInfoChange = (newState: Updater<ColumnSizingInfoState>) => {
            const columnResizeInfoState = typeof newState === 'function' ? newState(columnSizingInfo) : newState;

            setColumnSizingInfo(columnResizeInfoState);

            if (editing && !columnResizeInfoState.isResizingColumn) {
                updateVisualisationConfig?.({
                    ...config,
                    resizedColumns: {
                        columnWidths: {
                            ...config.resizedColumns?.columnWidths,
                            ...getState().columnSizing
                        }
                    }
                });
            }
        };
        const {
            getCenterTotalSize,
            getHeaderGroups,
            getRowModel,
            getState,
            setPageSize,
            getCanPreviousPage,
            getPrePaginationRowModel,
            previousPage,
            nextPage,
            getCanNextPage
        } = useReactTable({
            data: streamData,
            columns,
            defaultColumn,
            globalFilterFn,
            columnResizeMode: 'onChange',
            enableSorting: !config.transpose,
            enableColumnResizing: !config.transpose,
            state: {
                columnVisibility,
                columnOrder,
                columnSizingInfo,
                globalFilter
            },
            initialState: {
                pagination: {
                    pageSize: pageSize ?? 10
                }
            },
            onColumnSizingInfoChange,
            getColumnCanGlobalFilter: () => true,
            getCoreRowModel: getCoreRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getPaginationRowModel: getPaginationRowModel()
        });

        const styles = config.transpose
            ? allStyles[DataStreamTableStyleModes.transpose]
            : allStyles[DataStreamTableStyleModes.default];

        const rows = getRowModel().rows;

        return (
            <>
                {!preview && (
                    <DataStreamTableGlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                )}

                <div className='flex flex-col w-full h-full overflow-hidden text-sm'>
                    <div
                        className='min-h-0 overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'
                        ref={ref}
                    >
                        <Table
                            className={styles.table}
                            style={{ width: config.transpose ? 'auto' : `${getCenterTotalSize()}px` }}
                        >
                            <TableHeader className={styles.thead}>
                                {getHeaderGroups().map((headerGroup) => (
                                    <TableRow key={headerGroup.id} className={clsx(styles.theadtr, 'h-[34px]')}>
                                        {headerGroup.headers.map((header) => (
                                            <TableHead
                                                key={header.id}
                                                style={{ width: config.transpose ? 'auto' : header.getSize() }}
                                                className={styles.th}
                                            >
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(header.column.columnDef.header, header.getContext())}

                                                {!config.transpose && (
                                                    <div
                                                        {...{
                                                            onMouseDown: header.getResizeHandler(),
                                                            onTouchStart: header.getResizeHandler(),
                                                            className: clsx(
                                                                'absolute w-1 opacity-0 h-full bg-dividerSecondary top-0 right-0 select-none touch-none cursor-ew-resize group-hover:opacity-100',
                                                                header.column.getIsResizing() &&
                                                                    '!opacity-100 !bg-textPrimary'
                                                            )
                                                        }}
                                                    />
                                                )}
                                            </TableHead>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableHeader>

                            <TableBody className={styles.tbody}>
                                {rows.length > 0 ? (
                                    rows.map((row, index) => {
                                        const even = index % 2 === 0;

                                        const rowLinkHref = rowLink
                                            ? ((row.getValue(rowLink) as FormattedStreamValue)?.value as string)
                                            : '';

                                        return (
                                            <TableRow
                                                key={row.id}
                                                className={cn(
                                                    styles.tr,
                                                    !even && 'bg-tagBackground',
                                                    rowLinkHref &&
                                                        'hover:bg-secondaryButtonBackgroundHover cursor-pointer'
                                                )}
                                                {...(rowLinkHref && {
                                                    onClick: () => {
                                                        const linkInfo = getLinkInfo(rowLinkHref);
                                                        const isTextSelected = window?.getSelection()?.toString();

                                                        if (isTextSelected) {
                                                            return;
                                                        }

                                                        if (linkInfo.isExternal) {
                                                            window.open(rowLinkHref, '_blank');
                                                        } else {
                                                            navigate(linkInfo.relativeURL);
                                                        }
                                                    },
                                                    role: 'link',
                                                    'data-href': rowLinkHref
                                                })}
                                            >
                                                {row.getVisibleCells().map((cell) => (
                                                    <TableCell
                                                        key={cell.id}
                                                        style={{
                                                            width: config.transpose ? 'auto' : cell.column.getSize()
                                                        }}
                                                        className={styles.td}
                                                    >
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow className='!w-full border-dividerPrimary border-x'>
                                        <TableCell
                                            colSpan={getHeaderGroups()[0]?.headers.length}
                                            className='w-full my-auto'
                                        >
                                            No data to show
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>

                    {getPrePaginationRowModel().rows.length > 10 && (
                        <TablePagination
                            config={config}
                            getState={getState}
                            getPrePaginationRowModel={getPrePaginationRowModel}
                            getCanPreviousPage={getCanPreviousPage}
                            getCanNextPage={getCanNextPage}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            setPageSize={setPageSize}
                        />
                    )}
                </div>
            </>
        );
    }
);
