import { useWorkspace } from 'queries/hooks/useWorkspace';
import { datasourceConfigQueryKeys } from 'queries/queryKeys/datasourceConfigKeys';
import { perspectiveQueryKeys } from 'queries/queryKeys/perspectiveKeys';
import { variableObjectQueryKeys } from 'queries/queryKeys/variableObjectKeys';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { useMutation, useQueryClient } from 'react-query';
import {
    AddPluginLinksToWorkspace,
    Update as UpdateWorkspace,
    Workspace
} from 'services/WorkspaceService';
import { invalidateAfterWorkspaceLinksChange } from 'services/WorkspaceUtil';

interface Links {
    plugins: string[];
    workspaces: string[];
}

export const useWorkspaceLinking = (workspaceId: string) => {
    const queryClient = useQueryClient();

    const { data: workspaceData, isLoading } = useWorkspace(workspaceId);

    const workspaceName = workspaceData?.displayName;

    const linkConfigToWorkspace = async (datasourceConfigId: string, invalidate = true) => {
        await AddPluginLinksToWorkspace(workspaceId, [datasourceConfigId]);
        if (invalidate) {
            return invalidateAfterLinkingConfig();
        }
    };

    const invalidateAfterLinkingConfig = async () => {
        return Promise.all([
            invalidateAfterWorkspaceLinksChange(queryClient, workspaceId),
            queryClient.invalidateQueries(workspaceQueryKeys.all)
        ]);
    };

    const myMutation = useMutation({
        mutationFn: async (value: Links) =>
            UpdateWorkspace(workspaceId, undefined, undefined, {
                workspaces: value.workspaces,
                plugins: value.plugins
            }),
        onMutate: async (value: Links) => {
            queryClient.cancelQueries(workspaceQueryKeys.all);

            // Snapshot for rollback
            const previousSource = queryClient.getQueryData<Workspace[]>(workspaceQueryKeys.list);

            // Optimistically update
            queryClient.setQueryData<Workspace[]>(workspaceQueryKeys.list, (workspaces) => 
                workspaces?.map((workspace) => workspace.id === workspaceId ? {
                    ...workspace,
                    data: {
                        ...workspace.data,
                        links: {
                            ...workspace.data.links,
                            ...value
                        }
                    }
                } : workspace) ?? []
            );

            return { previousSource };
        },
        onError: async (error, variables, context) => {
            if (context == null) {
                return;
            }
            return queryClient.setQueryData(workspaceQueryKeys.list, context.previousSource);
        },
        onSettled: () => {
            queryClient.removeQueries(variableObjectQueryKeys.all);
            queryClient.removeQueries(perspectiveQueryKeys.all);
            queryClient.refetchQueries(workspaceQueryKeys.all);
            queryClient.refetchQueries(datasourceConfigQueryKeys.all);
        }
    });

    const unlinkConfigFromWorkspace = async (datasourceConfigId: string) => {
        if (!workspaceData) {
            throw new Error('Workspace unrecognised. Please reload the page or navigate to a different workspace.');
        }
        const { plugins, workspaces } = workspaceData.data.links;
        const newPlugins = plugins.filter((v) => v !== datasourceConfigId);
        myMutation.mutate({ workspaces, plugins: newPlugins });
    };

    const isLinked = (datasourceConfigId: string) => {
        if (!workspaceData) {
            throw new Error('Workspace unrecognised. Please reload the page or navigate to a different workspace.');
        }
        return workspaceData.data.links.plugins.includes(datasourceConfigId);
    };

    return {
        isLoading,
        isLinked,
        workspaceName,
        linkConfigToWorkspace,
        invalidateAfterLinkingConfig,
        unlinkConfigFromWorkspace,
        hasWorkspaceData: workspaceData !== undefined
    };
};
