import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faCircleCheck, faCircleXmark, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ModalButtons } from 'components/Modal';
import { Button } from 'components/button/Button';
import Field from 'components/forms/field/Field';
import { useLinkedPluginConfigs } from 'components/hooks/useLinkedPluginConfigs';
import { useWorkspacePermissions } from 'components/hooks/useWorkspacePermissions';
import { DashboardType } from 'dashboard-engine/types/Dashboard';
import { useWorkspaces } from 'queries/hooks/useWorkspaces';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { datasourceConfigQueryKeys } from 'queries/queryKeys/datasourceConfigKeys';
import { datasourceQueryKeys } from 'queries/queryKeys/datasourceKeys';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { ReactNode, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { CopyTo } from 'services/DashboardService';
import { getErrorMessage } from './utils';

type ResultMessageProps = {
    state: 'success' | 'error' | 'warning';
    title: ReactNode;
    body?: ReactNode;
    list?: ReactNode[];
    className?: string;
};

export const ResultMessage: React.FC<ResultMessageProps> = ({ state, title, body, list, className }) => {
    const statusClasses = {
        error: {
            fg: 'statusErrorPrimary',
            icon: faCircleXmark
        },
        success: {
            fg: 'statusHealthyPrimary',
            icon: faCircleCheck
        },
        warning: {
            fg: 'statusWarningPrimary',
            icon: faExclamationCircle
        }
    };

    const classesToUse = statusClasses[state];

    return (
        <div className={cn('w-full max-h-full', className)}>
            <div className={`relative p-4 text-left border-2 rounded border-${classesToUse.fg}`}>
                <div className='absolute top-0 left-0 w-full h-full opacity-10'></div>
                <div className='relative flex flex-col h-full'>
                    <div className='flex'>
                        <div className='mr-3 align-middle'>
                            <FontAwesomeIcon icon={classesToUse.icon} className={`text-base text-${classesToUse.fg}`} />
                        </div>
                        <Text.H4 className={`text-${classesToUse.fg}`} aria-label='resultText'>
                            {title}
                        </Text.H4>
                    </div>
                    <div className='ml-7'>
                        {body && (
                            <Text.Body className='mt-2' aria-label='errorText'>
                                {body}
                            </Text.Body>
                        )}
                        {list && (
                            <ul className='mt-2 overflow-y-scroll max-h-64 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                                {list.map((listItem) => (
                                    <li>
                                        <Text.Body>{listItem}</Text.Body>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

type DashboardCopyToModalProps = {
    dashboard: DashboardType | undefined;
    workspace: string;
    close: () => void;
};

export const DashboardCopyToModal: React.FC<DashboardCopyToModalProps> = ({ dashboard, workspace, close }) => {
    const queryClient = useQueryClient();
    const [isCopying, setIsCopying] = useState(false);
    const [error, setError] = useState<Record<string, any> | undefined>(undefined);
    const [success, setSuccess] = useState<Record<string, any> | undefined>(undefined);
    const navigate = useNavigate();

    const { data: linkedPlugins, isLoading: isLoadingLinkedPluginConfigs } = useLinkedPluginConfigs(workspace);
    const { data: workspaces, isLoading: isLoadingWorkspaces } = useWorkspaces();

    const { canWrite, isLoading: isLoadingPermissions } = useWorkspacePermissions();

    const isLoading = isLoadingWorkspaces || isLoadingPermissions || isLoadingLinkedPluginConfigs;

    const handleMove = useMutation(async (data: Record<string, Record<string, string>>) => {
        if (success) {
            close();
            navigate(`/dashboard/${success.newDashboardId}`);
        } else {
            const sourceWorkspaceId = workspace;
            const targetWorkspaceId = data.targetWorkspace.value;
            const targetWorkspaceName = data.targetWorkspace.label;

            if (dashboard === undefined) {
                throw new Error('Dashboard unrecognised. Please reload the page or navigate to a different dashboard.');
            }

            setError(undefined);
            setIsCopying(true);

            const response = await CopyTo(dashboard.id, sourceWorkspaceId, targetWorkspaceId);

            if (response.migrationResult.status === 201) {
                const newDashboardId = response.migrationResult.targetDashboard.dashboardId;
                setIsCopying(false);
                setSuccess({ newDashboardId, targetWorkspaceName });

                // Ensure navigation is kept up to date
                queryClient.invalidateQueries(workspaceQueryKeys.all);
                queryClient.invalidateQueries(dashboardQueryKeys.all);
                queryClient.invalidateQueries(datasourceQueryKeys.all);
                queryClient.invalidateQueries(datasourceConfigQueryKeys.all);
            } else {
                let pluginList;

                if (response.migrationResult.failureCode === 'PluginLinkPermission') {
                    pluginList = response.migrationResult.linksFailed.plugins.map(
                        (configId: string) =>
                            linkedPlugins?.find((linkedPlugin) => linkedPlugin.id === configId)?.displayName
                    );
                }

                const pluginDisplayName = pluginList?.[0];

                if (pluginList?.length === 1) {
                    pluginList = undefined;
                }

                setIsCopying(false);
                setError({
                    targetWorkspaceName,
                    data: response.migrationResult,
                    pluginDisplayName,
                    pluginList
                });
            }
        }
    });

    const methods = useForm({
        shouldUnregister: true,
        mode: 'all'
    });

    const {
        handleSubmit,
        formState: { isValid, errors, isSubmitting }
    } = methods;

    if (dashboard?.variables?.length) {
        return (
            <Modal title='Copy dashboard' close={close} fullWidth maxWidth='max-w-3xl'>
                <hr className='border-dividerPrimary' />
                <div className='px-8 py-4'>
                    <ResultMessage
                        state='error'
                        title='Unable to copy this dashboard'
                        body='Copying a dashboard that uses a dashboard variable is not supported at this time.'
                        className='mt-8'
                    />
                </div>
                <ModalButtons>
                    <Button type='button' variant='tertiary' onClick={() => close()}>
                        Close
                    </Button>
                </ModalButtons>
            </Modal>
        );
    }

    return (
        <Modal title='Copy dashboard' close={close} fullWidth maxWidth='max-w-3xl'>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit((data) => handleMove.mutateAsync(data))}>
                    <hr className='border-dividerPrimary' />
                    <div className='px-8 py-4'>
                        {isLoading ? (
                            <div className='flex justify-center my-2'>
                                <div>
                                    <LoadingSpinner />
                                </div>
                            </div>
                        ) : (
                            <Field.Input
                                name='targetWorkspace'
                                type='autocomplete'
                                label='Destination workspace'
                                placeholder='Search or select a workspace'
                                options={workspaces
                                    ?.filter((w) => canWrite(w) && w.id !== workspace)
                                    ?.map((w) => ({ label: w.displayName, value: w.id }))}
                                isMulti={false}
                                validation={{ required: true }}
                                isDisabled={isLoading || isSubmitting || success}
                                noOptionsMessage={() => 'No workspaces available.'}
                            />
                        )}
                        {error && (
                            <ResultMessage
                                state='error'
                                title={`Unable to copy this dashboard to "${error.targetWorkspaceName}" workspace`}
                                body={getErrorMessage(error)}
                                className='mt-8'
                                list={error.pluginList}
                            />
                        )}
                        {success && (
                            <ResultMessage
                                state='success'
                                title={`Successfully copied "${dashboard?.displayName}" dashboard to "${success.targetWorkspaceName}" workspace`}
                                className='mt-8'
                            />
                        )}
                    </div>
                    <ModalButtons>
                        <Button type='button' variant='tertiary' onClick={() => close()}>
                            {success ? 'Close' : 'Cancel'}
                        </Button>
                        <Button
                            type='submit'
                            disabled={isLoading || isSubmitting || !isValid || Object.keys(errors).length > 0}
                            data-testid='submit-workspaces'
                        >
                            {isCopying ? (
                                <LoadingSpinner size={18} />
                            ) : success ? (
                                'Go to dashboard'
                            ) : error ? (
                                'Try again'
                            ) : (
                                'Copy'
                            )}
                        </Button>
                    </ModalButtons>
                </form>
            </FormProvider>
        </Modal>
    );
};
