export enum DataStreamTableStyleModes {
    default,
    transpose
}
type DataStreamTableStyles = {
    [key in DataStreamTableStyleModes]: {
        table?: string;
        thead?: string;
        tbody?: string;
        theadtr?: string;
        tr?: string;
        th?: string;
        td?: string;
    };
};

export const styles: DataStreamTableStyles = {
    [DataStreamTableStyleModes.default]: {
        thead: 'block border-b border-dividerPrimary',
        theadtr: 'divide-x divide-dividerSecondary',
        tbody: 'block border-dividerPrimary border-b',
        tr: 'divide-x divide-dividerPrimary w-fit',
        td: 'first:border-l last:!border-r border-dividerPrimary'
    },
    [DataStreamTableStyleModes.transpose]: {
        table: 'flex',
        theadtr: 'flex-col border-dividerPrimary border-t',
        th: 'block px-4 py-2 border-dividerSecondary border-b',
        tbody: 'flex w-full border-dividerPrimary border-t',
        tr: 'flex-1 flex-col border-dividerPrimary border-r w-full',
        td: 'block border-dividerPrimary border-b'
    }
};
