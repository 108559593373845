import { Button } from '@/components/Button';
import Text from '@/components/Text';
import {
    ShapeName,
    StreamDataColumn,
    StreamPrimitive,
    findConverter,
    getShape,
    getValueShapeOf
} from '@squaredup/data-streams';
import { isDefined } from '@squaredup/utilities';
import { ColumnDef, Row, createColumnHelper } from '@tanstack/react-table';
import { BehindFlag } from 'components/BehindFlag';
import { Chevron } from 'components/Chevron';
import { TruncatedText } from 'components/TruncatedText';
import Field from 'components/forms/field/Field';
import { AutocompleteOption } from 'components/forms/jsonForms/autocompleteOptions';
import { renderCellValue } from 'dashboard-engine/visualisations/DataStreamTable/RenderCell';
import stringify from 'fast-json-stable-stringify';
import { useFlag } from 'lib/useFlag';
import { orderBy, toString, upperFirst } from 'lodash';
import { ApplicationTable } from 'pages/components/ApplicationTable/ApplicationTable';
import { Action } from 'pages/components/ApplicationTable/types';
import { Dispatch, FC, ReactNode, useCallback, useMemo } from 'react';
import EasyEdit from 'react-easy-edit';
import { Controller } from 'react-hook-form';
import { GroupBase } from 'react-select';
import { FormatExpressionEditor } from './FormatExpressionEditor';
import { toFormFieldName } from './MetadataEditorForm';
import { DataStreamMetadataAction, columnCanBeRemoved } from './MetadataEditorReducer';
import { hasShapeConfigFields, metadataEditorShapes } from './MetadataEditorShapes';
import {
    CloneStateColumn,
    DataStreamMetadataState,
    DataStreamMetadataStateColumn,
    StateColumn,
    getColumnShape,
    isNonPatternColumn
} from './MetadataEditorState';
import { findClones } from './MetadataEditorTileState';
import { ShapeConfigField } from './ShapeConfigField';

export type MetadataTableRow = {
    name: string;
    hasMetadata: boolean;
    isCloneable: boolean;
    isBeingCloned: boolean;
    displayName: {
        value: string;
        field: ReactNode;
    };
    shape: {
        value: string;
        field: ReactNode;
    };
    sampleValue: {
        value: StreamPrimitive;
        field: ReactNode;
    };
    sampleFormattedValue: {
        value: string;
        field: ReactNode;
    };
};

const getShapeOptionGroups = (shapeName?: ShapeName): GroupBase<AutocompleteOption>[] => {
    const valueShapeName = shapeName ? getValueShapeOf(shapeName)?.name : shapeName;

    const optionGroups: GroupBase<AutocompleteOption>[] = metadataEditorShapes.map((group) => ({
        label: group.groupName,
        options: group.shapes
            .filter((s) => {
                if (valueShapeName == null) {
                    return false;
                }

                const targetValueShapeName = s.isPrimitive || !s.isTotal ? s.name : s.valueShape.name;

                const requiresConversion = valueShapeName !== targetValueShapeName;
                const hasConverter = findConverter(valueShapeName, targetValueShapeName) != null;

                return !requiresConversion || hasConverter;
            })
            .map((s) => ({
                label: upperFirst(s.displayName),
                value: s.name
            }))
    }));

    const selectedShapeIsValid =
        shapeName == null || optionGroups.some((g) => g.options.some((o) => o.value === shapeName));

    if (!selectedShapeIsValid) {
        const shape = getShape(shapeName);

        if (shape != null) {
            // Selected shape is not one you can normally pick in the metadata editor
            // (e.g. a legacy shape like number.0), so add a disabled option to avoid
            // the internal shape name being displayed to the user
            optionGroups.push({
                label: 'Unsupported',
                options: [
                    { isDisabled: true, label: shape.displayName, labelText: shape.displayName, value: shape.name }
                ]
            });
        }
    }

    return optionGroups;
};

const ShapeDropdown: FC<{ columnName: string; sourceShapeName?: ShapeName; isOverridden: boolean }> = ({
    columnName,
    sourceShapeName,
    isOverridden
}) => {
    return (
        <Field.Input
            type='autocomplete'
            name={`${toFormFieldName(columnName)}.shape`}
            options={getShapeOptionGroups(sourceShapeName)}
            isMulti={false}
            selectOptionsAs='valueString'
            menuPosition='fixed'
            menuShouldBlockScroll={true}
            isClearable={isOverridden}
        />
    );
};

const EditableDisplayName: FC<{ columnName: string }> = ({ columnName }) => {
    return (
        <Text.Body>
            <Controller
                name={`${toFormFieldName(columnName)}.displayName`}
                render={({ field }) => {
                    return (
                        <EasyEdit
                            type='text'
                            value={field.value ?? ''}
                            placeholder={''}
                            onSave={(v: string) => field.onChange(v.trim())}
                            saveOnBlur={true}
                        />
                    );
                }}
            ></Controller>
        </Text.Body>
    );
};

export const getColumnsInDisplayOrder = (
    state: DataStreamMetadataState,
    rootColumns?: (StateColumn | CloneStateColumn)[]
): DataStreamMetadataStateColumn[] => {
    rootColumns ??= [...state.columnMap.values()].filter((c): c is StateColumn => !c.isPattern && !c.isClone);

    /**
     * If we don't have defined metadata, the order of the overrides will change the
     * displayIndex of the columns, which we don't want.
     *
     * By not sorting we keep the existing order which was set when the state was created.
     * This avoids the UI jumping around unexpectedly.
     */
    const order = state.hasDefinedMetadata
        ? (c: StateColumn | CloneStateColumn) => c.metadata?.displayIndex ?? Number.MAX_SAFE_INTEGER
        : () => Number.MAX_SAFE_INTEGER;

    const sortedColumns = orderBy(rootColumns, order);

    return sortedColumns.flatMap((c) => [c, ...getColumnsInDisplayOrder(state, findClones(state, c.columnName))]);
};

export const FormatExpressionField: FC<{ columnName: string; columns: StreamDataColumn[] }> = ({
    columnName,
    columns
}) => {
    return (
        <div className='max-w-4xl'>
            <Field.Label label='Format Expression'>
                <Controller
                    name={`${toFormFieldName(columnName)}.formatExpression`}
                    render={({ field: { value, onChange } }) => (
                        <FormatExpressionEditor
                            key={`${toFormFieldName(columnName)}.formatExpression_field`}
                            name={`${toFormFieldName(columnName)}.formatExpression_field`}
                            value={value || ''}
                            onChange={onChange}
                            columns={columns}
                        />
                    )}
                />
            </Field.Label>
        </div>
    );
};

/**
 * Override how an object is serialised to JSON to avoid circular references
 */
const makeSerialisable = (object: { value: string; field: ReactNode }): { value: string; field: ReactNode } => {
    const serialisable = { ...object, toJSON: () => object.value };

    return serialisable;
};

const getCommonRowProps = (
    columnName: string,
    column: StateColumn | CloneStateColumn
): Omit<MetadataTableRow, 'displayName'> => {
    const shapeName: ShapeName | undefined =
        getColumnShape(column.metadataOverrides?.shape)?.name ?? column.metadata?.shapeName;

    const FormattedValue = shapeName ? renderCellValue(shapeName, column.sampleValue, { align: 'left' }) : <></>;

    const sourceShapeName = getColumnShape(column.metadataOverrides?.shape)?.name ?? column.metadata?.shapeName;

    return {
        name: columnName,
        hasMetadata: column.metadata != null,
        isCloneable: column.isCloneable,
        isBeingCloned: column.isClone && column.isBeingCloned,
        shape: makeSerialisable({
            value: shapeName ?? '',
            field: (
                <ShapeDropdown
                    columnName={columnName}
                    sourceShapeName={sourceShapeName}
                    isOverridden={Boolean(column.metadataOverrides?.shape)}
                />
            )
        }),
        sampleValue: makeSerialisable({
            value: toString(column.sampleValue.raw),
            field: <TruncatedText title={toString(column.sampleValue.raw)}></TruncatedText>
        }),
        sampleFormattedValue: makeSerialisable({
            value: column.sampleValue.formatted,
            field: FormattedValue
        })
    };
};

export const toTableData = (state: DataStreamMetadataState): MetadataTableRow[] => {
    return getColumnsInDisplayOrder(state)
        .filter(isNonPatternColumn)
        .map((column): MetadataTableRow => {
            const columnDisplayName = column.metadataOverrides?.displayName ?? column.metadata?.displayName;

            if (column.isClone) {
                const cloneSource = state.columnMap.get(column.sourceColumnName);
                const cloneSourceColumn = cloneSource?.isPattern ? undefined : cloneSource;

                const cloneSourceDisplayName =
                    cloneSourceColumn?.metadataOverrides?.displayName ??
                    cloneSourceColumn?.metadata?.displayName ??
                    column.sourceColumnName;

                if (column.isBeingCloned) {
                    return {
                        name: column.columnName,
                        hasMetadata: false,
                        isCloneable: false,
                        isBeingCloned: column.isClone && column.isBeingCloned,
                        displayName: makeSerialisable({
                            value: `Copying ${cloneSourceDisplayName}...`,
                            field: (
                                <TruncatedText title={`Copying ${cloneSourceDisplayName}...`}>
                                    Copying {cloneSourceDisplayName}...
                                </TruncatedText>
                            )
                        }),
                        shape: { value: '', field: '' },
                        sampleValue: { value: '', field: '' },
                        sampleFormattedValue: { value: '', field: '' }
                    };
                }

                return {
                    ...getCommonRowProps(column.columnName, column),
                    displayName: makeSerialisable({
                        value: columnDisplayName ?? column.columnName,
                        field: (
                            <>
                                <TruncatedText title={`Copy of ${cloneSourceDisplayName}`}>
                                    <Text.SmallBody className='text-secondary'>
                                        Copy of {cloneSourceDisplayName}
                                    </Text.SmallBody>
                                </TruncatedText>
                                <EditableDisplayName columnName={column.columnName} />
                            </>
                        )
                    })
                };
            }

            return {
                ...getCommonRowProps(column.columnName, column),
                displayName: makeSerialisable({
                    value: columnDisplayName ?? column.columnName,
                    field: <EditableDisplayName columnName={column.columnName} />
                })
            };
        });
};

const columnHelper = createColumnHelper<MetadataTableRow>();
const tableColumns: ColumnDef<MetadataTableRow, string>[] = [
    columnHelper.display({
        id: 'rowExpandToggle',
        cell: ({ row }) => {
            if (!row.getCanExpand()) {
                return <></>;
            }

            return (
                <Button variant='tertiary' onClick={row.getToggleExpandedHandler()} className='flex cursor-pointer'>
                    <Chevron direction={row.getIsExpanded() ? 'up' : 'down'} className='flex' />
                </Button>
            );
        },
        size: 5,
        minSize: 5
    }),
    columnHelper.accessor((row) => row.displayName.value, {
        id: 'displayName',
        header: 'Name',
        cell: ({ row }) => {
            return row.original.displayName.field;
        },
        size: 150
    }),
    columnHelper.accessor((row) => row.shape.value, {
        header: 'Type',
        id: 'shape',
        cell: ({ row }) => {
            return row.original.shape.field;
        },
        size: 150
    }),
    columnHelper.accessor((row) => row.sampleValue.value, {
        header: 'Value',
        id: 'sampleValue',
        cell: ({ row }) => {
            return row.original.sampleValue.field;
        },
        size: 250
    }),
    columnHelper.accessor((row) => row.sampleFormattedValue.value, {
        header: 'Formatted',
        id: 'sampleFormattedValue',
        cell: ({ row }) => {
            return row.original.sampleFormattedValue.field;
        },
        size: 250
    })
];

const Table = ApplicationTable<MetadataTableRow, string>();

export const MetadataEditorTable: FC<{
    state: DataStreamMetadataState;
    dispatch: Dispatch<DataStreamMetadataAction>;
}> = ({ state, dispatch }) => {
    const isFormatExpressionUiEnabled = useFlag('metadataEditingFormatExpressionsUi');

    const actions = useMemo(
        (): Action[] => {
            return [
                {
                    icon: 'clone',
                    visible: (row: MetadataTableRow) => row.isCloneable,
                    tooltip: 'Clone column',
                    action: (row: MetadataTableRow) => {
                        return dispatch({ type: 'cloneColumn', sourceColumnName: row.name });
                    },
                    loading: (row: MetadataTableRow) => {
                        const foundColumn = state.columnMap.get(row.name);

                        if (!foundColumn || foundColumn.isPattern) {
                            return false;
                        }

                        const isBeingCloned = findClones(state, foundColumn.columnName).some((c) => c.isBeingCloned);

                        return isBeingCloned;
                    }
                },
                {
                    icon: 'trash',
                    visible: ({ name }: MetadataTableRow) => {
                        return columnCanBeRemoved(state, name);
                    },
                    tooltip: 'Delete column',
                    action: (column: MetadataTableRow) => {
                        dispatch({ type: 'removeColumn', columnName: column.name });
                    }
                }
            ];
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, stringify(state)]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const tableData = useMemo(() => toTableData(state), [stringify(state)]);

    const getRowCanExpand = useCallback(
        (row: Row<MetadataTableRow>) =>
            row.original.hasMetadata && (isFormatExpressionUiEnabled || hasShapeConfigFields(row.original.shape.value)),
        [isFormatExpressionUiEnabled]
    );
    const renderExpandedRow = useCallback(
        ({ row }: { row: Row<MetadataTableRow> }) => {
            /**
             * If someone changes the shape so we no longer have shape config fields while the
             * row is expanded, we need to avoid showing an empty row expansion
             */
            if (!row.getCanExpand()) {
                return <></>;
            }

            return (
                <div className='flex flex-col gap-6 p-4 bg-backgroundPrimary'>
                    <BehindFlag flagName='metadataEditingFormatExpressionsUi'>
                        <FormatExpressionField
                            key={`${row.original.name}_FormatExpression`}
                            columnName={row.original.name}
                            columns={[...state.columnMap.values()]
                                .filter(isNonPatternColumn)
                                .map((c) => c.metadata)
                                .filter(isDefined)}
                        />
                    </BehindFlag>

                    <ShapeConfigField
                        key={`${row.original.name}_ShapeConfig`}
                        columnName={row.original.name}
                        shapeName={row.original.shape.value}
                    />
                </div>
            );
        },
        [state.columnMap]
    );

    return (
        <Table
            classNames={{ actionColumn: 'from-tileBackground', tableHeader: 'z-10' }}
            config={{ actions, renderExpandedRow, getRowCanExpand }}
            columns={tableColumns}
            data={tableData}
            getRowId={row => row.name}
            hiddenActions='collapse'
        />
    );
};
