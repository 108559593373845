import { DashboardTileContent } from 'dashboard-engine/types/Dashboard';
import { Layout } from 'lib/gridLayout';
import { v4 as uuidv4 } from 'uuid';

// Helper function to stringify the layout data for comparison
export const layoutDataToJsonString = (layout: Layout) => {
    return JSON.stringify(
        layout
            .map(({ i, x, y, w, h }) => ({ i, x, y, w, h }))
            .sort((a, b) => {
                if (a.i < b.i) {
                    return -1;
                }
                if (a.i > b.i) {
                    return 1;
                }
                return 0;
            })
    );
};

export const layoutFill = (rows: number, columns: number) => {
    let grid: DashboardTileContent[] = [];

    for (let y = 0; y < rows; y++) {
        for (let x = 0; x < columns; x++) {
            grid.push({ i: uuidv4(), w: 1, h: 1, x, y, config: {} });
        }
    }

    return grid;
};

export const numRows = (layout: Layout) => {
    return layout.reduce((max, current) => Math.max(max, current.y + current.h), 0);
};

export const findConfig = (config: DashboardTileContent[], i: string) => {
    return config.find((c) => c.i === i)?.config || {};
};

export const mergeConfig = (layout: Layout, config: DashboardTileContent[]) => {
    return layout.map((cell) => {
        const cellConfig = findConfig(config, cell.i);
        return {
            ...cell,
            config: cellConfig
        };
    });
};
