import type { FormattedStreamData, FormattedStreamValue } from '@squaredup/data-streams';
import { getMonthlyBillingPeriod } from '@squaredup/utilities';
import { addDays, differenceInDays } from 'date-fns';
import type { MetricsResults } from 'dynamo-wrapper';

const purposes = ['open-access', 'monitoring', 'public-api', 'other'] as const;
const displayNames = ['Shared dashboards', 'Monitors', 'API requests', 'Other'] as const;
type Purpose = (typeof purposes)[number];
type PurposeDisplayName = (typeof displayNames)[number];

const purposeOrder: Record<PurposeDisplayName, number> = {
    'API requests': 1,
    'Shared dashboards': 2,
    Monitors: 3,
    Other: 4
};

const purposeDisplayNames: Record<Purpose, PurposeDisplayName> = {
    monitoring: 'Monitors',
    'open-access': 'Shared dashboards',
    'public-api': 'API requests',
    other: 'Other'
};

type MetricValuesByPurpose = Record<PurposeDisplayName, number>;
const purposesToShow: PurposeDisplayName[] = ['Shared dashboards', 'Monitors', 'API requests'];

export const requestMetricsToBarChartData = 
    (queryGroups: MetricsResults[], date: Date = new Date()): FormattedStreamData => {
    const valuesByTimestamp: Record<string, MetricValuesByPurpose> = {};
    const { start, end } = getMonthlyBillingPeriod(date);

    // Initialise metrics data with empty record for each daily timestamp.
    const dayCount = differenceInDays(end, start) + 1;
    for (let dayIndex = 0; dayIndex < dayCount; dayIndex++) {
        const keyDate = addDays(start, dayIndex).toISOString();
        valuesByTimestamp[keyDate] = {} as MetricValuesByPurpose;
    }

    // Fill in the metric values from the supplied metrics.
    queryGroups.forEach((group) =>
        group.MetricDataResults[0].Timestamps.forEach((v, i) => {
            const purpose =
                purposeDisplayNames[(group.MetricDataResults[0].Dimensions?.[0].Value as Purpose) ?? 'other'];
            const values = group.MetricDataResults[0].Values;
            valuesByTimestamp[v] = { ...valuesByTimestamp[v], [purpose]: values[i] };
        })
    );

    // Build data stream rows for the metrics we want to display.
    const rows: FormattedStreamValue[][] = [];

    for (const key in valuesByTimestamp) {
        if (!Object.hasOwn(valuesByTimestamp, key)) {
            continue;
        }
        purposesToShow.forEach((purpose) => {
            let value = valuesByTimestamp[key][purpose] ?? 0;
            rows.push([
                { raw: value, value, formatted: value.toString() },
                { raw: key, value: key, formatted: key },
                { raw: purpose, value: purpose, formatted: purpose },
                {
                    raw: purposeOrder[purpose],
                    value: purposeOrder[purpose],
                    formatted: purposeOrder[purpose].toString()
                }
            ]);
        });
    }

    rows.sort((s1, s2) => (s2[3].raw as number) - (s1[3].raw as number));

    const rawRowCount = queryGroups.reduce((acc, cur) => {
        acc += cur.MetricDataResults[0].Values.length;
        return acc;
    }, 0);

    return {
        rows: rows,
        metadata: {
            isGrouped: false,
            rawRowCount,
            requestStats: {
                total: 1,
                failed: 0,
                succeeded: 1,
                succeededWithWarnings: 0,
                completedAt: new Date().getTime()
            },
            rowData: [],
            columnDefinitions: {
                dataStreamDefinition: [
                    {
                        name: 'count',
                        shape: 'number',
                        role: 'value'
                    },
                    {
                        name: 'date',
                        displayName: 'Timestamp',
                        shape: 'date'
                    },
                    {
                        name: 'purpose',
                        displayName: 'Purpose',
                        shape: 'string'
                    },
                    {
                        name: 'orderKey',
                        displayName: 'orderKey',
                        shape: 'number'
                    }
                ],
                pluginResponse: [],
                request: []
            },
            columns: [
                {
                    name: 'count',
                    displayName: 'Count',
                    displayIndex: 1,
                    shapeName: 'shape_number',
                    targetShapeName: 'shape_number',
                    valueShapeName: 'shape_number',
                    rawShapeConfig: { decimalPlaces: 0 },
                    role: 'value',
                    visible: true,
                    expand: []
                },
                {
                    name: 'date',
                    displayName: 'Timestamp',
                    displayIndex: 2,
                    shapeName: 'shape_date',
                    targetShapeName: 'shape_date',
                    valueShapeName: 'shape_string',
                    rawShapeConfig: {},
                    role: 'none',
                    visible: true,
                    expand: []
                },
                {
                    name: 'purpose',
                    displayName: 'Purpose',
                    displayIndex: 3,
                    shapeName: 'shape_string',
                    targetShapeName: 'shape_string',
                    valueShapeName: 'shape_string',
                    rawShapeConfig: {},
                    role: 'none',
                    visible: true,
                    expand: []
                },
                {
                    name: 'orderKey',
                    displayName: 'orderKey',
                    displayIndex: 4,
                    shapeName: 'shape_number',
                    targetShapeName: 'shape_number',
                    valueShapeName: 'shape_number',
                    rawShapeConfig: {},
                    role: 'none',
                    visible: false,
                    expand: []
                }
            ]
        }
    };
};
