import { AppContext } from 'contexts/AppContext';
import { DashboardType } from 'dashboard-engine/types/Dashboard';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { perspectiveQueryKeys } from 'queries/queryKeys/perspectiveKeys';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useWorkspaceCanWrite } from 'services/AccessControlService';
import { Update } from 'services/DashboardService';
import {
    getDashboardVersion,
    isNewerDashboard,
    mutateDashboardVersion,
    setDashboardVersion
} from '../DashboardVersioning';

export const useHandleSave = () => {
    const { currentWorkspaceID } = useContext(AppContext);
    const queryClient = useQueryClient();
    const { data: canWriteToWorkspace } = useWorkspaceCanWrite(currentWorkspaceID || '');

    const optimisticUpdate = (newDashboard: DashboardType) => {
        const dashboardQueryKey = dashboardQueryKeys.detail(newDashboard.id);

        // Snapshot the previous value
        const dashboardFromCache = queryClient.getQueryData<DashboardType>(dashboardQueryKey);

        // Cancel any outgoing dashboard queries (so they don't overwrite our optimistic update)
        queryClient.cancelQueries(dashboardQueryKeys.detail(newDashboard.id));

        // Set the version on the modified dashboard
        mutateDashboardVersion(newDashboard, getDashboardVersion(dashboardFromCache) + 1);

        // Optimistically update to the new value
        queryClient.setQueryData(dashboardQueryKey, newDashboard);
    };

    // If we're not allowed to write, pretend we did - if this is being called without
    // permission then something unusual has happened!
    // Throwing can cause an infinite loop of the dashboard reloading.
    const nullMutation = useMutation<DashboardType | undefined, undefined, DashboardType>(
        async (newDashboard) => newDashboard,
        {
            onMutate: optimisticUpdate
        }
    );

    const apiMutation = useMutation<DashboardType | undefined, undefined, DashboardType>(
        (newDashboard) => Update(newDashboard.id, newDashboard),
        {
            // Things will break if this optimistic update isn't here because we use it to keep the version up to date
            onMutate: optimisticUpdate,
            onError: (_, newDashboard) => {
                const queryKey = dashboardQueryKeys.detail(newDashboard.id);
                // Decrement the version so that when we invalidate and reload, we'll accept the version
                // from the backend
                queryClient.setQueryData(
                    queryKey,
                    setDashboardVersion(newDashboard, getDashboardVersion(newDashboard) - 1)
                );
                // Force a reload as well as reverting because the error means we may be out of sync with the backend
                // and this is the only guaranteed way to recover from that
                queryClient.invalidateQueries(queryKey);
            },
            // Always refetch after error or success:
            onSettled: (newDashboard) => {
                if (newDashboard != null) {
                    const queryKey = dashboardQueryKeys.detail(newDashboard.id);
                    const dashboardFromCache = queryClient.getQueryData<DashboardType>(queryKey);

                    const newerDashboard = isNewerDashboard(dashboardFromCache, newDashboard);

                    if (newerDashboard === true) {
                        queryClient.setQueryData(queryKey, newDashboard);
                    } else if (newerDashboard === 'version-only') {
                        queryClient.setQueryData(
                            queryKey,
                            setDashboardVersion(dashboardFromCache ?? newDashboard, getDashboardVersion(newDashboard))
                        );
                    }

                    queryClient.invalidateQueries(dashboardQueryKeys.list);

                    // We want to invalidate health queries in case monitors have changed
                    queryClient.invalidateQueries(dashboardQueryKeys.states);
                    queryClient.invalidateQueries(workspaceQueryKeys.states);

                    queryClient.invalidateQueries(perspectiveQueryKeys.all);
                }
            }
        }
    );

    return canWriteToWorkspace ? apiMutation : nullMutation;
};
