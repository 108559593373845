import { UseQueryOptions } from 'react-query';
import { useDatasourceConfigs } from './useDatasourceConfigs';
import { useWorkspaces } from './useWorkspaces';
import { NO_ACTIVE_WORKSPACE } from 'services/WorkspaceUtil';

type QueryReturnType = NonNullable<ReturnType<typeof useDatasourceConfigs>['data']>;

/**
 * useQuery returning the data source configs for a specific workspace. It uses the WorkspaceService List API
 * to get a full list of workspaces (this is within useWorkspaces) and the SourceConfigService's 
 * ListPluginSourceConfigsForAllWorkspaces to get all data source configs for all workspaces. We then use the
 * `select` query option to find the workspace that's been requested in the passed `workspaceId`. Because each 
 * workspace includes the workspace/datasource links, we can filter the list of all data source configs to those 
 * in `links.plugins` for the target workspace.
 * 
 * If you pass `select` it will operate on the data source configs for the workspace
 * @param workspaceId The workspaceId you'd like data source configs for
 * @param options The react-query options e.g. refreshInterval
 * @returns An array of data source configs for the passed workspace
 */
export const useDatasourceConfigsForWorkspace = (
    workspaceId?: string | null, 
    options?: Omit<UseQueryOptions<QueryReturnType, unknown, any, string[]>, 'enabled'>
) => {
    const { select, suspense, ...queryOptions } = options ?? {};
    const { data: workspaces } = useWorkspaces({ suspense: Boolean(suspense) });
    const isGlobal = workspaceId === NO_ACTIVE_WORKSPACE;

    return useDatasourceConfigs({
        enabled: Boolean(workspaceId),
        suspense,
        select: (data) => {
            if (isGlobal) {
                return select ? select(data) : data;
            }

            const workspaceDatasourceConfigLinks = workspaces?.filter(({ id }) => id === workspaceId)
                ?.map((workspaceLinks) => workspaceLinks?.data?.links?.plugins)
                ?.flat();

            const workspaceDatasourceConfigs = data.filter(({ id }) => workspaceDatasourceConfigLinks?.includes(id));

            if (select) {
                select(workspaceDatasourceConfigs);
            }
            return workspaceDatasourceConfigs;
        },
        ...queryOptions
    });
};
