import { cn } from '@/lib/cn';
import { faBrowser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { stateStrings } from '@squaredup/monitoring';
import { PinnableNodeData } from 'components/map/data/types';
import { TruncatedText } from 'components/TruncatedText';
import { useDashboardHealthStates } from 'queries/hooks/useDashboardHealthStates';
import { memo } from 'react';
import { Handle, Position } from 'reactflow';
import { useHover } from 'ui/hooks/useHover';
import { DASHBOARD_NODE_SIZE } from '../DashboardNode';
import { useStoreHealthStateNode } from 'components/map/hooks/useStoreHealthStateNode';
import { NodeToolbar } from '../../nodeToolbar/NodeToolbar';
import { useNetworkBackground } from 'components/map/context/NetworkMapStoreContext';

const healthStateStyles = {
    [stateStrings.error]: 'stroke-statusErrorPrimary',
    [stateStrings.warning]: 'stroke-statusWarningPrimary',
    [stateStrings.success]: 'stroke-statusHealthyPrimary',
    [stateStrings.unknown]: 'stroke-statusUnknownPrimary'
};

interface DashboardNodeInternalProps extends PinnableNodeData {
    id: string;
}

export const DashboardNodeInternal: React.FC<DashboardNodeInternalProps> = memo(({ 
    id,
    label,
    pinned,
    expanded,
    sourceId,
    workspaceId,
    fixedPosition,
    hiddenConnections
}) => {
    const background = useNetworkBackground();
    const { isHovered, ...hoverProps } = useHover();

    const { data: dashboardHealthStates = [] } = useDashboardHealthStates(workspaceId);
    const healthState = dashboardHealthStates.find(({ id: dashId }) => sourceId === dashId)?.state;

    useStoreHealthStateNode(id, healthState);

    const showHalo = pinned || healthState !== stateStrings.unknown;

    return (
        <div 
            className={`relative p-2 rounded-full bg-${background}`}
            style={{ width: DASHBOARD_NODE_SIZE, height: DASHBOARD_NODE_SIZE }}
            {...hoverProps}
        >

            <NodeToolbar 
                id={id}
                isVisible={isHovered}
                isPinned={pinned}
                isFixed={fixedPosition}
                isExpanded={expanded}
                label={label}
                hiddenConnections={hiddenConnections}
                url={`/dashboard/${sourceId}`}
            />
            

            {showHalo && 
                <svg 
                    viewBox='0 0 100 100'
                    className={cn('absolute inset-0 w-full')}
                >
                    <circle 
                        cx='50%' 
                        cy='50%' 
                        r='42' 
                        vectorEffect='non-scaling-stroke' 
                        strokeWidth={4} 
                        className={cn(
                            `fill-${background}`, 
                            healthState && healthStateStyles[healthState]
                        )} 
                        {...!pinned && healthState && { strokeDasharray: '1.65 2' }}
                    />
                </svg>
            }

            <FontAwesomeIcon 
                icon={faBrowser} 
                className='absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2'
            />

            <Handle type='target' position={Position.Top} className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2' />
            <Handle type='source' position={Position.Bottom} className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2' />
            <span className='sr-only'>{label}</span>

            {label && 
                <div className={`text-primary text-[8px] rounded-sm bg-${background} leading-tight px-1 py-0.5 font-bold absolute top-[calc(100%+2px)] max-w-[120px] left-1/2 -translate-x-1/2 text-center`}>
                    <TruncatedText title={label}>
                        {label}
                    </TruncatedText>
                </div>
            }
        </div>
    );
});
