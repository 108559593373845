import { cn } from '@/lib/cn';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useTileContext } from 'contexts/TileContext';
import { motion } from 'framer-motion';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

interface DataStreamTableGlobalFilterProps {
    globalFilter: string;
    setGlobalFilter: Dispatch<SetStateAction<string>>;
}

export const DataStreamTableGlobalFilter: React.FC<DataStreamTableGlobalFilterProps> = ({
    globalFilter,
    setGlobalFilter
}) => {
    const globalFilterWrapper = useRef<HTMLDivElement>(null);

    const { tileId } = useTileContext();
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            const isClickOutside =
                globalFilterWrapper.current && !globalFilterWrapper.current.contains(event.target as Node);

            if (isClickOutside && !globalFilter) {
                setIsExpanded(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [globalFilter]);

    // We only want to attempt to mount the table search input if the tile exists
    const portal = document.getElementById(`${tileId}Toolbar`);

    if (!portal) {
        return null;
    }

    return createPortal(
        <motion.div
            className={cn(
                'items-center flex-shrink-0 text-sm text-textSecondary leading-input hidden group-hover/tile:flex',
                isExpanded && 'bg-componentBackgroundPrimary'
            )}
            ref={globalFilterWrapper}
            transition={{ ease: 'circOut' }}
        >
            {isExpanded && (
                <motion.input
                    type='string'
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    className='flex-1 min-w-0 pr-2 overflow-hidden leading-none text-[12px] bg-transparent border-none outline-none placeholder-textIncomplete focus:ring-0'
                    initial={{ width: '0' }}
                    animate={{ width: 100 }}
                    transition={{ ease: 'circOut' }}
                    placeholder='Search data'
                    autoFocus
                />
            )}
            <FontAwesomeIcon
                icon={faSearch}
                onClick={() => setIsExpanded(true)}
                className={clsx('flex-shrink-0 text-textSecondary py-px text-base hide-for-image-export', !isExpanded && 'hover:text-textPrimary cursor-pointer')}
                fixedWidth
            />
        </motion.div>,
        portal
    );
};
