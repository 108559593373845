import { TooltipButton } from '@/components/Button';
import { cn } from '@/lib/cn';
import { faBarsFilter, faPencil, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TruncatedText } from 'components/TruncatedText';
import DropdownMenu from 'components/dropdownMenu';
import { useDOMElement } from 'components/hooks/useDOMElement';
import Tooltip from 'components/tooltip/Tooltip';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useState } from 'react';
import { useCurrentWorkspaceId } from 'services/WorkspaceUtil';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { SearchBar } from '../SearchBar';
import { useFilterSearchItems } from '../hooks/useFilterSearchItems';

interface ScopeFilterDropdownProps {
    scopes?: any[];
    selectedScopeId?: string;
    setSelectedScopeId: (value: string | undefined) => void;
    onAddScope: () => void;
    onEditScope: (scope: any) => void;
}

const searchKeys = [{ name: 'displayName', weight: 10 }];

export const ScopeFilterDropdown: React.FC<ScopeFilterDropdownProps> = ({
    scopes = [],
    selectedScopeId,
    setSelectedScopeId,
    onAddScope,
    onEditScope
}) => {
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);
    const menuContainer = useDOMElement('dialogContent');

    const { variables = [] } = useDashboardContext();
    const { setConfig } = useDatasetContext();
    const workspaceId = useCurrentWorkspaceId();

    const variablesScopeIds = variables.map((v) => v.scopeId);

    const { searchItems, setSearchTerm } = useFilterSearchItems({
        keys: searchKeys,
        items: scopes
    });

    const selectedScope = scopes.find(({ id }) => id === selectedScopeId);

    const handleDropdownOpenChange = (open: boolean) => {
        setSearchTerm('');
        setIsDropdownOpened(open);
    };

    const handleItemSelect = (scopeId: string) => {
        setSelectedScopeId(selectedScopeId === scopeId ? undefined : scopeId);

        const scopeIsVariable = selectedScopeId !== scopeId && variablesScopeIds.includes(scopeId);
        const variableScopeId = variables[0]?.scopeId;
        setConfig((currentConfig) => ({
            ...currentConfig,
            scope: scopeIsVariable ? { scope: variableScopeId, workspace: workspaceId } : currentConfig.scope,
            variables: scopeIsVariable ? variables.map((v) => v.id) : undefined
        }));
    };

    const handleClear = () => {
        setSelectedScopeId(undefined);
        setConfig((currentConfig) => ({
            ...currentConfig,
            scope: undefined,
            variables: undefined
        }));
    };

    return (
        <DropdownMenu onOpenChange={handleDropdownOpenChange} modal={false}>
            <DropdownMenu.Button
                active={Boolean(selectedScopeId)}
                className='whitespace-nowrap'
                dropdownOpen={isDropdownOpened}
                showChevron
                useFilterStyle
                data-testid='scopeFilter'
            >
                {selectedScope ? (
                    <div className='inline-flex items-center align-middle'>
                        {variablesScopeIds.includes(selectedScope.id) && (
                            <FontAwesomeIcon
                                icon={faBarsFilter}
                                fixedWidth={true}
                                className='mr-2 w-4 h-4 text-textPrimary'
                            />
                        )}
                        <TruncatedText
                            title={selectedScope.displayName}
                            className='flex-1 min-w-0 max-w-[80px] truncate'
                        />
                    </div>
                ) : (
                    'Objects'
                )}
            </DropdownMenu.Button>
            <DropdownMenu.Menu align='end' className='w-[260px]' zIndex={14} container={menuContainer}>
                <SearchBar placeholder='Search for a collection...' onChange={setSearchTerm} className='mb-3' />
                <DropdownMenu.Group className='max-h-[320px] overflow-y-scroll scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                    {searchItems?.map((scope) => (
                        <DropdownMenu.Item
                            key={scope.id}
                            className={cn('group', {
                                'bg-dividerPrimary': selectedScopeId === scope.id
                            })}
                            onSelect={() => handleItemSelect(scope.id)}
                        >
                            <div className='flex'>
                                <TruncatedText title={scope.displayName ?? ''} className='flex-1 min-w-0 truncate' />
                                {variablesScopeIds.includes(scope.id) && (
                                    <Tooltip
                                        title={`This collection is a dashboard variable (${variables.find(
                                            (v) => v.scopeId === scope.id
                                        )?.name})`}
                                        placement='bottom-start'
                                        variant='light'
                                    >
                                        <FontAwesomeIcon
                                            icon={faBarsFilter}
                                            fixedWidth={true}
                                            className='ml-2 text-textPrimary'
                                        />
                                    </Tooltip>
                                )}
                                <TooltipButton
                                    variant='tertiary'
                                    icon={<FontAwesomeIcon icon={faPencil} fixedWidth={true} />}
                                    title='Edit collection'
                                    className='hidden ml-2 group-hover:block text-textSecondary hover:text-textPrimary'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onEditScope(scope);
                                    }}
                                />
                            </div>
                        </DropdownMenu.Item>
                    ))}
                </DropdownMenu.Group>
                <DropdownMenu.Separator />
                <DropdownMenu.SecondaryItem onSelect={onAddScope}>
                    <FontAwesomeIcon icon={faPlus} className='w-4 h-4 mr-3' />
                    Add collection
                </DropdownMenu.SecondaryItem>
                <DropdownMenu.Separator />
                <DropdownMenu.SecondaryItem disabled={!selectedScopeId} onSelect={handleClear}>
                    Clear filter
                </DropdownMenu.SecondaryItem>
            </DropdownMenu.Menu>
        </DropdownMenu>
    );
};
