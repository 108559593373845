import { cn } from '@/lib/cn';
import { faFilter, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Popover from '@radix-ui/react-popover';
import LoadingSpinner from 'components/LoadingSpinner';
import { TruncatedText } from 'components/TruncatedText';
import { buttonVariants } from 'components/button';
import { useDOMElement } from 'components/hooks/useDOMElement';
import Tooltip from 'components/tooltip/Tooltip';
import stringify from 'fast-json-stable-stringify';
import trackEvent, { trackEventDebounced } from 'lib/analytics';
import { upperFirst } from 'lodash';
import { useMemo, useState } from 'react';
import { useTileEditorObjectsFilterContext } from 'ui/editor/dataStream/contexts/TileEditorObjectsFilterContext';

export const AddPropertyFilter: React.FC = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const container = useDOMElement('dialogContent');

    const { properties, filterProperties, isLoadingProperties, filtersDisabled, setFilterProperties } =
        useTileEditorObjectsFilterContext();

    const filterPropertyKeys = useMemo(() => Object.keys(filterProperties), [filterProperties]);

    const filteredProperties = useMemo(
        () =>
            (properties || []).filter(
                (property) =>
                    !filterPropertyKeys.includes(property) && property.toLowerCase().includes(searchQuery.toLowerCase())
            ),
        [properties, filterPropertyKeys, searchQuery]
    );

    const onSearch = (query: string) => {
        trackEventDebounced('Objects Add Filter Searched', { query });
        return setSearchQuery(query);
    };

    return (
        <Popover.Root onOpenChange={() => setSearchQuery('')}>
            <Popover.Anchor>
                <Tooltip
                    title='Filtering by property is not available when a scope has been selected'
                    disabled={!filtersDisabled}
                >
                    <Popover.Trigger
                        className={cn(buttonVariants({ variant: 'secondary' }), 'justify-center w-full space-x-3')}
                        disabled={
                            filtersDisabled ||
                            isLoadingProperties ||
                            stringify(properties) === stringify(filterPropertyKeys)
                        }
                    >
                        <FontAwesomeIcon icon={faFilter} />
                        <span>Add filter</span>
                        {isLoadingProperties && <LoadingSpinner />}
                    </Popover.Trigger>
                </Tooltip>
            </Popover.Anchor>
            <Popover.Portal container={container ?? undefined}>
                <Popover.Content
                    align='start'
                    side='bottom'
                    sideOffset={4}
                    avoidCollisions={true}
                    className='flex flex-col text-sm border shadow-none rounded-b-input bg-componentBackgroundPrimary text-textPrimary border-outlinePrimary p-xxxs [width:var(--radix-popover-trigger-width)]'
                    style={{ zIndex: 50 }}
                >
                    <div className='flex items-center w-full mb-xxxs ring-inset ring-1 ring-outlinePrimary focus-within:ring-outlineSecondary rounded-input'>
                        <label htmlFor='propertyFilterSearch' className='inline-flex items-center flex-shrink-0 pl-md'>
                            <FontAwesomeIcon icon={faSearch} />
                        </label>
                        <input
                            value={searchQuery}
                            id='propertyFilterSearch'
                            onChange={(e) => onSearch(e.target.value)}
                            className='flex-1 min-w-0 text-sm bg-transparent border-0 outline-none placeholder:text-textIncomplete leading-input focus:ring-0 py-input pr-md pl-xxs'
                            placeholder='Search'
                        />
                    </div>

                    <div className='flex-1 min-h-0 overflow-auto max-h-60 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                        {filteredProperties.map((property) => (
                            <Popover.Close
                                key={property}
                                className='block w-full text-left cursor-pointer focus:bg-dividerTertiary hover:bg-dividerTertiary leading-input py-autocomplete px-xs text-textSecondary hover:text-textPrimary'
                                onClick={() => {
                                    trackEvent('Objects Add Filter Property Selected', { property });
                                    return setFilterProperties((currentFilterProperties) => ({
                                        ...currentFilterProperties,
                                        [property]: []
                                    }));
                                }}
                            >
                                <TruncatedText title={upperFirst(property)} className='w-full' />
                            </Popover.Close>
                        ))}
                    </div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};
