import { cn } from '@/lib/cn';
import { faMinus, faPencil, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppContext } from 'contexts/AppContext';
import DashboardContext from 'contexts/DashboardContext';
import RenderTile from 'dashboard-engine/render/RenderTile';
import { DashboardTileContent } from 'dashboard-engine/types/Dashboard';
import { getDashboardColumns, setDashboardColumns, setDashboardContents } from 'dashboard-engine/util/dashboard';
import { GridLayout, Layout } from 'lib/gridLayout';
import 'lib/gridLayout/styles.css';
import { useHandleSave } from 'pages/dashboard/hooks/useHandleSave';
import { useCallback, useContext, useEffect, useState } from 'react';
import 'react-resizable/css/styles.css';
import './Grid.css';
import { ColumnButton } from './components/ColumnButton';
import { PlaceholderGrid } from './components/PlaceholderGrid';
import { getCellLayout } from './getCellLayout';
import { maxColumns, rowHeight } from './gridConstants';
import { findConfig, layoutDataToJsonString, mergeConfig, numRows } from './gridUtils';
import { Button } from '@/components/Button';

interface GridProps {
    size: {
        width: number;
    };
    config: {
        contents: DashboardTileContent[];
        columns: number;
    };
}

const Grid: React.FC<GridProps> = ({ size, config }) => {
    const { isNarrowLayout } = useContext(AppContext);
    const { editing, setEditing, dashboard } = useContext(DashboardContext);

    const { width } = size;
    const { columns, contents: layout } = config;

    const [rows, setRows] = useState(numRows(layout));

    const { mutate: updateDashboard } = useHandleSave();

    // TODO: need to re-look how to update cell layout
    useEffect(() => {
        if (isNarrowLayout) {
            setRows(
                layout.reduce(function (a, b) {
                    return a + b.h;
                }, 0)
            );
        } else {
            setRows(numRows(layout));
        }
    }, [editing, config, width]); // eslint-disable-line

    const handleLayoutChange = useCallback(
        (updatedLayout: Layout) => {
            // Compare JSON.stringify version of configs

            const sortedOriginalLayout = [...layout].sort((a, b) => {
                if (a.y < b.y) {
                    return -1;
                }
                if (a.y > b.y) {
                    return 1;
                }
                return 0;
            });

            const originalLayoutValues = layoutDataToJsonString(sortedOriginalLayout);
            /**
             * We sort the values (the order is essentially top to bottom of the dashboard)
             * based on the assumption that tiles near the bottom (with a higher 'Y value') have been added later.
             * We want the tiles created at the top to be at the front of the config.contents (layout) array
             * because when this wasn't the case, it led to unpredictable results where tiles might wrongly
             * jump to the bottom of the dashboard when resized (see SAAS-2338).
             */
            const newLayoutValues = updatedLayout.sort((a, b) => {
                if (a.y < b.y) {
                    return -1;
                }
                if (a.y > b.y) {
                    return 1;
                }
                return 0;
            });
            const sortedLayoutJson = layoutDataToJsonString(newLayoutValues);
            // We only want to save if the layout has truly changed
            if (originalLayoutValues !== sortedLayoutJson) {
                const updatedConfigLayout = mergeConfig(newLayoutValues, layout);
                updateDashboard(setDashboardContents(dashboard, updatedConfigLayout));
            }
        },
        [dashboard, updateDashboard, layout]
    );

    const handleAddCell = useCallback(
        (cell: DashboardTileContent) => {
            const newContents = getCellLayout(cell, layout, columns);

            updateDashboard(setDashboardContents(dashboard, newContents));
        },
        [columns, dashboard, updateDashboard, layout]
    );

    const handleColumnChange = useCallback(
        (change: number) => {
            const dashboardColumns = getDashboardColumns(dashboard);
            let currentDashboard = dashboard;
            //Shrink tiles if they are using the column being deleted
            if (change === -1) {
                const newLayout = layout.map(({ x, w, ...props }) => {
                    if (x + w === columns) {
                        return { x, w: w - 1, ...props };
                    } else {
                        return { x, w, ...props };
                    }
                });
                currentDashboard = setDashboardContents(dashboard, newLayout);
            }
            updateDashboard(setDashboardColumns(currentDashboard, Math.min(dashboardColumns + change, maxColumns)));
        },
        [dashboard, updateDashboard, layout, columns]
    );

    const handleDrag = useCallback(
        ({ layout: updatedLayout }: { layout: Layout }) => {
            const updatedRows = numRows(updatedLayout);
            if (updatedRows > rows) {
                setRows(numRows(updatedLayout));
            }
        },
        [rows]
    );

    const canDeleteLastColumn = !layout.some(({ x }) => x === columns - 1) && columns > 1;

    const singleColumnLayout = layout.map((l, i) => ({
        ...l,
        w: 1,
        x: 0,
        y: layout[i - 1] ? layout[i - 1].y + layout[i - 1].h : 0
    }));

    const responsiveLayout = isNarrowLayout ? singleColumnLayout : layout;

    if (isNarrowLayout) {
        setEditing?.(false);
    }

    return (
        <div style={{ ...(editing && { width: 'calc(100% - 4rem)' }) }}>
            {editing && (
                <>
                    <span
                        className='absolute -right-8 mt-1 -top-1 -mb-1.5 bottom-1.5 z-0'
                        data-testid='addColumnButton'
                    >
                        <ColumnButton onClick={() => handleColumnChange(1)}>
                            <FontAwesomeIcon icon={faPlus} />
                        </ColumnButton>
                    </span>
                    <span
                        className='absolute -right-16 mt-1 -top-1 -mb-1.5 bottom-1.5 z-0'
                        data-testid='removeColumnButton'
                    >
                        <ColumnButton
                            className={cn({ 'opacity-50': !canDeleteLastColumn })}
                            onClick={() => canDeleteLastColumn && handleColumnChange(-1)}
                        >
                            <FontAwesomeIcon icon={faMinus} />
                        </ColumnButton>
                    </span>
                    <PlaceholderGrid onClick={handleAddCell} width={width} cols={columns} rows={rows + 1} />
                </>
            )}
            {layout.length > 0 && (
                <GridLayout
                    className={cn('layout pointer-events-none', { 'absolute top-0': editing })}
                    compactType={null}
                    layout={responsiveLayout}
                    cols={isNarrowLayout ? 1 : columns}
                    width={width}
                    margin={[10, 10]}
                    containerPadding={[0, 0]}
                    draggableHandle={'.drag-handle'}
                    useCSSTransforms={true}
                    isDraggable={editing}
                    isResizable={editing}
                    onDrag={handleDrag}
                    onLayoutChange={handleLayoutChange}
                    rowHeight={rowHeight}
                >
                    {layout.map((cell) => (
                        <div key={cell.i} data-testid='gridItem'>
                            <RenderTile tileId={cell.i} config={findConfig(layout, cell.i)} preview={false} />
                        </div>
                    ))}
                </GridLayout>
            )}
            {layout.length === 0 && !editing && (
                <div className='mt-12 space-y-8 text-center'>
                    <p className='text-2xl text-textSecondary'>
                        This dashboard is empty... <span className='grayscale'>🥺</span>
                    </p>
                    {setEditing && (
                        <Button onClick={() => setEditing(true)} icon={<FontAwesomeIcon icon={faPencil} fixedWidth />}>
                            Edit
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};

export default Grid;
