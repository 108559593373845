const SqupActionAfterLogoutKey = 'SqupActionAfterLogout';
const SignUp = 'signup';

/**
 * Returns true if we should go to the sign UP page rather than the normal sign IN page
 * on app startup.
 */
export function shouldSignUpAfterLogout(clearAfterRead = true): boolean {
    const goToSignUp = sessionStorage.getItem(SqupActionAfterLogoutKey) === SignUp;
    if (goToSignUp && clearAfterRead) {
        // Normally the flag is a one-off instruction, so don't retain it unless requested.
        sessionStorage.removeItem(SqupActionAfterLogoutKey); 
    }
    return goToSignUp;
}

/**
 * Call before logout to signal that the app should go to the sign UP page on reload.
 * (Normally we go to the sign IN page)
 */
export function setSignUpAfterLogout(): void {
    sessionStorage.setItem(SqupActionAfterLogoutKey, SignUp);
}