import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { migrateDynamicScopeToBooleanQuery } from '@squaredup/graph';
import { baseDataStreamConfig } from 'dashboard-engine/constants';
import { useState } from 'react';
import { ObjectFilters } from 'ui/editor/dataStream/TileEditor/hooks/useDataStreamObjectFilters';
import { ObjectsFilterContextWrapper } from 'ui/editor/dataStream/TileEditor/steps/ObjectsFilterContextWrapper';
import DatasetContext, { datasetContextDefaultValue } from 'ui/editor/dataStream/contexts/DatasetContext';
import { CreateEditScopeModal } from './CreateEditScopeModal';
import type { Scope } from './ScopesPage';
import { migrateTagsToPropertyFilters } from './migrateTagsToPropertyFilters';

interface CreateEditScopeProps {
    scope?: Scope;
    currentScope?: {
        query: string;
        queryDetail: any;
        bindings: any;
    };
    filters?: ObjectFilters;
    defaultName?: string;
    onClose: (modifiedScope?: { id: string }) => void;
}

/**
 * Provides the needed contexts for CreateEditScopeModal to be used on the Scopes page
 */
export const CreateEditScope: React.FC<CreateEditScopeProps> = ({
    scope,
    currentScope,
    filters,
    defaultName,
    onClose
}) => {
    const existingScope = scope
        ? {
              id: scope.id,
              version: scope.data.version,
              workspace: scope.workspaceId,
              scope: scope.data.scope,
              displayName: scope.displayName,
              isDependency: scope.data.isDependency,
              query: scope.data.query,
              queryDetail: JSON.parse(scope?.data.queryDetail || '{}'),
              bindings: JSON.parse(scope?.data.bindings || '{}')
          }
        : undefined;

    migrateDynamicScopeToBooleanQuery(existingScope?.queryDetail);
    migrateTagsToPropertyFilters(existingScope, existingScope?.queryDetail?.tags);

    const [config, setConfig] = useState<DataStreamBaseTileConfig>({
        ...baseDataStreamConfig,
        scope: existingScope ?? currentScope
    });

    return (
        <DatasetContext.Provider
            value={{
                ...datasetContextDefaultValue,
                config,
                setConfig
            }}
        >
            <ObjectsFilterContextWrapper objectFilters={filters}>
                <CreateEditScopeModal existingScope={existingScope} defaultName={defaultName} onClose={onClose} />
            </ObjectsFilterContextWrapper>
        </DatasetContext.Provider>
    );
};
