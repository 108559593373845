import { Node } from '@squaredup/graph';
import LoadingSpinner from 'components/LoadingSpinner';
import React from 'react';
import { VariableObjectList } from './VariableObjectList';

interface VariableDropdownObjectsProps {
    variableObjects: {
        objects?: Node[];
        count?: number;
        hasNextObjectsPage?: boolean;
        isFetchingNextObjectsPage: boolean;
        isFetchingObjects: boolean;
        fetchNextObjectsPage: () => void;
    };
    resetScrollKey: string;
    renderObjectRow: (object: Node, index: number) => React.ReactNode;
}

export const VariableDropdownObjects: React.FC<VariableDropdownObjectsProps> = ({
    variableObjects,
    resetScrollKey,
    renderObjectRow
}) => {
    const { objects, count, hasNextObjectsPage, isFetchingNextObjectsPage, isFetchingObjects, fetchNextObjectsPage } =
        variableObjects;

    if (!objects) {
        return (
            <div className='flex justify-center py-4'>
                <LoadingSpinner />
            </div>
        );
    }

    if (objects.length === 0) {
        return (
            <p className='px-xs py-xs font-semibold'>
                {resetScrollKey.length === 0 ? 'This collection is empty' : 'No results'}
            </p>
        );
    }

    return (
        <VariableObjectList
            objects={objects}
            totalCount={count || 0}
            resetScrollKey={resetScrollKey}
            hasNextObjectsPage={hasNextObjectsPage}
            isFetchingNextObjectsPage={isFetchingNextObjectsPage}
            isFetchingObjects={isFetchingObjects}
            fetchNextObjectsPage={fetchNextObjectsPage}
            renderObjectRow={renderObjectRow}
        />
    );
};
