import { Node } from '@squaredup/graph';
import Button from 'components/button/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ifNotOutside, ModalButtons } from 'components/Modal';
import { TruncatedText } from 'components/TruncatedText';
import { getDrilldownLink } from 'lib/drilldownLinks';
import { debounce } from 'lodash';
import pluralize from 'pluralize';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useObjectFilterObjects } from 'ui/editor/dataStream/TileEditor/hooks/objectFilters/useObjectFilterObjects';
import { useObjectFilterScopeNodes } from 'ui/editor/dataStream/TileEditor/hooks/objectFilters/useObjectFilterScopeNodes';
import { useObjectFilterSources } from 'ui/editor/dataStream/TileEditor/hooks/objectFilters/useObjectFilterSources';
import { ObjectList } from 'ui/editor/dataStream/TileEditor/objects/ObjectList';
import { ObjectOption } from 'ui/editor/dataStream/TileEditor/objects/ObjectOption';
import { SearchBar } from 'ui/editor/dataStream/TileEditor/SearchBar';
import { ScopeModalHeader } from './scopeModal/ScopeModalHeader';

interface ViewScopeModalProps {
    scopeId: string;
    scopeName: string;
    onClose: () => void;
    onEdit: () => void;
}

const tableHeaders = ['Name', 'Data Source', 'Type'];

export const ViewScopeModal: React.FC<ViewScopeModalProps> = ({ scopeId, scopeName, onEdit, onClose }) => {
    const [filterQuery, setFilterQuery] = useState('');
    const navigate = useNavigate();

    const { data: sources, isLoading: isLoadingSources } = useObjectFilterSources({
        dataStreamQuery: '',
        queryParams: {}
    });

    const { data: scopeBaseQuery, isFetching: isFetchingScopeObjectQuery } = useObjectFilterScopeNodes(scopeId);

    const {
        objects,
        count,
        isFetchingObjects,
        isFetchingNextObjectsPage,
        isLoadingObjects,
        hasNextObjectsPage,
        fetchNextObjectsPage
    } = useObjectFilterObjects({
        scopeBaseQuery: scopeBaseQuery || '',
        queryParams: {
            booleanQuery: filterQuery
        },
        isFilterQueryReady: Boolean(scopeBaseQuery)
    });

    const debouncedSearch = useMemo(
        () =>
            debounce((searchTerm: string) => {
                setFilterQuery(searchTerm);
            }, 500),
        [setFilterQuery]
    );

    const pluginLookup = new Map(
        sources
            ?.filter((source) => Boolean(source.plugin?.pluginId))
            ?.map((source) => [
                source.id as string,
                {
                    pluginName: source.plugin?.name ?? (source.displayName as string),
                    sourceName: source.displayName as string
                }
            ])
    );

    const isLoading = isLoadingObjects || isFetchingScopeObjectQuery || isLoadingSources;

    return (
        <Modal
            close={ifNotOutside(() => onClose())}
            fullWidth={true}
            fullHeight={true}
            maxWidth='max-w-7xl'
            maxHeight='h-[550px]'
        >
            <div className='flex h-full overflow-hidden'>
                <div className='flex flex-col flex-1 min-w-0'>
                    <ScopeModalHeader isViewing={true} existingScopeName={scopeName} onClose={onClose} />

                    <div className='flex flex-1 min-h-0 py-5 pr-5 space-x-6 pl-9'>
                        <div className='flex flex-col flex-1 h-full min-w-0 min-h-0'>
                            <div className='flex mb-4 mr-4'>
                                <SearchBar
                                    initialValue={filterQuery}
                                    placeholder='Search objects...'
                                    onChange={debouncedSearch}
                                    className='flex-1 min-w-0 mr-8'
                                    showHelp={true}
                                />
                            </div>
                            <div className='flex flex-col flex-1 min-h-0 text-sm' data-testid='scopeObjectsTable'>
                                {isLoading && (
                                    <div className='flex flex-col items-center justify-center flex-1 w-full min-h-0'>
                                        <LoadingSpinner size={20} />
                                    </div>
                                )}
                                {objects && objects.length > 0 && !isLoading && (
                                    <>
                                        <div
                                            className='grid self-stretch gap-6 p-1 px-2 mr-4 font-semibold text-left select-none'
                                            style={{
                                                gridTemplateColumns: `minmax(10rem, 1fr) repeat(${
                                                    tableHeaders.length - 1
                                                }, minmax(0, 1fr))`
                                            }}
                                        >
                                            {tableHeaders.map((header) => (
                                                <span key={header} className='flex items-center space-x-2'>
                                                    <TruncatedText key={header} title={header} />
                                                </span>
                                            ))}
                                        </div>

                                        <ObjectList
                                            objects={objects}
                                            selectedObjects={[]}
                                            resetScrollKey={filterQuery}
                                            hasNextObjectsPage={hasNextObjectsPage}
                                            isFetchingNextObjectsPage={isFetchingNextObjectsPage}
                                            isFetchingObjects={isFetchingObjects}
                                            fetchNextObjectsPage={fetchNextObjectsPage}
                                            renderObjectRow={(object: Node, index: number) => (
                                                <ObjectOption
                                                    object={object}
                                                    readOnly={true}
                                                    className={index % 2 === 0 ? 'bg-tagBackground' : ''}
                                                    filterPropertiesColumns={[]}
                                                    pluginLookup={pluginLookup}
                                                    onClick={() => {
                                                        const path = getDrilldownLink(object);
                                                        navigate(path);
                                                    }}
                                                />
                                            )}
                                        />

                                        <div className='flex items-center mt-4 text-textSecondary min-h-[20px]'>
                                            <p className='mr-2 text-textSecondary'>
                                                {count} {pluralize('objects', count)}
                                            </p>
                                            {isFetchingObjects && <LoadingSpinner size={20} />}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <ModalButtons hideTopMargin={true}>
                        <Button type='button' onClick={() => onEdit()} variant='tertiary'>
                            Edit
                        </Button>

                        <Button onClick={() => onClose()}>Close</Button>
                    </ModalButtons>
                </div>
            </div>
        </Modal>
    );
};
