import { cn } from '@/lib/cn';
import LoadingSpinner from 'components/LoadingSpinner';
import { useAppContext } from 'contexts/AppContext';
import { useWorkspace } from 'queries/hooks/useWorkspace';
import { ReactNode, useCallback, useMemo } from 'react';
import ReactFlow, { Controls, ReactFlowProvider } from 'reactflow';
import { v4 as uuid } from 'uuid';
import { CollapseAllButton } from './components/CollapseAllButton';
import { ArrowHeadMarkers } from './components/edges/ArrowHeadMarkers';
import { FLOATING_EDGE, FloatingEdge } from './components/edges/FloatingEdge';
import { LayoutButton } from './components/LayoutButton';
import { DASHBOARD_NODE, DashboardNode } from './components/nodes/DashboardNode';
import { GROUP_NODE, GroupNode } from './components/nodes/GroupNode';
import { MONITOR_NODE, MonitorNode } from './components/nodes/MonitorNode';
import { OBJECT_NODE, ObjectNode } from './components/nodes/ObjectNode';
import { WORKSPACE_NODE, WorkspaceNode } from './components/nodes/WorkspaceNode';
import { DefaultControls } from './components/nodeToolbar/DefaultControls';
import { NetworkMapStoreProvider, useNetworkEdges, useNetworkEdgesChange, useNetworkNodes, useNetworkNodesChange } from './context/NetworkMapStoreContext';
import { PinnableNode } from './data/types';
import { useObjectMapData } from './data/useObjectMapData';
import { setNodesAndEdgeVisibility } from './data/utils/setNodesAndEdgeVisibility';
import { useAnimateLayout } from './hooks/useAnimateLayout';
import { useHandleFixedNodePosition } from './hooks/useHandleFixedNodePosition';
import { NodeAction, useHandleNodeAction } from './hooks/useHandleNodeAction';
import { LayoutTypes } from './layout/types';
import { NetworkMapStoreState } from './NetworkMapStore';


const nodeTypes = { 
    [WORKSPACE_NODE]: WorkspaceNode,
    [DASHBOARD_NODE]: DashboardNode,
    [MONITOR_NODE]: MonitorNode,
    [OBJECT_NODE]: ObjectNode,
    [GROUP_NODE]: GroupNode
};

const edgeTypes = { [FLOATING_EDGE]: FloatingEdge };

interface LayoutFlowProps {
    controls?: ReactNode;
    id?: string;
}

const LayoutFlow = ({ controls }: LayoutFlowProps) => {
    const nodes = useNetworkNodes();
    const edges = useNetworkEdges();
    const onNodesChange = useNetworkNodesChange();
    const onEdgesChange = useNetworkEdgesChange();
    const handleNodeAction = useHandleNodeAction();
    const { fixPosition } = useHandleFixedNodePosition();

    useAnimateLayout();

    const handleNodeClick = useCallback((_, node: PinnableNode) => {
        const isGroupNode = node.type === GROUP_NODE;
        
        if (
            (node.data.pinned && !node.data.expanded) ||
            (!node.data.pinned && node.data.expanded) || 
            (!node.data.pinned && !node.data.expanded && !node.data.hiddenConnections)
        ) {
            return handleNodeAction(NodeAction.pin, { nodeIds: [node.id] });
        }

        if ((node.data.hiddenConnections && !node.data.expanded) || node.data.expanded) {
            handleNodeAction(
                isGroupNode ? NodeAction.pinAndExpandGroup : NodeAction.pinAndExpand, 
                { nodeIds: [node.id], ...isGroupNode && { sourceNodeIds: node.data.sourceNodeIds } }
            );
        }
    }, [handleNodeAction]);

    const handleNodeDrag = useCallback((_, node) => fixPosition(node.id), [fixPosition]);

    return (
        <>
            <ArrowHeadMarkers />
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                minZoom={0.1}
                proOptions={{ hideAttribution: true }}
                elevateNodesOnSelect={true}
                nodesConnectable={false}
                selectionOnDrag={false}
                onNodeClick={handleNodeClick}
                onNodeDrag={handleNodeDrag}
            >
                <Controls 
                    showZoom={false}
                    showFitView={false}
                    showInteractive={false}
                >
                    {controls}
                    <DefaultControls />
                    <LayoutButton />
                    <CollapseAllButton />
                </Controls>
            </ReactFlow>
        </>
    );
};

export interface MapProps {
    controls?: ReactNode;
    className?: string;
    expandedNodeIds?: string[];
    layoutType?: LayoutTypes;
    background?: NetworkMapStoreState['background'];
}

export const NetworkMap: React.FC<MapProps> = ({ 
    controls,
    className,
    expandedNodeIds: initialExpandedNodeIds,
    layoutType = LayoutTypes.network,
    background = 'backgroundSecondary'
}) => {
    const { currentWorkspaceID } = useAppContext();
    const { data: workspace, isLoading: isLoadingWorkspace } = useWorkspace(currentWorkspaceID);

    const mapId = useMemo(() => `map-${uuid()}`, []);

    // Initialise expandedNodeIds to the passed nodes or the current workspace's nodeId if none are passed
    const expandedNodeIds = initialExpandedNodeIds ?? [workspace?.data.id];
    const { data, isLoading } = useObjectMapData(expandedNodeIds);
    const { nodes: graphNodes, edges: graphEdges } = data ?? {};

    const { nodes, edges } = setNodesAndEdgeVisibility(
        graphNodes ?? [], 
        graphEdges ?? [], 
        expandedNodeIds ?? [],
        expandedNodeIds ?? [],
        new Map<string, string[]>(),
        []
    );
    
    if (isLoadingWorkspace || isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div 
            id={mapId}
            className={cn('h-full w-full relative', className)}
        >
            <div className='absolute inset-0 w-full'>
                <NetworkMapStoreProvider 
                    mapId={mapId}
                    layoutType={layoutType}
                    nodes={nodes ?? []} 
                    edges={edges ?? []} 
                    graphNodes={graphNodes ?? []} 
                    graphEdges={graphEdges ?? []} 
                    background={background}
                    expandedNodeIds={expandedNodeIds}
                    pinnedNodeIds={expandedNodeIds}
                    ungroupedNodeIds={[]}
                    pinnedGroupsWithMemberIds={new Map<string, string[]>()}
                >
                    <ReactFlowProvider key='mapReactFlow'>
                        <LayoutFlow 
                            controls={controls}
                            key='mapReactFlowLayout'
                        />
                    </ReactFlowProvider>
                </NetworkMapStoreProvider>
            </div>
        </div>
    );
};
