import type { KPIValue } from 'dynamo-wrapper';
import stringify from 'fast-json-stable-stringify';
import { groupBy } from 'lodash';
import { useQuery } from 'react-query';
import { List as ListKPIs } from 'services/KPIService';

export type KPIComboResponse = {
    kpiData: KPIValue[];
    kpiMap: Map<string, KPIValue[]>;
};

const KPI_HEALTH_DETAILS = 'KPI_HEALTH_DETAILS';

const getWorkspaceKPILookup = async (workspacesToLookup: string[]): Promise<KPIComboResponse> => {
    // Lookup workspace KPIs and group them by workspaceId so they can be added to the node data
    const kpis = (await ListKPIs(workspacesToLookup, undefined, { includeValues: true })) as KPIValue[];
    const groupedKpis = groupBy(kpis, 'workspaceId');

    return {
        kpiData: kpis,
        kpiMap: new Map(Object.entries(groupedKpis).map(([workspaceID, workspaceKpis]) => [workspaceID, workspaceKpis]))
    };
};

export const useKPIHealthDetails = (workspaceIds: string[]) =>
    useQuery([KPI_HEALTH_DETAILS, stringify(workspaceIds)], async () => getWorkspaceKPILookup(workspaceIds), {
        enabled: (workspaceIds?.length ?? 0) > 0
    });
