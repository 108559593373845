import { Button, buttonVariants } from '@/components/Button';
import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { FeatureDisplay } from '@squaredup/tenants';
import { Pill } from 'components/pill/Pill';
import { useNavigate } from 'react-router-dom';

type HeadingProps = React.HTMLAttributes<HTMLDivElement> & { feature: FeatureDisplay; title?: string };

export const Heading = ({ title, feature, ...props }: HeadingProps) => (
    <div className='flex mb-2 max-w-fit' {...props}>
        <Text.H3 className='text-textPrimary'>{title ?? feature.singular ?? feature.displayName}</Text.H3>
        <Pill className='flex items-center ml-4 bg-upgrade text-primaryButtonText h-min' variant='rounded'>
            <Text.H3>{feature.availableInTier}</Text.H3>
        </Pill>
    </div>
);

type SummaryProps = { 
    summary?: string,
    canUpgrade: boolean,
    feature: FeatureDisplay,
    className?: string
};

export const Summary = ({ summary, canUpgrade, feature, className } : SummaryProps) => (
    <Text.Body className={cn('w-0 min-w-full text-textPrimary', className)}>
        {summary ?? feature.upgradeMessage}{' '}
        {!canUpgrade && 'Please contact your administrator to upgrade.'}
    </Text.Body>
);

type LearnMoreProps = React.HTMLAttributes<HTMLAnchorElement> & { learnMoreUrl: string };

export const LearnMore = ({ learnMoreUrl, ...props }: LearnMoreProps) => (
    <Text.Body>
        <a className={buttonVariants({ variant: 'link' })} href={learnMoreUrl} target='_blank' rel='noopener noreferrer' {...props}>
            Learn more
        </a>
    </Text.Body>
);

type UpgradeButtonProps = Omit<React.HtmlHTMLAttributes<HTMLDivElement>, 'onClick'> & { canUpgrade: boolean, onClick?: () => void };

export const UpgradeButton = ({ canUpgrade, onClick, ...props } : UpgradeButtonProps) => {
    const nav = useNavigate();
    if (!canUpgrade) {
        return <></>;
    }

    const clickHandler = () => {
        nav('/usage');
        if (onClick) {
            onClick();
        }
    };

    return (
        <div {...props}>
            <Button variant='upgrade' onClick={clickHandler} className='text-[14px]'>
                Upgrade
            </Button>
        </div>
    );
};

type FeatureUnavailableContentProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
    feature: FeatureDisplay,
    title?: string,
    canUpgrade: boolean,
    summary?: string
    onUpgradeClicked?: () => void
};

export const FeatureUnavailableContent = ({
    feature,
    title,
    canUpgrade,
    summary,
    onUpgradeClicked
}: FeatureUnavailableContentProps) => (
    <>
        <div className='flex mb-2 leading-6 gap-9'>
            <div className='flex-grow'>
                <Heading title={title} feature={feature} />
                <Summary canUpgrade={canUpgrade} feature={feature} summary={summary} />
            </div>
            <UpgradeButton canUpgrade={canUpgrade} onClick={onUpgradeClicked} />
        </div>
        <LearnMore learnMoreUrl={feature.kbLink} />
    </>
);

export const NarrowFeatureUnavailableContent = ({
    feature,
    title,
    canUpgrade,
    summary,
    children,
    onUpgradeClicked
}: FeatureUnavailableContentProps) => (
    <div className='flex flex-col leading-6'>
        <div className='flex items-start mb-7 gap-9'>
            <div className='flex-grow'>
                <Heading title={title} feature={feature} />
                <Summary canUpgrade={canUpgrade} feature={feature} summary={summary} />
            </div>
            {children}
        </div>
        <div className={cn('flex items-center gap-6', canUpgrade && 'ml-auto')}>
            <LearnMore learnMoreUrl={feature.kbLink} />
            <UpgradeButton canUpgrade={canUpgrade} onClick={onUpgradeClicked} />
        </div>
    </div>
);
