import { scopeLimitMaximum } from '@squaredup/constants';
import { Node } from '@squaredup/graph';
import LoadingSpinner from 'components/LoadingSpinner';
import { TruncatedText } from 'components/TruncatedText';
import stringify from 'fast-json-stable-stringify';
import { uniq, upperFirst } from 'lodash';
import { MouseEvent, useRef } from 'react';
import { useTileEditorObjectsFilterContext } from '../../contexts/TileEditorObjectsFilterContext';
import { ObjectList } from './ObjectList';
import { ObjectOption } from './ObjectOption';

const tableHeaders = ['Name', 'Data Source', 'Type'];

export const ObjectTable: React.FC = () => {
    const {
        objects,
        count,
        filterQuery,
        filterSources,
        filterTypes,
        pluginLookup,
        isFetchingObjects,
        isLoadingObjects,
        hasNextObjectsPage,
        isFetchingNextObjectsPage,
        selectedObjects,
        selectedObjectsCount,
        selectedAllObjects,
        isDynamic,
        filterProperties,
        hasVariables,
        handleFixedScope,
        fetchNextObjectsPage
    } = useTileEditorObjectsFilterContext();

    const canSelectObject = !isDynamic && !hasVariables;

    const lastChecked = useRef<number | null>(null);

    const resetScrollKey = stringify({ filterQuery, filterSources, filterTypes, filterProperties });

    if ((!objects || objects?.length === 0) && isLoadingObjects) {
        return (
            <div className='flex flex-col items-center justify-center flex-1 w-full min-h-0'>
                <LoadingSpinner size={20} />
            </div>
        );
    }

    if (!objects || !count || objects?.length === 0) {
        return <p className='flex-1 text-sm'>No objects available</p>;
    }

    const filterPropertiesColumns = Object.keys(filterProperties);
    const headers = [...tableHeaders, ...filterPropertiesColumns.map(upperFirst)];

    const handleObjectClick = (e: MouseEvent<HTMLButtonElement>, index: number) => {
        const object = objects[index];

        // Shift click allows selection of multiple objects at once
        if (lastChecked.current !== null && e.nativeEvent.shiftKey) {
            const start = Math.min(lastChecked.current, index);
            const end = Math.max(lastChecked.current, index);

            const shiftSelectedObjects = objects.slice(start, end + 1).map(({ id }) => id);

            // The object the user is clicking is already selected, deselect it and all objects in between
            if (selectedObjects.includes(object.id)) {
                handleFixedScope(uniq([...selectedObjects.filter((id) => !shiftSelectedObjects.includes(id))]));
            } else {
                // Add all the objects between the last check object and this one
                handleFixedScope(uniq([...selectedObjects, ...shiftSelectedObjects]));
            }
            return;
        }

        lastChecked.current = index;
        if (!selectedObjects.includes(object.id)) {
            handleFixedScope([...selectedObjects, object.id]);
        } else {
            handleFixedScope(selectedObjects.filter((id) => id !== object.id));
        }
    };

    return (
        <div className='flex flex-col flex-1 min-h-0 text-sm'>
            {objects.length > 0 && (
                <>
                    <div
                        className='grid self-stretch gap-6 p-1 px-2 mr-4 font-semibold text-left select-none'
                        style={{
                            gridTemplateColumns: `minmax(10rem, 1fr) repeat(${headers.length - 1}, minmax(0, 1fr))`
                        }}
                    >
                        {headers.map((header) => (
                            <span key={header} className='flex items-center space-x-2'>
                                <TruncatedText key={header} title={header} />
                            </span>
                        ))}
                    </div>

                    <ObjectList
                        objects={objects}
                        selectedObjects={selectedObjects}
                        resetScrollKey={resetScrollKey}
                        hasNextObjectsPage={hasNextObjectsPage}
                        isFetchingNextObjectsPage={isFetchingNextObjectsPage}
                        isFetchingObjects={isFetchingObjects}
                        fetchNextObjectsPage={fetchNextObjectsPage}
                        renderObjectRow={(object: Node, index: number) => (
                            <ObjectOption
                                object={object}
                                isActive={selectedObjects.includes(object.id) || selectedAllObjects}
                                className={index % 2 === 0 ? 'bg-tagBackground' : ''}
                                checkboxDisabled={
                                    selectedObjectsCount >= scopeLimitMaximum && !selectedObjects.includes(object.id)
                                }
                                filterPropertiesColumns={filterPropertiesColumns}
                                pluginLookup={pluginLookup}
                                // We only want to pass onClick if we're selecting a
                                // fixed scope (!dynamic)
                                {...(canSelectObject && {
                                    onClick: (e) => handleObjectClick(e, index)
                                })}
                            />
                        )}
                    />
                </>
            )}
        </div>
    );
};
