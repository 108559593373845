import { DashboardType } from 'dashboard-engine/types/Dashboard';
import { fiveMinutes } from 'queries/constants';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { Get } from 'services/DashboardService';

type QueryReturnType = Awaited<ReturnType<typeof Get>>;
type QuerySelectReturnType = QueryReturnType;

/**
 * useQuery returning a specific dashboards. We populate the initialData using the useDashboards data (if it exists)
 * @param options The react-query options e.g. refreshInterval
 * @returns An array of all dashboards in a tenant
 */
export const useDashboard = (
    dashboardId?: string,
    options?: Omit<UseQueryOptions<QueryReturnType, unknown, QuerySelectReturnType, string[]>, 'enabled' | 'initialData'>
) => {
    const queryClient = useQueryClient();

    return useQuery(
        dashboardQueryKeys.detail(dashboardId), 
        async () => Get(dashboardId!), 
        {
            enabled: Boolean(dashboardId),
            staleTime: fiveMinutes,
            cacheTime: fiveMinutes,
            placeholderData: () => {
                const dashboardListData = queryClient.getQueryData<DashboardType[]>(dashboardQueryKeys.list)
                    ?.find(({ id }) => id === dashboardId);
                return dashboardListData;
            },
            ...options
        }
    );
};
