import Logo from 'images/squaredup-logo-full.svg?react';
import { NavLink } from 'react-router-dom';

/**
 * LoginPage
 * Provides two login methods to authorise user, either using Microsoft
 * Account or username & password combination.
 *
 * @example
 * `<LoginPage />`
 */
function LoginWrapper({ children }) {
    return (
        <div className='flex h-full upgradePage bg-backgroundPrimary text-textPrimary' data-theme='dark'>
            <div className='relative z-50 flex flex-col w-full h-full max-w-xl py-[4.375rem] px-[3.75rem] text-sm bg-backgroundPrimary'>
                <NavLink to='/' className='inline-block w-40'>
                    <Logo />
                </NavLink>
                {children}
            </div>
            <div className='grow login-background'>
                <img
                    sizes='(max-width: 3840px) 100vw, 3840px'
                    srcset='
                        https://s3.amazonaws.com/account-common-auth.dev.app.squaredup.com/v2/img/dashboard_190.jpg 190w,
                        https://s3.amazonaws.com/account-common-auth.dev.app.squaredup.com/v2/img/dashboard_1286.jpg 1286w,
                        https://s3.amazonaws.com/account-common-auth.dev.app.squaredup.com/v2/img/dashboard_1988.jpg 1988w,
                        https://s3.amazonaws.com/account-common-auth.dev.app.squaredup.com/v2/img/dashboard_2571.jpg 2571w,
                        https://s3.amazonaws.com/account-common-auth.dev.app.squaredup.com/v2/img/dashboard_3840.jpg 3840w'
                    src='https://s3.amazonaws.com/account-common-auth.dev.app.squaredup.com/v2/img/dashboard_3840.png'
                    alt='SquaredUp Dashboard'
                />
            </div>
        </div>
    );
}

LoginWrapper.propTypes = {};

export default LoginWrapper;
