import { DataStreamErrors } from 'dashboard-engine/dataStreams/DataStreamErrors';
import { FallbackProps } from 'react-error-boundary';

export const TileErrorFallBack: React.FC<{ error: FallbackProps['error'] }> = ({ error }) => {
    return (
        <div className='flex flex-col items-center justify-center w-full h-full max-h-full p-4'>
            <DataStreamErrors error={error} />
        </div>
    );
};
