import { Checkbox } from '@/components/forms/Checkbox';
import { Node } from '@squaredup/graph';
import { TruncatedText } from 'components/TruncatedText';
import { ObjectOptionColumn } from './ObjectOption';

interface SelectedObjectsOptionProps {
    object: Node;
    isActive?: boolean;
    onClick?: () => void;
}

export const SelectedObjectsOption: React.FC<SelectedObjectsOptionProps> = ({ object, isActive, onClick }) => {
    const { name, id } = object;

    const disabled = !onClick;

    return (
        <ObjectOptionColumn>
            <label
                className='flex flex-row items-center space-x-2 truncate cursor-pointer'
                onClick={onClick}
                aria-label='Interacted object'
                htmlFor={`object-${id}`}
            >
                {!disabled && <Checkbox checked={isActive} name={`object-${id}`} />}
                <TruncatedText title={name} className='min-w-0 py-1' />
            </label>
        </ObjectOptionColumn>
    );
};
