import { HealthState, stateStrings } from '@squaredup/monitoring';
import stringify from 'fast-json-stable-stringify';
import objectHash from 'object-hash';
import { FilterCriteria, getAllWorkspacesWithDependencies } from 'pages/monitoring/common';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { WORKSPACES } from 'services/WorkspaceService';
import { WorkspaceNetworkNode, generateWorkspaceNetworkData, getInitialNodes } from './generateTenantNetwork';
import { getFilteredNodes } from './getFilteredNodes';
import { cytoscapeWebWorker } from './networkWorker';
import { useKPIHealthDetails } from './useKPIHealthDetails';
import { useWorkspaceHealthData } from './useWorkspaceHealthData';
import { isUnhealthy } from 'constants/state';

export const GRAPH_LAYOUT = 'WORKSPACE_GRAPH_LAYOUT';
export const GRAPH_DATA = 'GRAPH_DATA';
const GRAPH_RENDER = 'GRAPH_RENDER';
const WORKSPACES_WITH_DEPENDENCIES = 'WORKSPACES_WITH_DEPENDENCIES';

type MiddleData = {
    nodes: WorkspaceNetworkNode[];
    networkConfig: any;
};

/**
 * All the external data access required to populate the tenant level map
 * In theory a lot of this data access and processing could passed to the backend
 *
 * @param data The list of workspace IDs
 * @param config Whatever configuration options the cytoscape worker might want
 * @param filterCriteria What special filter options we have for the graph
 * @param expandedNodeIds What extra nodes we have added to the graph
 * @returns
 */
export const useFetchGraphData = (
    data: string[],
    config: any,
    filterCriteria: FilterCriteria,
    expandedNodeIds: string[],
    statusFilters: HealthState[]
) => {
    const { data: allWorkspacesWithDependencies } = useQuery([WORKSPACES_WITH_DEPENDENCIES], async () =>
        getAllWorkspacesWithDependencies('direct')
    );

    const { data: healthData } = useWorkspaceHealthData(allWorkspacesWithDependencies?.map((w) => w.workspaceID) ?? []);
    const { data: kpiData } = useKPIHealthDetails(allWorkspacesWithDependencies?.map((w) => w.workspaceID) ?? []);

    const filteredWorkspaces = useMemo(() => {
        if (statusFilters.length === 0) {
            return data;
        }
        return data.filter((id) => statusFilters.includes(healthData?.get(id)?.state ?? 'unknown'));
    }, [healthData, data, statusFilters]);


    const { data: workspaceNetworkData, isLoading: isLoadingData } = useQuery(
        [
            WORKSPACES,
            GRAPH_DATA,
            stringify(filteredWorkspaces),
            stringify(config),
            stringify(kpiData),
            stringify(healthData)
        ],
        async () => {
            return generateWorkspaceNetworkData(
                filteredWorkspaces,
                allWorkspacesWithDependencies ?? [],
                config,
                kpiData,
                healthData
            );
        },
        {
            enabled:
                Boolean(allWorkspacesWithDependencies) && data?.length > 0 && Boolean(healthData) && Boolean(kpiData),
            keepPreviousData: true
        }
    );

    const {
        networkData,
        workspacesWithDependencies,
        workspacesWithDependenciesByNodeId = new Map()
    } = workspaceNetworkData || {};

    const targetWorkspaces =
        networkData?.nodes.filter(({ data: { workspaceID } }) => filteredWorkspaces.includes(workspaceID)) || [];

    const { data: middleData, isLoading: isLoadingLayout } = useQuery(
        [GRAPH_LAYOUT, objectHash(workspaceNetworkData || {}), stringify(expandedNodeIds), stringify(config)],
        async (): Promise<MiddleData> => {
            const initialNodeIds: string[] = [];
            getInitialNodes(
                initialNodeIds,
                targetWorkspaces.map(({ data: { id } }) => id),
                workspacesWithDependenciesByNodeId!
            );

            let nodes =
                networkData?.nodes.filter(({ data: { id } }) => [...initialNodeIds, ...expandedNodeIds].includes(id)) ||
                [];

            const networkConfig = {
                ...config,
                nodeType: 'kpi',
                layoutType: config.layoutType
            };

            return {
                nodes: nodes || [],
                networkConfig
            };
        },
        {
            enabled: Boolean(workspaceNetworkData),
            keepPreviousData: true,
            refetchInterval: 600_000
        }
    );

    const { data: graphRender, isLoading: isLoadingRender } = useQuery(
        [GRAPH_RENDER, objectHash(middleData || {}), stringify(filterCriteria)],
        async () => {
            if (isLoadingLayout || isLoadingData) {
                return undefined;
            }
            let nodes = middleData?.nodes ?? [];

            // Apply filter criteria
            const { showIssuesOnly, showHealthyMonitors } = filterCriteria;

            if (showIssuesOnly) {
                // Filter nodes based on health - initialise with all nodes that are passed to the Workspace Network
                const filteredNodes = [...targetWorkspaces];

                getFilteredNodes(
                    filteredNodes,
                    nodes,
                    filteredNodes,
                    filterCriteria,
                    workspacesWithDependenciesByNodeId!
                );

                nodes = filteredNodes;
            }

            const edges = networkData?.edges.filter(
                (edge: any) =>
                    nodes?.some(({ data: { id } }) => id === edge.data.to) &&
                    nodes?.some(({ data: { id } }) => id === edge.data.from)
            );

            // Hide healthy monitors if not requested
            if (!showHealthyMonitors) {
                nodes = nodes?.map((node) => ({
                    ...node,
                    data: {
                        ...node.data,
                        tiles: node.data.tiles?.filter((tileMonitor) =>
                            isUnhealthy(tileMonitor.state)
                        )
                    }
                }));
            }

            return cytoscapeWebWorker(nodes || [], edges ?? [], middleData?.networkConfig);
        },
        {
            enabled: Boolean(middleData),
            keepPreviousData: true
        }
    );

    return {
        workspaceNetworkLayout: graphRender,
        workspaceNetworkData,
        workspacesWithDependencies: workspacesWithDependencies ?? [],
        workspacesWithDependenciesByNodeId,
        isLoading: isLoadingData || isLoadingLayout || isLoadingRender
    };
};
