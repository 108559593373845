import Text from '@/components/Text';
import Button from 'components/button/Button';
import Field from 'components/forms/field/Field';
import Form from 'components/forms/form/Form';
import { AutocompleteOption } from 'components/forms/jsonForms/autocompleteOptions';
import { AgentGroupWithPlatform, summarisePlatform } from 'components/hooks/useAgentGroups';
import { AgentWithRelatedObjects } from 'components/hooks/useAgents';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal, { ifNotOutside, ModalButtons } from 'components/Modal';
import { Pill } from 'components/pill/Pill';
import type { AgentGroup } from 'dynamo-wrapper';
import trackEvent from 'lib/analytics';
import { difference, upperFirst, xorBy } from 'lodash';
import { FC, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AGENT_GROUPS, Create as AgentGroupCreate, Update as AgentGroupUpdate } from 'services/AgentGroupService';
import { AddToGroups, AGENTS, RemoveFromGroups } from 'services/AgentService';

type AgentGroupFormData = Pick<AgentGroup, 'description' | 'name'> & { agents?: AutocompleteOption[] };

type defaultAgentGroupFormData = Partial<AgentGroupFormData> | undefined;

type AgentGroupAddModalProps = {
    onClose: () => void;
    onSave: () => void;
    group: AgentGroupWithPlatform | undefined;
    agents: AgentWithRelatedObjects[] | undefined;
    restrictedToPlatforms: string[] | undefined;
};

export const AgentGroupAddModal: FC<AgentGroupAddModalProps> = ({
    onClose,
    onSave,
    group,
    agents,
    restrictedToPlatforms
}) => {
    const queryClient = useQueryClient();
    const [newPlatform, setNewPlatform] = useState<string>(group?.platformSummary ?? 'Unknown');

    const { mutateAsync: saveAgentGroup } = useMutation(
        async (data: AgentGroupFormData) => {
            const { name, description = '' } = data;
            const agentIds = data.agents?.map((option) => option.value) ?? [];

            if (group?.id) {
                // editing existing group
                const groupId = group.id;
                await AgentGroupUpdate(groupId, name, description);
                trackEvent('Agent Group Updated', { name });

                const existingIds = group.agentOptions.map((option) => option.value);
                const addedAgents = difference(agentIds, existingIds);
                await Promise.allSettled(addedAgents.map((id) => AddToGroups(id, [groupId])));

                const removedAgents = difference(existingIds, agentIds);
                await Promise.allSettled(removedAgents.map((id) => RemoveFromGroups(id, [groupId])));
            } else {
                // add new group
                const newGroup = await AgentGroupCreate(name, description);
                trackEvent('Agent Group Created', { name });

                if (agentIds.length > 0) {
                    await Promise.allSettled(agentIds.map((id) => AddToGroups(id, [newGroup.id])));
                }
            }
        },
        {
            onSettled: async (result, error, data) => {
                const promises: Promise<void>[] = [];
                promises.push(queryClient.invalidateQueries([AGENT_GROUPS]));

                const isEdit = Boolean(group?.id);
                const modifiedAgents = isEdit
                    ? xorBy(group?.agentOptions, data.agents, (option) => option.value)
                    : data.agents ?? [];

                if (modifiedAgents.length > 0) {
                    promises.push(queryClient.invalidateQueries([AGENTS]));
                }

                await Promise.allSettled(promises);
                onSave();
            }
        }
    );

    const defaults: defaultAgentGroupFormData = group && {
        name: group.displayName,
        description: group.description,
        agents: group.agentOptions
    };

    const formattedAgents: AutocompleteOption[] = agents
        ? agents.map((agent) => ({
              label: `${agent.displayName ?? agent.name} (${upperFirst(agent.platform ?? 'unknown')} platform)`,
              value: agent.id,
              platform: agent.platform,
              isDisabled:
                  (restrictedToPlatforms?.length ?? 0) > 0 &&
                  (!agent.platform || !restrictedToPlatforms?.includes(agent.platform.toLowerCase()))
          }))
        : [];

    const onChanged = (data: AgentGroupFormData) => {
        const platformMap = new Map<string, string | undefined>(agents?.map((agent) => [agent.id, agent.platform]));
        const dataPlatform = summarisePlatform(data.agents?.map((agent) => platformMap.get(agent.value)) ?? []);

        if (dataPlatform !== newPlatform) {
            setNewPlatform(dataPlatform);
        }
    };

    return (
        <Modal
            title={group ? 'Edit agent group' : 'Add agent group'}
            close={ifNotOutside(onClose)}
            fullWidth
            maxWidth='max-w-3xl'
        >
            <div className='pr-1 tile-scroll-overflow'>
                <Form submit={saveAgentGroup} defaultValues={defaults} onChange={onChanged}>
                    {(isValid, isSubmitting) => (
                        <>
                            <div className='relative h-full px-8 mb-16'>
                                <Field.Input
                                    name='name'
                                    label='Name'
                                    title='Name'
                                    placeholder='Enter a name'
                                    validation={{ required: true, minLength: 2, maxLength: 128 }}
                                    data-testid='agentGroupNameInput'
                                />
                                <Field.Input
                                    name='description'
                                    label='Description'
                                    title='Description'
                                    placeholder='Enter a description'
                                    validation={{ maxLength: 128 }}
                                />
                                <Field.Input
                                    name='agents'
                                    label='Agent(s)'
                                    title='Agent(s)'
                                    placeholder='Select one or more agents'
                                    type='autocomplete'
                                    isMulti={true}
                                    options={formattedAgents}
                                    noOptionsMessage={() => 'No groups available'}
                                />
                                <div className='flex items-center mt-9'>
                                    <Text.Body>Platform:</Text.Body>
                                    <Pill variant='outline' data-testid='pill-platform' className='ml-2 capitalize'>
                                        {newPlatform}
                                    </Pill>
                                </div>
                                <Text.SmallBody className='mt-2 text-textSecondary'>
                                    The platform is inferred from the agents assigned to the group. You will not be able
                                    to select an agent if a data source is assigned to this agent group that does not
                                    support the agent's platform.
                                </Text.SmallBody>
                            </div>

                            <ModalButtons>
                                <Button type='button' onClick={() => onClose()} variant='tertiary'>
                                    Cancel
                                </Button>
                                <Button type='submit' disabled={isSubmitting || !isValid} data-testid='submitChanges'>
                                    {isSubmitting ? <LoadingSpinner size={18} /> : 'Save'}
                                </Button>
                            </ModalButtons>
                        </>
                    )}
                </Form>
            </div>
        </Modal>
    );
};
