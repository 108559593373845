import { FormattedStreamValue } from '@squaredup/data-streams';
import { formatDate, standardDatePatterns } from '@squaredup/utilities';
import Tooltip from 'components/tooltip/Tooltip';
import { parseISO } from 'date-fns';

export const DateCell: React.FC<FormattedStreamValue> = ({ value, formatted }) => {
    if (!value) {
        return null;
    }

    const formattedDate = isNaN(parseISO(formatted).valueOf())
        ? // Pre-formatted value is not an ISO date, so it must have been properly formatted already
          formatted
        : formatDate(value as string);

    const withUtcOffset = formatDate(value as string | number, {
        appendUtcOffset: true,
        pattern: standardDatePatterns.long
    });

    return (
        <Tooltip disabled={false} className='truncate' title={withUtcOffset}>
            {formattedDate}
        </Tooltip>
    );
};
