// If tags or types are provided, return all workspaces that have those tags or types
import { WorkspaceNotSetType } from '@squaredup/constants';
import { useWorkspaces } from 'queries/hooks/useWorkspaces';

// If no tags or types are provided, return all workspaces
export const useFilteredWorkspaces = (
    tags?: string[],
    types?: string[],
    options?: {
        enabled?: boolean;
    }
) => {
    const { data: workspaces, ...rest } = useWorkspaces(options);

    const data = workspaces?.filter(
        (workspace) =>
            (!tags || tags.length === 0 || workspace?.data?.properties?.tags?.find((tag) => tags.includes(tag))) &&
            (!types ||
                types.length === 0 ||
                types?.includes(workspace?.data?.properties?.type || '') ||
                (types?.includes(WorkspaceNotSetType) &&
                    (workspace?.data?.properties?.type == null || workspace?.data?.properties?.type === '')))
    );

    return {
        data,
        ...rest
    };
};
