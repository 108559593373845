import { HealthState, stateStrings } from '@squaredup/monitoring';
import { DashboardType } from 'dashboard-engine/types/Dashboard';
import { oneMinute } from 'queries/constants';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { ListDashboardHealthByIds } from 'services/HealthService';
import {
    useDashboardsForWorkspace,
    type QueryReturnType as UseDashboardsForWorkspaceReturnType
} from './useDashboardsForWorkspace';

type QueryReturnType = Awaited<ReturnType<typeof ListDashboardHealthByIds>>;
type QuerySelectReturnType<T extends { id: string }> = (T & { state: HealthState })[];

/**
 * useQuery returning dashboards with their respective health states. It uses the DashboardService List API
 * to get a full list of dashboards and then groups them (this is within useDashboardsForWorkspace). We then
 * leverage the `select` query option to merge the data.
 * @param workspaceId The workspaceId you'd like dashboards for
 * @param options The react-query options e.g. refreshInterval
 * @returns An array of dashboards with their health state
 */
export const useDashboardHealthStates = <T extends { id: string } = DashboardType>(
    workspaceId?: string | null,
    options?: Omit<
        UseQueryOptions<QueryReturnType, unknown, QuerySelectReturnType<T>, string[]>,
        'select' | 'enabled'
    > &
        Pick<UseQueryOptions<UseDashboardsForWorkspaceReturnType, unknown, T[], string[]>, 'select'>
) => {
    const { select, ...rest } = options || {};
    const { data: dashboards } = useDashboardsForWorkspace<T[]>(workspaceId, {
        select
    });
    const dashboardIds = dashboards?.map(({ id }) => id);

    return useQuery(dashboardQueryKeys.state(dashboardIds), async () => ListDashboardHealthByIds(dashboardIds || []), {
        enabled: Boolean(dashboards),
        staleTime: oneMinute,
        cacheTime: oneMinute,
        refetchInterval: oneMinute,
        keepPreviousData: true,
        select: (data) => {
            const dashboardHealthLookup = new Map(data.dashboardStates?.map((d) => [d.dashboardId, d.state]));

            return (
                dashboards?.map((dashboard) => ({
                    ...dashboard,
                    state: dashboardHealthLookup.get(dashboard.id) ?? stateStrings.unknown
                })) ?? []
            );
        },
        ...rest
    });
};
