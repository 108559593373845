import * as Popover from '@radix-ui/react-popover';
import clsx from 'clsx';
import { INDENT_PER_LEVEL, Instruction } from './DashboardTreeUtils';

interface DropIndicatorProps {
    instruction?: Instruction;
}

export const DashboardDropIndicator: React.FC<DropIndicatorProps> = ({ instruction }) => {
    let blocked = false;
    let tooltip = '';

    if (!instruction) {
        return <></>;
    }

    if (instruction.type === 'instruction-blocked') {
        blocked = true;
        tooltip = instruction.reason;
        instruction = instruction.desired;
    }

    if (instruction.type === 'add-folder' || instruction.type === 'remove-folder' || instruction.type === 'rename') {
        return <></>;
    }

    const classes = clsx(
        // Positioning
        'absolute border-current -right-1 pointer-events-none rounded-md z-10',
        // Colour
        blocked ? 'text-navBarBlockedIndicator' : 'text-navBarDropIndicator',
        // Base line styles
        ['reorder-above', 'reorder-below', 'reparent'].includes(instruction.type) && 'border-b-2',
        // Line above
        instruction.type === 'reorder-above' && '-top-1',
        // Line below
        instruction.type === 'reorder-below' && 'bottom-[1.5px]',
        // line below but indent changes
        instruction.type === 'reparent' && 'bottom-[1.5px]',
        // Box arouund
        instruction.type === 'make-child' && '-top-1 h-[25px]  border-2'
    );

    let left = INDENT_PER_LEVEL * ('desiredLevel' in instruction ? instruction.desiredLevel : instruction.currentLevel);

    if (['reorder-above', 'reorder-below', 'reparent'].includes(instruction.type)) {
        left += 8;
    }

    if (!tooltip) {
        return (
            <span
                style={{
                    left
                }}
                className={classes}
            />
        );
    }

    return (
        <Popover.Root open>
            <Popover.Anchor>
                <span
                    style={{
                        left
                    }}
                    className={classes}
                />
            </Popover.Anchor>
            <Popover.Portal>
                <Popover.Content
                    side='top'
                    sideOffset={16}
                    className='p-4 text-sm transition ease-out rounded-lg shadow-lg pointer-events-none select-none text-primaryButtonText bg-tooltipBackground'
                >
                    <Popover.Arrow fill='currentColor' className='text-tooltipBackground' />
                    {tooltip}
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};
