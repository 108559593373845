import { HealthState, stateStrings } from '@squaredup/monitoring';
import { sortBy } from 'lodash';
import { workspaceQueryKeys } from 'queries/queryKeys/workspaceKeys';
import { UseQueryOptions, useQuery } from 'react-query';
import { ListWorkspaceHealthByIds, TileState } from 'services/HealthService';
import { List } from 'services/WorkspaceService';
import { IterableElement } from 'type-fest';
import { useWorkspaces } from './useWorkspaces';
import { oneMinute } from 'queries/constants';

type QueryReturnType = Awaited<ReturnType<typeof ListWorkspaceHealthByIds>>;
type QuerySelectReturnType = (IterableElement<Awaited<ReturnType<typeof List>>> & { 
    state: HealthState, reasons?: Partial<TileState & { text: string }>[]
})[];

export const useWorkspacesWithHealthRollup = (
    options?: Omit<UseQueryOptions<QueryReturnType, unknown, QuerySelectReturnType, string[]>, | 'select'>
) => {
    const { data: workspaces } = useWorkspaces();
    const { enabled, ...queryOptions } = options ?? {};
    
    return useQuery(
        workspaceQueryKeys.stateRollups, 
        async () => ListWorkspaceHealthByIds(workspaces?.map((w) => w.id) ?? []),
        {
            enabled: (enabled === undefined || enabled) && workspaces && workspaces.length > 0,
            select: (data) => {
                const { workspaceStates } = data;

                const workspaceHealthsLookup = new Map(
                    workspaceStates?.map(({ workspaceId, state: workspaceState, unhealthyTileStates }) => [
                        workspaceId,
                        {
                            state: workspaceState,
                            reasons: unhealthyTileStates?.map(({ dashId, tileId, state, stateReason }) => ({
                                text: stateReason?.text,
                                dashId,
                                tileId,
                                state
                            }))
                        }
                    ])
                );
            
                return sortBy(workspaces ?? [], 'displayName')
                    .map((workspace) => {
                        return {
                            ...workspace,
                            state: (workspaceHealthsLookup.get(workspace.id)?.state) || stateStrings.unknown,
                            reasons: workspaceHealthsLookup.get(workspace.id)?.reasons
                        };
                    });
            },
            cacheTime: oneMinute,
            staleTime: oneMinute,
            ...queryOptions
        }
    );
};