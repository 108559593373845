import { Serialised } from '@squaredup/ids';
import type { Config, ProjectedPlugin } from 'dynamo-wrapper';
import trackEvent from 'lib/analytics';
import { upperFirst } from 'lodash';
import { useMutation } from 'react-query';
import { TestConfigAPI } from '../PluginTest';
import { PluginConfigFormData } from '../types';
import { usePlugin } from './usePlugin';

interface PluginConfigModalProps {
    config: (Serialised<Config<Omit<PluginConfigFormData, 'displayName'>>> & { json?: string }) | undefined;
    selectedPlugin: Serialised<ProjectedPlugin>;
    triggerReset: () => void;
}

export const PLUGIN = 'PLUGIN';

export const useInteractiveTester = ({ config, selectedPlugin, triggerReset }: PluginConfigModalProps) => {
    const { id: pluginId } = selectedPlugin ?? { id: '' };
    const { data: plugin } = usePlugin(pluginId);

    // Handles interactive testing for field groups with the testResultField
    // property set
    const handleInteractiveTest = async (testName: string, pluginConfig: Record<string, unknown>) => {
        const pluginConfigToUse = {
            ...pluginConfig,
            ___testName: testName // May be better to add extra arg to the whole test config route?
        } as any as PluginConfigFormData;
        if (plugin?.onPrem && !pluginConfigToUse.agentGroupId) {
            throw new Error('Please select an Agent Group before running test');
        }
        const testAPI = TestConfigAPI(plugin, config?.id);
        const result = await testAPI({ data: pluginConfigToUse });
        return result;
    };

    // Handles testing the form config
    const {
        data: testData,
        mutate: handleTest,
        isLoading: testLoading
    } = useMutation(TestConfigAPI(plugin, config?.id), {
        onSuccess: ({ testState }) => {
            trackEvent(`Plugin Config Tested / ${upperFirst(testState)}`, {
                type: selectedPlugin.displayName,
                status: testState
            });
            triggerReset();
        }
    });

    return {
        testLoading,
        handleInteractiveTest,
        handleTest,
        testData
    };
};
