import { Tabs } from 'ui/Tabs';
import { useDatasetContext } from '../contexts/DatasetContext';
import { AddDatasetButton } from './AddDatasetButton';

export const DatasetEditor = () => {
    const { datasetTabs, activeDataset, setActiveDataset, setActivePreviewTab } = useDatasetContext();

    return (
        <Tabs
            tabs={datasetTabs}
            tabsID='datasetTabs'
            className='w-full h-full'
            tabListClassName='w-auto'
            selectedIndex={activeDataset}
            onSelect={(index) => {
                setActiveDataset(index);
                setActivePreviewTab(index + 1);
            }}
            additionalControl={
                <div className='flex px-4 space-x-4'>
                    <AddDatasetButton />
                </div>
            }
        />
    );
};
