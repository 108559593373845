import { Edge } from 'reactflow';
import { FLOATING_EDGE } from '../../components/edges/FloatingEdge';
import { EntityTypes } from '@squaredup/constants';

export enum EdgeTypes {
    WORKSPACE = EntityTypes.WORKSPACE,
    DASHBOARD = EntityTypes.DASHBOARD,
    MONITOR = EntityTypes.MONITOR,
    OBJECT = 'object'
};

/**
 * The passed contentType is the type of the source node, therefore the type of edge can be inferred
 * e.g. a contentType of 'dashboard' is a part of a workspace and therefore we set the type as 'workspace'
 */

export const edge = (
    source: string, 
    target: string, 
    properties?: Record<string, unknown>,
    label?: string
): Edge => {
    
    return {
        id: `${source}-${target}`,
        source,
        target,
        label,
        type: FLOATING_EDGE,
        data: {
            edgeType: properties?.contentType ?? EdgeTypes.OBJECT
        }
    };
};
