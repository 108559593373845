import { cn } from '@/lib/cn';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as SelectPrimitive from '@radix-ui/react-select';
import { buttonVariants } from 'components/button';
import { forwardRef } from 'react';

export const SelectTrigger = forwardRef<
    React.ElementRef<typeof SelectPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
    <SelectPrimitive.Trigger
        ref={ref}
        className={cn(buttonVariants({ variant: 'secondary' }), 'px-input py-smallButton')}
        {...props}
    >
        <span className='flex items-center space-x-2'>
            {children}
            <SelectPrimitive.Icon asChild>
                <FontAwesomeIcon icon={faChevronDown} />
            </SelectPrimitive.Icon>
        </span>
    </SelectPrimitive.Trigger>
));
